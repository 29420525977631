import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_COMPANYS,
  ADD_NEW_COMPANY,
  DELETE_COMPANY,
  UPDATE_COMPANY,
  ADD_COMPANY_USER,
  SEARCH_EMAIL,
  GET_COMPANY_TYPE,
} from "./actionTypes"
import {
  getCompanysSuccess,
  getCompanysFail,
  addCompanyFail,
  addCompanySuccess,
  updateCompanySuccess,
  updateCompanyFail,
  deleteCompanySuccess,
  deleteCompanyFail,
  addCompanyUserSuccess,
  addCompanyUserFail,
  searchEmailSuccess,
  searchEmailFail,
  getCompanyTypeSuccess,
  getCompanyTypeFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  postJson,
  isUserAuthenticated,
  postUrlEncode,
} from "helpers/fakebackend_helper"

function* fetchCompanys({ payload: { request, history } }) {
  if (isUserAuthenticated()) {
    try {
      const response = yield call(
        postJson,
        {
          token: request.token,
          start: request.page,
          length: 10,
        },
        "/Admin/CompanyWebService/get_company_list"
      )
      if (response.responseResultType === "SUCCESS") {
        yield put(getCompanysSuccess(response))
      } else {
        if (response.failureMessages.message[0].failureCode === "ERR_009") {
          history.push("/logout")
        } else {
          yield put(
            getCompanysFail(response.failureMessages.message[0].failureMessage)
          )
        }
      }
    } catch (error) {
      yield put(getCompanysFail(error))
    }
  } else history.push("/logout")
}
function* onAddNewCompany({ payload: company }) {
  if (isUserAuthenticated()) {
    try {
      const response = yield call(
        postJson,
        company,
        "/Admin/CompanyWebService/register_company"
      )
      if (response.responseResultType === "SUCCESS") {
        yield put(addCompanySuccess(response))
      } else {
        if (response.failureMessages.message[0].failureCode === "ERR_009") {
          history.push("/logout")
        } else {
          yield put(
            addCompanyFail(response.failureMessages.message[0].failureMessage)
          )
        }
      }
    } catch (error) {
      yield put(addCompanyFail(error))
    }
  } else history.push("/logout")
}

function* onUpdateCompany({ payload: company }) {
  try {
    const response = yield call(
      postJson,
      company,
      "/Admin/CompanyWebService/update_company"
    )
    if (response.responseResultType === "SUCCESS") {
      yield put(updateCompanySuccess(response))
    } else {
      if (response.failureMessages.message[0].failureCode === "ERR_009") {
        history.push("/logout")
      } else {
        yield put(
          updateCompanyFail(response.failureMessages.message[0].failureMessage)
        )
      }
    }
  } catch (error) {
    yield put(updateCompanyFail(error))
  }
}

function* onDeleteCompany({ payload: company }) {
  try {
    const response = yield call(
      postJson,
      company,
      "/Admin/CompanyWebService/remove_company"
    )
    yield put(deleteCompanySuccess(response))
  } catch (error) {
    yield put(deleteCompanyFail(error))
  }
}

function* onAddCompanyUser({ payload: company }) {
  if (isUserAuthenticated()) {
    try {
      const response = yield call(
        postJson,
        company,
        "/Admin/CompanyWebService/register_company_admin"
      )
      if (response.responseResultType === "SUCCESS") {
        yield put(addCompanyUserSuccess(response))
      } else {
        if (response.failureMessages.message[0].failureCode === "ERR_009") {
          history.push("/logout")
        } else {
          // console.log(response.failureMessages.message[0].failureMessage)
          yield put(
            addCompanyUserFail(
              response.failureMessages.message[0].failureMessage
            )
          )
        }
      }
    } catch (error) {
      yield put(addCompanyUserFail(error))
    }
  } else history.push("/logout")
}
function* onSearchEmail({ payload: { request, history } }) {
  if (isUserAuthenticated()) {
    try {
      const response = yield call(
        postJson,
        { token: request.token, email: request.email },
        "/Admin/CompanyWebService/search_email"
      )
      if (response.responseResultType === "SUCCESS") {
        yield put(searchEmailSuccess(response))
      } else {
        if (response.failureMessages.message[0].failureCode === "ERR_009") {
          history.push("/logout")
        } else {
          console.log(response.failureMessages.message[0].failureMessage)
          yield put(
            searchEmailFail(
              response.failureMessages.message[0]
                ? response.failureMessages.message[0].failureMessage
                : "Алдаа гарлаа"
            )
          )
        }
      }
    } catch (error) {
      yield put(searchEmailFail(error))
    }
  } else history.push("/logout")
}

/* CompanyType   */
function* fetchCompanyType({ payload }) {
  // alert(1)
  try {
    // alert(2)
    const response = yield call(
      postUrlEncode,
      {
        token: payload.token,
        name: "RefCompanyType",
      },
      "/Admin/ReferenceWebService/get_data"
    )
    // alert(2)
    yield put(getCompanyTypeSuccess(response.datas))
  } catch (error) {
    yield put(getCompanyTypeFail(error))
  }
}

function* adminCompanySaga() {
  yield takeEvery(GET_COMPANYS, fetchCompanys)
  yield takeEvery(ADD_NEW_COMPANY, onAddNewCompany)
  yield takeEvery(UPDATE_COMPANY, onUpdateCompany)
  yield takeEvery(DELETE_COMPANY, onDeleteCompany)
  yield takeEvery(ADD_COMPANY_USER, onAddCompanyUser)
  yield takeEvery(SEARCH_EMAIL, onSearchEmail)
  yield takeEvery(GET_COMPANY_TYPE, fetchCompanyType)
}

export default adminCompanySaga
