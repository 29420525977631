import {
  GET_COMPANYS,
  GET_COMPANYS_FAIL,
  GET_COMPANYS_SUCCESS,
  ADD_NEW_COMPANY,
  ADD_COMPANY_SUCCESS,
  ADD_COMPANY_FAIL,
  UPDATE_COMPANY,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAIL,
  DELETE_COMPANY,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_FAIL,
  ADD_COMPANY_USER,
  ADD_COMPANY_USER_SUCCESS,
  ADD_COMPANY_USER_FAIL,
  SEARCH_EMAIL,
  SEARCH_EMAIL_SUCCESS,
  SEARCH_EMAIL_FAIL,
  GET_COMPANY_TYPE,
  GET_COMPANY_TYPE_SUCCESS,
  GET_COMPANY_TYPE_FAIL,
} from "./actionTypes"

export const getCompanys = (token, page, totalPage, history) => ({
  type: GET_COMPANYS,
  payload: { request: { token, page, totalPage }, history },
})

export const getCompanysSuccess = response => ({
  type: GET_COMPANYS_SUCCESS,
  companys: response.data,
  totalPage: response.recordsTotal,
})

export const getCompanysFail = error => ({
  type: GET_COMPANYS_FAIL,
  error: error,
})

export const addNewCompany = company => ({
  type: ADD_NEW_COMPANY,
  payload: company,
})

export const addCompanySuccess = company => ({
  type: ADD_COMPANY_SUCCESS,
  payload: company,
})

export const addCompanyFail = error => ({
  type: ADD_COMPANY_FAIL,
  error: error,
})

export const updateCompany = company => ({
  type: UPDATE_COMPANY,
  payload: company,
})

export const updateCompanySuccess = company => ({
  type: UPDATE_COMPANY_SUCCESS,
  payload: company,
})

export const updateCompanyFail = error => ({
  type: UPDATE_COMPANY_FAIL,
  error: error,
})

export const deleteCompany = company => ({
  type: DELETE_COMPANY,
  payload: company,
})

export const deleteCompanySuccess = company => ({
  type: DELETE_COMPANY_SUCCESS,
  payload: company,
})

export const deleteCompanyFail = error => ({
  type: DELETE_COMPANY_FAIL,
  error: error,
})

export const addCompanyUser = company => ({
  type: ADD_COMPANY_USER,
  payload: company,
})

export const addCompanyUserSuccess = company => ({
  type: ADD_COMPANY_USER_SUCCESS,
  payload: company,
})

export const addCompanyUserFail = error => ({
  type: ADD_COMPANY_USER_FAIL,
  error: error,
})
export const searchEmail = (token, email, history) => ({
  type: SEARCH_EMAIL,
  payload: { request: { token, email }, history },
})

export const searchEmailSuccess = company => ({
  type: SEARCH_EMAIL_SUCCESS,
  payload: company,
})

export const searchEmailFail = error => ({
  type: SEARCH_EMAIL_FAIL,
  error: error,
})
// Компаний төрөл дуудах лавлах
/*--------------------------------------------------*/
export const getCompanyType = token => ({
  type: GET_COMPANY_TYPE,
  payload: { token },
})

export const getCompanyTypeSuccess = response => ({
  type: GET_COMPANY_TYPE_SUCCESS,
  companyType: response,
})

export const getCompanyTypeFail = error => ({
  type: GET_COMPANY_TYPE_FAIL,
  payload: error,
})
/*--------------------------------------------------*/
