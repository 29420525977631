import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import { connect } from "react-redux"
import { isEmpty, size } from "lodash"
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import * as moment from "moment"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { AvForm, AvField } from "availity-reactstrap-validation"

import {
  getCompanyShares,
  addNewShares,
  updateShares,
  deleteShares,
} from "store/actions"

//import css from "./style.module.css"
class MeetingCustomer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      shares: [],
      selectedCustomer: [],
      token: "",
      isAccredit: false,
      CompanyMemberColumns: [
        {
          text: "id",
          dataField: "id",
          sort: true,
          hidden: true,
          formatter: (cellContent, user) => <>{row.id}</>,
        },
        {
          dataField: "firstname",
          text: "Овог / Нэр",
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <p className="mb-1">{row.firstname}</p>
              <p className="mb-0">{row.lastname}</p>
            </>
          ),
        },
        {
          text: "Утас / И-Мэйл",
          dataField: "phone",
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <p className="mb-1">{row.phone}</p>
              <p className="mb-0">{row.email}</p>
            </>
          ),
        },
        {
          dataField: "register",
          text: "Регистр",
          sort: true,
        },
        {
          dataField: "quantity",
          text: "Хувьцааны тоо",
          sort: true,
        },
        {
          dataField: "isAccredit",
          text: "Итгэмжлэлтэй эсэх",
          sort: true,
          formatter: (cellContent, row) => (
            <>
              {row.isAccredit ? (
                <Badge color="success" className="bg-success font-size-12">
                  Тийм
                </Badge>
              ) : (
                <Badge color="danger" className="bg-danger font-size-12">
                  Үгүй
                </Badge>
              )}
            </>
          ),
        },
      ],
    }
  }

  componentDidMount() {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      const { shares, onGetShares } = this.props
      const token = obj.token
      if (shares && !shares.length) {
        onGetShares(
          obj.token,
          this.props.page,
          this.props.totalPage,
          this.props.history
        )
      }
      this.setState({ shares })
      this.setState({ token })
    }
  }

  // eslint-disable-next-line no-unused-vars

  /* Insert,Update Delete data */

  handleDeleteCustomer = customer => {
    const { onDeleteCustomer } = this.props
    onDeleteCustomer(customer)
  }

  // Өмнөх таб-руу буцах
  prevPage = () => {
    this.props.toggle(2)
  }
  nextPage = () => {
    this.props.toggle(4)
  }
  // Форм шалгах
  handleValidCustomerSubmit = () => {
    this.props.saveForm(this.state.selectedCustomer)
    this.nextPage()
  }

  render() {
    const { shares } = this.props
    const { isEdit } = this.state

    //pagination customization
    const pageOptions = {
      sizePerPage: 10,
      totalSize: this.props.totalPage, // replace later with size(companys),
      custom: true,
    }

    const defaultSorted = [
      {
        dataField: "id",
        order: "desc",
      },
    ]

    const { SearchBar } = Search

    const selectRow = {
      mode: "checkbox",
      onSelect: (row, isSelect, rowIndex, e) => {
        if (isSelect) {
          const selectedCustomer = {
            customerId: row.id,
          }
          this.setState({
            selectedCustomer: [
              ...this.state.selectedCustomer,
              selectedCustomer,
            ],
          })
        } else {
          const selectedCustomer = this.state.selectedCustomer.filter(
            (s, sidx) => s.customerId !== row.id
          )
          this.setState({ selectedCustomer: selectedCustomer })
        }
        console.log(this.state.selectedCustomer)
      },
    }
    return (
      <React.Fragment>
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  keyField="id"
                  columns={this.state.CompanyMemberColumns}
                  data={shares}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      columns={this.state.CompanyMemberColumns}
                      data={shares}
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row>
                            <Col sm="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar {...toolkitProps.searchProps} />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <div className="table-responsive">
                            <BootstrapTable
                              keyField={"id"}
                              responsive
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              selectRow={selectRow}
                              classes={"table align-middle table-nowrap"}
                              headerWrapperClasses={"thead-light"}
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                            />
                          </div>
                          <div className="pagination pagination-rounded justify-content-end mb-2">
                            <PaginationListStandalone {...paginationProps} />
                          </div>
                          <div className="customButton clearfix">
                            <Button
                              color="primary"
                              className="mt-3 mt-lg-0"
                              onClick={this.prevPage}
                            >
                              Өмнөх
                            </Button>
                            <Button
                              color="primary"
                              // type="submit"
                              onClick={this.handleValidCustomerSubmit}
                              className="mt-3 mt-lg-0"
                            >
                              Дараах
                            </Button>
                          </div>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}
MeetingCustomer.propTypes = {
  saveForm: PropTypes.func,
}
MeetingCustomer.propTypes = {
  shares: PropTypes.array,
  page: PropTypes.number,
  stokeType: PropTypes.array,
  accreditInfo: PropTypes.array,
  totalPage: PropTypes.number,
  onGetCustomers: PropTypes.func,
  onAddNewCustomer: PropTypes.func,
  onDeleteCustomer: PropTypes.func,
  onUpdateCustomer: PropTypes.func,
  history: PropTypes.object,
}

// const mapStateToProps = ({ ecommerce }) => ({
//   companys: ecommerce.companys,
// })
const mapStateToProps = state => {
  return {
    shares: state.CompanyShareRed.shares,
    page: state.CompanyShareRed.page,
    totalPage: state.CompanyShareRed.totalPage,
    stokeType: state.CompanyMemberRed.stokeType,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetShares: (token, page, totalPage, history) =>
    dispatch(getCompanyShares(token, page, totalPage, history)),
  onAddNewCustomer: customer => dispatch(addNewShares(customer)),
  onUpdateCustomer: customer => dispatch(updateShares(customer)),
  onDeleteCustomer: customer => dispatch(deleteShares(customer)),
  //   onGetStokeType: token => dispatch(getStokeType(token)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MeetingCustomer)
