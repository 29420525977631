import React, { Component } from "react"
import { map, isEmpty, size } from "lodash"
import { Button, Card, CardBody, Col, Input, Label, Row } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { getComMeetingDocumentType } from "store/actions"
import axios from "axios"
class MeetingDocument extends Component {
  constructor(props) {
    super(props)
    this.state = {
      documents: [
        {
          documentTypeId: null,
          fileName: "",
          description: "",
          filePath: "",
          sysName: "",
          extension: "",
        },
      ],
      fileResponse: null,
    }
  }

  componentDidMount() {
    const { onGetDocumentType } = this.props
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      onGetDocumentType(obj.token)
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const documents = this.props.documents
    if (!isEmpty(documents) && size(prevProps.documents) !== size(documents)) {
      this.setState({ documents: documents })
    }
  }

  handleAddRow = () => {
    const item = {
      documentTypeId: "",
      fileName: "",
      description: "",
      filePath: "",
      sysName: "",
      extension: "",
      size: "",
    }
    this.setState({
      documents: [...this.state.documents, item],
    })
  }

  handleRemoveRow = (e, idx) => {
    this.setState({
      documents: this.state.documents.filter((s, sidx) => idx !== sidx),
    })
  }
  handleDocumentTypeChange = idx => evt => {
    const documents = this.state.documents.map((document, sidx) => {
      if (idx !== sidx) return document
      return { ...document, documentTypeId: evt.target.value }
    })

    this.setState({ documents: documents })
  }
  handleFileNameChange = idx => evt => {
    const documents = this.state.documents.map((document, sidx) => {
      if (idx !== sidx) return document
      return { ...document, fileName: evt.target.value }
    })

    this.setState({ documents: documents })
  }

  handleDescriptionChange = idx => evt => {
    const documents = this.state.documents.map((document, sidx) => {
      if (idx !== sidx) return document
      return { ...document, description: evt.target.value }
    })

    this.setState({ documents: documents })
  }

  // Өмнөх таб-руу буцах
  prevPage = () => {
    this.props.toggle(3)
  }
  // Дараагийн таб-руу орох
  nextPage = () => {
    this.props.toggle(5)
  }
  // Форм шалгах
  handleValidDocumentSubmit = (e, values) => {
    this.props.saveForm(this.state.documents)
    this.nextPage()
  }

  fileChangedHandler = (event, idx) => {
    let formData = new FormData()

    formData.append("file", event.target.files[0])
    const response = this.uploadSave(formData, idx)
  }

  uploadSave(formData, idx) {
    const requestOptions = {
      method: "POST",
      body: formData,
    }
    fetch("http://103.48.116.95:8081/api/upload", requestOptions)
      .then(response => response.json())
      .then(data => {
        const documents = this.state.documents.map((document, sidx) => {
          if (idx !== sidx) return document
          return {
            ...document,
            extension: data.response.mimetype,
            filePath: data.response.filename,
            sysName: data.response.originalname,
            size: data.response.size,
          }
        })

        this.setState({ documents: documents })
      })
      .catch(error => {
        // error response
      })
  }

  render() {
    const { documentType } = this.props

    return (
      <React.Fragment>
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <Button
                  onClick={this.handleAddRow}
                  color="success"
                  className="mt-3 mt-lg-0"
                >
                  Нэмэх{" "}
                </Button>{" "}
                <AvForm
                  className="repeater mt-3"
                  encType="multipart/form-data"
                  onValidSubmit={this.handleValidDocumentSubmit}
                >
                  <table style={{ width: "100%" }}>
                    <tbody>
                      {this.state.documents.map((row, idx) => (
                        <tr id={"addr" + idx} key={"document-" + idx}>
                          <td>
                            <div data-repeater-list="group-a">
                              <Row data-repeater-item>
                                <Col lg="2" className="mb-3">
                                  <AvField
                                    id={"documentTypeId" + idx}
                                    name={"[" + idx + "]documentTypeId"}
                                    label={idx + 1 + ". Төрөл "}
                                    type="select"
                                    errorMessage="Төрөл сонгоно уу"
                                    onChange={this.handleDocumentTypeChange(
                                      idx
                                    )}
                                    validate={{
                                      required: { value: true },
                                    }}
                                    className="form-select"
                                    value={row ? row.documentTypeId : ""}
                                  >
                                    <option key="documentType" value="">
                                      -Сонгох-
                                    </option>
                                    {map(documentType, (type, key) => (
                                      <option
                                        key={"documentType" + key}
                                        value={type.value}
                                      >
                                        {type.label}
                                      </option>
                                    ))}
                                  </AvField>
                                </Col>

                                <Col lg="3" className="mb-3">
                                  <AvField
                                    id={"fileName" + idx}
                                    name={"[" + idx + "]fileName"}
                                    label="Баримт бичгийн нэр"
                                    type="text"
                                    errorMessage="Талбар хоосон байна!!"
                                    onChange={this.handleFileNameChange(idx)}
                                    value={row ? row.fileName : ""}
                                    validate={{
                                      required: { value: true },
                                    }}
                                  />
                                </Col>

                                <Col lg="3" className="mb-3">
                                  <AvField
                                    name={"[" + idx + "]description"}
                                    label="Товч тайлбар"
                                    type="textarea"
                                    errorMessage="Талбар хоосон байна!!"
                                    onChange={this.handleDescriptionChange(idx)}
                                    value={row ? row.description : ""}
                                    validate={{
                                      required: { value: true },
                                    }}
                                  />
                                </Col>
                                <Col lg="3" className="mb-3">
                                  <AvField
                                    name={"[" + idx + "]file"}
                                    label="Файл"
                                    type="file"
                                    onChange={e =>
                                      this.fileChangedHandler(e, idx)
                                    }
                                    errorMessage="Талбар хоосон байна!!"
                                    className="form-control"
                                    validate={{
                                      required: { value: true },
                                    }}
                                  />
                                  <AvField
                                    id={"extension" + idx}
                                    name={"[" + idx + "]extension"}
                                    value={row ? row.extension : ""}
                                    type="hidden"
                                  />
                                  <AvField
                                    id={"sysName" + idx}
                                    name={"[" + idx + "]sysName"}
                                    value={row ? row.sysName : ""}
                                    type="hidden"
                                  />
                                  <AvField
                                    id={"filePath" + idx}
                                    name={"[" + idx + "]filePath"}
                                    value={row ? row.filePath : ""}
                                    type="hidden"
                                  />
                                  <AvField
                                    id={"size" + idx}
                                    name={"[" + idx + "]size"}
                                    value={row ? row.size : ""}
                                    type="hidden"
                                  />
                                </Col>

                                <Col lg="1" className="align-self-center">
                                  <div className="d-grid">
                                    <Button
                                      onClick={e =>
                                        this.handleRemoveRow(e, idx)
                                      }
                                      color="danger"
                                      style={{ width: "100%" }}
                                      block
                                    >
                                      {" "}
                                      Устгах
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="customButton clearfix">
                    <Button
                      color="primary"
                      className="mt-3 mt-lg-0"
                      onClick={this.prevPage}
                    >
                      Өмнөх
                    </Button>
                    <Button
                      color="primary"
                      type="submit"
                      // onClick={this.nextPage}
                      className="mt-3 mt-lg-0"
                    >
                      Дараах
                    </Button>
                  </div>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}
MeetingDocument.propTypes = {
  documents: PropTypes.array,
  documentType: PropTypes.array,
  onGetDocumentType: PropTypes.func,
  saveForm: PropTypes.func,
}

const mapStateToProps = state => {
  return {
    documentType: state.CompanyMeet.documentType,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetDocumentType: token => dispatch(getComMeetingDocumentType(token)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MeetingDocument))
