import { call, put, takeEvery } from "redux-saga/effects"

// Ecommerce Redux States
import {
  GET_MEMBERS,
  ADD_NEW_MEMBER,
  DELETE_MEMBER,
  UPDATE_MEMBER,
  GET_MEMBER_DTL,
  GET_MEET_STORY_DTL,
  GET_STOKE_TYPE,
  GET_EXCELS,
} from "./actionTypes"
import {
  getMembersFail,
  getMembersSuccess,
  addMemberFail,
  addMemberSuccess,
  updateMemberSuccess,
  updateMemberFail,
  deleteMemberSuccess,
  deleteMemberFail,
  getMemberDtlSuccess,
  getMemberDtlFail,
  getMeetStorySuccess,
  getMeetStoryFail,
  getStokeTypeSuccess,
  getStokeTypeFail,
  getExcelsSuccess,
  getExcelsFail,
} from "./actions"

//Include Both Helper File with needed methods
import { postJson, postUrlEncode } from "helpers/fakebackend_helper"

function* fetchMembers({ payload: { request, history } }) {
  try {
    const response = yield call(
      postJson,
      {
        token: request.token,
        start: request.page,
        length: 10,
      },
      "/Admin/CompanyWebService/get_customer_list"
    )
    if (response.responseResultType === "SUCCESS") {
      yield put(getMembersSuccess(response))
    } else {
      if (response.failureMessages.message[0].failureCode === "ERR_009") {
        history.push("/logout")
      }
      yield put(
        getMembersFail(response.failureMessages.message[0].failureMessage)
      )
    }
  } catch (error) {
    yield put(getMembersFail(error))
  }
}

function* onAddNewMember({ payload: customer }) {
  try {
    const response = yield call(
      postJson,
      customer,
      "/Admin/CompanyWebService/register_customer"
    )
    // console.log(response );
    // console.log(response.responseResultType === "SUCCESS" );
    if (response.responseResultType === "SUCCESS") {
      yield put(addMemberSuccess(response))
      // alert(123)
    } else {
      if (response.failureMessages.message[0].failureCode === "ERR_009") {
        // alert(2)
        history.push("/logout")
      } else {
        // alert(3)
        yield put(
          addMemberFail(response.failureMessages.message[0].failureMessage)
        )
      }
    }
  } catch (error) {
    // alert(4)
    yield put(addMemberFail(error))
  }
}

function* onUpdateMember({ payload: customer }) {
  try {
    const response = yield call(
      postJson,
      customer,
      "/Admin/CompanyWebService/update_customer"
    )
    if (response.responseResultType === "SUCCESS") {
      yield put(updateMemberSuccess(response))
    } else {
      if (response.failureMessages.message[0].failureCode === "ERR_009") {
        history.push("/logout")
      } else {
        yield put(
          updateMemberFail(response.failureMessages.message[0].failureMessage)
        )
      }
    }
  } catch (error) {
    yield put(updateMemberFail(error))
  }
}

function* onDeleteMember({ payload: customer }) {
  try {
    const response = yield call(
      postJson,
      customer,
      "/Admin/CompanyWebService/remove_customer"
    )
    yield put(deleteMemberSuccess(response))
  } catch (error) {
    yield put(deleteMemberFail(error))
  }
}

function* fetchMemberDetail({ payload: { request, history } }) {
  try {
    const response = yield call(
      postJson,
      {
        token: request.token,
        customerId: request.customerId,
      },
      "/Admin/CompanyWebService/get_customer_introduction"
    )
    console.log("response", response)
    if (response.responseResultType === "SUCCESS") {
      yield put(getMemberDtlSuccess(response))
    } else {
      if (response.failureMessages.message[0].failureCode === "ERR_009") {
        history.push("/logout")
      }
      yield put(
        getMemberDtlFail(response.failureMessages.message[0].failureMessage)
      )
    }
  } catch (error) {
    yield put(getMemberDtlFail(error))
  }
}

function* fetchMeetStory({ payload }) {
  try {
    const response = yield call(
      postJson,
      {
        token: payload.token,
        customerId: payload.customerId,
      },
      "/Admin/CompanyWebService/get_meeting_by_customer"
    )
    if (response.responseResultType === "SUCCESS") {
      // console.log(response)
      yield put(getMeetStorySuccess(response.meetings))
    } else {
      if (response.failureMessages.message[0].failureCode === "ERR_009") {
        history.push("/logout")
      }
      yield put(
        getMeetStoryFail(response.failureMessages.message[0].failureMessage)
      )
    }
  } catch (error) {
    yield put(getMeetStoryFail(error))
  }
}

function* fetchStokeType({ payload }) {
  try {
    const response = yield call(
      postUrlEncode,
      {
        token: payload.token,
        name: "RefStockType",
      },
      "/Admin/ReferenceWebService/get_data_value"
    )
    yield put(getStokeTypeSuccess(response.datas))
  } catch (error) {
    yield put(getStokeTypeFail(error))
  }
}

function* onExcelFile({ payload: data }) {
  // console.log(customer);
  try {
    const response = yield call(
      postJson,
      data,
      "/Admin/CompanyWebService/create_customer_list"
    )
    yield put(getExcelsSuccess(response))
  } catch (error) {
    yield put(getExcelsFail(error))
  }
}

function* memberSaga() {
  yield takeEvery(GET_MEMBERS, fetchMembers)
  yield takeEvery(ADD_NEW_MEMBER, onAddNewMember)
  yield takeEvery(UPDATE_MEMBER, onUpdateMember)
  yield takeEvery(DELETE_MEMBER, onDeleteMember)
  yield takeEvery(GET_MEMBER_DTL, fetchMemberDetail)
  yield takeEvery(GET_MEET_STORY_DTL, fetchMeetStory)
  yield takeEvery(GET_STOKE_TYPE, fetchStokeType)
  yield takeEvery(GET_EXCELS, onExcelFile)
}

export default memberSaga
