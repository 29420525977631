/* MEMBERS */
export const GET_MEMBERS = "GET_MEMBERS"
export const GET_MEMBERS_SUCCESS = "GET_MEMBERS_SUCCESS"
export const GET_MEMBERS_FAIL = "GET_MEMBERS_FAIL"

export const ADD_NEW_MEMBER = "ADD_NEW_MEMBER"
export const ADD_MEMBER_SUCCESS = "ADD_MEMBER_SUCCESS"
export const ADD_MEMBER_FAIL = "ADD_MEMBER_FAIL"

export const UPDATE_MEMBER = "UPDATE_MEMBER"
export const UPDATE_MEMBER_SUCCESS = "UPDATE_MEMBER_SUCCESS"
export const UPDATE_MEMBER_FAIL = "UPDATE_MEMBER_FAIL"

export const DELETE_MEMBER = "DELETE_MEMBER"
export const DELETE_MEMBER_SUCCESS = "DELETE_MEMBER_SUCCESS"
export const DELETE_MEMBER_FAIL = "DELETE_MEMBER_FAIL"

export const GET_MEMBER_DTL = "GET_MEMBER_DTL"
export const GET_MEMBER_DTL_SUCCESS = "GET_MEMBER_DTL_SUCCESS"
export const GET_MEMBER_DTL_FAIL = "GET_MEMBER_DTL_FAIL"

export const GET_MEET_STORY_DTL = "GET_MEET_STORY_DTL"
export const GET_MEET_STORY_DTL_SUCCESS = "GET_MEET_STORY_DTL_SUCCESS"
export const GET_MEET_STORY_DTL_FAIL = "GET_MEET_STORY_DTL_FAIL"

export const GET_STOKE_TYPE = "GET_STOKE_TYPE"
export const GET_STOKE_TYPE_SUCCESS = "GET_STOKE_TYPE_SUCCESS"
export const GET_STOKE_TYPE_FAIL = "GET_STOKE_TYPE_FAIL"

export const GET_EXCELS = "GET_EXCELS"
export const GET_EXCELS_SUCCESS = "GET_EXCELS_SUCCESS"
export const GET_EXCELS_FAIL = "GET_EXCELS_FAIL"
