import React, { Component } from "react"
import { map } from "lodash"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { isEmpty, size } from "lodash"
import {
  setComVoteStart,
  setComVoteFinish,
  addComNewAgenda,
  updateComAgenda,
  deleteComAgenda,
} from "store/actions"
import MeetingAgenda from "../CompanyMeetingCreate/meeting-agenda"
import { Badge, Button, Modal, ModalBody, ModalHeader, Table } from "reactstrap"
class CompanyMeetingAgenda extends Component {
  constructor(props) {
    super(props)
    this.state = {
      token: this.props.token,
      agendas: [],
      choiseList: [],
      checkedCount: 0,
      votes: [],
      vote: {},
      agendaForm: {
        token: "",
        meetingId: "",
        content: "",
        agendaTypeId: 0,
        agendaTypeName: "",
        voteTypeId: 0,
        voteMethodId: 0,
        baseVoteTime: 0,
        voteStartTime: "",
        voteFinishTime: "",
        isAdvanceVote: false,
        isMeetingVote: false,
        isExternalVote: false,
        isVote: false,
        choiseCount: 0,
        selectedTypeId: 0,
        choiseList: [
          {
            choise: "",
          },
        ],
      },
    }
    this.handleUpdateAgendaClick = this.handleUpdateAgendaClick.bind(this)
    this.toggle = this.toggle.bind(this)
  }
  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { agendas } = this.props
    if (!isEmpty(agendas) && size(prevProps.agendas) !== size(agendas)) {
      this.setState({ agendas: {}, isEdit: false })
    }
  }
  handleAddAgendaClick = arg => {
    this.setState({ selectedAgenda: arg, isEdit: false, agendas: [] })
    this.toggle()
  }

  handleUpdateAgendaClick = arg => {
    const agenda = arg
    const item = {
      id: agenda.id,
      content: agenda.content,
      agendaTypeId: agenda.agendaTypeId,
      agendaTypeName: agenda.agendaTypeName,
      voteTypeId: agenda.voteTypeId,
      voteMethodId: agenda.voteMethodId,
      baseVoteTime: agenda.baseVoteTime,
      voteStartTime: agenda.voteStartTime,
      voteFinishTime: agenda.voteFinishTime,
      isAdvanceVote: agenda.isAdvanceVote,
      isMeetingVote: agenda.isMeetingVote,
      isExternalVote: agenda.isExternalVote,
      isVote: agenda.isVote,
      choiseCount: agenda.choiseCount,
      choiseList: agenda.choiseList,
    }
    this.setState({
      agendas: [item],
      isEdit: true,
    })
    this.toggle()
  }
  handleDeleteCustomer = customer => {
    const { onDeleteCustomer } = this.props
    onDeleteCustomer(customer)
  }
  handleVoteValidDate = (isAdvanceVote, startDateYime, endDateTime) => {
    if (isAdvanceVote) {
      const today = new Date()
      const startDate = new Date(startDateYime)
      const endDate = new Date(endDateTime)
      if (
        startDate.getTime() < today.getTime() &&
        endDate.getTime() > today.getTime()
      ) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  handleVoteFinishClick = agenda => {
    const { error } = this.props
    if (!agenda.isStart) {
      this.props.onSetVoteStart(this.props.token, agenda.id)
    } else {
      this.props.onSetVoteFinish(this.props.token, agenda.id)
    }
    if (error !== "") {
      this.showToast("success", "Амжиттай илгээгдлээ.", "Амжилттай")
      if (!agenda.isStart) {
        agenda.isStart = true
      }
      if (agenda.isStart && !agenda.isFinish) {
        agenda.isFinish = true
      }
    } else {
      this.showToast("error", error, "Алдаа гарлаа")
    }
  }

  handleValidFormAgendaSubmit = values => {
    const error = this.props.error
    const { onAddNewAgenda, onUpdateAgenda } = this.props
    const { isEdit, agendas, selectedAgenda } = this.state

    this.setState({
      agendaForm: {
        ...this.state.agendaForm,
        token: this.props.token,
        meetingId: this.props.meetingId,
        agendaId: values[0].id,
        content: values[0].content,
        agendaTypeId: values[0].agendaTypeId,
        agendaType: values[0].agendaTypeName
          ? values[0].agendaTypeName[0].name
          : "",
        voteTypeId: values[0].voteTypeId ? values[0].voteTypeId : 0,
        voteType: values[0].voteTypeName ? values[0].voteTypeName[0].name : "",
        voteMethodId: values[0].voteMethodId ? values[0].voteMethodId : 0,
        voteMethod: values[0].voteMethodName
          ? values[0].voteMethodName[0].name
          : "",
        baseVoteTime: values[0].baseVoteTime ? values[0].baseVoteTime : 0,
        voteStartTime: values[0].voteStartTime ? values[0].voteStartTime : "",
        voteFinishTime: values[0].voteFinishTime
          ? values[0].voteFinishTime
          : "",
        isAdvanceVote: values[0].isAdvanceVote
          ? values[0].isAdvanceVote
          : false,
        isMeetingVote: values[0].isMeetingVote
          ? values[0].isMeetingVote
          : false,
        isExternalVote: values[0].isExternalVote
          ? values[0].isExternalVote
          : false,
        isVote: values[0].isVote ? values[0].isVote : false,
        choiseCount: values[0].choiseCount ? values[0].choiseCount : 0,
        choiseList: values[0].choiseList ? values[0].choiseList : [],
        choiseName: values[0].choiseList.choise
          ? values[0].choiseList.choise
          : [],
      },
    })
    if (isEdit) {
      onUpdateAgenda(this.state.agendaForm)
      if (error == {}) {
        this.showToast("success", "Амжилттай хадгалагдлаа.", "Амжилттай")
        setTimeout(() => {
          this.props.history.push("/company/meeting/dtl/" + agendas.id)
        }, 1000)
      } else {
        this.showToast("error", error, "Алдаа гарлаа")
      }
    } else {
      onAddNewAgenda(this.state.agendaForm)
      if (error !== "") {
        this.showToast("success", "Амжилттай хадгалагдлаа.", "Амжилттай")
        this.toggle()
      } else {
        this.showToast("error", error, "Алдаа гарлаа")
      }
    }
  }

  showToast(type, message, title) {
    toastr.options = {
      timeOut: 5000,
      closeButton: true,
      progressBar: true,
      newestOnTop: true,
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
      showDuration: 300,
      hideDuration: 1000,
    }
    if (type === "info") toastr.info(message, title)
    else if (type === "warning") toastr.warning(message, title)
    else if (type === "error") toastr.error(message, title)
    else toastr.success(message, title)
  }
  render() {
    const { agendas } = this.props
    const { isEdit } = this.state
    return (
      <React.Fragment>
        <div className="mt-4">
          <div className="text-sm-end">
            <Button
              onClick={this.handleAddAgendaClick}
              color="success"
              className="mt-3 mt-lg-0 "
            >
              Хэлэлцэх асуудал нэмэх{" "}
            </Button>
          </div>
          <div className="table-responsive">
            <Table className="project-list-table align-middle table-borderless">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Хэлэлцэх асуудал</th>
                  <th scope="col">Төрөл</th>
                  <th scope="col">Санал авах арга</th>
                  <th scope="col">Хугацаа</th>
                  <th scope="col">Төлөв</th>
                  <th scope="col">Урьдчилсан</th>
                  <th scope="col">Эчнээ</th>
                  <th scope="col">Хурлын</th>
                  <th scope="col">Үйлдэл</th>
                </tr>
              </thead>
              <tbody>
                {map(agendas, (agenda, index) => (
                  <tr key={agenda.id}>
                    <td>{index + 1}</td>
                    <td style={{ width: "400px" }}>
                      {agenda.agendaType}
                      <div className="p text-muted mb-0">{agenda.content}</div>
                    </td>
                    <td>{agenda.voteType}</td>
                    <td>{agenda.voteMethod}</td>
                    <td>{agenda.baseVoteTime} минут</td>
                    {agenda.isVote ? (
                      <>
                        <td>
                          <Badge
                            color={agenda.isStart ? "success" : "danger"}
                            className={
                              "bg-" + (agenda.isStart ? "success" : "danger")
                            }
                          >
                            {agenda.isStart && !agenda.isFinish
                              ? "Санал хураалт эхэлсэн"
                              : agenda.isStart && agenda.isFinish
                              ? "Санал хураалт дууссан"
                              : "Санал хураалт эхлээгүй"}
                          </Badge>
                        </td>
                        <td>
                          <div className="form-check font-size-16">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id={agenda.displayIndex}
                              checked={agenda.isAdvanceVote}
                              readOnly="readonly"
                              value={agenda.isAdvanceVote}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={agenda.displayIndex}
                            />
                          </div>
                        </td>

                        <td>
                          <div className="form-check font-size-16">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id={agenda.displayIndex}
                              checked={agenda.isExternalVote}
                              readOnly="readonly"
                              value={agenda.isExternalVote}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={agenda.displayIndex}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="form-check font-size-16">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id={agenda.displayIndex}
                              checked={agenda.isMeetingVote}
                              readOnly="readonly"
                              value={agenda.isMeetingVote}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={agenda.displayIndex}
                            />
                          </div>
                        </td>
                      </>
                    ) : (
                      <td colSpan="4"></td>
                    )}

                    <td>
                      <div className="d-flex gap-3">
                        {agenda.isMeetingVote && !agenda.isStart ? (
                          <>
                            <Button
                              type="button"
                              color="primary"
                              className="mt-3 mt-lg-0"
                              onClick={() => this.handleVoteFinishClick(agenda)}
                            >
                              <i
                                className="mdi mdi-check font-size-18"
                                id="edittooltip"
                              />{" "}
                              Нээх
                            </Button>
                            <Button
                              key="update-btn"
                              type="button"
                              color="success"
                              className="mt-3 mt-lg-0"
                              onClick={() =>
                                this.handleUpdateAgendaClick(agenda)
                              }
                            >
                              <i
                                className="mdi mdi-pencil font-size-18"
                                key="update"
                              />{" "}
                              Засах
                            </Button>
                            <Button
                              key="delete-btn"
                              type="button"
                              color="danger"
                              className="mt-3 mt-lg-0"
                              onClick={() =>
                                this.handleDeleteAgendaClick(agenda)
                              }
                            >
                              <i
                                className="mdi mdi-trash-can font-size-18"
                                key="delete"
                              />{" "}
                              Устгах
                            </Button>
                          </>
                        ) : agenda.isMeetingVote && !agenda.isFinish ? (
                          <>
                            <Button
                              key="extend-btn"
                              type="button"
                              color="warning"
                              className="mt-3 mt-lg-0"
                              onClick={() => this.handleVoteExtendClick(agenda)}
                            >
                              <i
                                className="mdi mdi-alpha-c-circle-outline font-size-18"
                                key="extend"
                              />{" "}
                              Сунгах
                            </Button>
                            <Button
                              key="close-btn"
                              type="button"
                              color="danger"
                              className="mt-3 mt-lg-0"
                              onClick={() => this.handleVoteFinishClick(agenda)}
                            >
                              <i
                                className="mdi mdi-account-tie-voice-off-outline font-size-18"
                                key="close"
                              />{" "}
                              Хаах
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button
                              key="update-btn"
                              type="button"
                              color="success"
                              className="mt-3 mt-lg-0"
                              onClick={() =>
                                this.handleUpdateAgendaClick(agenda)
                              }
                            >
                              <i
                                className="mdi mdi-pencil font-size-18"
                                key="update"
                              />{" "}
                              Засах
                            </Button>
                            <Button
                              key="delete-btn"
                              type="button"
                              color="danger"
                              className="mt-3 mt-lg-0"
                              onClick={() => this.handleVoteFinishClick(agenda)}
                            >
                              <i
                                className="mdi mdi-trash-can font-size-18"
                                key="delete"
                              />{" "}
                              Устгах
                            </Button>
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Modal
              size="xl"
              isOpen={this.state.modal}
              className={this.props.className}
            >
              <ModalHeader toggle={this.toggle} tag="h4">
                {!!isEdit ? "Хэлэлцэх асуудал засах" : "Хэлэлцэх асуудал нэмэх"}
              </ModalHeader>
              <ModalBody>
                <MeetingAgenda
                  isButton={false}
                  agendas={this.state.agendas}
                  saveForm={this.handleValidFormAgendaSubmit}
                />
              </ModalBody>
            </Modal>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

CompanyMeetingAgenda.propTypes = {
  onAddNewAgenda: PropTypes.func,
  onUpdateAgenda: PropTypes.func,
  error: PropTypes.any,
  history: PropTypes.object,
  votes: PropTypes.array,
  token: PropTypes.string,
  meetingId: PropTypes.string,
  onSetVoteStart: PropTypes.func,
  onSetVoteFinish: PropTypes.func,
}
const mapStateToProps = state => {
  return {
    error: state.CompanyMeet.error,
    votes: state.CompanyMeet.votes,
  }
}

const mapDispatchToProps = dispatch => ({
  onAddNewAgenda: agenda => dispatch(addComNewAgenda(agenda)),
  onUpdateAgenda: agenda => dispatch(updateComAgenda(agenda)),

  onSetVoteStart: (token, agendaId) =>
    dispatch(setComVoteStart(token, agendaId)),
  onSetVoteFinish: (token, agendaId) =>
    dispatch(setComVoteFinish(token, agendaId)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyMeetingAgenda)
