/* CUSTOMER COMPANY */
export const GET_CUSTOMER_COMPANYS = "GET_CUSTOMER_COMPANYS"
export const GET_CUSTOMER_COMPANYS_SUCCESS = "GET_CUSTOMER_COMPANYS_SUCCESS"
export const GET_CUSTOMER_COMPANYS_FAIL = "GET_CUSTOMER_COMPANYS_FAIL"


export const GET_CUSTOMER_COMPANY_DTL = "GET_CUSTOMER_COMPANY_DTL"
export const GET_CUSTOMER_COMPANY_DTL_SUCCESS = "GET_CUSTOMER_COMPANY_DTL_SUCCESS"
export const GET_CUSTOMER_COMPANY_DTL_FAIL = "GET_CUSTOMER_COMPANY_DTL_FAIL"

export const GET_CUSTOMER_COMPANY_AGENDA = "GET_CUSTOMER_COMPANY_AGENDA"
export const GET_CUSTOMER_COMPANY_AGENDA_SUCCESS = "GET_CUSTOMER_COMPANY_AGENDA_SUCCESS"
export const GET_CUSTOMER_COMPANY_AGENDA_FAIL = "GET_CUSTOMER_COMPANY_AGENDA_FAIL"

export const GET_CUSTOMER_COMPANY_DOCUMENT = "GET_CUSTOMER_COMPANY_DOCUMENT"
export const GET_CUSTOMER_COMPANY_DOCUMENT_SUCCESS = "GET_CUSTOMER_COMPANY_DOCUMENT_SUCCESS"
export const GET_CUSTOMER_COMPANY_DOCUMENT_FAIL = "GET_CUSTOMER_COMPANY_DOCUMENT_FAIL"

