import React, { Component } from "react"

import PropTypes from "prop-types"

import { connect } from "react-redux"

import { Card, CardBody, Col, Button, Row, Collapse } from "reactstrap"

import { getMeetings } from "store/actions"
import { map } from "lodash"

class MeetingUserDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      col1: true,
      col2: false,
      col3: false,
      meetingForm: {
        token: "",
        meetingName: "",
        address: "",
        declareDate: "",
        endTime: "",
        meetingDate: "",
        meetingTypeId: null,
        membersCount: null,
        registrationDate: "",
        startTime: "",
        agendaDtos: [],
        contactDtos: [],
        documentDtos: [],
        customerDtos: [],
      },
    }
    this.t_col1 = this.t_col1.bind(this)
    this.t_col2 = this.t_col2.bind(this)
    this.t_col3 = this.t_col3.bind(this)
    this.toggle = this.toggle.bind(this)
  }
  t_col1() {
    this.setState({
      col1: !this.state.col1,
      col2: false,
      col3: false,
    })
  }
  t_col2() {
    this.setState({
      col1: false,
      col2: !this.state.col2,
      col3: false,
    })
  }

  t_col3() {
    this.setState({
      col1: false,
      col2: false,
      col3: !this.state.col3,
    })
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  componentDidMount() {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      const { meetings } = this.props
      const token = obj.token
      this.props.onGetMeets(
        obj.token,
        this.props.page,
        this.props.totalPage,
        this.props.history
      )
    }
  }

  // Өмнөх таб-руу буцах
  prevPage = () => {
    this.props.toggle(6)
  }

  // Форм шалгах
  handleValidCustomerSubmit = () => {
    this.props.saveForm(this.state.selectedCustomer)
  }

  render() {
    console.log("dto")
    console.log(this.props.formData.agendaDtos)
    const { agendaType, voteType, methodType } = this.props
    return (
      <React.Fragment>
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <Row className="accordion" id="accordion">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button fw-medium"
                        type="button"
                        onClick={this.t_col1}
                        style={{ cursor: "pointer" }}
                      >
                        <strong> Ерөнхий мэдээлэл</strong>
                      </button>
                    </h2>

                    <Collapse
                      isOpen={this.state.col1}
                      className="accordion-collapse"
                    >
                      <div className="accordion-body">
                        <Row>
                          <Col lg="4">
                            <div key="meetingName">
                              <p className="text-muted">
                                <i className="bx bx-comment-minus font-size-16 align-middle text-primary me-2" />
                                <strong>Хурлын нэр: </strong>
                                {this.props.formData.meetingName}
                              </p>
                            </div>
                          </Col>
                          <Col lg="4">
                            <div key="meetingTypeId">
                              <p className="text-muted">
                                <i className="bx bx-comment-minus font-size-16 align-middle text-primary me-2" />
                                <strong>Хурлын төрөл: </strong>
                                {this.props.formData.meetingTypeId}
                              </p>
                            </div>
                          </Col>
                          <Col>
                            <div key="declareDate">
                              <p className="text-muted">
                                <i className="bx bx-comment-minus font-size-16 align-middle text-primary me-2" />
                                <strong>Хурлын зарласан огноо: </strong>
                                {this.props.formData.declareDate}
                              </p>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div key="meetingDate">
                              <p className="text-muted">
                                <i className="bx bx-comment-minus font-size-16 align-middle text-primary me-2" />
                                <strong>Хурлын огноо: </strong>
                                {this.props.formData.meetingDate}
                              </p>
                            </div>
                          </Col>
                          <Col>
                            <div key="startTime">
                              <p className="text-muted">
                                <i className="bx bx-comment-minus font-size-16 align-middle text-primary me-2" />
                                <strong>Хурал эхлэх цаг: </strong>
                                {this.props.formData.startTime}
                              </p>
                            </div>
                          </Col>
                          <Col>
                            <div key="startTime">
                              <p className="text-muted">
                                <i className="bx bx-comment-minus font-size-16 align-middle text-primary me-2" />
                                <strong>Хурал дуусах цаг: </strong>
                                {this.props.formData.endTime}
                              </p>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div key="meetingDate">
                              <p className="text-muted">
                                <i className="bx bx-comment-minus font-size-16 align-middle text-primary me-2" />
                                <strong>Бүртгэлийн огноо: </strong>
                                {this.props.formData.registrationDate}
                              </p>
                            </div>
                          </Col>
                          <Col>
                            <div key="startTime">
                              <p className="text-muted">
                                <i className="bx bx-comment-minus font-size-16 align-middle text-primary me-2" />
                                <strong>Гишүүдийн тоо: </strong>
                                {this.props.formData.membersCount}
                              </p>
                            </div>
                          </Col>
                          <Col>
                            <div key="startTime">
                              <p className="text-muted">
                                <i className="bx bx-comment-minus font-size-16 align-middle text-primary me-2" />
                                <strong>
                                  Хурал зохион байгуулагдах газар:{" "}
                                </strong>
                                {this.props.formData.address}
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Collapse>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button fw-medium collapsed"
                        type="button"
                        onClick={this.t_col2}
                        style={{ cursor: "pointer" }}
                      >
                        <strong> Хэлэлцэх асуудал</strong>
                      </button>
                    </h2>
                    <Collapse
                      isOpen={this.state.col2}
                      className="accordion-collapse"
                    >
                      <div className="accordion-body">
                        <Row>
                          {this.props.formData.agendaDtos
                            ? map(
                                this.props.formData.agendaDtos,
                                (row, idx) => (
                                  <tr
                                    id={"addr" + idx}
                                    key={idx}
                                    className=" border-top relativeContent"
                                  >
                                    <Row>
                                      <Col lg="4">
                                        <div key="meetName">
                                          <p className="text-muted">
                                            <strong>
                                              {idx + 1}. Хэлэлцэх асуудал:
                                            </strong>
                                            <i className="align-middle me-2" />
                                            {row.content}
                                          </p>
                                        </div>
                                      </Col>
                                      <Col lg="4">
                                        <div key="meetType">
                                          <p className="text-muted">
                                            <strong>
                                              Хэлэлцэх асуудлын төрөл:{" "}
                                            </strong>
                                            <i className=" align-middle me-2" />
                                            {row.agendaTypeName
                                              ? row.agendaTypeName[0].name
                                              : ""}
                                          </p>
                                        </div>
                                      </Col>
                                      <Col lg="4">
                                        <div key="vote">
                                          <p className="text-muted">
                                            <strong>Санал авах эсэх: </strong>
                                            <i className=" align-middle me-2" />
                                            {row.isVote ? "Тийм" : "Үгүй"}
                                          </p>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg="4">
                                        <div key="VoteTime">
                                          <p className="text-muted">
                                            <strong>
                                              Санал хураалт явагдах минут:{" "}
                                            </strong>
                                            <i className=" align-middle me-2" />
                                            {row.baseVoteTime}
                                          </p>
                                        </div>
                                      </Col>
                                      <Col lg="4">
                                        <div key="startTime">
                                          <p className="text-muted">
                                            <strong>
                                              Санал хураалт эхлэх огноо:{" "}
                                            </strong>
                                            <i className=" align-middle me-2" />
                                            {row.voteStartTime}
                                          </p>
                                        </div>
                                      </Col>
                                      <Col lg="4">
                                        <div key="finishTime">
                                          <p className="text-muted">
                                            <strong>
                                              Санал хураалт дуусах огноо:{" "}
                                            </strong>
                                            <i className=" align-middle me-2" />
                                            {row.voteFinishTime}
                                          </p>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg="4">
                                        <div key="VoteType">
                                          <p className="text-muted">
                                            <strong>Санал авах төрөл: </strong>
                                            {row.voteTypeId}
                                            {/* {row.voteTypeName[0].name} */}
                                          </p>
                                        </div>
                                      </Col>
                                      <Col lg="4">
                                        <div key="startTime">
                                          <p className="text-muted">
                                            <strong>Санал авах хэлбэр: </strong>
                                            {row.voteMethodId}
                                          </p>
                                        </div>
                                      </Col>
                                    </Row>
                                  </tr>
                                )
                              )
                            : ""}
                        </Row>
                      </div>
                    </Collapse>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button fw-medium collapsed"
                        type="button"
                        onClick={this.t_col3}
                        style={{ cursor: "pointer" }}
                      >
                        <strong>Баримт бичиг</strong>
                      </button>
                    </h2>
                    <Collapse
                      isOpen={this.state.col3}
                      className="accordion-collapse"
                    >
                      <div className="accordion-body">
                        <Row>
                          {this.props.formData.documentDtos
                            ? map(
                                this.props.formData.documentDtos,
                                (row, idx) => (
                                  <tr
                                    id={"addr" + idx}
                                    key={idx}
                                    className=" border-top relativeContent"
                                  >
                                    <Row>
                                      <Col lg="4">
                                        <div key="docName">
                                          <p className="text-muted">
                                            <strong>
                                              {idx + 1}. Баримт бичгийн төрөл:{" "}
                                            </strong>
                                            {row.documentTypeId}
                                          </p>
                                        </div>
                                      </Col>
                                      <Col lg="4">
                                        <div key="docType">
                                          <p className="text-muted">
                                            <strong>
                                              Баримт бичгийн нэр:{" "}
                                            </strong>
                                            {row.fileName}
                                          </p>
                                        </div>
                                      </Col>
                                      <Col lg="4">
                                        <div key="docDescription">
                                          <p className="text-muted">
                                            <strong>Товч тайлбар:</strong>
                                            {row.description}
                                          </p>
                                        </div>
                                      </Col>
                                    </Row>
                                  </tr>
                                )
                              )
                            : ""}
                        </Row>
                      </div>
                    </Collapse>
                  </div>
                </Row>
                {/* <span>
                            Хурлын нэр: {this.props.formData.meetingName}
                          </span> */}

                <div className="customButton clearfix mt-3">
                  <Button
                    color="primary"
                    className="mt-3 mt-lg-0"
                    onClick={this.prevPage}
                  >
                    Өмнөх
                  </Button>
                  <Button
                    color="success"
                    onClick={this.handleValidCustomerSubmit}
                    className="mt-3 mt-lg-0"
                  >
                    Хадгалах
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

MeetingUserDetail.propTypes = {
  formData: PropTypes.any,
  saveForm: PropTypes.any,
  contactDtos: PropTypes.any,
  meetings: PropTypes.array,
  page: PropTypes.number,
  totalPage: PropTypes.number,
  error: PropTypes.any,
  onGetCustomers: PropTypes.func,
  onAddNewCustomer: PropTypes.func,
  onDeleteCustomer: PropTypes.func,
  onUpdateCustomer: PropTypes.func,
}

const mapStateToProps = state => {
  return {
    meetings: state.CompanyMeet.meetings,
    page: state.CompanyMeet.page,
    totalPage: state.CompanyMeet.totalPage,
    history: PropTypes.object,
    error: state.CompanyMeet.error,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetMeets: (token, page, totalPage, history) =>
    dispatch(getMeetings(token, page, totalPage, history)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MeetingUserDetail)
