import React, { Component } from "react"
import PropTypes from "prop-types"
import {
  Row,
  Col,
  Button,
  Table,
} from "reactstrap"
import {
  AvForm,
  AvField
} from "availity-reactstrap-validation"
import { map } from "lodash"
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"
// import classname from "classnames"
import {
  saveMarkNtf,
} from "store/actions"

class Notification extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
  }

  handleValidSubmit = (e, values) => {
    console.log(this.props.token)
    const { error } = this.props
    const form = {
      token: this.props.token,
      ids: values.id,
    }
    const saveMarkNtf = this.props.onSaveMarkNtf(form)

    if (error !== "") {
      localStorage.removeItem("newNotification")
      this.props.toggle()
    } else {
      // this.showToast("error", error, "Алдаа гарлаа")
    }
  }

  render() {
    const notifications = this.props.notifications
    return (
      <React.Fragment>
        <AvForm onValidSubmit={this.handleValidSubmit}>
          <Table className="project-list-table align-middle table-borderless">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Сонордуулга</th>
              </tr>
            </thead>
            <tbody>
              {notifications ? map(notifications, (ntf, index) => (
                <tr key={ntf.id}>
                  <td style={{ width: "15px" }}>{index + 1}
                    <AvField
                      id="id"
                      name={
                        "id[" +
                        index +
                        "]"
                      }
                      value={
                        ntf.id
                      }
                      type="hidden"
                    /></td>
                  <td style={{ width: "400px" }}>
                    {ntf.content}
                  </td>
                </tr>
              )): <></>}
            </tbody>
          </Table>
          <Row>
            <Col>
              <div className="text-end">
                <button
                  type="submit"
                  className="btn btn-success save-company"
                >
                  Бүгдийг уншсан
                </button>
              </div>
            </Col>
          </Row>
        </AvForm>
      </React.Fragment>
    ) 
  }
}

Notification.propTypes = {
  location: PropTypes.object,
  notifications: PropTypes.any,
  token: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    error: state.Dashboard.error
  }
}

const mapDispatchToProps = dispatch => ({
  onSaveMarkNtf: form => dispatch(saveMarkNtf(form)),
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Notification))
