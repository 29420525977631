import {
  GET_MEMBERS_FAIL,
  GET_MEMBERS_SUCCESS,
  ADD_MEMBER_SUCCESS,
  ADD_MEMBER_FAIL,
  UPDATE_MEMBER_SUCCESS,
  UPDATE_MEMBER_FAIL,
  DELETE_MEMBER_SUCCESS,
  DELETE_MEMBER_FAIL,
  GET_MEMBER_DTL_FAIL,
  GET_MEMBER_DTL_SUCCESS,
  GET_MEET_STORY_DTL_SUCCESS,
  GET_MEET_STORY_DTL_FAIL,
  GET_STOKE_TYPE_SUCCESS,
  GET_STOKE_TYPE_FAIL,
  GET_EXCELS_SUCCESS,
  GET_EXCELS_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  page: 0,
  length: 10,
  totalPage: 0,
  draw: 1,
  members: [],
  story: [],
  message: [],
  error: {},
}

const CompanyMemberRed = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MEMBERS_SUCCESS:
      return {
        ...state,
        members: action.members,
        totalPage: action.totalPage,
      }

    case GET_MEMBERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    /*--------------------------------------------------*/
    case ADD_MEMBER_SUCCESS:
      return {
        ...state,
        error: {},
        members: [...state.members, action.payload],
        message: action.message,
      }

    case ADD_MEMBER_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    /*--------------------------------------------------*/
    case UPDATE_MEMBER_SUCCESS:
      return {
        ...state,
        error: {},
        members: state.members.map(customer =>
          customer.id.toString() === action.payload.id.toString()
            ? { customer, ...action.payload }
            : customer
        ),
      }

    case UPDATE_MEMBER_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    /*--------------------------------------------------*/
    case DELETE_MEMBER_SUCCESS:
      return {
        ...state,
        error: {},
        members: state.members.filter(
          customer => customer.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_MEMBER_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    /*--------------------------------------------------*/
    case GET_MEMBER_DTL_SUCCESS:
      return {
        ...state,
        error: {},
        member: action.member,
      }

    case GET_MEMBER_DTL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_MEET_STORY_DTL_SUCCESS:
      return {
        ...state,
        story: action.story,
      }

    case GET_MEET_STORY_DTL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_STOKE_TYPE_SUCCESS:
      return {
        ...state,
        error: {},
        stokeType: action.stokeType,
      }

    case GET_STOKE_TYPE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_EXCELS_SUCCESS:
      return {
        ...state,
        data: action.data,
        message: action.message,
      }
    case GET_EXCELS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default CompanyMemberRed
