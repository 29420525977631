/* CUSTOMER MEETING */
export const GET_AUDITS = "GET_AUDITS"
export const GET_AUDITS_FAIL = "GET_AUDITS_FAIL"
export const GET_AUDITS_SUCCESS = "GET_AUDITS_SUCCESS"

// export const ADD_NEW_COMPANY = "ADD_NEW_COMPANY"
// export const ADD_COMPANY_SUCCESS = "ADD_COMPANY_SUCCESS"
// export const ADD_COMPANY_FAIL = "ADD_COMPANY_FAIL"

export const UPDATE_COMPANY = "UPDATE_COMPANY"
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS"
export const UPDATE_COMPANY_FAIL = "UPDATE_COMPANY_FAIL"

// export const DELETE_COMPANY = "DELETE_COMPANY"
// export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS"
// export const DELETE_COMPANY_FAIL = "DELETE_COMPANY_FAIL"

// export const ADD_COMPANY_USER = "ADD_COMPANY_USER"
// export const ADD_COMPANY_USER_SUCCESS = "ADD_COMPANY_USER_SUCCESS"
// export const ADD_COMPANY_USER_FAIL = "ADD_COMPANY_USER_FAIL"
