import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_AUDIT_MEETINGS,
  GET_AUDIT_MEETING_DTL,
  GET_AUDIT_MEETING_AGENDA,
  GET_AUDIT_MEETING_DOCUMENT,
  GET_AUDIT_MEETING_RESULT,
  START_AUDIT_VOTE,
  SAVE_AUDIT_VOTE,
  SET_AUDIT_ATTENDANCE,
} from "./actionTypes"
import {
  getAuditMeetingsSuccess,
  getAuditMeetingsFail,
  getAuditMeetingDtlSuccess,
  getAuditMeetingDtlFail,
  getAuditMeetingAgendaSuccess,
  getAuditMeetingAgendaFail,
  getAuditMeetingDocumentSuccess,
  getAuditMeetingDocumentFail,
  getAuditMeetingResultSuccess,
  getAuditMeetingResultFail,
  starAudittVoteSuccess,
  startAuditVoteFail,
  saveAuditVoteSuccess,
  saveAuditVoteFail,
  setAuditAttendanceSuccess,
  setAuditAttendanceFail,
} from "./actions"

//Include Both Helper File with needed methods
import { postJson, isUserAuthenticated } from "helpers/fakebackend_helper"

function* fetchAuditMeetings({ payload: { request, history } }) {
  if (isUserAuthenticated()) {
    try {
      const response = yield call(
        postJson,
        {
          token: request.token,
          start: request.page,
          length: 9,
        },
        "/Admin/CompanyWebService/get_meeting_list_company"
      )
      console.log(response)
      if (response.responseResultType === "SUCCESS") {
        yield put(getAuditMeetingsSuccess(response))
      } else {
        if (response.failureMessages.message[0].failureCode === "ERR_009") {
          history.push("/logout")
        }
        yield put(
          getAuditMeetingsFail(
            response.failureMessages.message[0].failureMessage
          )
        )
      }
    } catch (error) {
      yield put(getAuditMeetingsFail(error))
    }
  } else history.push("/logout")
}
function* fetchAuditMeetingDetail({ payload: { request, history } }) {
  if (isUserAuthenticated()) {
    try {
      const response = yield call(
        postJson,
        {
          token: request.token,
          meetingId: request.meetingId,
        },
        "/Admin/CompanyWebService/get_meeting_introduction"
      )
      if (response.responseResultType === "SUCCESS") {
        yield put(getAuditMeetingDtlSuccess(response))
      } else {
        if (response.failureMessages.message[0].failureCode === "ERR_009") {
          history.push("/logout")
        }
        yield put(
          getAuditMeetingsFail(
            response.failureMessages.message[0].failureMessage
          )
        )
      }
    } catch (error) {
      yield put(getAuditMeetingDtlFail(error))
    }
  } else history.push("/logout")
}

function* fetchAuditMeetingAgenda({ payload }) {
  if (isUserAuthenticated()) {
    try {
      const response = yield call(
        postJson,
        {
          token: payload.token,
          meetingId: payload.meetingId,
        },
        "/Admin/CompanyWebService/get_meeting_detail_agenda"
      )
      yield put(getAuditMeetingAgendaSuccess(response.agendaDtos))
    } catch (error) {
      yield put(getAuditMeetingAgendaFail(error))
    }
  } else history.push("/logout")
}
function* fetchAuditMeetingDocument({ payload }) {
  if (isUserAuthenticated()) {
    try {
      const response = yield call(
        postJson,
        {
          token: payload.token,
          meetingId: payload.meetingId,
        },
        "/Admin/CompanyWebService/get_meeting_detail_documents"
      )
      yield put(getAuditMeetingDocumentSuccess(response.documentDtos))
    } catch (error) {
      yield put(getAuditMeetingDocumentFail(error))
    }
  } else history.push("/logout")
}
function* fetchAuditMeetingResult({ payload }) {
  if (isUserAuthenticated()) {
    try {
      const response = yield call(
        postJson,
        {
          token: payload.token,
          meetingId: payload.meetingId,
        },
        "/Admin/CompanyWebService/get_meeting_detail_result"
      )
      yield put(getAuditMeetingResultSuccess(response))
    } catch (error) {
      yield put(getAuditMeetingResultFail(error))
    }
  } else history.push("/logout")
}

// function* fetchStartVote({ payload }) {
//   if (isUserAuthenticated()) {
//     try {
//       const response = yield call(
//         postJson,
//         {
//           token: payload.token,
//           agendaId: payload.agendaId,
//           loginIp: 1,
//         },
//         "/Customer/CustomerWebService/start_vote"
//       )
//       yield put(startVoteSuccess(response))
//     } catch (error) {
//       yield put(startVoteFail(error))
//     }
//   } else history.push("/logout")
// }

// function* fetchSaveVote({ payload: vote }) {
//   if (isUserAuthenticated()) {
//     try {
//       const response = yield call(
//         postJson,
//         vote,
//         "/Customer/CustomerWebService/save_customer_vote"
//       )
//       yield put(saveVoteSuccess(response.documentDtos))
//     } catch (error) {
//       yield put(saveVoteFail(error))
//     }
//   } else history.push("/logout")
// }
// function* fetchSetAttendance({ payload }) {
//   if (isUserAuthenticated()) {
//     try {
//       const response = yield call(
//         postJson,
//         {
//           token: payload.token,
//           meetingId: payload.meetingId,
//         },
//         "/Customer/CustomerWebService/get_meeted_customer_registering"
//       )
//       yield put(setAttendanceSuccess(response.documentDtos))
//     } catch (error) {
//       yield put(setAttendanceFail(error))
//     }
//   } else history.push("/logout")
// }

function* auditMeetingSaga() {
  yield takeEvery(GET_AUDIT_MEETINGS, fetchAuditMeetings)
  yield takeEvery(GET_AUDIT_MEETING_DTL, fetchAuditMeetingDetail)
  yield takeEvery(GET_AUDIT_MEETING_AGENDA, fetchAuditMeetingAgenda)
  yield takeEvery(GET_AUDIT_MEETING_DOCUMENT, fetchAuditMeetingDocument)
  yield takeEvery(GET_AUDIT_MEETING_RESULT, fetchAuditMeetingResult)
  // yield takeEvery(START_AUDIT_VOTE, fetchStartVote)
  // yield takeEvery(SAVE_AUDIT_VOTE, fetchSaveVote)
  // yield takeEvery(SET_AUDIT_ATTENDANCE, fetchSetAttendance)
}

export default auditMeetingSaga
