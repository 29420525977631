import {
  GET_SHARES_FAIL,
  GET_SHARES_SUCCESS,
  ADD_SHARES_SUCCESS,
  ADD_SHARES_FAIL,
  UPDATE_SHARES_SUCCESS,
  UPDATE_SHARES_FAIL,
  DELETE_SHARES_SUCCESS,
  DELETE_SHARES_FAIL,
  GET_SHARES_DTL_SUCCESS,
  GET_SHARES_DTL_FAIL,
  GET_SHARESMEET_DTL_SUCCESS,
  GET_SHARESMEET_DTL_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  shares: [],
  sharesdtl: {},
  sharesmeetdtl: [],
  message: [],
  page: 0,
  length: 10,
  totalPage: 0,
  draw: 1,
  error: {},
}

const CompanyShareRed = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SHARES_SUCCESS:
      return {
        ...state,
        error: {},
        shares: action.shares,
        totalPage: action.totalPage,
      }

    case GET_SHARES_FAIL:
      return {
        ...state,
        error: action.error,
      }

    case ADD_SHARES_SUCCESS:
      return {
        ...state,
        error: {},
        shares: [...state.shares, action.payload],
      }

    case ADD_SHARES_FAIL:
      return {
        ...state,
        error: action.error,
      }

    case UPDATE_SHARES_SUCCESS:
      return {
        ...state,
        error: {},
        shares: state.shares.map(customer =>
          customer.id.toString() === action.payload.id.toString()
            ? { customer, ...action.payload }
            : customer
        ),
      }

    case UPDATE_SHARES_FAIL:
      return {
        ...state,
        error: action.error,
      }

    case DELETE_SHARES_SUCCESS:
      return {
        ...state,
        error: {},
        shares: state.shares.filter(
          customer => customer.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_SHARES_FAIL:
      return {
        ...state,
        error: action.error,
      }

    case GET_SHARES_DTL_SUCCESS:
      return {
        ...state,
        error: {},
        sharesdtl: action.sharesdtl,
      }

    case GET_SHARES_DTL_FAIL:
      return {
        ...state,
        error: action.error,
      }

    case GET_SHARESMEET_DTL_SUCCESS:
      return {
        ...state,
        error: {},
        sharesmeetdtl: action.sharesmeetdtl,
      }

    case GET_SHARESMEET_DTL_FAIL:
      return {
        ...state,
        error: action.error,
      }
    default:
      return state
  }
}

export default CompanyShareRed
