import PropTypes from "prop-types"
import React from "react"
import { Link } from "react-router-dom"
import { map } from "lodash"
import { Badge } from "reactstrap"

const AuditChoiseResultCustomer = ({ choiseList }) => {
  return (
    <div>
      <td>
        <ul className="list-inline mb-0">
          {map(choiseList, (choise, dkey) => (
            <li className="list-item me-3" key={dkey}>
              <i className="bx bx-plus me-1" /> {choise.choise}
            </li>
          ))}
        </ul>
      </td>
    </div>
  )
}

AuditChoiseResultCustomer.propTypes = {
  choiseList: PropTypes.array,
}

export default AuditChoiseResultCustomer
