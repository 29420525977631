import {
  GET_COM_MEETINGS,
  GET_COM_MEETINGS_FAIL,
  GET_COM_MEETINGS_SUCCESS,
  ADD_COM_NEW_MEETING,
  ADD_COM_MEETING_SUCCESS,
  ADD_COM_MEETING_FAIL,
  UPDATE_COM_MEETING,
  UPDATE_COM_MEETING_SUCCESS,
  UPDATE_COM_MEETING_FAIL,
  DELETE_COM_MEETING,
  DELETE_COM_MEETING_SUCCESS,
  DELETE_COM_MEETING_FAIL,
  GET_COM_AGENDA_TYPE,
  GET_COM_AGENDA_TYPE_SUCCESS,
  GET_COM_AGENDA_TYPE_FAIL,
  GET_COM_VOTE_TYPE,
  GET_COM_VOTE_TYPE_SUCCESS,
  GET_COM_VOTE_TYPE_FAIL,
  GET_COM_METHOD_TYPE,
  GET_COM_METHOD_TYPE_SUCCESS,
  GET_COM_METHOD_TYPE_FAIL,
  GET_COM_MEETING_TYPE,
  GET_COM_MEETING_TYPE_SUCCESS,
  GET_COM_MEETING_TYPE_FAIL,
  GET_COM_MEETING_DOCUMENT_TYPE,
  GET_COM_MEETING_DOCUMENT_TYPE_SUCCESS,
  GET_COM_MEETING_DOCUMENT_TYPE_FAIL,
  GET_COM_MEETING_DTL,
  GET_COM_MEETING_DTL_FAIL,
  GET_COM_MEETING_DTL_SUCCESS,
  GET_COM_MEETING_AGENDA,
  GET_COM_MEETING_AGENDA_SUCCESS,
  GET_COM_MEETING_AGENDA_FAIL,
  ADD_COM_NEW_AGENDA,
  ADD_COM_AGENDA_SUCCESS,
  ADD_COM_AGENDA_FAIL,
  UPDATE_COM_AGENDA,
  UPDATE_COM_AGENDA_SUCCESS,
  UPDATE_COM_AGENDA_FAIL,
  DELETE_COM_AGENDA,
  DELETE_COM_AGENDA_SUCCESS,
  DELETE_COM_AGENDA_FAIL,
  GET_COM_MEETING_DOCUMENT,
  GET_COM_MEETING_DOCUMENT_SUCCESS,
  GET_COM_MEETING_DOCUMENT_FAIL,
  GET_COM_MEETING_RESULT,
  GET_COM_MEETING_RESULT_SUCCESS,
  GET_COM_MEETING_RESULT_FAIL,
  SET_COM_MEETING_START,
  SET_COM_MEETING_START_SUCCESS,
  SET_COM_MEETING_START_FAIL,
  SET_COM_VOTE_START,
  SET_COM_VOTE_START_SUCCESS,
  SET_COM_VOTE_START_FAIL,
  SET_COM_VOTE_FINISH,
  SET_COM_VOTE_FINISH_SUCCESS,
  SET_COM_VOTE_FINISH_FAIL,
  SET_COM_MEETING_FINISH,
  SET_COM_MEETING_FINISH_SUCCESS,
  SET_COM_MEETING_FINISH_FAIL,
  GET_MEETING_CUSTOMER,
  GET_MEETING_CUSTOMER_SUCCESS,
  GET_MEETING_CUSTOMER_FAIL,
} from "./actionTypes"

/*--------------------------------------------------*/
export const getComMeetings = (token, page, totalPage, history) => ({
  type: GET_COM_MEETINGS,
  payload: { request: { token, page, totalPage }, history },
})

export const getComMeetingsSuccess = response => ({
  type: GET_COM_MEETINGS_SUCCESS,
  meetings: response.data,
  totalPage: response.recordsTotal,
})

export const getComMeetingsFail = error => ({
  type: GET_COM_MEETINGS_FAIL,
  payload: error,
})

/*--------------------------------------------------*/
export const addComNewMeeting = (meeting, history) => ({
  type: ADD_COM_NEW_MEETING,
  payload: { meeting, history },
})

export const addComMeetingSuccess = meeting => ({
  type: ADD_COM_MEETING_SUCCESS,
  payload: meeting,
})

export const addComMeetingFail = error => ({
  type: ADD_COM_MEETING_FAIL,
  payload: error,
})

/*--------------------------------------------------*/

export const updateComMeeting = meeting => ({
  type: UPDATE_COM_MEETING,
  payload: meeting,
})

export const updateComMeetingSuccess = meeting => ({
  type: UPDATE_COM_MEETING_SUCCESS,
  payload: meeting,
})

export const updateComMeetingFail = error => ({
  type: UPDATE_COM_MEETING_FAIL,
  payload: error,
})

/*--------------------------------------------------*/
export const deleteComMeeting = meeting => ({
  type: DELETE_COM_MEETING,
  payload: meeting,
})

export const deleteComMeetingSuccess = meeting => ({
  type: DELETE_COM_MEETING_SUCCESS,
  payload: meeting,
})

export const deleteComMeetingFail = error => ({
  type: DELETE_COM_MEETING_FAIL,
  payload: error,
})
/*--------------------------------------------------*/
export const getComAgendaType = token => ({
  type: GET_COM_AGENDA_TYPE,
  payload: { token },
})

export const getComAgendaTypeSuccess = response => ({
  type: GET_COM_AGENDA_TYPE_SUCCESS,
  agendaType: response,
})

export const getComAgendaTypeFail = error => ({
  type: GET_COM_AGENDA_TYPE_FAIL,
  payload: error,
})
/*--------------------------------------------------*/

/*--------------------------------------------------*/
export const getComVoteType = token => ({
  type: GET_COM_VOTE_TYPE,
  payload: { token },
})

export const getComVoteTypeSuccess = response => ({
  type: GET_COM_VOTE_TYPE_SUCCESS,
  voteType: response,
})

export const getComVoteTypeFail = error => ({
  type: GET_COM_VOTE_TYPE_FAIL,
  payload: error,
})
/*--------------------------------------------------*/

export const getComMethodType = token => ({
  type: GET_COM_METHOD_TYPE,
  payload: { token },
})

export const getComMethodTypeSuccess = response => ({
  type: GET_COM_METHOD_TYPE_SUCCESS,
  methodType: response,
})

export const getComMethodTypeFail = error => ({
  type: GET_COM_METHOD_TYPE_FAIL,
  payload: error,
})
/*--------------------------------------------------*/
export const getComMeetingType = token => ({
  type: GET_COM_MEETING_TYPE,
  payload: { token },
})

export const getComMeetingTypeSuccess = response => ({
  type: GET_COM_MEETING_TYPE_SUCCESS,
  meetingType: response,
})

export const getComMeetingTypeFail = error => ({
  type: GET_COM_MEETING_TYPE_FAIL,
  payload: error,
})
/*--------------------------------------------------*/
export const getComMeetingDocumentType = token => ({
  type: GET_COM_MEETING_DOCUMENT_TYPE,
  payload: { token },
})

export const getComMeetingDocumentTypeSuccess = response => ({
  type: GET_COM_MEETING_DOCUMENT_TYPE_SUCCESS,
  documentType: response,
})

export const getComMeetingDocumentTypeFail = error => ({
  type: GET_COM_MEETING_DOCUMENT_TYPE_FAIL,
  payload: error,
})
/*--------------------------------------------------*/

export const getComMeetingDtl = (token, meetingId, history) => ({
  type: GET_COM_MEETING_DTL,
  payload: { request: { token, meetingId }, history },
})

export const getComMeetingDtlSuccess = response => ({
  type: GET_COM_MEETING_DTL_SUCCESS,
  meeting: response,
})

export const getComMeetingDtlFail = error => ({
  type: GET_COM_MEETING_DTL_FAIL,
  payload: error,
})
/*--------------------------------------------------*/
export const getComMeetingAgenda = (token, meetingId, history) => ({
  type: GET_COM_MEETING_AGENDA,
  payload: { request: { token, meetingId }, history },
})

export const getComMeetingAgendaSuccess = response => ({
  type: GET_COM_MEETING_AGENDA_SUCCESS,
  agenda: response,
})

export const getComMeetingAgendaFail = error => ({
  type: GET_COM_MEETING_AGENDA_FAIL,
  payload: error,
})
/*--------------------------------------------------*/
export const addComNewAgenda = (agenda, history) => ({
  type: ADD_COM_NEW_AGENDA,
  payload: { agenda, history },
})

export const addComAgendaSuccess = agenda => ({
  type: ADD_COM_AGENDA_SUCCESS,
  payload: agenda,
})

export const addComAgendaFail = error => ({
  type: ADD_COM_AGENDA_FAIL,
  payload: error,
})

/*--------------------------------------------------*/

export const updateComAgenda = meeting => ({
  type: UPDATE_COM_AGENDA,
  payload: meeting,
})

export const updateComAgendaSuccess = meeting => ({
  type: UPDATE_COM_AGENDA_SUCCESS,
  payload: meeting,
})

export const updateComAgendaFail = error => ({
  type: UPDATE_COM_AGENDA_FAIL,
  payload: error,
})

/*--------------------------------------------------*/
export const deleteComAgenda = meeting => ({
  type: DELETE_COM_AGENDA,
  payload: meeting,
})

export const deleteComAgendaSuccess = meeting => ({
  type: DELETE_COM_AGENDA_SUCCESS,
  payload: meeting,
})

export const deleteComAgendaFail = error => ({
  type: DELETE_COM_AGENDA_FAIL,
  payload: error,
})

/*--------------------------------------------------*/
export const getComMeetingDocument = (token, meetingId, history) => ({
  type: GET_COM_MEETING_DOCUMENT,
  payload: { request: { token, meetingId }, history },
})

export const getComMeetingDocumentSuccess = response => ({
  type: GET_COM_MEETING_DOCUMENT_SUCCESS,
  document: response,
})

export const getComMeetingDocumentFail = error => ({
  type: GET_COM_MEETING_DOCUMENT_FAIL,
  payload: error,
})

/*--------------------------------------------------*/
export const getComMeetingResult = (token, meetingId, history) => ({
  type: GET_COM_MEETING_RESULT,
  payload: { request: { token, meetingId }, history },
})

export const getComMeetingResultSuccess = response => ({
  type: GET_COM_MEETING_RESULT_SUCCESS,
  result: response,
})

export const getComMeetingResultFail = error => ({
  type: GET_COM_MEETING_RESULT_FAIL,
  payload: error,
})

/*--------------------------------------------------*/
export const setComMeetingStart = (token, meetingId) => ({
  type: SET_COM_MEETING_START,
  payload: { token, meetingId },
})

export const setComMeetingStartSuccess = response => ({
  type: SET_COM_MEETING_START_SUCCESS,
  votes: response,
})

export const setComMeetingStartFail = error => ({
  type: SET_COM_MEETING_START_FAIL,
  payload: error,
})
/*--------------------------------------------------*/
export const setComVoteStart = (token, agendaId) => ({
  type: SET_COM_VOTE_START,
  payload: { token, agendaId },
})

export const setComVoteStartSuccess = response => ({
  type: SET_COM_VOTE_START_SUCCESS,
  votes: response,
})

export const setComVoteStartFail = error => ({
  type: SET_COM_VOTE_START_FAIL,
  payload: error,
})
/*--------------------------------------------------*/
export const setComVoteFinish = (token, agendaId) => ({
  type: SET_COM_VOTE_FINISH,
  payload: { token, agendaId },
})

export const setComVoteFinishSuccess = response => ({
  type: SET_COM_VOTE_FINISH_SUCCESS,
  votes: response,
})

export const setComVoteFinishFail = error => ({
  type: SET_COM_VOTE_FINISH_FAIL,
  payload: error,
})
/*--------------------------------------------------*/
export const setComMeetingFinish = (token, meetingId) => ({
  type: SET_COM_MEETING_FINISH,
  payload: { token, meetingId },
})

export const setComMeetingFinishSuccess = response => ({
  type: SET_COM_MEETING_FINISH_SUCCESS,
  votes: response,
})

export const setComMeetingFinishFail = error => ({
  type: SET_COM_MEETING_FINISH_FAIL,
  payload: error,
})
/*--------------------------------------------------*/
export const getMeetingCustomer = (
  token,
  meetingId,
  page,
  totalPage,
  history
) => ({
  type: GET_MEETING_CUSTOMER,
  payload: { request: { token, meetingId, page, totalPage }, history },
})

export const getMeetingCustomerSuccess = response => ({
  type: GET_MEETING_CUSTOMER_SUCCESS,
  customers: response.data,
  totalPage: response.recordsTotal,
})

export const getMeetingCustomerFail = error => ({
  type: GET_MEETING_CUSTOMER_FAIL,
  payload: error,
})
