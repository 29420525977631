import React, { Component } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
  Card,
  CardBody,
  CardText,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Table,
} from "reactstrap"

import { withRouter } from "react-router-dom"
import { map, isEmpty } from "lodash"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import classnames from "classnames"
import AuditMeetingIntroduction from "./audit-meeting-indroduction"
import AuditMeetingContact from "./audit-meeting-contact"
import AuditMeetingDocument from "./audit-meeting-document"
import AuditMeetingAgenda from "./audit-meeting-agenda"
import AuditMeetingResult from "./audit-meeting-result"
import {
  getAuditMeetingDtl,
  getAuditMeetingAgenda,
  getAuditMeetingDocument,
  getAuditMeetingResult,
  setAuditAttendance,
} from "../../../store/actions"

class AuditMeetingDtl extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: "1",
      token: "",
      meetingId: "",
      votes: [],
    }
    this.toggle = this.toggle.bind(this)
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  componentDidMount() {
    const {
      match: { params },
      onAuditGetMeeting,
      onAuditGetMeetingAgenda,
      onAuditGetMeetingDocument,
      onAuditGetMeetingResult,
    } = this.props
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      if (params && params.id) {
        this.setState({ meetingId: params.id })

        onAuditGetMeeting(obj.token, params.id, this.props.history)
        onAuditGetMeetingAgenda(obj.token, params.id)
        onAuditGetMeetingDocument(obj.token, params.id)
        onAuditGetMeetingResult(obj.token, params.id)
      } else {
        onAuditGetMeeting(obj.token, 0, this.props.history) //remove this after full integration
        onAuditGetMeetingAgenda(obj.token, 0)
        onAuditGetMeetingDocument(obj.token, 0)
        onAuditGetMeetingResult(obj.token, 0)
      }
      this.setState({ token: obj.token })
    }
  }
  handleAttendance = e => {
    console.log(this.state.token, this.state.meetingId)
    this.props.onSetAttendance(this.state.token, this.state.meetingId)
  }

  render() {
    const { meeting, agendas, documents, results } = this.props

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Хурлын хяналт | Хурлын дэлгэрэнгүй</title>
          </MetaTags>
          <Container fluid={true}>
            <Breadcrumbs title="Аудит" breadcrumbItem="Хурлын дэлгэрэнгүй" />
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "1",
                          })}
                          onClick={() => {
                            this.toggle("1")
                          }}
                        >
                          Хурлын мэдээлэл
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "2",
                          })}
                          onClick={() => {
                            this.toggle("2")
                          }}
                        >
                          Баримт бичиг
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "3",
                          })}
                          onClick={() => {
                            this.toggle("3")
                          }}
                        >
                          Хэлэлцэх асуудал
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "4",
                          })}
                          onClick={() => {
                            this.toggle("4")
                          }}
                        >
                          Саналын түүх
                        </NavLink>
                      </NavItem>
                    </Nav>

                    <TabContent
                      activeTab={this.state.activeTab}
                      className="p-3 text-muted"
                    >
                      <TabPane tabId="1">
                        <Row>
                          <Col sm="12">
                            <CardText className="mb-0">
                              {!isEmpty(meeting) && (
                                <>
                                  <Row>
                                    <Col lg="8">
                                      <AuditMeetingIntroduction
                                        meeting={meeting}
                                        results={results}
                                      />
                                    </Col>

                                    <Col lg="4">
                                      <AuditMeetingContact
                                        team={meeting.contactDtos}
                                      />
                                      <div className="table-responsive">
                                        <Table className="table align-middle table-nowrap">
                                          <tbody>
                                            <tr key="attendance">
                                              <td style={{ width: "150px" }}>
                                                {meeting.participatingCustomerCount +
                                                  "/" +
                                                  meeting.membersCount}
                                              </td>
                                              <td className="text-end"></td>
                                            </tr>
                                          </tbody>
                                        </Table>
                                      </div>
                                    </Col>
                                  </Row>
                                </>
                              )}
                            </CardText>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="2">
                        <Row>
                          <Col sm="12">
                            <CardText className="mb-0">
                              <AuditMeetingDocument documents={documents} />
                            </CardText>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="3">
                        <Row>
                          <Col sm="12">
                            <CardText className="mb-0">
                              <AuditMeetingAgenda
                                agendas={agendas}
                                token={this.state.token}
                                votes={this.state.votes}
                              />
                            </CardText>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="4">
                        <Row>
                          <Col sm="12">
                            <CardText className="mb-0">
                              <AuditMeetingResult results={results} />
                            </CardText>
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

AuditMeetingDtl.propTypes = {
  meeting: PropTypes.object,
  attendance: PropTypes.object,
  documents: PropTypes.array,
  agendas: PropTypes.array,
  results: PropTypes.array,
  onAuditGetMeeting: PropTypes.func,
  onAuditGetMeetingAgenda: PropTypes.func,
  onAuditGetMeetingDocument: PropTypes.func,
  onAuditGetMeetingResult: PropTypes.func,
  history: PropTypes.object,
}

const mapStateToProps = state => {
  // console.log(state)
  return {
    meeting: state.AuditMeeting.meeting,
    agendas: state.AuditMeeting.agendas,
    documents: state.AuditMeeting.documents,
    results: state.AuditMeeting.results,
    attendance: state.AuditMeeting.attendance,
  }
}

const mapDispatchToProps = dispatch => ({
  onAuditGetMeeting: (token, meetingId, history) =>
    dispatch(getAuditMeetingDtl(token, meetingId, history)),
  onAuditGetMeetingAgenda: (token, meetingId) =>
    dispatch(getAuditMeetingAgenda(token, meetingId)),
  onAuditGetMeetingDocument: (token, meetingId) =>
    dispatch(getAuditMeetingDocument(token, meetingId)),
  onAuditGetMeetingResult: (token, meetingId) =>
    dispatch(getAuditMeetingResult(token, meetingId)),
  onAuditSetAttendance: (token, meetingId) =>
    dispatch(setAuditAttendance(token, meetingId)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AuditMeetingDtl))
