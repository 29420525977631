import React, { Component } from "react"
import { map, isEmpty, size } from "lodash"
import PropTypes from "prop-types"
import { Card, CardBody, Col, Row, Button } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import "../CompanyMeetingCreate/style.css"
class MeetingContact extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contacts: [
        {
          position: "",
          name: "",
          phone: "",
          email: "",
        },
      ],
    }
  }

  handleAddRow = () => {
    const item = {
      position: "",
      name: "",
      phone: "",
      email: "",
    }
    this.setState({
      contacts: [...this.state.contacts, item],
    })
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const contacts = this.props.contacts
    if (!isEmpty(contacts) && size(prevProps.contacts) !== size(contacts)) {
      this.setState({ contacts: contacts })
    }
  }
  handleRemoveRow = (e, idx) => {
    this.setState({
      contacts: this.state.contacts.filter((s, sidx) => idx !== sidx),
    })
  }

  handlePositionChange = idx => evt => {
    const contacts = this.state.contacts.map((contact, sidx) => {
      if (idx !== sidx) return contact
      return { ...contact, position: evt.target.value }
    })

    this.setState({ contacts: contacts })
  }
  handleNameChange = idx => evt => {
    const contacts = this.state.contacts.map((contact, sidx) => {
      if (idx !== sidx) return contact
      return { ...contact, name: evt.target.value }
    })

    this.setState({ contacts: contacts })
  }

  handlePhoneChange = idx => evt => {
    const contacts = this.state.contacts.map((contact, sidx) => {
      if (idx !== sidx) return contact
      return { ...contact, phone: evt.target.value }
    })

    this.setState({ contacts: contacts })
  }

  handleEmailChange = idx => evt => {
    const contacts = this.state.contacts.map((contact, sidx) => {
      if (idx !== sidx) return contact
      return { ...contact, email: evt.target.value }
    })

    this.setState({ contacts: contacts })
  }

  handleValidContactSubmit = (e, values) => {
    this.props.saveForm(this.state.contacts)
    this.nextPage()
  }
  // Өмнөх таб-руу буцах
  prevPage = () => {
    this.props.toggle(5)
  }
  // Дараагийн таб-руу орох
  nextPage = () => {
    this.props.toggle(7)
  }

  render() {
    return (
      <React.Fragment>
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <Button
                  onClick={this.handleAddRow}
                  color="success"
                  className="mt-3 mt-lg-0"
                >
                  Нэмэх{" "}
                </Button>{" "}
                <AvForm
                  className="repeater mt-3"
                  encType="multipart/form-data"
                  onValidSubmit={this.handleValidContactSubmit}
                >
                  <table style={{ width: "100%" }}>
                    <tbody>
                      {this.state.contacts.map((row, idx) => (
                        <tr id={"addr" + idx} key={idx}>
                          <td>
                            <div data-repeater-list="group-a">
                              <Row data-repeater-item>
                                <Col lg="3" className="mb-3">
                                  <AvField
                                    name={"[" + idx + "]position"}
                                    label={idx + 1 + ". Албан тушаал"}
                                    type="text"
                                    key={"position" + idx}
                                    errorMessage="Талбар хоосон байна!!"
                                    onChange={this.handlePositionChange(idx)}
                                    validate={{
                                      required: { value: true },
                                    }}
                                    value={row ? row.position : ""}
                                  />
                                </Col>
                                <Col lg="3" className="mb-3">
                                  <AvField
                                    name={"[" + idx + "]name"}
                                    label="Нэр"
                                    type="text"
                                    key={"contactName" + idx}
                                    errorMessage="Талбар хоосон байна!!"
                                    onChange={this.handleNameChange(idx)}
                                    validate={{
                                      required: { value: true },
                                    }}
                                    value={row ? row.name : ""}
                                  />
                                </Col>
                                <Col lg="2" className="mb-3">
                                  <AvField
                                    name={"[" + idx + "]phone"}
                                    label="Утас"
                                    type="number"
                                    key={"phone" + idx}
                                    errorMessage="Талбар хоосон байна!!"
                                    onChange={this.handlePhoneChange(idx)}
                                    validate={{
                                      required: { value: true },
                                      minLength: {
                                        value: 6,
                                        errorMessage:
                                          "Утасны дугаар багадаа 6 урттай байна",
                                      },
                                      maxLength: {
                                        value: 8,
                                        errorMessage:
                                          "Утасны дугаар ихдээ 8 урттай байна",
                                      },
                                    }}
                                    value={row ? row.phone : ""}
                                  />
                                </Col>
                                <Col lg="3" className="mb-3">
                                  <AvField
                                    name={"[" + idx + "]email"}
                                    label="Имэйл"
                                    type="email"
                                    errorMessage="Формат буруу байна!!"
                                    onChange={this.handleEmailChange(idx)}
                                    validate={{
                                      required: { value: true },
                                      email: { value: true },
                                    }}
                                    value={row ? row.email : ""}
                                  />
                                </Col>

                                <Col lg="1" className="align-self-center">
                                  <div className="d-grid">
                                    <Button
                                      onClick={e =>
                                        this.handleRemoveRow(e, idx)
                                      }
                                      color="danger"
                                      style={{ width: "100%" }}
                                      block
                                    >
                                      {" "}
                                      Устгах
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="customButton clearfix">
                    <Button
                      color="primary"
                      className="mt-3 mt-lg-0"
                      onClick={this.prevPage}
                    >
                      Өмнөх
                    </Button>
                    <Button
                      color="primary"
                      type="submit"
                      // onClick={this.nextPage}
                      className="mt-3 mt-lg-0"
                    >
                      Дараах
                    </Button>
                  </div>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}
MeetingContact.propTypes = {
  contacts: PropTypes.array,
  saveForm: PropTypes.func,
}
export default MeetingContact
