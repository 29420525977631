import React from "react"
import PropTypes from "prop-types"
import { map, get } from "lodash"
import { Card, CardBody, Col, Media, Row, Button } from "reactstrap"
import Example from "./renderPdf"
const AuditMeetingIntroduction = ({ meeting, results }) => {
  return (
    <Card>
      <CardBody>
        <div className="d-flex">
          <img src={meeting.companyLogo} alt="" className="avatar-sm me-4" />

          <div className="flex-1 overflow-hidden">
            <h5 className="text-truncate font-size-15">
              {meeting.meetingName}
            </h5>
            <p className="text-muted">{meeting.meetingType}</p>
          </div>
        </div>

        <h5 className="font-size-15 mt-4">Хурлын хаяг :</h5>

        <p className="text-muted">{meeting.address}</p>

        <div className="text-muted mt-4">
          {meeting.meetingDetails &&
            map(meeting.meetingDetails.points, (point, index) => (
              <p key={index}>
                <i className="mdi mdi-chevron-right text-primary me-1" />
                {point}
              </p>
            ))}
        </div>

        <Row className="task-dates">
          <Col sm="4" xs="6">
            <div className="mt-4">
              <h5 className="font-size-14">
                <i className="bx bx-calendar me-1 text-primary" /> Бүртгэлийн
                өдөр
              </h5>
              <p className="text-muted mb-0">{meeting.registrationDate}</p>
            </div>
          </Col>

          <Col sm="4" xs="6">
            <div className="mt-4">
              <h5 className="font-size-14">
                <i className="bx bx-calendar-check me-1 text-primary" />{" "}
                Зарласан өдөр
              </h5>
              <p className="text-muted mb-0">{meeting.declareDate}</p>
            </div>
          </Col>
        </Row>
        <Row className="task-dates">
          <Col sm="4" xs="6">
            <div className="mt-4">
              <h5 className="font-size-14">
                <i className="bx bx-calendar me-1 text-primary" /> Хурлын өдөр
              </h5>
              <p className="text-muted mb-0">{meeting.meetingDate}</p>
            </div>
          </Col>

          <Col sm="4" xs="6">
            <div className="mt-4 mb-4">
              <h5 className="font-size-14">
                <i className="bx bx-time me-1 text-primary" /> Хурлын цаг
              </h5>
              <p className="text-muted mb-0">
                {meeting.startTime + " " + meeting.endTime}
              </p>
            </div>
          </Col>
          <Example meeting={meeting} results={results} />
        </Row>
      </CardBody>
    </Card>
  )
}

AuditMeetingIntroduction.propTypes = {
  meeting: PropTypes.object,
  results: PropTypes.object,
}

export default AuditMeetingIntroduction
