import React, { Component } from "react"
import MetaTags from "react-meta-tags"
import {
  Container, Row, Col, Card, CardBody,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap"
import { isEmpty, size, map } from "lodash"
import { connect } from "react-redux"
import PropTypes from "prop-types"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Notification from "../../components/Notification"

import { getChartsData } from "store/actions"

class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reports: [],
      role: "",
      col: 3,
      notifications: [],
      token: ""
    }
    // this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      // console.log(localStorage.getItem("newNotification"))
      const ntf = localStorage.getItem("newNotification") ? JSON.parse(localStorage.getItem("newNotification")) : []
      const token = obj.token
      const role = obj.role
      const { onGetDashboard } = this.props
      setTimeout(() => this.setState({ subscribemodal: true }), 2000)
      onGetDashboard(token, role, this.props.history)
      this.setState({ notifications: ntf })
      this.setState({ token: token })
      this.setState({ role: role })

      // const ntf = localStorage.getItem("newNotification") ? JSON.parse(localStorage.getItem("newNotification")) : []
      this.setState({ notifications: ntf })
      // console.log(typeof ntf != "undefined" && ntf==[], ntf);
      if (typeof ntf != "undefined" && ntf.length != 0) {
        this.toggle();
      }

      console.log(this.state.notifications, ntf)

      console.log(this.state.notifications.length)
    }
  }
  handleChange() {

  }
  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }

  render() {
    const dashboard = this.props.dashboard
    const notifications = this.state.notifications
    // if (this.state.notifications.length === 0) {
    //   this.setState({ notifications: ntf });
    // }
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Хянах самбар | Цахим хурал</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Дашбоард" breadcrumbItem="Дашбоард" />
            <Row>
              <Col xl="12">
                <Row>
                  {this.state.role != "COMPANY" ? (
                    <Col md={this.state.col} key={"_col_1"}>
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">Компани</p>
                              <h4 className="mb-0">{dashboard.companyCount}</h4>
                            </div>
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                              <span className="avatar-title">
                                <i
                                  className={
                                    "bx bx-chevron-up-square font-size-24"
                                  }
                                />
                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  ) : (
                    ""
                  )}
                  <Col md={this.state.col} key={"_col_2"}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">Нийт хурал</p>
                            <h4 className="mb-0">
                              {this.state.role == "CUSTOMER"
                                ? dashboard.meetingCount
                                : dashboard.meetingCount}
                            </h4>
                          </div>
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                            <span className="avatar-title">
                              <i className={"bx bx-chair font-size-24"} />
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  {this.state.role != "ADMIN" && this.state.role != "AUDIT" ? (
                    <Col md={this.state.col} key={"_col_3"}>
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                                {this.state.role == "CUSTOMER"
                                  ? "Оролцсон хурал"
                                  : "Товлогдсон хурал"}
                              </p>
                              <h4 className="mb-0">
                                {this.state.role == "CUSTOMER"
                                  ? dashboard.meetedCount
                                  : (+dashboard.meetingCount -
                                    +dashboard.meetedCount).toString()}
                              </h4>
                            </div>
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                              <span className="avatar-title">
                                <i
                                  className={
                                    "bx bx-message-square-dots font-size-24"
                                  }
                                />
                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  ) : (
                    ""
                  )}
                  {this.state.role == "COMPANY" ? (
                    <Col md={this.state.col} key={"_col_4"}>
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                                {this.state.role != "CUSTOMER"
                                  ? "Харилцагчийн тоо"
                                  : ""}
                              </p>
                              <h4 className="mb-0">
                                {dashboard.customerCount}
                              </h4>
                            </div>
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                              <span className="avatar-title">
                                <i className={"bx bx-group font-size-24"} />
                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>
              </Col>
            </Row>
          </Container>
          <Modal
            size="lg"
            backdrop={"static"}
            isOpen={this.state.modal}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggle} tag="h4">
              {"Шинэ сонордуулга"}
            </ModalHeader>
            <ModalBody>

              <Notification notifications={this.state.notifications} token={this.state.token} toggle={this.toggle.bind(this)} ></Notification>

            </ModalBody>
          </Modal>
        </div>
      </React.Fragment>
    )
  }
}

Dashboard.propTypes = {
  dashboard: PropTypes.object,
  // notifications: PropTypes.any,
  error: PropTypes.any,
  history: PropTypes.object,
  onGetDashboard: PropTypes.func,
}

const mapStateToProps = state => {
  return {
    dashboard: state.Dashboard.chartsData,
    // notifications: state.Dashboard.chartsData,
    error: state.Dashboard.error,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetDashboard: (token, role, history) =>
    dispatch(getChartsData(token, role, history)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
