import { PROFILE_ERROR, PROFILE_SUCCESS, EDIT_PROFILE, CHANGE_PASSWORD, RESET_PROFILE_FLAG} from "./actionTypes"

export const changePassword = (token, oldPassword, password, history) => {
  return {
    type: CHANGE_PASSWORD,
    payload: { request: { token, oldPassword, password }, history },
  }
}
export const editProfile = user => {
  return {
    type: EDIT_PROFILE,
    payload: { user },
  }
}

export const profileSuccess = msg => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  }
}

export const profileError = error => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  }
}

export const resetProfileFlag = error => {
  return {
    type: RESET_PROFILE_FLAG,
  }
}
