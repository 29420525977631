import React, { Component } from "react"
import PropTypes from 'prop-types'

import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"

// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"

// actions
import { apiError, loginUser, socialLogin } from "../../store/actions"

// import images
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/34x34.svg"
import lightlogo from "../../assets/images/34x34.svg"

import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import { v4 as uuidv4 } from 'uuid';
// import uuid from 'react-uuid'

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {}

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this)
  }

  // handleValidSubmit
  handleValidSubmit(event, values) {
    // const { error } = this.props;
    this.props.loginUser(values, this.props.history)
    // if (error !== "") {
    //   this.showToast("error", error, "Алдаа гарлаа")
    // }
  }

  // componentDidMount() {
  //   this.props.apiError("")
  // }

  signIn = (res, type) => {
    const { socialLogin } = this.props
  }

  showToast(type, message, title) {

    toastr.options = {
      timeOut: 5000,
      closeButton: true,
      progressBar: true,
      newestOnTop: true,
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
      showDuration: 300,
      hideDuration: 1000,
    }

    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (type === "info") toastr.info(message, title)
    else if (type === "warning") toastr.warning(message, title)
    else if (type === "error") toastr.error(message, title)
    else toastr.success(message, title)
  }

  ngDan() {
    console.log(uuidv4());

    var state = base64_encode('{"action":"DAN_LOGIN","uuid":"' + uuidv4() + '"}');
    localStorage.setItem('state', state);
    var service_structure = '[{"wsdl":"https://xyp.gov.mn/citizen-1.3.0/ws?WSDL","services":["WS100101_getCitizenIDCardInfo"]}]';
    var url = "https://sso.gov.mn/oauth2/authorize?response_type=code&client_id=d405fcdd5aceb0b529ac968c-0c2949539e3b7bf251209bd36d49d7ba&scope="
      + base64_encode(unescape(encodeURIComponent(service_structure))) + "&redirect_uri=https://ehural.mn/authorized&state=" + state;
    window.open(url, '_blank')


    console.log(atob('W3sic2VydmljZXMiOiBbIldTMTAwMTAxX2dldENpdGl6ZW5JRENhcmRJbmZvIl0sICJ3c2RsIjogImh0dHBzOi8veHlwLmdvdi5tbi9jaXRpemVuLTEuMi4wL3dzP1dTREwifSwgeyJzZXJ2aWNlcyI6IFsiV1MxMDAyMDFfZ2V0UHJvcGVydHlJbmZvIl0sICJ3c2RsIjogImh0dHBzOi8veHlwLmdvdi5tbi9wcm9wZXJ0eS0xLjIuMC93cz9XU0RMIiwgInBhcmFtcyI6IHsiV1MxMDAyMDFfZ2V0UHJvcGVydHlJbmZvIjogeyJwcm9wZXJ0eU51bWJlciI6ICJ2YWx1ZSJ9fX1d'));
    // V-0106 - REDIRECT URI мэдээлэл зөрүүтэй байна

    // return this.http.get<any>(url, { params: params });
  }

  render() {
    return (
      <React.Fragment>
        {/* <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="bx bx-home h2" />
          </Link>
        </div> */}
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Цахим хурал</h5>
                          <p>Нэвтрэх</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div className="auth-logo">
                      <Link to="/" className="auth-logo-light">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={lightlogo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                      <Link to="/" className="auth-logo-dark">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={this.handleValidSubmit}
                      >
                        {this.props.error && this.props.error ? (
                          <Alert color="danger">{this.props.error}</Alert>
                        ) : null}

                        <div className="mb-3">
                          <AvField
                            name="username"
                            label="Нэвтрэх нэр"
                            value=""
                            className="form-control"
                            placeholder="Нэвтрэх нэр"
                            type="text"
                            required
                            errorMessage="Нэвтрэх нэр оруулна уу"
                          />
                        </div>

                        <div className="mb-3">
                          <AvField
                            name="password"
                            label="Нууц үг"
                            value=""
                            type="password"
                            required
                            placeholder="Нууц үг"
                            errorMessage="Нууц үг оруулна уу"
                          />
                        </div>

                        {/* <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customControlInline"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customControlInline"
                          >
                            Remember me
                          </label>
                        </div> */}

                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            Нэвтрэх
                          </button>
                        </div>

                        <div className="mt-4 text-center">
                          <h5 className="font-size-14 mb-3">Өөр хэлбэрээр нэвтрэх</h5>

                          <ul className="list-inline">
                            <li className="list-inline-item">

                              <button
                                className="btn btn-primary btn-block"
                                type="button"
                                onClick={this.ngDan}
                              >
                                ДАН систем
                              </button>
                            </li>
                          </ul>
                        </div>

                        <div className="mt-4 text-center">
                          <Link to="/forgot-password" className="text-muted">
                            <i className="mdi mdi-lock me-1" /> Нууц үгээ мартсан уу?
                          </Link>
                        </div>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  {/* <p>
                    Don't have an account ?{" "}
                    <Link
                      to="register"
                      className="fw-medium text-primary"
                    >
                      {" "}
                      Signup now{" "}
                    </Link>{" "}
                  </p> */}
                  <p>
                    © {new Date().getFullYear()} DigitalMethod
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

Login.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func
}

const mapStateToProps = state => {
  return { error: state.Login.error }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, socialLogin })(Login)
)
