import {
  GET_MEMBERS,
  GET_MEMBERS_FAIL,
  GET_MEMBERS_SUCCESS,
  ADD_NEW_MEMBER,
  ADD_MEMBER_SUCCESS,
  ADD_MEMBER_FAIL,
  UPDATE_MEMBER,
  UPDATE_MEMBER_SUCCESS,
  UPDATE_MEMBER_FAIL,
  DELETE_MEMBER,
  DELETE_MEMBER_SUCCESS,
  DELETE_MEMBER_FAIL,
  GET_MEMBER_DTL,
  GET_MEMBER_DTL_FAIL,
  GET_MEMBER_DTL_SUCCESS,
  GET_MEET_STORY_DTL,
  GET_MEET_STORY_DTL_SUCCESS,
  GET_MEET_STORY_DTL_FAIL,
  GET_STOKE_TYPE,
  GET_STOKE_TYPE_SUCCESS,
  GET_STOKE_TYPE_FAIL,
  GET_EXCELS,
  GET_EXCELS_SUCCESS,
  GET_EXCELS_FAIL,
} from "./actionTypes"

/*----------------COMPANY MEMBER-------------------------------*/
export const getCompanyMembers = (token, page, totalPage, history) => ({
  type: GET_MEMBERS,
  payload: { request: { token, page, totalPage }, history },
})

export const getMembersSuccess = response => ({
  type: GET_MEMBERS_SUCCESS,
  members: response.data,
  totalPage: response.recordsTotal,
})

export const getMembersFail = error => ({
  type: GET_MEMBERS_FAIL,
  payload: error,
})

/*--------------------------------------------------*/
export const addNewMember = customer => ({
  type: ADD_NEW_MEMBER,
  payload: customer,
})

export const addMemberSuccess = customer => ({
  type: ADD_MEMBER_SUCCESS,
  payload: customer,
  message: typeof customer.message != "undefined" ? [{id: 1, content: customer.message}] : [],
})

export const addMemberFail = error => ({
  type: ADD_MEMBER_FAIL,
  payload: error,
})
/*--------------------------------------------------*/
export const updateMember = customer => ({
  type: UPDATE_MEMBER,
  payload: customer,
})

export const updateMemberSuccess = customer => ({
  type: UPDATE_MEMBER_SUCCESS,
  payload: customer,
})

export const updateMemberFail = error => ({
  type: UPDATE_MEMBER_FAIL,
  payload: error,
})
/*--------------------------------------------------*/
export const deleteMember = customer => ({
  type: DELETE_MEMBER,
  payload: customer,
})

export const deleteMemberSuccess = customer => ({
  type: DELETE_MEMBER_SUCCESS,
  payload: customer,
})

export const deleteMemberFail = error => ({
  type: DELETE_MEMBER_FAIL,
  payload: error,
})

/*----------------COMPANY MEMBER DETAIL-------------------------------*/
export const getMemberDtl = (token, customerId, history) => ({
  type: GET_MEMBER_DTL,
  payload: { request: { token, customerId }, history },
})

export const getMemberDtlSuccess = response => ({
  type: GET_MEMBER_DTL_SUCCESS,
  member: response,
})

export const getMemberDtlFail = error => ({
  type: GET_MEMBER_DTL_FAIL,
  payload: error,
})

/*--------------------------------------------------*/
export const getMeetStory = (token, customerId) => ({
  type: GET_MEET_STORY_DTL,
  payload: { token, customerId },
})

export const getMeetStorySuccess = response => ({
  type: GET_MEET_STORY_DTL_SUCCESS,
  story: response,
})

export const getMeetStoryFail = error => ({
  type: GET_MEET_STORY_DTL_FAIL,
  payload: error,
})

/*--------------------------------------------------*/
export const getStokeType = token => ({
  type: GET_STOKE_TYPE,
  payload: { token },
})

export const getStokeTypeSuccess = response => ({
  type: GET_STOKE_TYPE_SUCCESS,
  stokeType: response,
})

export const getStokeTypeFail = error => ({
  type: GET_STOKE_TYPE_FAIL,
  payload: error,
})

/*--------------------------------------------------*/
export const getExcels = (datas, token) => ({
  type: GET_EXCELS,
  payload: { datas, token },
})

export const getExcelsSuccess = response => ({
  type: GET_EXCELS_SUCCESS,
  data: response.data,
  message: response.newNotification,
})

export const getExcelsFail = error => ({
  type: GET_EXCELS_FAIL,
  payload: error,
})
