import { takeEvery, put, call, takeLatest } from "redux-saga/effects"

// Login Redux States
import {
  LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN,
  LOGIN_DAN,
  LOGIN_DAN_SUCCESS
} from "./actionTypes"
import { loginSuccess, logoutUserSuccess, apiError, loginDanSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  postSocialLogin,
  postJavaLogin,
  postUrlEncodeDan
} from "../../../helpers/fakebackend_helper"

const fireBaseBackend = getFirebaseBackend()

function* loginUser({ payload: { user, history } }) {
  const expireDate = new Date(new Date().getTime() + 3600 * 1000)
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.loginUser,
        user.username,
        user.password
      )
      yield put(loginSuccess(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "java") {
      const response = yield call(postJavaLogin, {
        username: user.username,
        password: user.password,
        //token: user.token
      })
      if (response.responseResultType === "SUCCESS") {
        localStorage.setItem("authUser", JSON.stringify(response))
        localStorage.setItem("expireDate", expireDate)
        // console.log(response.newNotification)
        if (response.newNotification) {
          localStorage.setItem("newNotification", JSON.stringify(response.newNotification))
        }
        yield put(loginSuccess(response))
        history.push("/dashboard")
        setTimeout(() => {
          history.push("/logout")
        },
          3600000)
      } else {
        yield put(apiError(response.failureMessages.message[0].failureMessage))
      }
    }
  } catch (error) {
    console.log(error);
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")
    localStorage.removeItem("expireDate")
    localStorage.removeItem("newNotification")

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))
    }
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    const expireDate = new Date(new Date().getTime() + 3600 * 1000)
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend()
      const response = yield call(
        fireBaseBackend.socialLoginUser,
        data,
        type
      )
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    } else {
      const response = yield call(postSocialLogin, data)
      localStorage.setItem("authUser", JSON.stringify(response))
      localStorage.setItem("expireDate", expireDate)
      yield put(loginSuccess(response))
    }
    history.push("/dashboard")
  } catch (error) {
    yield put(apiError(error))
  }
}



function* loginDan({ payload: { url, history } }) {
  const expireDate = new Date(new Date().getTime() + 3600 * 1000)
  try {

      const response = yield call(postUrlEncodeDan(url))
      console.log(response)
  } catch (error) {
    console.log(error);
    yield put(apiError(error))
  }
}


function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
  yield takeEvery(LOGIN_DAN, loginDan)
}

export default authSaga
