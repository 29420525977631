import React, { Component } from "react"
import { Table } from "reactstrap"
import { map } from "lodash"
import PropTypes from "prop-types"
import ChoiseResult from "./audit-choise-result"
import ChoiseResultCustomer from "../../Company/CompanyMeeting/CompanyMeetingDtl/choise-result-customer"

class AuditMeetingResult extends Component {
  constructor(props) {
    super(props)
    this.state = {
      token: "",
      results: [],
    }
  }
  render() {
    const { results } = this.props
    return (
      <React.Fragment>
        <div className="mt-4" key={"attendance_vote"}>
          <div className="d-flex flex-wrap">
            <h5 className="font-size-16 me-3">ХУРЛЫН ИРЦ</h5>
          </div>
          <hr className="mt-2" />

          <div className="table-responsive">
            <Table
              className="table table-striped table-bordered"
              key={"attendance_vote_table"}
            >
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Овог</th>
                  <th scope="col">Нэр</th>
                  <th scope="col">Шууд оролцсон</th>
                  <th scope="col">Урьдчилан санал илгээсэн</th>
                </tr>
              </thead>
              <tbody>
                {results.meetedCustomers
                  ? map(results.meetedCustomers, (customer, index) => (
                      <tr key={"attendance" + index + customer.id}>
                        <td>{index + 1}</td>
                        <td>{customer.lastname}</td>
                        <td>{customer.firstname}</td>
                        <td>
                          <div className="text-center">
                            {customer.isMeeting ? "+" : ""}
                          </div>
                        </td>
                        <td>
                          {customer.isAdvance ? customer.advanceDate : ""}
                        </td>
                      </tr>
                    ))
                  : ""}
              </tbody>
            </Table>
          </div>
        </div>

        <div className="mt-4" key={"audit_vote"}>
          <div className="d-flex flex-wrap">
            <h5 className="font-size-16 me-3">ТООЛЛОГЫН КОМИССЫН ДҮГНЭЛТ</h5>
          </div>
          <hr className="mt-2" />

          <div className="table-responsive">
            <Table
              className="table table-striped table-bordered"
              key={"audit_vote_table"}
            >
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Хэлэлцэх асуудал</th>
                  <th scope="col">Төрөл</th>
                  <th scope="col">Саналын төрөл</th>
                  <th scope="col" colSpan="3">
                    Саналын үр дүн
                  </th>
                </tr>
              </thead>
              <tbody>
                {results.voteResultDto
                  ? map(results.voteResultDto, (agenda, index) => (
                      <tr key={"audit" + index + agenda.displayIndex}>
                        <td>{agenda.displayIndex}</td>
                        <td>
                          <div className="p text-muted mb-0">
                            {agenda.content}
                          </div>
                        </td>
                        <td>{agenda.agendaType}</td>
                        <td>{agenda.voteType}</td>
                        {agenda.agendaTypeId == 1 ? (
                          <>
                            <td
                              key={
                                "agenda-totalAccept" +
                                index +
                                agenda.displayIndex
                              }
                            >
                              Зөвшөөрсөн
                              <div className="form-check font-size-12">
                                <label className="form-check-label">
                                  {agenda.totalAccept +
                                    "/" +
                                    results.meetedCustomerCount +
                                    " (" +
                                    agenda.acceptPercent +
                                    "%)"}
                                </label>
                              </div>
                            </td>
                            <td
                              key={
                                "agenda-totalSuspend" +
                                index +
                                agenda.displayIndex
                              }
                            >
                              Түдгэлзсэн
                              <div className="form-check font-size-12">
                                <label className="form-check-label">
                                  {agenda.totalSuspend +
                                    "/" +
                                    results.meetedCustomerCount +
                                    " (" +
                                    agenda.suspendPercent +
                                    "%)"}
                                </label>
                              </div>
                            </td>
                            <td
                              key={
                                "agenda-totalReject" +
                                index +
                                agenda.displayIndex
                              }
                            >
                              Татгалзсан
                              <div className="form-check font-size-12">
                                <label className="form-check-label">
                                  {agenda.totalReject +
                                    "/" +
                                    results.meetedCustomerCount +
                                    " (" +
                                    agenda.rejectPercent +
                                    "%)"}
                                </label>
                              </div>
                            </td>
                          </>
                        ) : (
                          <>
                            <ChoiseResult
                              choiseList={agenda.choiseList}
                              key={"choiseList" + agenda.displayIndex}
                            />
                          </>
                        )}
                      </tr>
                    ))
                  : ""}
              </tbody>
            </Table>
          </div>
        </div>

        <div className="mt-4" key={"dtl_vote"}>
          <div className="d-flex flex-wrap">
            <h5 className="font-size-16 me-3">
              САНАЛ ХУРААЛТЫН ДЭЛГЭРЭНГҮЙ ҮР ДҮН
            </h5>
          </div>
          <hr className="mt-2" />

          <div className="table-responsive">
            <Table
              className="table table-striped table-bordered"
              key={"dtl_vote_table"}
            >
              <thead>
                <tr>
                  <th scope="col" rowSpan="2">
                    #
                  </th>
                  <th scope="col" rowSpan="2">
                    Овог
                  </th>
                  <th scope="col" rowSpan="2">
                    Нэр
                  </th>
                  <th
                    scope="col"
                    className="text-center"
                    colSpan={
                      results.voteResultDto ? results.voteResultDto.length : 1
                    }
                  >
                    Хэлэлцэх асуудлын дугаар
                  </th>
                </tr>
                <tr>
                  {results.voteResultDto
                    ? map(results.voteResultDto, (agenda, index) => (
                        <th
                          scope="col"
                          key={"customer-column" + index + agenda.displayIndex}
                        >
                          {agenda.displayIndex}
                        </th>
                      ))
                    : ""}
                </tr>
              </thead>
              <tbody>
                {results.voteResultDto
                  ? map(
                      results.voteResultDto[0].meetedCustomerVote,
                      (customer, cI) => (
                        <tr key={"customer" + cI + customer.id}>
                          <td>{cI + 1}</td>
                          <td>{customer.lastname}</td>
                          <td>{customer.firstname}</td>
                          {map(results.voteResultDto, (agendaCl, aI) =>
                            agendaCl.agendaTypeId == 1 ? (
                              <td key={"customer-row" + aI}>
                                {agendaCl.meetedCustomerVote[cI]
                                  ? agendaCl.meetedCustomerVote[cI].cusAccept
                                    ? "+"
                                    : agendaCl.meetedCustomerVote[cI].cusReject
                                    ? "x"
                                    : agendaCl.meetedCustomerVote[cI].cusSuspend
                                    ? "-"
                                    : ""
                                  : ""}
                              </td>
                            ) : agendaCl.agendaTypeId != 2 ? (
                              <ChoiseResultCustomer
                                choiseList={
                                  agendaCl.meetedCustomerVote[cI]
                                    .CustomerChoiseList
                                    ? agendaCl.meetedCustomerVote[cI]
                                        .CustomerChoiseList
                                    : []
                                }
                                key={
                                  "choiseList_column" + agendaCl.displayIndex
                                }
                              />
                            ) : (
                              <td></td>
                            )
                          )}
                        </tr>
                      )
                    )
                  : ""}
              </tbody>
            </Table>
            <code className="float-end">
              (+) Зөвшөөрсөн (-) Түтгэлзсэн (x) Татгалзсан
            </code>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

AuditMeetingResult.propTypes = {
  results: PropTypes.array,
}

export default AuditMeetingResult

// const mapStateToProps = state => {
//   return {
//     votes: state.CustomerMeetings.votes,
//   }
// }

// const mapDispatchToProps = dispatch => ({
//   onStartVote: (token, agendaId) => dispatch(startVote(token, agendaId)),
//   onSaveVote: vote => dispatch(saveVote(vote)),
// })

// export default connect(mapStateToProps, mapDispatchToProps)(MeetingResultt)
