/* SHAREHOLDER */
export const GET_SHARES = "GET_SHARES"
export const GET_SHARES_SUCCESS = "GET_SHARES_SUCCESS"
export const GET_SHARES_FAIL = "GET_SHARES_FAIL"

export const ADD_NEW_SHARES = "ADD_NEW_SHARES"
export const ADD_SHARES_SUCCESS = "ADD_SHARES_SUCCESS"
export const ADD_SHARES_FAIL = "ADD_SHARES_FAIL"

export const UPDATE_SHARES = "UPDATE_SHARES"
export const UPDATE_SHARES_SUCCESS = "UPDATE_SHARES_SUCCESS"
export const UPDATE_SHARES_FAIL = "UPDATE_SHARES_FAIL"

export const DELETE_SHARES = "DELETE_SHARES"
export const DELETE_SHARES_SUCCESS = "DELETE_SHARES_SUCCESS"
export const DELETE_SHARES_FAIL = "DELETE_SHARES_FAIL"

export const GET_SHARES_DTL = "GET_SHARES_DTL"
export const GET_SHARES_DTL_SUCCESS = "GET_SHARES_DTL_SUCCESS"
export const GET_SHARES_DTL_FAIL = "GET_SHARES_DTL_FAIL"


export const GET_SHARESMEET_DTL = "GET_SHARESMEET_DTL"
export const GET_SHARESMEET_DTL_SUCCESS = "GET_SHARESMEET_DTL_SUCCESS"
export const GET_SHARESMEET_DTL_FAIL = "GET_SHARESMEET_DTL_FAIL"