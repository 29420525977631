import React from "react"
import PropTypes from "prop-types"
import { Card, CardBody, CardTitle, Table } from "reactstrap"
import { Link } from "react-router-dom"
import { map } from "lodash"

const CompanyMeetingAdvanceVoteTime = ({ time }) => {
  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">Урьдчилсан санал авах хуваарь</CardTitle>
        <div className="table-responsive">
          <Table className="table align-middle table-nowrap">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Хэлэлцэх асуудал</th>
                <th scope="col">Санал хураалт эхлэх огноо</th>
                <th scope="col">Санал хураалт дуусах огноо</th>
              </tr>
            </thead>
            <tbody>
              {map(time, (voteTime, k) => (
                <tr key={"_voteTime_" + k}>
                  <td>{k + 1}</td>
                  <td>{voteTime.name}</td>
                  <td>{voteTime.advanceVoteStartTime}</td>
                  <td>{voteTime.advanceVoteFinishTime}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  )
}

CompanyMeetingAdvanceVoteTime.propTypes = {
  time: PropTypes.array,
}

export default CompanyMeetingAdvanceVoteTime
