import React, { Component } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import { withRouter } from "react-router-dom"
import { map, isEmpty } from "lodash"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import classnames from "classnames"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

import CompanyMeetingIntroduction from "./company-meeting-indroduction-dtl"
import CompanyMeetingContact from "./company-meeting-contact-dtl"
import CompanyMeetingAttendance from "./company-meeting-attendance-dtl"
import ComapnyMeetingDocument from "./company-meeting-document-dtl"
import CompanyMeetingAgenda from "./company-meeting-agenda-dtl"
import CompanyMeetingResult from "./company-meeting-result-dtl"
import ComapnyMeetingAudit from "./meeting-dtl-audit"
import CompanyMeetingCustomer from "./company_meeting_customer"
import CompanyMeetingAdvanceVoteTime from "./company-meeting-advanceVoteTime"
import {
  getComMeetingDtl,
  getMeetingCustomer,
  getComMeetingAgenda,
  getComMeetingDocument,
  getComMeetingResult,
  setComMeetingStart,
  setComMeetingFinish,
} from "store/actions"

class CompanyMeetingDtl extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: "1",
      token: "",
      meetingId: "",
      votes: [],
    }
    this.toggle = this.toggle.bind(this)
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  componentDidMount() {
    const {
      match: { params },
      onGetMeeting,
      onGetMeetingAgenda,
      onGetMeetingDocument,
      onGetMeetingResult,
      onGetCustomers,
    } = this.props
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      if (params && params.id) {
        this.setState({ meetingId: params.id })
        onGetMeeting(obj.token, params.id, this.props.history)
        onGetMeetingAgenda(obj.token, params.id, this.props.history)
        onGetMeetingDocument(obj.token, params.id, this.props.history)
        onGetMeetingResult(obj.token, params.id, this.props.history)
        onGetCustomers(
          obj.token,
          params.id,
          this.props.page,
          this.props.totalPage,
          this.props.history
        )
      } else {
        onGetMeeting(obj.token, 0, this.props.history) //remove this after full integration
        onGetCustomers(
          obj.token,
          0,
          this.props.page,
          this.props.totalPage,
          this.props.history
        )
        onGetMeetingAgenda(obj.token, 0, this.props.history)
        onGetMeetingDocument(obj.token, 0, this.props.history)
        onGetMeetingResult(obj.token, 0, this.props.history)
      }
      this.setState({ token: obj.token })
    }
  }
  handleStart = e => {
    const { error } = this.props
    if (!this.props.meeting.isStart) {
      this.props.onSetStart(this.state.token, this.state.meetingId)
    } else {
      this.props.onSetFinish(this.state.token, this.state.meetingId)
    }
    if (error !== "") {
      this.showToast("success", "Амжиттай илгээгдлээ.", "Амжилттай")
      this.props.meeting.isStart = true
    } else {
      this.showToast("error", error, "Алдаа гарлаа")
    }
  }

  showToast(type, message, title) {
    toastr.options = {
      timeOut: 5000,
      closeButton: true,
      progressBar: true,
      newestOnTop: true,
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
      showDuration: 300,
      hideDuration: 1000,
    }

    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (type === "info") toastr.info(message, title)
    else if (type === "warning") toastr.warning(message, title)
    else if (type === "error") toastr.error(message, title)
    else toastr.success(message, title)
  }

  render() {
    const { meeting, agendas, documents, results, customers } = this.props
    // console.log("CUSTOMER: " + customers)
    // console.log("meetingIntr" + meeting)
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Хурлын дэлгэрэнгүй | Цахим хурал</title>
          </MetaTags>
          <Container fluid={true}>
            <Breadcrumbs title="Хурал" breadcrumbItem="Хурлын дэлгэрэнгүй" />
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "1",
                          })}
                          onClick={() => {
                            this.toggle("1")
                          }}
                        >
                          Хурлын мэдээлэл
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "2",
                          })}
                          onClick={() => {
                            this.toggle("2")
                          }}
                        >
                          Хэлэлцэх асуудал
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "3",
                          })}
                          onClick={() => {
                            this.toggle("3")
                          }}
                        >
                          Баримт бичиг
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "4",
                          })}
                          onClick={() => {
                            this.toggle("4")
                          }}
                        >
                          Хурлын оролцогч
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "5",
                          })}
                          onClick={() => {
                            this.toggle("5")
                          }}
                        >
                          Саналын түүх
                        </NavLink>
                      </NavItem>
                    </Nav>

                    <TabContent
                      activeTab={this.state.activeTab}
                      className="p-3 text-muted"
                    >
                      <TabPane tabId="1">
                        <Row>
                          <Col sm="12">
                            <CardText className="mb-0">
                              {!isEmpty(meeting) && (
                                <>
                                  <Row>
                                    <Col lg="8">
                                      <CompanyMeetingIntroduction
                                        meeting={meeting}
                                      />
                                      <CompanyMeetingAdvanceVoteTime
                                        time={
                                          meeting.isAdvanceVote
                                            ? meeting.advanceVoteTime
                                            : []
                                        }
                                      />
                                    </Col>

                                    <Col lg="4">
                                      <CompanyMeetingContact
                                        team={meeting.contactDtos}
                                      />
                                      <ComapnyMeetingAudit
                                        team={meeting.auditDto}
                                      />

                                      <CompanyMeetingAttendance
                                        clickAttendance={this.handleStart}
                                        participatingCustomerCount={
                                          meeting.participatingCustomerCount
                                        }
                                        membersCount={meeting.membersCount}
                                        isStart={meeting.isStart}
                                      />
                                    </Col>
                                  </Row>
                                </>
                              )}
                            </CardText>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="2">
                        <Row>
                          <Col sm="12">
                            <CardText className="mb-0">
                              <CompanyMeetingAgenda
                                agendas={agendas}
                                meetingId={this.state.meetingId}
                                token={this.state.token}
                                votes={this.state.votes}
                              />
                            </CardText>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="3">
                        <Row>
                          <Col sm="12">
                            <CardText className="mb-0">
                              <ComapnyMeetingDocument documents={documents} />
                            </CardText>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="4">
                        <Row>
                          <Col sm="12">
                            <CardText className="mb-0">
                              <CompanyMeetingCustomer customers={customers} />
                            </CardText>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="5">
                        <Row>
                          <Col sm="12">
                            <CardText className="mb-0">
                              <CompanyMeetingResult
                                results={results}
                                meeting={meeting}
                              />
                            </CardText>
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

CompanyMeetingDtl.propTypes = {
  meeting: PropTypes.object,
  attendance: PropTypes.object,
  documents: PropTypes.array,
  customers: PropTypes.array,
  agendas: PropTypes.array,
  results: PropTypes.object,
  onGetMeeting: PropTypes.func,
  onGetMeetingAgenda: PropTypes.func,
  onGetMeetingDocument: PropTypes.func,
  onGetMeetingResult: PropTypes.func,
  onGetCustomers: PropTypes.func,
  onSetStart: PropTypes.func,
  onSetFinish: PropTypes.func,
  history: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    meeting: state.CompanyMeet.meeting,
    customers: state.CompanyMeet.customers,
    agendas: state.CompanyMeet.agendas,
    documents: state.CompanyMeet.documents,
    results: state.CompanyMeet.results,
    attendance: state.CompanyMeet.attendance,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetMeeting: (token, meetingId, history) =>
    dispatch(getComMeetingDtl(token, meetingId, history)),
  onGetCustomers: (token, meetingId, page, totalPage, history) =>
    dispatch(getMeetingCustomer(token, meetingId, page, totalPage, history)),
  onGetMeetingAgenda: (token, meetingId, history) =>
    dispatch(getComMeetingAgenda(token, meetingId, history)),
  onGetMeetingDocument: (token, meetingId, history) =>
    dispatch(getComMeetingDocument(token, meetingId, history)),
  onGetMeetingResult: (token, meetingId, history) =>
    dispatch(getComMeetingResult(token, meetingId, history)),
  onSetStart: (token, meetingId, history) =>
    dispatch(setComMeetingStart(token, meetingId, history)),
  onSetFinish: (token, meetingId, history) =>
    dispatch(setComMeetingFinish(token, meetingId, history)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CompanyMeetingDtl))
