import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import { connect } from "react-redux"
import { isEmpty, size } from "lodash"
import Notification from "../../../components/Notification"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  FormGroup,
  Table,
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  ButtonDropdown,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import * as moment from "moment"
import { Link } from "react-router-dom"
import toastr from "toastr"
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  AvForm,
  AvField,
  AvRadio,
  AvRadioGroup,
} from "availity-reactstrap-validation"
import {
  getCompanyShares,
  addNewShares,
  updateShares,
  deleteShares,
  getExcels,
} from "store/actions"
import Excelformat from "../../../assets/file/Template_Members_001.xlsx"
import css from "./style.module.css"
import dateFormat from "dateformat"
const now = new Date().toJSON().split("T")[0]

class Shareholder extends Component {
  constructor(props) {
    super(props)
    this.state = {
      shares: [],
      token: "",
      isAccredit: false,
      isCitizen: true,
      isExcel: false,
      CompanyShareholderColumns: [
        {
          text: "id",
          dataField: "id",
          sort: true,
          hidden: true,
          formatter: (cellContent, user) => <>{row.id}</>,
        },
        {
          dataField: "firstname",
          text: "Овог / Нэр",
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <p className="mb-1">
                <Link
                  to={`/company/shareholder/dtl/${row.id}`}
                  className="text-dark"
                >
                  {row.firstname}
                </Link>
              </p>
              <p className="mb-0">{row.lastname}</p>
            </>
          ),
        },
        {
          text: "Утас / И-Мэйл",
          dataField: "phone",
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <p className="mb-1">{row.phone}</p>
              <p className="mb-0">{row.email}</p>
            </>
          ),
        },
        {
          dataField: "register",
          text: "Регистр",
          sort: true,
        },
        {
          dataField: "position",
          text: "Албан тушаал",
          sort: true,
        },
        {
          dataField: "quantity",
          text: "Хувьцааны тоо",
          sort: true,
        },

        {
          dataField: "registrationDate",
          text: "Бүртгүүлсэн огноо",
          sort: true,
          formatter: (cellContent, row) =>
            this.handleValidDate(row.registrationDate),
        },
        {
          dataField: "isAccredit",
          text: "Итгэмжлэлтэй эсэх",
          sort: true,
          formatter: (cellContent, row) => (
            <>
              {row.isAccredit ? (
                <Badge color="success" className="bg-success font-size-12">
                  Тийм
                </Badge>
              ) : (
                <Badge color="danger" className="bg-danger font-size-12">
                  Үгүй
                </Badge>
              )}
            </>
          ),
        },

        {
          dataField: "position",
          text: "Албан тушаал",
          sort: true,
        },
        {
          dataField: "stockType",
          text: "Хувьцааны төрөл",
          sort: true,
        },
        {
          dataField: "menu",
          isDummyField: true,
          text: "Үйлдэл",
          formatter: (cellContent, customer) => (
            <UncontrolledDropdown>
              <DropdownToggle href="#" className="card-drop" tag="a">
                <i className="mdi mdi-dots-horizontal font-size-18" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  href="#"
                  onClick={() => this.handleCustomerClick(customer)}
                >
                  <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
                  Засах
                </DropdownItem>
                <DropdownItem
                  href="#"
                  onClick={() => this.handleDeleteCustomer(customer)}
                >
                  <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />{" "}
                  Устгах
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          ),
        },
      ],
    }
    this.handleCustomerClick = this.handleCustomerClick.bind(this)
    this.toggle = this.toggle.bind(this)
    this.handleValidCustomerSubmit = this.handleValidCustomerSubmit.bind(this)
    this.handleCustomerClicks = this.handleCustomerClicks.bind(this)
  }

  componentDidMount() {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      const { shares, onGetShares } = this.props
      const token = obj.token
      if (shares && !shares.length) {
        onGetShares(
          obj.token,
          this.props.page,
          this.props.totalPage,
          this.props.history
        )
      }
      this.setState({ shares })
      this.setState({ token })
      // onGetStokeType(obj.token)
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { shares } = this.props
    if (!isEmpty(shares) && size(prevProps.shares) !== size(shares)) {
      this.setState({ shares: {}, isEdit: false })
    }
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }

  handleCustomerClicks = arg => {
    this.setState({
      selectedCustomer: arg,
      shares: {
        lastname: "",
        firstname: "",
        phone: "",
        email: "",
        register: "",
        positionId: "",
        registrationDate: "",
        password: "",
        quantity: "",
        isAccredit: "",
        accreditInfo: {
          accFirstname: "",
          accLastname: "",
          accRegister: "",
          accPhone: "",
          accStartDate: "",
          accExpiryDate: "",
        },
      },
      isExcel: false,
      isEdit: false,
    })
    this.toggle()
  }

  handleDeleteCustomer = customer => {
    const { error } = this.props
    const deleteShares = {
      token: this.state.token,
      customerId: customer.id,
    }
    const { onDeleteShares } = this.props
    swal({
      title: "Устгах уу?",
      text: "Та гишүүний мэдээлэл устгах гэж байна!",
      icon: "info",
      buttons: {
        cancel: {
          text: "Үгүй",
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
        confirm: {
          text: "Тийм",
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        onDeleteShares(deleteShares)
        if (error == "") {
          this.showToast(
            "success",
            "Устгах үйлдэл амжилттай боллоо.",
            "Амжилттай"
          )
        } else {
          this.showToast("error", error, "Алдаа гарлаа")
        }
      } else {
        // swal("Your imaginary file is safe!");
      }
    })
    // if (error !== "") {

    //     this.showToast("success", "Усгах үйлдэл амжилттай боллоо.", "Амжилттай")
    // } else {
    //     this.showToast("error", error, "Алдаа гарлаа")
    // }
  }
  handleExcel = arg => {
    this.setState({
      isEdit: true,
      isExcel: true,
      //notifications: [],
    })

    this.toggle()
  }
  handleCustomerClick = arg => {
    const customer = arg
    this.setState({
      shares: {
        customerId: customer.id,
        lastname: customer.lastname,
        firstname: customer.firstname,
        phone: customer.phone,
        email: customer.email,
        register: customer.register,
        positionId: customer.positionId,
        registrationDate: customer.registrationDate,
        isAccredit: customer.isAccredit === "true" ? true : false,
        stockTypeId: customer.stockTypeId,
        password: customer.password,
        quantity: customer.quantity,
        accreditInfo: this.state.accreditInfo,
        //accredit: customer.accredit,
      },
      //isAccredit: customer.isAccredit,
      isEdit: true,
      isExcel: false,
    })
    this.toggle()
  }

  /**
   * Handling submit Customer on Customer form
   */
  handleValidCustomerSubmit = (e, values) => {
    const { error } = this.props
    const { onAddNewShares, onUpdateShares, onExcelFile } = this.props
    const { isEdit, shares, selectedCustomer, isExcel } = this.state

    if (isEdit) {
      if (isExcel) {
        onExcelFile(this.state.datas, this.state.token)
      } else {
        const updateCustomer = {
          token: this.state.token,
          customerId: values.id,
          lastname: values.lastname,
          firstname: values.firstname,
          phone: values.phone,
          email: values.email,
          register: values.register,
          positionId: values.positionId,
          registrationDate: values.registrationDate,
          isAccredit: values.isAccredit === "true" ? true : false,
          accreditInfo: {
            accExpiryDate: values.accExpiryDate,
            accFirstname: values.accFirstname,
            accLastname: values.accLastname,
            accPhone: values.accPhone,
            accRegister: values.accRegister,
            accStartDate: values.accStartDate,
          },
        }
        // update Customer
        onUpdateShares(updateCustomer)
      }
    } else {
      const newCustomer = {
        token: this.state.token,
        lastname: values["lastname"],
        firstname: values["firstname"],
        phone: values["phone"],
        email: values["email"],
        register: values["register"],
        positionId: values["positionId"],
        registrationDate: values["registrationDate"],
        isAccredit: values["isAccredit"] === "true" ? true : false,
        accreditInfo: {
          accExpiryDate: values["accExpiryDate"],
          accFirstname: values["accFirstname"],
          accLastname: values["accLastname"],
          accPhone: values["accPhone"],
          accRegister: values["accRegister"],
          accStartDate: values["accStartDate"],
        },
        password: values["password"],
        isCitizen: values["isCitizen"] === "true" ? true : false,
      }
      // save new Shareholder
      onAddNewShares(newCustomer)
    }

    setTimeout(() => {
      if (
        isEmpty(this.props.error) &&
        this.props.error != "" &&
        typeof this.props.error != "undefined"
      ) {
        this.showToast(
          "success",
          this.props.message ? this.props.message : "Үйлдэл амжилттай боллоо.",
          "Амжилттай"
        )
        this.setState({ selectedCustomer: null })
        this.setState({ notifications: this.props.message })
        //this.toggle()
      } else {
        this.showToast("error", this.props.error, "Алдаа гарлаа")
      }
    }, 2000)
    //this.setState({ selectedCompany: null })
    // if (error == "") {
    //   this.showToast("success", "Үйлдэл амжилттай боллоо.", "Амжилттай")
    //   this.setState({ selectedCustomer: null })
    //   this.toggle()
    //   setTimeout(() => {
    //     this.props.history.push("/company/shareholder")
    //   }, 1000)
    // } else {
    //   this.showToast("error", error, "Алдаа гарлаа")
    // }
  }

  handleValidDate = date => {
    const date1 = moment(new Date(date)).format("YYYY-MM-DD")
    return date1
  }

  handleAddRowNested = () => {
    const item = {
      accFirstname: "",
      accLastname: "",
      accRegister: "",
      accPhone: "",
      accStartDate: "",
      accExpiryDate: "",
    }
    this.setState({
      accreditInfo: [...this.state.accreditInfo, item],
    })
  }
  handleRemoveRowNested = (e, idx) => {
    document.getElementById("nested" + idx).style.display = "none"
  }
  handleFNameChange = idx => evt => {
    const accreditInfo = this.state.accreditInfo.map((accredit, sidx) => {
      if (idx !== sidx) return accredit
      return { ...accredit, accFirstname: evt.target.value }
    })

    this.setState({ accreditInfo: accreditInfo })
  }
  handleLNameChange = idx => evt => {
    const accreditInfo = this.state.accreditInfo.map((accredit, sidx) => {
      if (idx !== sidx) return accredit
      return { ...accredit, accLastname: evt.target.value }
    })

    this.setState({ accreditInfo: accreditInfo })
  }

  handleRegisterChange = idx => evt => {
    const accreditInfo = this.state.accreditInfo.map((accredit, sidx) => {
      if (idx !== sidx) return accredit
      return { ...accredit, accRegister: evt.target.value }
    })

    this.setState({ accreditInfo: accreditInfo })
  }

  handlePhoneChange = idx => evt => {
    const accreditInfo = this.state.accreditInfo.map((accredit, sidx) => {
      if (idx !== sidx) return accredit
      return { ...accredit, accPhone: evt.target.value }
    })

    this.setState({ accreditInfo: accreditInfo })
  }
  handleSDateChange = idx => evt => {
    const accreditInfo = this.state.accreditInfo.map((accredit, sidx) => {
      if (idx !== sidx) return accredit
      return { ...accredit, accStartDate: evt.target.value }
    })

    this.setState({ accreditInfo: accreditInfo })
  }
  handleEDateChange = idx => evt => {
    const accreditInfo = this.state.accreditInfo.map((accredit, sidx) => {
      if (idx !== sidx) return accredit
      return { ...accredit, accExpiryDate: evt.target.value }
    })

    this.setState({ accreditInfo: accreditInfo })
  }
  /* Insert,Update Delete data */

  handleAccreditChange = evt => {
    //  const isAccredit = evt.target.value == 1 ? true : false;
    //   console.log("isAcredit1", evt);

    const isAccredit = evt.target.value == "true" ? true : false
    //  console.log("isAcredit2", isAccredit);
    this.setState({ isAccredit: isAccredit })
  }
  handleOrgChange = evt => {
    const isCitizen = evt.target.value == "true" ? true : false
    this.setState({ isCitizen: isCitizen })
  }

  showToast(type, message, title) {
    toastr.options = {
      timeOut: 5000,
      closeButton: true,
      progressBar: true,
      newestOnTop: true,
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
      showDuration: 300,
      hideDuration: 1000,
    }

    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (type === "info") toastr.info(message, title)
    else if (type === "warning") toastr.warning(message, title)
    else if (type === "error") toastr.error(message, title)
    else toastr.success(message, title)
  }
  importExcel = e => {
    const file = e.target.files[0]

    // console.log(file)
    const reader = new FileReader()
    reader.onload = event => {
      //parse data

      const bstr = event.target.result
      const workBook = XLSX.read(bstr, { type: "binary" })
      //get first sheet
      const workSheetName = workBook.SheetNames[0]
      const workSheet = workBook.Sheets[workSheetName]
      //convert to array
      const fileData = XLSX.utils.sheet_to_json(workSheet, { header: 1 })
      // console.log("file Data", fileData)
      const headers = fileData[0]
      //console.log("file header", headers)
      const heads = headers.map(head => ({ title: head, field: head }))
      this.setState({ colDefs: heads })
      //removing header
      fileData.splice(0, 1)
      this.setState({ datas: this.convertToJson(headers, fileData) })
    }

    if (file) {
      reader.readAsBinaryString(file)
    } else {
      this.setState({ colDefs: [] })
      this.setState({ datas: [] })
    }
  }

  convertToJson = (headers, data) => {
    const rows = []
    data.forEach(row => {
      // console.log(row)
      let rowData = {
        isCitizen: row[0].toLowerCase() === "Тийм".toLowerCase() ? true : false,
        lastname: row[1],
        firstname: row[2],
        phone: row[3],
        email: row[4],
        register: row[5],
        registrationDate: row[6],
        password: row[7],
        positionId:
          row[8].toLowerCase() === "Гишүүн".toLowerCase()
            ? 2
            : "Хяналтын зөвлөлийн гишүүн"
            ? 4
            : 5,
        isAccredit:
          row[9].toLowerCase() === "Тийм".toLowerCase() ? true : false,
        accreditInfo: {
          accLastname:
            row[9].toLowerCase() === "Тийм".toLowerCase() ? row[10] : "",
          accFirstname:
            row[9].toLowerCase() === "Тийм".toLowerCase() ? row[11] : "",
          accRegister:
            row[9].toLowerCase() === "Тийм".toLowerCase() ? row[12] : "",
          accPhone:
            row[9].toLowerCase() === "Тийм".toLowerCase() ? row[13] : "",
          accStartDate:
            row[9].toLowerCase() === "Тийм".toLowerCase()
              ? dateFormat(
                  new Date(row[13] - (25567 + 2)) * 86400 * 1000,
                  "yyyy-mm-dd"
                )
              : "",
          accExpiryDate:
            row[9].toLowerCase() === "Тийм".toLowerCase()
              ? dateFormat(
                  new Date(row[14] - (25567 + 2)) * 86400 * 1000,
                  "yyyy-mm-dd"
                ).toString()
              : "",
        },
      }

      // row.forEach((element, index) => {
      //   rowData[headers[index]] = element
      // })
      rows.push(rowData)
    })
    return rows
  }

  render() {
    const { shares } = this.props
    const { isEdit, isExcel } = this.state

    //pagination customization
    const pageOptions = {
      sizePerPage: 10,
      totalPage: shares.length,
      custom: true,
    }

    const sizePerPageList = [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "15", value: 15 },
      { text: "20", value: 20 },
      { text: "25", value: 25 },
      { text: "Бүгд", value: this.props.totalSize },
    ]
    const defaultSorted = [
      {
        dataField: "id",
        order: "desc",
      },
    ]

    const { SearchBar } = Search

    const selectRow = {
      mode: "checkbox",
    }
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Хувьцаа эзэмшигчийн жагсаалт | Цахим хурал</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs
              title="Дашбоард"
              breadcrumbItem="Хувьцаа эзэмшигчийн жагсаалт"
            />
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={this.state.CompanyShareholderColumns}
                      data={shares}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={this.state.CompanyShareholderColumns}
                          data={shares}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row>
                                <Col sm="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        placeholder="Хайх"
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="8">
                                  <div className="text-sm-end">
                                    <ButtonDropdown
                                      isOpen={this.state.drp_info1}
                                      toggle={() =>
                                        this.setState({
                                          drp_info1: !this.state.drp_info1,
                                        })
                                      }
                                    >
                                      <DropdownToggle
                                        caret
                                        color="info"
                                        className="mb-2 me-2"
                                      >
                                        <i className="mdi mdi-chevron-down me-1"></i>
                                        Excel
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem>
                                          <a target="_blank" href={Excelformat}>
                                            <i className="mdi mdi-download  me-1" />{" "}
                                            Жишээ файл татах
                                          </a>
                                        </DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem
                                          onClick={() => this.handleExcel()}
                                        >
                                          <i className="mdi mdi-upload me-1" />
                                          Файл хавсаргах
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </ButtonDropdown>
                                    <Button
                                      type="button"
                                      className="btn btn-success mb-2 me-2"
                                      onClick={this.handleCustomerClicks}
                                    >
                                      <i className="mdi mdi-plus me-1" />{" "}
                                      Хувьцаа эзэмшигч нэмэх
                                    </Button>
                                  </div>
                                </Col>
                              </Row>

                              <div className="table-responsive">
                                <BootstrapTable
                                  keyField={"id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  classes={"table align-middle table-nowrap"}
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                />

                                <Modal
                                  size={!isExcel ? "xl" : "md"}
                                  isOpen={this.state.modal}
                                  className={this.props.className}
                                >
                                  <ModalHeader toggle={this.toggle} tag="h4">
                                    {!!isEdit
                                      ? isExcel
                                        ? "Файл хавсаргах"
                                        : "Хувьцаа эзэмшигчийн мэдээлэл засах"
                                      : "Хувьцаа эзэмшигч нэмэх"}
                                  </ModalHeader>
                                  <ModalBody>
                                    <AvForm
                                      onValidSubmit={
                                        this.handleValidCustomerSubmit
                                      }
                                    >
                                      <AvField
                                        id="id"
                                        name="id"
                                        value={
                                          this.state.shares
                                            ? this.state.shares.customerId
                                            : ""
                                        }
                                        type="hidden"
                                      />
                                      {!isExcel ? <></> : <></>}
                                      <Row>
                                        <Col md="6">
                                          <div className="mb-3">
                                            <AvRadioGroup
                                              inline
                                              name="isCitizen"
                                              required
                                              errorMessage="Талбар хоосон байна!!"
                                              onChange={this.handleOrgChange}
                                            >
                                              <AvRadio
                                                label="Хувь хүн"
                                                value="true"
                                              />
                                              <AvRadio
                                                label="Хуулийн этгээд"
                                                value="false"
                                              />
                                            </AvRadioGroup>
                                          </div>
                                        </Col>

                                        <Col lg="6">
                                          <div className="mb-3">
                                            <AvField
                                              name="positionId"
                                              label="Албан тушаал"
                                              type="select"
                                              errorMessage="Албан тушаал сонгоно уу"
                                              className="form-select"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                this.state.shares
                                                  ? this.state.shares.positionId
                                                  : ""
                                              }
                                            >
                                              <option value>-Сонгох-</option>
                                              <option value="1">
                                                Хувьцаа эзэмшигч
                                              </option>
                                              <option value="3">
                                                Төлөөлөн Удирдах Зөвлөлийн
                                                гишүүн
                                              </option>
                                            </AvField>
                                          </div>
                                        </Col>
                                      </Row>
                                      {!this.state.isCitizen ? (
                                        <>
                                          <Row className={css.memberInput}>
                                            <Col md="6">
                                              <FormGroup className="mb-6">
                                                <AvField
                                                  id="lastname"
                                                  name="lastname"
                                                  label="Байгууллагын төрөл:"
                                                  type="select"
                                                  errorMessage="талбар хоосон байна!!"
                                                  className="form-select"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  value={
                                                    this.state.shares
                                                      ? this.state.shares
                                                          .lastname
                                                      : ""
                                                  }
                                                >
                                                  <option value="">
                                                    -Сонгох-
                                                  </option>
                                                  <option value="ХК">
                                                    Хувьцаат Компани
                                                  </option>
                                                  <option value="ХЗХ">
                                                    Хадгаламж Зээлийн Хоршоо
                                                  </option>
                                                </AvField>
                                              </FormGroup>
                                            </Col>
                                            <Col md="6">
                                              <FormGroup className="mb-6">
                                                <AvField
                                                  name="firstname"
                                                  label="Байгууллагын нэр:"
                                                  type="text"
                                                  errorMessage="талбар хоосон байна!!"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  value={
                                                    this.state.shares
                                                      ? this.state.shares
                                                          .firstname
                                                      : ""
                                                  }
                                                />
                                              </FormGroup>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col md="6">
                                              <FormGroup className="mb-6">
                                                <AvField
                                                  name="register"
                                                  label="Байгууллагын регистр:"
                                                  type="text"
                                                  errorMessage="Байгууллагын регистр оруулна уу"
                                                  validate={{
                                                    required: { value: true },
                                                    minLength: {
                                                      value: 7,
                                                      errorMessage:
                                                        "Байгууллагын регистрийн дугаар 7 оронтой байна.",
                                                    },
                                                    maxLength: {
                                                      value: 7,
                                                      errorMessage:
                                                        "Байгууллагын регистрийн дугаар 7 оронтой байна.",
                                                    },
                                                  }}
                                                  value={
                                                    this.state.shares
                                                      ? this.state.shares
                                                          .register
                                                      : ""
                                                  }
                                                />
                                              </FormGroup>
                                            </Col>
                                            <Col md="6">
                                              <FormGroup className="mb-6">
                                                <AvField
                                                  name="phone"
                                                  label="Утасны дугаар:"
                                                  type="number"
                                                  rows="3"
                                                  errorMessage="Утас оруулна уу"
                                                  validate={{
                                                    required: { value: true },
                                                    minLength: {
                                                      value: 6,
                                                      errorMessage:
                                                        "Утасны дугаар багадаа 6 урттай байна",
                                                    },
                                                    maxLength: {
                                                      value: 8,
                                                      errorMessage:
                                                        "Утасны дугаар ихдээ 8 урттай байна",
                                                    },
                                                  }}
                                                  value={
                                                    this.state.shares
                                                      ? this.state.shares.phone
                                                      : ""
                                                  }
                                                />
                                              </FormGroup>
                                            </Col>
                                          </Row>
                                        </>
                                      ) : (
                                        <>
                                          <Row className={css.memberInput}>
                                            <Col md="6">
                                              <FormGroup className="mb-6">
                                                <AvField
                                                  name="lastname"
                                                  label="Овог:"
                                                  type="text"
                                                  errorMessage="талбар хоосон байна!!"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  value={
                                                    this.state.shares
                                                      ? this.state.shares
                                                          .lastname
                                                      : ""
                                                  }
                                                />
                                              </FormGroup>
                                            </Col>
                                            <Col md="6">
                                              <FormGroup className="mb-6">
                                                <AvField
                                                  name="firstname"
                                                  label="Нэр:"
                                                  type="text"
                                                  errorMessage="талбар хоосон байна!!"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  value={
                                                    this.state.shares
                                                      ? this.state.shares
                                                          .firstname
                                                      : ""
                                                  }
                                                />
                                              </FormGroup>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col md="6">
                                              <FormGroup className="mb-6">
                                                <AvField
                                                  name="register"
                                                  label="Регистр:"
                                                  type="text"
                                                  errorMessage="Регистр оруулна уу"
                                                  validate={{
                                                    required: { value: true },
                                                    pattern: {
                                                      value:
                                                        "^[А-Яа-я]{2}[0-9]{8}$",
                                                      errorMessage:
                                                        "Регистрын формат буруу байна. Жишээ: АА12345678",
                                                    },
                                                  }}
                                                  value={
                                                    this.state.shares
                                                      ? this.state.shares
                                                          .register
                                                      : ""
                                                  }
                                                />
                                              </FormGroup>
                                            </Col>
                                            <Col md="6">
                                              <FormGroup className="mb-6">
                                                <AvField
                                                  name="phone"
                                                  label="Утасны дугаар:"
                                                  type="number"
                                                  rows="3"
                                                  errorMessage="Утас оруулна уу"
                                                  validate={{
                                                    required: { value: true },
                                                    minLength: {
                                                      value: 6,
                                                      errorMessage:
                                                        "Утасны дугаар багадаа 6 урттай байна",
                                                    },
                                                    maxLength: {
                                                      value: 8,
                                                      errorMessage:
                                                        "Утасны дугаар ихдээ 8 урттай байна",
                                                    },
                                                  }}
                                                  value={
                                                    this.state.shares
                                                      ? this.state.shares.phone
                                                      : ""
                                                  }
                                                />
                                              </FormGroup>
                                            </Col>
                                          </Row>
                                        </>
                                      )}
                                      {/* <Row>
                                        <Col md="6">
                                          <FormGroup className="mb-6">
                                            <AvField
                                              name="register"
                                              label="РД:"
                                              type="text"
                                              errorMessage="талбар хоосон байна!!"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                this.state.shares
                                                  ? this.state.shares.register
                                                  : ""
                                              }
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col md="6">
                                          <FormGroup className="mb-6">
                                            <AvField
                                              name="phone"
                                              label="Утас:"
                                              type="number"
                                              errorMessage="талбар хоосон байна!!"
                                              rows="3"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                this.state.shares
                                                  ? this.state.shares.phone
                                                  : ""
                                              }
                                            />
                                          </FormGroup>
                                        </Col>
                                      </Row> */}
                                      <Row>
                                        <Col md="6">
                                          <FormGroup className="mb-6">
                                            <AvField
                                              name="email"
                                              label="И-Мэйл"
                                              type="text"
                                              errorMessage="Талбар хоосон байна!!"
                                              validate={{
                                                required: { value: true },
                                                email: {
                                                  value: true,
                                                  errorMessage: "Формат буруу.",
                                                },
                                              }}
                                              value={
                                                this.state.shares
                                                  ? this.state.shares.email
                                                  : ""
                                              }
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col md="6">
                                          <FormGroup className="mb-6">
                                            <AvField
                                              name="registrationDate"
                                              htmlFor="registrationDate"
                                              label="Элссэн огноо:"
                                              type="date"
                                              errorMessage="талбар хоосон байна!!"
                                              validate={{
                                                required: { value: true },
                                                max: {
                                                  value: now,
                                                  errorMessage:
                                                    "Огноо буруу байна.",
                                                },
                                              }}
                                              value={
                                                this.state.shares
                                                  ? this.state.shares
                                                      .registrationDate
                                                  : ""
                                              }
                                            />
                                          </FormGroup>
                                        </Col>
                                      </Row>
                                      {!this.state.isEdit ? (
                                        <Row className="mb-3">
                                          <Col md="6">
                                            <FormGroup className="mb-6">
                                              <AvField
                                                name="password"
                                                label="Нууц үг:"
                                                type="password"
                                                errorMessage="Нууц үг оруулна уу"
                                                validate={{
                                                  required: { value: true },
                                                  pattern: {
                                                    value:
                                                      "^(?=.*[A-Za-z])(?=.*[0-9])(?=.*[@$!%*#?&])[A-Za-z0-9@$!%*#?&]{8,20}$",
                                                    errorMessage:
                                                      "Нууц үг нь 8-20 урттай багадаа нэг тоо, нэг үсэг, нэг тэмдэгт (@$!%*#?&) агуулсан байна.",
                                                  },
                                                }}
                                                value={
                                                  this.state.shares
                                                    ? this.state.shares.password
                                                    : ""
                                                }
                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col md="6">
                                            <FormGroup className="mb-6">
                                              <AvField
                                                name="passwordConfirm"
                                                label="Нууц үг давтах"
                                                type="password"
                                                errorMessage="Нууц үг давтан оруулна уу"
                                                validate={{
                                                  required: { value: true },
                                                  match: {
                                                    value: "password",
                                                    errorMessage:
                                                      "Нууц үг таарахгүй байна.",
                                                  },
                                                  pattern: {
                                                    value:
                                                      "^(?=.*[A-Za-z])(?=.*[0-9])(?=.*[@$!%*#?&])[A-Za-z0-9@$!%*#?&]{8,20}$",
                                                    errorMessage:
                                                      "Нууц үг нь 8-20 урттай багадаа нэг тоо, нэг үсэг, нэг тэмдэгт (@$!%*#?&) агуулсан байна.",
                                                  },
                                                }}
                                              />
                                            </FormGroup>
                                          </Col>
                                        </Row>
                                      ) : null}

                                      <Row className="mb-3">
                                        <Col md="6">
                                          <FormGroup className="mb-6">
                                            <AvField
                                              id="stockTypeId"
                                              name="stockTypeId"
                                              label="Хувьцааны төрөл"
                                              type="select"
                                              errorMessage="Төрөл сонгоно уу"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              className="form-select"
                                              value={
                                                this.state.shares
                                                  ? this.state.shares
                                                      .stockTypeId
                                                  : ""
                                              }
                                            >
                                              <option value="">-Сонгох-</option>
                                              <option value="1">Энгийн</option>
                                              <option value="2">
                                                Давуу эрхийн
                                              </option>
                                            </AvField>
                                          </FormGroup>
                                        </Col>
                                        <Col md="6">
                                          <FormGroup className="mb-6">
                                            <AvField
                                              name="quantity"
                                              label="Хувьцааны тоо:"
                                              type="number"
                                              errorMessage="талбар хоосон байна!!"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                this.state.shares
                                                  ? this.state.shares.quantity
                                                  : ""
                                              }
                                            />
                                          </FormGroup>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col md="6">
                                          <FormGroup className="mb-6">
                                            <AvField
                                              id="isAccredit"
                                              name="isAccredit"
                                              label="Итгэмжлэлтэй эсэх:"
                                              type="select"
                                              errorMessage="талбар хоосон байна!!"
                                              className="form-select"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                this.state.shares
                                                  ? this.state.shares.isAccredit
                                                  : ""
                                              }
                                              onChange={
                                                this.handleAccreditChange
                                              }
                                            >
                                              <option value="">-Сонгох-</option>
                                              <option value="true">Тийм</option>
                                              <option value="false">
                                                Үгүй
                                              </option>
                                            </AvField>
                                          </FormGroup>
                                        </Col>
                                      </Row>
                                      {!this.state.isAccredit ? (
                                        ""
                                      ) : (
                                        <Row>
                                          <Col className="col-lg-12">
                                            <div className="inner-repeater mb-10">
                                              <div className={css.accStyle}>
                                                <Row>
                                                  <Col>
                                                    <h4>
                                                      Итгэмжлэгчийн мэдээлэл
                                                    </h4>
                                                  </Col>
                                                </Row>
                                                <br></br>
                                              </div>
                                              <div>
                                                <Row>
                                                  <Col md="6">
                                                    <div className="mb-3">
                                                      <AvField
                                                        name="accLastname"
                                                        label="Овог:"
                                                        type="text"
                                                        errorMessage="Талбар хоосон байна!!"
                                                        value={
                                                          this.state.shares
                                                            .accreditInfo
                                                            ? this.state.shares
                                                                .accreditInfo
                                                                .accLastname
                                                            : ""
                                                        }
                                                        validate={{
                                                          required: {
                                                            value: true,
                                                          },
                                                        }}
                                                      />
                                                    </div>
                                                  </Col>
                                                  <Col md="6">
                                                    <div className="mb-3">
                                                      <AvField
                                                        name="accFirstname"
                                                        label="Нэр:"
                                                        type="text"
                                                        errorMessage="Талбар хоосон байна!!"
                                                        value={
                                                          this.state.shares
                                                            .accreditInfo
                                                            ? this.state.shares
                                                                .accreditInfo
                                                                .accFirstname
                                                            : ""
                                                        }
                                                        validate={{
                                                          required: {
                                                            value: true,
                                                          },
                                                        }}
                                                      />
                                                    </div>
                                                  </Col>
                                                </Row>
                                                <Row>
                                                  <Col lg="6">
                                                    <AvField
                                                      label="Регистр:"
                                                      name="accRegister"
                                                      type="text"
                                                      errorMessage="Регистрын дугаараа оруулна уу!!"
                                                      value={
                                                        this.state.shares
                                                          .accreditInfo
                                                          ? this.state.shares
                                                              .accreditInfo
                                                              .accRegister
                                                          : ""
                                                      }
                                                      validate={{
                                                        required: {
                                                          value: true,
                                                        },
                                                        pattern: {
                                                          value:
                                                            "^[А-Яа-я]{2}[0-9]{8}$",
                                                          errorMessage:
                                                            "Регистрын формат буруу байна. Жишээ нь: АА12345678",
                                                        },
                                                      }}
                                                    />
                                                  </Col>
                                                  <Col lg="6">
                                                    <AvField
                                                      label="Утасны дугаар:"
                                                      name="accPhone"
                                                      type="number"
                                                      errorMessage="Утасны дугаар оруулна уу!!"
                                                      value={
                                                        this.state.shares
                                                          .accreditInfo
                                                          ? this.state.shares
                                                              .accreditInfo
                                                              .accPhone
                                                          : ""
                                                      }
                                                      validate={{
                                                        required: {
                                                          value: true,
                                                        },
                                                        minLength: {
                                                          value: 8,
                                                          errorMessage:
                                                            "Утасны дугаар буруу байна!!",
                                                        },

                                                        maxLength: {
                                                          value: 8,
                                                          errorMessage:
                                                            "Утасны дугаар буруу байна!!",
                                                        },
                                                      }}
                                                    />
                                                  </Col>
                                                </Row>
                                                <Row>
                                                  <Col lg="6">
                                                    <AvField
                                                      label="Итгэмжлэл эхлэх огноо:"
                                                      name="accStartDate"
                                                      type="date"
                                                      errorMessage="Талбар хоосон байна!!"
                                                      value={
                                                        this.state.shares
                                                          .accreditInfo
                                                          ? this.state.shares
                                                              .accreditInfo
                                                              .accStartDate
                                                          : ""
                                                      }
                                                      validate={{
                                                        required: {
                                                          value: true,
                                                        },
                                                      }}
                                                    />
                                                  </Col>
                                                  <Col lg="6">
                                                    <AvField
                                                      label=" Итгэмжлэл дуусах огноо:"
                                                      name="accExpiryDate"
                                                      type="date"
                                                      errorMessage="Талбар хоосон байна!!"
                                                      value={
                                                        this.state.shares
                                                          .accreditInfo
                                                          ? this.state.shares
                                                              .accreditInfo
                                                              .accExpiryDate
                                                          : ""
                                                      }
                                                      validate={{
                                                        required: {
                                                          value: true,
                                                        },
                                                      }}
                                                    />
                                                  </Col>
                                                </Row>
                                              </div>
                                            </div>
                                          </Col>
                                        </Row>
                                      )}
                                      <Row>
                                        <Col>
                                          <div className="text-end">
                                            <button
                                              type="submit"
                                              className="btn btn-success save-customer"
                                            >
                                              {!!isEdit ? "Засах" : "Нэмэх"}
                                            </button>
                                          </div>
                                        </Col>
                                      </Row>
                                    </AvForm>
                                  </ModalBody>
                                </Modal>
                              </div>
                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

Shareholder.propTypes = {
  shares: PropTypes.array,
  page: PropTypes.number,
  stokeType: PropTypes.array,
  accreditInfo: PropTypes.array,
  totalPage: PropTypes.number,
  onGetCustomers: PropTypes.func,
  onAddNewShares: PropTypes.func,
  onDeleteShares: PropTypes.func,
  onUpdateShares: PropTypes.func,
  history: PropTypes.object,
  error: PropTypes.any,
}

// const mapStateToProps = ({ecommerce}) => ({
//   companys: ecommerce.companys,
// })
const mapStateToProps = state => {
  return {
    shares: state.CompanyShareRed.shares,
    page: state.CompanyShareRed.page,
    totalPage: state.CompanyShareRed.totalPage,
    stokeType: state.CompanyShareRed.stokeType,
    message: state.CompanyShareRed.message,
    error: state.CompanyShareRed.error,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetShares: (token, page, totalPage, history) =>
    dispatch(getCompanyShares(token, page, totalPage, history)),
  onAddNewShares: customer => dispatch(addNewShares(customer)),
  onUpdateShares: customer => dispatch(updateShares(customer)),
  onDeleteShares: customer => dispatch(deleteShares(customer)),
  //   onGetStokeType: token => dispatch(getStokeType(token)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Shareholder)
