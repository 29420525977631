import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import { connect } from "react-redux"
import { isEmpty, size } from "lodash"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import * as moment from "moment"

import imagesDefault from "../../../assets/images/default.jpg"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import { getAuditCompanys } from "../../../store/actions"

class AuditCompany extends Component {
  constructor(props) {
    super(props)
    this.state = {
      companys: [],
      companyLogo: imagesDefault,
      token: "",
      CompanyColumns: [
        {
          text: "id",
          dataField: "id",
          sort: true,
          hidden: true,
          formatter: (cellContent, user) => <>{row.id}</>,
        },
        {
          dataField: "logo",
          text: "",
          formatter: (cellContent, row) => (
            <>
              {!row.logo ? (
                <div className="avatar-xs">
                  <span className="avatar-title rounded-circle">
                    {row.name.charAt(0)}
                  </span>
                </div>
              ) : (
                <div>
                  <img
                    className="rounded-circle avatar-xs"
                    src={row.logo}
                    alt=""
                  />
                </div>
              )}
            </>
          ),
        },
        {
          text: "Компаний нэр/ Төрөл",
          dataField: "name",
          sort: true,
          formatter: (cellContent, row) => (
            <Link to={`/audit/meeting/dtl/${row.id}`} className="text-dark">
              <p className="mb-1">{row.name}</p>
              <p className="mb-0">{row.type}</p>
            </Link>
          ),
        },
        {
          dataField: "meetingDate",
          text: "Хурлын огноо",
          sort: true,
        },
        {
          dataField: "startTime",
          text: "Хурал эхлэх",
          sort: true,
        },
        {
          dataField: "endTime",
          text: "Хурал дуусах",
          sort: true,
        },
        {
          dataField: "voteDate",
          text: "Санал хураалтын хугацаа",
          sort: true,
        },
        {
          dataField: "meetingStatus",
          text: "Төлөв",
          sort: true,
        },
      ],
    }
  }

  componentDidMount() {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      const { companys, onGetAuditCompany } = this.props
      const token = obj.token
      if (companys && !companys.length) {
        onGetAuditCompany(obj.token, this.props.page, this.props.totalPage)
      }
      this.setState({ companys })
      this.setState({ token })
    }
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log("update")
    const { companys } = this.props
    if (!isEmpty(companys) && size(prevProps.companys) !== size(companys)) {
      this.setState({ companys: {}, isEdit: false })
    }
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }

  handleCompanyClicks = arg => {
    console.log(arg)
    this.setState({ selectedCompany: arg })
    this.toggle()
  }

  /* Insert,Update Delete data */

  handleCompanyClick = arg => {
    const company = arg
    this.setState({ companyLogo: company.logo })
    this.setState({
      companys: {
        id: company.id,
        name: company.name,
        register: company.register,
        type: company.type,
        address: company.address,
        stockQuantity: company.stockQuantity,
        registrationDate: company.registrationDate,
        memberCount: company.memberCount,
        active: company.active,
      },
      isEdit: false,
    })

    this.toggle()
  }

  handleSelectFile = event => {
    console.log(event)
    // debugger;
    if (!event.target.files[0] || event.target.files[0].length == 0) {
      //this.msg = 'You must select an image';
      return
    }
    var mimeType = event.target.files[0].type
    if (mimeType.match(/image\/*/) == null) {
      //this.msg = "Only images are supported";
      return
    }
    var reader = new FileReader()
    reader.readAsDataURL(event.target.files[0])
    reader.onload = _event => {
      this.setState({ companyLogo: reader.result })
    }
  }

  /**
   * Handling submit Company on Company form
   */

  handleValidDate = date => {
    const date1 = moment(new Date(date)).format("YYYY-MM-DD")
    return date1
  }

  /* Insert,Update Delete data */

  render() {
    const { companys } = this.props

    //pagination customization
    const pageOptions = {
      sizePerPage: 10,
      totalSize: this.props.totalPage, // replace later with size(companys),
      custom: true,
    }

    const defaultSorted = [
      {
        dataField: "id",
        order: "desc",
      },
    ]

    const { SearchBar } = Search

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Хяналт | Хурлын жагсаалт</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Хяналт" breadcrumbItem="Хурлын жагсаалт" />
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={this.state.CompanyColumns}
                      data={companys}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={this.state.CompanyColumns}
                          data={companys}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row>
                                <Col sm="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <div className="table-responsive">
                                <BootstrapTable
                                  keyField={"id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  classes={"table align-middle table-nowrap"}
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                />
                              </div>
                              <div className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

AuditCompany.propTypes = {
  companys: PropTypes.array,
  page: PropTypes.number,
  totalPage: PropTypes.number,
  onGetAuditCompany: PropTypes.func,
}

const mapStateToProps = state => {
  return {
    companys: state.AuditCompany.companys,
    // page: state.AuditCompany.page,
    // totalPage: state.AuditCompany.totalPage,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetAuditCompany: (token, page, totalPage) =>
    dispatch(getAuditCompanys(token, page, totalPage)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AuditCompany)
