export const convertCurrency = (amount, currency = "MNT") => {
  const formatter = Intl.NumberFormat(undefined, {
    style: "currency",
    currency,
  })

  if (isNaN(parseInt(amount))) return 0
  return formatter
    .format(parseInt(amount))
    .replace(new RegExp(currency, "g"), "")
    .split(".")[0]
}

Number.prototype.convertCurrency = convertCurrency

String.prototype.convertCurrency = convertCurrency
