import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import { connect } from "react-redux"
import { isEmpty, size } from "lodash"
import { Card, CardBody, Col, Container, Row, Badge } from "reactstrap"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator"
import * as moment from "moment"
import imagesDefault from "../../../assets/images/default.jpg"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import { getCustomerCompanys } from "store/actions"

class CustomerCompany extends Component {
  constructor(props) {
    super(props)
    this.state = {
      companys: [],
      companyLogo: imagesDefault,
      token: "",
      CompanyColumns: [
        {
          text: "id",
          dataField: "id",
          sort: true,
          hidden: true,
          formatter: (cellContent, user) => <>{row.id}</>,
        },
        {
          dataField: "logo",
          text: "Лого",
          formatter: (cellContent, row) => (
            <>
              {!row.logo ? (
                <div className="avatar-xs">
                  <span className="avatar-title rounded-circle">
                    {row.name.charAt(0)}
                  </span>
                </div>
              ) : (
                <div>
                  <img
                    className="rounded-circle avatar-xs"
                    src={row.logo}
                    alt=""
                  />
                </div>
              )}
            </>
          ),
        },
        {
          text: "Байгууллагын нэр/ Төрөл",
          dataField: "name",
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <p className="mb-1">{row.name}</p>
              <p className="mb-0">{row.type}</p>
            </>
          ),
        },
        {
          dataField: "register",
          text: "Регистр",
          sort: true,
        },
        {
          dataField: "phone",
          text: "Утас",
          sort: true,
        },
        {
          dataField: "registrationDate",
          text: "Бүртгүүлсэн огноо",
          sort: true,
          formatter: (cellContent, row) =>
            this.handleValidDate(row.registrationDate),
        },
        {
          dataField: "address",
          text: "Хаяг",
          sort: true,
        },
        {
          dataField: "status",
          text: "Төлөв",
          sort: true,
          formatter: (cellContent, row) => (
            <Badge color={row.statusColor} className={"bg-" + row.statusColor}>
              {row.status}
            </Badge>
          ),
        },
      ],
    }
  }

  componentDidMount() {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      const { companys, onGetCompanys } = this.props
      const token = obj.token
      if (companys && !companys.length) {
        onGetCompanys(obj.token, this.props.page, this.props.totalPage)
      }
      this.setState({ companys })
      this.setState({ token })
    }
  }


  handleValidDate = date => {
    const date1 = moment(new Date(date)).format("YYYY-MM-DD")
    return date1
  }

  /* Insert,Update Delete data */

  render() {
    const { companys } = this.props

    //pagination customization
    const pageOptions = {
      sizePerPage: 10,
      totalSize: this.props.totalPage, // replace later with size(companys),
      custom: true,
    }

    const defaultSorted = [
      {
        dataField: "id",
        order: "desc",
      },
    ]

    const { SearchBar } = Search
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Компанийн жагсаалт | Цахим хурал</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Дашбоард" breadcrumbItem="Байгууллагын жагсаалт" />
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={this.state.CompanyColumns}
                      data={companys}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={this.state.CompanyColumns}
                          data={companys}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row>
                                <Col sm="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        placeholder="Хайлт"
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

CustomerCompany.propTypes = {
  companys: PropTypes.array,
  page: PropTypes.number,
  totalPage: PropTypes.number,
  onGetCompanys: PropTypes.func,
}

// const mapStateToProps = ({ ecommerce }) => ({
//   companys: ecommerce.companys,
// })
const mapStateToProps = state => {
  return {
    companys: state.CustomerCompanys.companys,
    page: state.CustomerCompanys.page,
    totalPage: state.CustomerCompanys.totalPage,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetCompanys: (token, page, totalPage) =>
    dispatch(getCustomerCompanys(token, page, totalPage)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomerCompany)
