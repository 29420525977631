import React, { Component, useState } from "react"
import { Link } from "react-router-dom"
import * as moment from "moment"
import {
  Badge,
  Table,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  FormGroup,
} from "reactstrap"
import { map } from "lodash"
import PropTypes from "prop-types"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

class MeetStoryDtl extends Component {
  constructor(props) {
    super(props)
    this.state = {
      story: [],
      expandedRows: [],
      //  setExpandedRows: [],
      expandState: {},
      //   setExpandState: [],
      // columns: [
      //     {
      //         text: "№",
      //         dataField: "meetings.id",
      //         sort: true,
      //         hidden: true,
      //         formatter: (cellContent, user) => (
      //             <>
      //                 {row.id}
      //             </>
      //         ),
      //     },
      //     {
      //         dataField: 'name',
      //         text: 'Хурлийн нэр'
      //     }, {
      //         dataField: 'meetingType',
      //         text: 'Төрөл'
      //     }, {
      //         dataField: 'meetingDate',
      //         text: 'Хурлийн огноо'
      //     }, {
      //         dataField: 'declareDate',
      //         text: 'Зарласан огноо'
      //     }, {
      //         dataField: 'startDate',
      //         text: 'Эхлэх огноо'
      //     }, {
      //         dataField: 'endDate',
      //         text: 'Дуусах огноо'
      //     }],
    }
  }
  handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y")
    return date1
  }

  handleEpandRow = (event, id) => {
    const currentExpandedRows = this.state.expandedRows
    const isRowExpanded = currentExpandedRows.includes(id)
    let obj = {}
    isRowExpanded ? (obj[id] = false) : (obj[id] = true)
    this.setState({ expandState: obj })
    //  setExpandState(obj);

    // If the row is expanded, we are here to hide it. Hence remove
    // it from the state variable. Otherwise add to it.
    const newExpandedRows = isRowExpanded
      ? currentExpandedRows.filter(id => id !== id)
      : currentExpandedRows.concat(id)
    this.setState({ expandedRows: newExpandedRows })
    //  setExpandedRows(newExpandedRows);
  }
  render() {
    const { story } = this.props

    return (
      <React.Fragment>
        <Row>
          <Col sm={12}>
            <Table>
              <thead>
                <tr>
                  <th></th>
                  <th>Хурлийн нэр</th>
                  <th>Төрөл</th>
                  <th>Хурлийн огноо</th>
                  <th>Зарласан огноо</th>
                  <th>Эхлэх огноо</th>
                  <th>Дуусах огноо </th>
                  <th> </th>
                </tr>
              </thead>
              <tbody>
                {story
                  ? story.map(meeting => (
                      <>
                        <tr key={meeting.id}>
                          <td>{meeting["name"]}</td>
                          <td>{meeting["meetingType"]}</td>
                          <td>{meeting["meetingDate"]}</td>
                          <td>{meeting["declareDate"]}</td>
                          <td>{meeting["startDate"]}</td>
                          <td>{meeting["endDate"]}</td>

                          <td>
                            <Button
                              variant="link"
                              onClick={event =>
                                this.handleEpandRow(event, meeting.id)
                              }
                            >
                              {this.state.expandState[meeting.id]
                                ? "Хаах"
                                : "ХА харах"}
                            </Button>
                          </td>
                        </tr>

                        <>
                          {this.state.expandedRows.includes(meeting.id) ? (
                            <tr>
                              <td colspan="8">
                                <div>
                                  <h2> Хэлэлцсэн асуудал </h2>
                                  <ul>
                                    {meeting.voteResult.map(agenda => (
                                      <>
                                        <Row>
                                          <Col xs="4">
                                            <li>
                                              <span style={{ color: "green" }}>
                                                <b>
                                                  Хэлэлцэх асуудлын төрөл:
                                                  &nbsp;
                                                </b>
                                              </span>{" "}
                                              <span style={{ color: "blue" }}>
                                                {" "}
                                                {agenda.agendaType}{" "}
                                              </span>
                                            </li>
                                          </Col>
                                          <Col xs="4">
                                            <li>
                                              <span style={{ color: "green" }}>
                                                <b>Агуулга: &nbsp;</b>
                                              </span>{" "}
                                              <span style={{ color: "blue" }}>
                                                {" "}
                                                {agenda.content}{" "}
                                              </span>
                                            </li>
                                          </Col>
                                        </Row>
                                      </>
                                    ))}
                                  </ul>
                                </div>
                              </td>
                            </tr>
                          ) : null}
                        </>
                      </>
                    ))
                  : ""}
              </tbody>
            </Table>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}
MeetStoryDtl.propTypes = {
  story: PropTypes.any,
}
export default MeetStoryDtl
