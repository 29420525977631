import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"

// action
import { loginDan } from "../../store/actions"

// Redux
import { connect } from "react-redux"
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import { v4 as uuidv4 } from 'uuid';

class Authorized extends Component {
  constructor(props) {
    super(props)
    this.state = {}

  }

  componentDidMount() {

    var stateIn = localStorage.getItem('state');

    const queryParams = new URLSearchParams(window.location.search)
    const code = queryParams.get("code")
    const state = queryParams.get("state")
    console.log(code, state) //pizza

    var service_structure = '[{"wsdl":"https://xyp.gov.mn/citizen-1.3.0/ws?WSDL","services":["WS100101_getCitizenIDCardInfo"]}]';
    var url = "https://sso.gov.mn/oauth2/authorize?grant_type=authorization_code&client_id=d405fcdd5aceb0b529ac968c-0c2949539e3b7bf251209bd36d49d7ba&scope="
      + base64_encode(unescape(encodeURIComponent(service_structure))) + "&redirect_uri=https://ehural.mn/authorized&state=" + state
      + "&code=" + code + "&client_secret=MGFlNjcyMmE5ZTM0MWMyZjhkOWM3YjE4M2Y1ZDNmZThhYWUwMjI2MDhhZmRhNDA3NWVmNTk3MTFjMTgzNTI5NTAzOWVjNDAxZDRkYmUyYTkzYjhlNGExN2NhMTBlY2IwM2M2MDI4ZDcxZjZiYmE2MTgwNmQ3ODE0YjRjMWJhODM=";
    // window.open(url, '_blank')

    const requestOptions = {
      method: "POST",
      // body: formData,
    }
    fetch(url, requestOptions)
      .then(response => response.json())
      .then(data => {
        console.log(data);
      })
      .catch(error => {
        console.log(error)
        // error response
      })

    // this.props.loginDan(url)

  }

  render() {
    return (
      <React.Fragment>
        test
      </React.Fragment>
    )
  }
}

Authorized.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginDan: PropTypes.func,
  user: PropTypes.object,
}

const mapStateToProps = state => {
  return { error: state.Login.error }
}

export default withRouter(
  connect(mapStateToProps, { loginDan })(Authorized)
)
