import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import { withRouter } from "react-router-dom"
import { map, isEmpty } from "lodash"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import classnames from "classnames"

import GeneralSharesDetail from "./general-sharesdtl"
import SharesMeetDtl from "./shares-meet-dtl"
import {
  getSharesDtl,
  getSharesMeetDtl,
} from "store/actions"

class CompanySharesDtl extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: "1",
      token: "",
      // col1: true,
      // col2: false,
    }
    this.toggle = this.toggle.bind(this)

    // this.t_col1 = this.t_col1.bind(this)
    // this.t_col2 = this.t_col2.bind(this)
  }

  // t_col1() {
  //   this.setState({
  //     col1: !this.state.col1,
  //     col2: false,
  //     col3: false
  //   })
  // }

  // t_col2() {
  //   this.setState({
  //     col1: false,
  //     col2: !this.state.col2,
  //     col3: false
  //   })
  // }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  componentDidMount() {
    const {
      match: { params },
      onGetSharesDtl,
      onGetSharesMeetDtl
    } = this.props
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      if (params && params.id) {
        onGetSharesDtl(obj.token, params.id, this.props.history)
        onGetSharesMeetDtl(obj.token, params.id)
      } else {
        onGetSharesDtl(obj.token, 0, this.props.history) //remove this after full integration
        onGetSharesMeetDtl(obj.token, 0)
      }
      this.setState({ token: obj.token })
    }

  }
  render() {
    const { sharesdtl, sharesmeetdtl } = this.props

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Хувьцаа эзэмшигчийн дэлгэрэнгүй | Цахим хурал</title>
          </MetaTags>
          <Container fluid={true}>
            <Breadcrumbs title="Комдпаний Гишүүн" breadcrumbItem="Хувьцаа эзэмшигчийн дэлгэрэнгүй" />
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "1",
                          })}
                          onClick={() => {
                            this.toggle("1")
                          }}
                        >
                          Ерөнхий мэдээлэл
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "2",
                          })}
                          onClick={() => {
                            this.toggle("2")
                          }}
                        >
                          Хурлийн түүх
                        </NavLink>
                      </NavItem>

                    </Nav>

                    <TabContent activeTab={this.state.activeTab} className="p-3 text-muted">
                      <TabPane tabId="1">
                        <Row>
                          <Col sm="12">
                            {!isEmpty(sharesdtl) && (
                              <>
                                <Row>
                                  <Col lg="12">
                                    <GeneralSharesDetail sharesdtl={sharesdtl}> </GeneralSharesDetail>
                                  </Col>
                                </Row>
                              </>
                            )}
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="2">
                        <Row>
                          <Col sm="12">
                            <SharesMeetDtl sharesmeetdtl={sharesmeetdtl}> </SharesMeetDtl>
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>

            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

CompanySharesDtl.propTypes = {
  sharesdtl: PropTypes.any,
  sharesmeetdtl: PropTypes.any,
  onGetSharesDtl: PropTypes.func,
  onGetSharesMeetDtl: PropTypes.func,
  history: PropTypes.object,
}

const mapStateToProps = state => {
  //console.log("state", state);
  return {
    sharesdtl: state.CompanyShareRed.sharesdtl,
    sharesmeetdtl: state.CompanyShareRed.sharesmeetdtl,
  }
}
const mapDispatchToProps = dispatch => ({
  onGetSharesDtl: (token, customerId, history) => dispatch(getSharesDtl(token, customerId, history)),
  onGetSharesMeetDtl: (token, customerId) => dispatch(getSharesMeetDtl(token, customerId)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CompanySharesDtl))
