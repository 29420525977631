import {
  GET_CUSTOMER_COMPANYS_FAIL,
  GET_CUSTOMER_COMPANYS_SUCCESS,
  GET_CUSTOMER_COMPANY_DTL_FAIL,
  GET_CUSTOMER_COMPANY_DTL_SUCCESS,
  GET_CUSTOMER_COMPANY_AGENDA_SUCCESS,
  GET_CUSTOMER_COMPANY_AGENDA_FAIL,
  GET_CUSTOMER_COMPANY_DOCUMENT_SUCCESS,
  GET_CUSTOMER_COMPANY_DOCUMENT_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  page: 0,
  length: 9,
  totalPage: 0,
  draw: 1,
  companys: [],
  meeting: {},
  agendas: [],
  documents: [],
  error: {},
}

const CustomerCompanys = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CUSTOMER_COMPANYS_SUCCESS:
      return {
        ...state,
        companys: action.companys,
        totalPage: action.totalPage,
      }

    case GET_CUSTOMER_COMPANYS_FAIL:
      return {
        ...state,
        error: action.error,
      }

    case GET_CUSTOMER_COMPANY_DTL_SUCCESS:
      return {
        ...state,
        meeting: action.meeting,
      }

    case GET_CUSTOMER_COMPANY_DTL_FAIL:
      return {
        ...state,
        error: action.error,
      }

    case GET_CUSTOMER_COMPANY_AGENDA_SUCCESS:
      return {
        ...state,
        agendas: action.agenda,
      }

    case GET_CUSTOMER_COMPANY_AGENDA_FAIL:
      return {
        ...state,
        error: action.error,
      }
    case GET_CUSTOMER_COMPANY_DOCUMENT_SUCCESS:
      return {
        ...state,
        documents: action.document,
      }

    case GET_CUSTOMER_COMPANY_DOCUMENT_FAIL:
      return {
        ...state,
        error: action.error,
      }

    default:
      return state
  }
}

export default CustomerCompanys
