import {
  GET_AUDIT_MEETINGS,
  GET_AUDIT_MEETINGS_FAIL,
  GET_AUDIT_MEETINGS_SUCCESS,
  GET_AUDIT_MEETING_DTL,
  GET_AUDIT_MEETING_DTL_FAIL,
  GET_AUDIT_MEETING_DTL_SUCCESS,
  GET_AUDIT_MEETING_AGENDA,
  GET_AUDIT_MEETING_AGENDA_SUCCESS,
  GET_AUDIT_MEETING_AGENDA_FAIL,
  GET_AUDIT_MEETING_DOCUMENT,
  GET_AUDIT_MEETING_DOCUMENT_SUCCESS,
  GET_AUDIT_MEETING_DOCUMENT_FAIL,
  GET_AUDIT_MEETING_RESULT,
  GET_AUDIT_MEETING_RESULT_SUCCESS,
  GET_AUDIT_MEETING_RESULT_FAIL,
  START_AUDIT_VOTE,
  START_AUDIT_VOTE_SUCCESS,
  START_AUDIT_VOTE_FAIL,
  SAVE_AUDIT_VOTE,
  SAVE_AUDIT_VOTE_SUCCESS,
  SAVE_AUDIT_VOTE_FAIL,
  SET_AUDIT_ATTENDANCE,
  SET_AUDIT_ATTENDANCE_SUCCESS,
  SET_AUDIT_ATTENDANCE_FAIL,
} from "./actionTypes"

export const getAuditMeetings = (token, page, totalPage, history) => ({
  type: GET_AUDIT_MEETINGS,
  payload: { request: { token, page, totalPage }, history },
})

export const getAuditMeetingsSuccess = response => ({
  type: GET_AUDIT_MEETINGS_SUCCESS,
  meetings: response.data,
  totalPage: response.recordsTotal,
})

export const getAuditMeetingsFail = error => ({
  type: GET_AUDIT_MEETINGS_FAIL,
  payload: error,
})
/*--------------------------------------------------*/
export const getAuditMeetingDtl = (token, meetingId, history) => ({
  type: GET_AUDIT_MEETING_DTL,
  payload: { request: { token, meetingId }, history },
})

export const getAuditMeetingDtlSuccess = response => ({
  type: GET_AUDIT_MEETING_DTL_SUCCESS,
  meeting: response,
})

export const getAuditMeetingDtlFail = error => ({
  type: GET_AUDIT_MEETING_DTL_FAIL,
  payload: error,
})
/*--------------------------------------------------*/
export const getAuditMeetingAgenda = (token, meetingId) => ({
  type: GET_AUDIT_MEETING_AGENDA,
  payload: { token, meetingId },
})

export const getAuditMeetingAgendaSuccess = response => ({
  type: GET_AUDIT_MEETING_AGENDA_SUCCESS,
  agenda: response,
})

export const getAuditMeetingAgendaFail = error => ({
  type: GET_AUDIT_MEETING_AGENDA_FAIL,
  payload: error,
})
/*--------------------------------------------------*/
export const getAuditMeetingDocument = (token, meetingId) => ({
  type: GET_AUDIT_MEETING_DOCUMENT,
  payload: { token, meetingId },
})

export const getAuditMeetingDocumentSuccess = response => ({
  type: GET_AUDIT_MEETING_DOCUMENT_SUCCESS,
  document: response,
})

export const getAuditMeetingDocumentFail = error => ({
  type: GET_AUDIT_MEETING_DOCUMENT_FAIL,
  payload: error,
})

/*--------------------------------------------------*/
export const getAuditMeetingResult = (token, meetingId) => ({
  type: GET_AUDIT_MEETING_RESULT,
  payload: { token, meetingId },
})

export const getAuditMeetingResultSuccess = response => ({
  type: GET_AUDIT_MEETING_RESULT_SUCCESS,
  result: response,
})

export const getAuditMeetingResultFail = error => ({
  type: GET_AUDIT_MEETING_RESULT_FAIL,
  payload: error,
})
/*--------------------------------------------------*/
// export const startAuditVote = (token, agendaId) => ({
//   type: START_AUDIT_VOTE,
//   payload: { token, agendaId },
// })
// export const startAuditVoteSuccess = response => ({
//   type: START_AUDIT_VOTE_SUCCESS,
//   votes: response,
// })

// export const startAuditVoteFail = error => ({
//   type: START_AUDIT_VOTE_FAIL,
//   payload: error,
// })

/*--------------------------------------------------*/

// export const saveAuditVote = vote => ({
//   type: SAVE_AUDIT_VOTE,
//   payload: vote,
// })

// export const saveAuditVoteSuccess = response => ({
//   type: SAVE_AUDIT_VOTE_SUCCESS,
//   votes: response,
// })

// export const saveAuditVoteFail = error => ({
//   type: SAVE_AUDIT_VOTE_FAIL,
//   payload: error,
// })

/*--------------------------------------------------*/
export const setAuditAttendance = (token, meetingId) => ({
  type: SET_AUDIT_ATTENDANCE,
  payload: { token, meetingId },
})

export const setAuditAttendanceSuccess = response => ({
  type: SET_AUDIT_ATTENDANCE_SUCCESS,
  votes: response,
})

export const setAuditAttendanceFail = error => ({
  type: SET_AUDIT_ATTENDANCE_FAIL,
  payload: error,
})

/*--------------------------------------------------*/
