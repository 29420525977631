import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";

import rootReducer from "./reducers";
import rootSaga from "./sagas";

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const logger = store => {
  return next => {
    return action => {
      // console.log("MyLoggerMiddleware: Dispaching ==>", action);
      // console.log("MyLoggerMiddleware: State before: ", store.getState());
      const result = next(action);
      // console.log("MyLoggerMiddleware: State after: ", store.getState());
      return result;
    }
  }
}

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware, logger))
);
sagaMiddleware.run(rootSaga);


export default store;
