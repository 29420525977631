import React, { Component } from "react"
import PropTypes from "prop-types"
import { Card, CardBody, CardTitle, Table, Button } from "reactstrap"

class CompanyMeetingAttendance extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  clickAttendance = e => {
    this.props.clickAttendance()
  }
  render() {
    const percent = +(this.props.participatingCustomerCount * 100 / this.props.membersCount).toFixed(2);
    return (
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Хурлын ирц</CardTitle>
          <div className="table-responsive">
            <Table className="table align-middle table-nowrap">
              <tbody>
                <tr key="attendance">
                  <td style={{ width: "150px" }}>
                    {this.props.participatingCustomerCount +
                      "/" +
                      this.props.membersCount + " (" + percent + "%)"}
                  </td>
                  <td className="text-end">
                    {
                      percent > 50 ?
                        <Button
                          type="button"
                          color="primary"
                          onClick={this.clickAttendance}
                          className=""
                        >
                          <i
                            className="mdi mdi-check font-size-18"
                            id="edittooltip"
                          />{" "}
                          Хурал {!this.props.isStart ? "эхлүүлэх" : "хаах"}
                        </Button>
                        : ""
                    }
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    )
  }
}

CompanyMeetingAttendance.propTypes = {
  isStart: PropTypes.bool,
  membersCount: PropTypes.number,
  participatingCustomerCount: PropTypes.number,
}

export default CompanyMeetingAttendance
