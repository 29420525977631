import React, { Component } from "react"
import PropTypes from "prop-types"
import {
  Card,
  CardBody,
  CardTitle,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import { Link } from "react-router-dom"
import { map } from "lodash"

class ComapnyMeetingAudit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isDisabled: false,
    }
  }
  toggle = () => {
    if (this.state.votes) {
      this.setState(prevState => ({
        modal: !prevState.modal,
      }))
    }
  }
  // нэмэх
  handleAddRow = () => {
    this.toggle()
  }
  render() {
    const team = this.props.team
    return (
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Тооллогын коммис</CardTitle>
          <Button
            onClick={this.handleAddRow}
            color="success"
            className="mt-3 mt-lg-0"
          >
            Нэмэх{" "}
          </Button>{" "}
          <div className="table-responsive">
            <Table className="table align-middle table-nowrap">
              <tbody>
                {map(team, (member, k) => (
                  <tr key={"_member_" + k}>
                    <td style={{ width: "50px" }}>
                      <div className="avatar-xs">
                        <span className="avatar-title rounded-circle bg-primary text-white font-size-16">
                          {member.firstname.charAt(0)}
                        </span>
                      </div>
                    </td>
                    <td>
                      <h5 className="font-size-14 m-0">
                        <Link to="" className="text-dark">
                          {member.lastname.charAt(0)}.{member.firstname}
                        </Link>
                      </h5>
                      <span className="text-muted">{member.username}</span>
                    </td>
                    <td>
                      <div>
                        <Link
                          to="#"
                          className="badge bg-primary bg-soft text-primary font-size-11 me-1"
                        >
                          {member.phone}{" "}
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <Modal
            size="lg"
            backdrop={"static"}
            isOpen={this.state.modal}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggle} tag="h4">
              {"Гишүүдийн жагсаалт"}
            </ModalHeader>
            <ModalBody></ModalBody>
          </Modal>
        </CardBody>
      </Card>
    )
  }
}

ComapnyMeetingAudit.propTypes = {
  team: PropTypes.array,
}

export default ComapnyMeetingAudit
