import React, { Component, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import map from "lodash"
import MetaTags from "react-meta-tags"
import { connect } from "react-redux"
import { isEmpty, size } from "lodash"
import swal from "sweetalert"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  Form,
  FormGroup,
  Label,
  Table,
  th,
  thead,
  ButtonDropdown,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import * as moment from "moment"
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  AvForm,
  AvField,
  AvRadio,
  AvRadioGroup,
} from "availity-reactstrap-validation"
import XLSX from "xlsx"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import {
  getCompanyMembers,
  addNewMember,
  updateMember,
  deleteMember,
  getStokeType,
  getExcels,
} from "store/actions"
// import css from "./style.module.css"
import Excelformat from "../../../assets/file/Template_Members_001.xlsx"
import dateFormat from "dateformat"

const now = new Date().toJSON().split("T")[0]

class CompanyCustomers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      members: [],
      //notifications: [],
      token: "",
      isAccredit: false,
      isCitizen: true,
      isExcel: false,

      CompanyCustomerColumns: [
        {
          text: "id",
          dataField: "id",
          sort: true,
          hidden: true,
          formatter: (cellContent, row) => <>{row.id}</>,
        },
        {
          dataField: "firstname",
          text: "Овог / Нэр",
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <p className="mb-1">
                <Link
                  to={`/company/member/dtl/${row.id}`}
                  className="text-dark"
                >
                  {row.firstname}
                </Link>
              </p>
              <p className="mb-0">{row.lastname}</p>
            </>
          ),
        },
        {
          text: "Утас / И-Мэйл",
          dataField: "phone",
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <p className="mb-1">{row.phone}</p>
              <p className="mb-0">{row.email}</p>
            </>
          ),
        },
        {
          dataField: "register",
          text: "Регистр",
          sort: true,
        },
        {
          dataField: "position",
          text: "Албан тушаал",
          sort: true,
        },

        {
          dataField: "registrationDate",
          text: "Бүртгүүлсэн огноо",
          sort: true,
          formatter: (cellContent, row) =>
            this.handleValidDate(row.registrationDate),
        },
        {
          dataField: "isAccredit",
          text: "Итгэмжлэлтэй эсэх",
          sort: true,
          formatter: (cellContent, row) => (
            <>
              {row.isAccredit ? (
                <Badge color="success" className="bg-success font-size-12">
                  Тийм
                </Badge>
              ) : (
                <Badge color="danger" className="bg-danger font-size-12">
                  Үгүй
                </Badge>
              )}
            </>
          ),
        },
        {
          dataField: "menu",
          isDummyField: true,
          text: "Үйлдэл",
          formatter: (cellContent, customer) => (
            <UncontrolledDropdown>
              <DropdownToggle href="#" className="card-drop" tag="a">
                <i className="mdi mdi-dots-horizontal font-size-18" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  href="#"
                  onClick={() => this.handleMemberClick(customer)}
                >
                  <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
                  Засварлах
                </DropdownItem>
                <DropdownItem
                  href="#"
                  onClick={() => this.handleDeleteMember(customer)}
                >
                  <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />{" "}
                  Устгах
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          ),
        },
      ],
    }
    this.handleMemberClick = this.handleMemberClick.bind(this)
    this.toggle = this.toggle.bind(this)
    this.handleValidMemberSubmit = this.handleValidMemberSubmit.bind(this)
    this.handleMemberClicks = this.handleMemberClicks.bind(this)
  }

  componentDidMount() {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      const { members, onGetMembers, onGetStokeType } = this.props
      const token = obj.token
      if (members && !members.totalPage) {
        onGetMembers(
          obj.token,
          this.props.start,
          this.props.totalPage,
          this.props.history
        )
      }
      this.setState({ members })
      this.setState({ token })
      onGetStokeType(obj.token)
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { members } = this.props
    if (!isEmpty(members) && size(prevProps.members) !== size(members)) {
      this.setState({ members: {}, isEdit: false })
    }
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }

  handleMemberClicks = arg => {
    this.setState({
      selectedMember: arg,
      members: {
        lastname: "",
        firstname: "",
        phone: "",
        email: "",
        register: "",
        positionId: "",
        registrationDate: "",
        isAccredit: "",
        password: "",
        quantity: "",
        accreditInfo: {
          accFirstname: "",
          accLastname: "",
          accRegister: "",
          accPhone: "",
          accStartDate: "",
          accExpiryDate: "",
        },
      },

      isExcel: false,
      isEdit: false,
    })
    this.toggle()
  }

  handleDeleteMember = customer => {
    const { error } = this.props
    const deleteMember = {
      token: this.state.token,
      customerId: customer.id,
    }
    const { onDeleteMember } = this.props
    swal({
      title: "Устгах уу?",
      text: "Та гишүүний мэдээлэл устгах гэж байна!",
      icon: "info",
      buttons: {
        cancel: {
          text: "Үгүй",
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
        confirm: {
          text: "Тийм",
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
      },

      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        onDeleteMember(deleteMember)
        if (error == "") {
          this.showToast(
            "success",
            "Устгах үйлдэл амжилттай боллоо.",
            "Амжилттай"
          )
        } else {
          this.showToast("error", error, "Алдаа гарлаа")
        }
      } else {
      }
    })
  }
  handleExcel = arg => {
    this.setState({
      isEdit: true,
      isExcel: true,
      //notifications: [],
    })

    this.toggle()
  }

  handleMemberClick = arg => {
    // this.setState({ notifications: [] })
    const customer = arg
    this.setState({
      members: {
        customerId: customer.id,
        lastname: customer.lastname,
        firstname: customer.firstname,
        phone: customer.phone,
        email: customer.email,
        register: customer.register,
        positionId: customer.positionId,
        registrationDate: customer.registrationDate,
        isAccredit: customer.isAccredit === "true" ? true : false,
        password: customer.password,
        accreditInfo: {
          accFirstname: customer.accreditInfo
            ? customer.accreditInfo.accFirstname
            : "",
          accLastname: customer.accreditInfo
            ? customer.accreditInfo.accLastname
            : "",
          accRegister: customer.accreditInfo
            ? customer.accreditInfo.accRegister
            : "",
          accPhone: customer.accreditInfo ? customer.accreditInfo.accPhone : "",
          accStartDate: customer.accreditInfo
            ? customer.accreditInfo.accStartDate
            : "",
          accExpiryDate: customer.accreditInfo
            ? customer.accreditInfo.accExpiryDate
            : "",
        },
      },
      isAccredit: customer.isAccredit,
      isEdit: true,
      isExcel: false,
    })
    this.toggle()
  }

  /**
   * Handling submit Customer on Customer form
   */
  handleValidMemberSubmit = (e, values) => {
    const { onAddNewMember, onUpdateMember, error, onExcelFile } = this.props
    const { isEdit, members, selectedMember, isExcel } = this.state

    // console.log(values)
    if (isEdit) {
      if (isExcel) {
        onExcelFile(this.state.datas, this.state.token)
      } else {
        const updateMember = {
          token: this.state.token,
          customerId: values.id,
          lastname: values.lastname,
          firstname: values.firstname,
          phone: values.phone,
          email: values.email,
          register: values.register,
          positionId: values.positionId,
          registrationDate: values.registrationDate,
          isAccredit: values.isAccredit == "true" ? true : false,
          accreditInfo: {
            accExpiryDate: values.accExpiryDate,
            accFirstname: values.accFirstname,
            accLastname: values.accLastname,
            accPhone: values.accPhone,
            accRegister: values.accRegister,
            accStartDate: values.accStartDate,
          },
        }
        // update Member
        onUpdateMember(updateMember)
      }
    } else {
      const newMember = {
        token: this.state.token,
        lastname: values["lastname"],
        firstname: values["firstname"],
        phone: values["phone"],
        email: values["email"],
        register: values["register"],
        positionId: values["positionId"],
        registrationDate: values["registrationDate"],
        isAccredit: values["isAccredit"] === "true" ? true : false,
        accreditInfo: {
          accExpiryDate: values["accExpiryDate"],
          accFirstname: values["accFirstname"],
          accLastname: values["accLastname"],
          accPhone: values["accPhone"],
          accRegister: values["accRegister"],
          accStartDate: values["accStartDate"],
        },
        password: values["password"],
        isCitizen: values["isCitizen"] === "true" ? true : false,
      }
      onAddNewMember(newMember)
    }

    setTimeout(() => {
      if (
        isEmpty(this.props.error) &&
        this.props.error != "" &&
        typeof this.props.error != "undefined"
      ) {
        this.showToast(
          "success",
          this.props.message ? this.props.message : "Үйлдэл амжилттай боллоо.",
          "Амжилттай"
        )
        this.setState({ selectedMember: null })
        // this.setState({ notifications: this.props.message })
        this.toggle()
      } else {
        this.showToast("error", this.props.error, "Алдаа гарлаа")
      }
    }, 2000)
  }

  handleValidDate = date => {
    const date1 = moment(new Date(date)).format("YYYY-MM-DD")
    return date1
  }

  handleAccreditChange = evt => {
    const isAccredit = evt.target.value == "true" ? true : false
    this.setState({ isAccredit: isAccredit })
  }

  handleisCitizenChange = evt => {
    const isCitizen = evt.target.value == "true" ? true : false
    this.setState({ isCitizen: isCitizen })
  }

  showToast(type, message, title) {
    toastr.options = {
      timeOut: 5000,
      closeButton: true,
      progressBar: true,
      newestOnTop: true,
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
      showDuration: 300,
      hideDuration: 1000,
    }
    if (type === "info") toastr.info(message, title)
    else if (type === "warning") toastr.warning(message, title)
    else if (type === "error") toastr.error(message, title)
    else toastr.success(message, title)
  }

  importExcel = e => {
    const file = e.target.files[0]

    console.log(file)
    const reader = new FileReader()
    reader.onload = event => {
      //parse data

      const bstr = event.target.result
      const workBook = XLSX.read(bstr, { type: "binary" })
      //get first sheet
      const workSheetName = workBook.SheetNames[0]
      const workSheet = workBook.Sheets[workSheetName]
      //convert to array
      const fileData = XLSX.utils.sheet_to_json(workSheet, { header: 1 })
      console.log("file Data", fileData)
      const headers = fileData[0]
      //console.log("file header", headers)
      const heads = headers.map(head => ({ title: head, field: head }))
      this.setState({ colDefs: heads })
      //removing header
      fileData.splice(0, 1)
      this.setState({ datas: this.convertToJson(headers, fileData) })
    }

    if (file) {
      reader.readAsBinaryString(file)
    } else {
      this.setState({ colDefs: [] })
      this.setState({ datas: [] })
    }
  }

  convertToJson = (headers, data) => {
    const rows = []
    data.forEach(row => {
      let rowData = {
        isCitizen: row[0].toLowerCase() === "Тийм".toLowerCase() ? true : false,
        lastname: row[1],
        firstname: row[2],
        phone: row[3],
        email: row[4],
        register: row[5],
        registrationDate: row[6],
        password: row[7],
        positionId:
          row[8].toLowerCase() === "Хэрэглэгч".toLowerCase()
            ? 7
            : "Хяналтын зөвлөлийн гишүүн".toLowerCase()
            ? 4
            : 5,
        isAccredit:
          row[9].toLowerCase() === "Тийм".toLowerCase() ? true : false,
        accreditInfo: {
          accLastname:
            row[9].toLowerCase() === "Тийм".toLowerCase() ? row[10] : "",
          accFirstname:
            row[9].toLowerCase() === "Тийм".toLowerCase() ? row[11] : "",
          accRegister:
            row[9].toLowerCase() === "Тийм".toLowerCase() ? row[12] : "",
          accPhone:
            row[9].toLowerCase() === "Тийм".toLowerCase() ? row[13] : "",
          accStartDate:
            row[9].toLowerCase() === "Тийм".toLowerCase()
              ? row[14]
                ? row[14].toString().includes("-")
                  ? row[14]
                  : dateFormat(
                      new Date(row[13] - (25567 + 2)) * 86400 * 1000,
                      "yyyy-mm-dd"
                    )
                : ""
              : "",
          accExpiryDate:
            row[9].toLowerCase() === "Тийм".toLowerCase()
              ? row[15]
                ? row[15].toString().includes("-")
                  ? row[15]
                  : dateFormat(
                      new Date(row[14] - (25567 + 2)) * 86400 * 1000,
                      "yyyy-mm-dd"
                    )
                : ""
              : "",
        },
      }
      rows.push(rowData)
    })
    return rows
  }

  render() {
    const { members } = this.props
    const { isEdit, isExcel } = this.state
    const pageOptions = {
      sizePerPage: 10,
      custom: true,
      totalPage: members.length,
    }
    const defaultSorted = [
      {
        dataField: "id",
        order: "desc",
      },
    ]

    const { SearchBar } = Search

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title> Хэрэглэгчийн жагсаалт | Цахим хурал</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs
              title="Дашбоард"
              breadcrumbItem="Хэрэглэгчийн жагсаалт"
            />
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={this.state.CompanyCustomerColumns}
                      data={members}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={this.state.CompanyCustomerColumns}
                          data={members}
                          search
                        >
                          {toolkitProps => (
                            <>
                              <Row>
                                <Col sm="3">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        placeholder="Хайх"
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>

                                <Col sm="9">
                                  <div className="text-sm-end">
                                    <ButtonDropdown
                                      isOpen={this.state.drp_info1}
                                      toggle={() =>
                                        this.setState({
                                          drp_info1: !this.state.drp_info1,
                                        })
                                      }
                                    >
                                      <DropdownToggle
                                        caret
                                        color="info"
                                        className="mb-2 me-2"
                                      >
                                        <i className="mdi mdi-chevron-down me-1"></i>
                                        Excel
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem>
                                          <a target="_blank" href={Excelformat}>
                                            <i className="mdi mdi-download  me-1" />{" "}
                                            Жишээ файл татах
                                          </a>
                                        </DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem
                                          onClick={() => this.handleExcel()}
                                        >
                                          <i className="mdi mdi-upload me-1" />
                                          Файл хавсаргах
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </ButtonDropdown>
                                    <Button
                                      type="button"
                                      className="btn btn-success mb-2 me-2"
                                      onClick={this.handleMemberClicks}
                                    >
                                      <i className="mdi mdi-plus me-1" />{" "}
                                      Хэрэглэгч нэмэх
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />

                                  <Modal
                                    size={!isExcel ? "xl" : "md"}
                                    isOpen={this.state.modal}
                                    className={this.props.className}
                                  >
                                    <ModalHeader toggle={this.toggle} tag="h4">
                                      {!!isEdit
                                        ? isExcel
                                          ? "Файл хавсаргах"
                                          : "Хэрэглэгчийн мэдээлэл засах"
                                        : "Хэрэглэгч нэмэх"}
                                    </ModalHeader>
                                    <ModalBody>
                                      <AvForm
                                        onValidSubmit={
                                          this.handleValidMemberSubmit
                                        }
                                      >
                                        <AvField
                                          id="id"
                                          name="id"
                                          value={
                                            this.state.members
                                              ? this.state.members.customerId
                                              : ""
                                          }
                                          type="hidden"
                                        />
                                        {!isExcel ? (
                                          <>
                                            <Row>
                                              <Col md="6">
                                                <div className="mb-3">
                                                  <AvRadioGroup
                                                    inline
                                                    name="isCitizen"
                                                    required
                                                    errorMessage="Сонголт хийнэ үү!!"
                                                    onChange={
                                                      this.handleisCitizenChange
                                                    }
                                                  >
                                                    <AvRadio
                                                      label="Хувь хүн"
                                                      value="true"
                                                    />
                                                    <AvRadio
                                                      label="Хуулийн этгээд"
                                                      value="false"
                                                    />
                                                  </AvRadioGroup>
                                                </div>
                                              </Col>
                                              <Col lg="6">
                                                <div className="mb-3">
                                                  <AvField
                                                    name="positionId"
                                                    label="Албан тушаал"
                                                    type="select"
                                                    errorMessage="Албан тушаал сонгоно уу!!"
                                                    className="form-select"
                                                    validate={{
                                                      required: { value: true },
                                                    }}
                                                    value={
                                                      this.state.members
                                                        ? this.state.members
                                                            .positionId
                                                        : ""
                                                    }
                                                  >
                                                    <option value>
                                                      -Сонгох-
                                                    </option>
                                                    <option value="7">
                                                      Хэрэглэгч
                                                    </option>
                                                    <option value="8">
                                                      Төлөөлөн удирдах зөвлөлийн
                                                      гишүүн
                                                    </option>
                                                    <option value="9">
                                                      Хяналтын зөвлөлийн гишүүн
                                                    </option>
                                                    <option value="10">
                                                      Зээлийн хорооны гишүүн
                                                    </option>
                                                    <option value="11">
                                                      Тэргүүлэгчдийн зөвлөлийн
                                                      гишүүн
                                                    </option>
                                                  </AvField>
                                                </div>
                                              </Col>
                                            </Row>
                                            {!this.state.isCitizen ? (
                                              <>
                                                <Row
                                                // className={css.memberInput}
                                                >
                                                  <Col md="6">
                                                    <div className="mb-3">
                                                      <AvField
                                                        id="lastname"
                                                        name="lastname"
                                                        label="Байгууллагын төрөл:"
                                                        type="select"
                                                        errorMessage="Талбар хоосон байна!!"
                                                        className="form-select"
                                                        validate={{
                                                          required: {
                                                            value: true,
                                                          },
                                                        }}
                                                        value={
                                                          this.state.members
                                                            ? this.state.members
                                                                .lastname
                                                            : ""
                                                        }
                                                      >
                                                        <option value="">
                                                          -Сонгох-
                                                        </option>
                                                        <option value="ХК">
                                                          Хувьцаат Компани
                                                        </option>
                                                        <option value="ХЗХ">
                                                          Хадгаламж Зээлийн
                                                          Хоршоо
                                                        </option>
                                                        <option value="Бусад">
                                                          Бусад
                                                        </option>
                                                      </AvField>
                                                    </div>
                                                  </Col>
                                                  <Col md="6">
                                                    <div className="mb-3">
                                                      <AvField
                                                        name="firstname"
                                                        label="Байгууллагын нэр:"
                                                        type="text"
                                                        errorMessage="Талбар хоосон байна!!"
                                                        validate={{
                                                          required: {
                                                            value: true,
                                                          },
                                                        }}
                                                        value={
                                                          this.state.members
                                                            ? this.state.members
                                                                .firstname
                                                            : ""
                                                        }
                                                      />
                                                    </div>
                                                  </Col>
                                                </Row>
                                                <Row>
                                                  <Col md="6">
                                                    <div className="mb-3">
                                                      <AvField
                                                        name="register"
                                                        label="Байгууллагын регистр:"
                                                        type="text"
                                                        errorMessage="Байгууллагын регистр оруулна уу!!"
                                                        validate={{
                                                          required: {
                                                            value: true,
                                                          },
                                                          minLength: {
                                                            value: 7,
                                                            errorMessage:
                                                              "Байгууллагын регистрийн дугаар 7 оронтой байна!!",
                                                          },
                                                          maxLength: {
                                                            value: 7,
                                                            errorMessage:
                                                              "Байгууллагын регистрийн дугаар 7 оронтой байна.",
                                                          },
                                                        }}
                                                        value={
                                                          this.state.members
                                                            ? this.state.members
                                                                .register
                                                            : ""
                                                        }
                                                      />
                                                    </div>
                                                  </Col>
                                                  <Col md="6">
                                                    <div className="mb-3">
                                                      <AvField
                                                        name="phone"
                                                        label="Утасны дугаар:"
                                                        type="number"
                                                        rows="3"
                                                        errorMessage="Утас оруулна уу!!"
                                                        validate={{
                                                          required: {
                                                            value: true,
                                                          },
                                                          minLength: {
                                                            value: 8,
                                                            errorMessage:
                                                              "Утасны дугаар буруу байна!!",
                                                          },
                                                          maxLength: {
                                                            value: 8,
                                                            errorMessage:
                                                              "Утасны дугаар буруу байна!!",
                                                          },
                                                        }}
                                                        value={
                                                          this.state.members
                                                            ? this.state.members
                                                                .phone
                                                            : ""
                                                        }
                                                      />
                                                    </div>
                                                  </Col>
                                                </Row>
                                              </>
                                            ) : (
                                              <>
                                                <Row
                                                //className={css.memberInput}
                                                >
                                                  <Col md="6">
                                                    <div className="mb-3">
                                                      <AvField
                                                        name="lastname"
                                                        label="Овог:"
                                                        type="text"
                                                        errorMessage="Талбар хоосон байна!!"
                                                        validate={{
                                                          required: {
                                                            value: true,
                                                          },
                                                        }}
                                                        value={
                                                          this.state.members
                                                            ? this.state.members
                                                                .lastname
                                                            : ""
                                                        }
                                                      />
                                                    </div>
                                                  </Col>
                                                  <Col md="6">
                                                    <div className="mb-3">
                                                      <AvField
                                                        name="firstname"
                                                        label="Нэр:"
                                                        type="text"
                                                        errorMessage="Талбар хоосон байна!!"
                                                        validate={{
                                                          required: {
                                                            value: true,
                                                          },
                                                        }}
                                                        value={
                                                          this.state.members
                                                            ? this.state.members
                                                                .firstname
                                                            : ""
                                                        }
                                                      />
                                                    </div>
                                                  </Col>
                                                </Row>
                                                <Row>
                                                  <Col md="6">
                                                    <div className="mb-3">
                                                      <AvField
                                                        name="register"
                                                        label="Регистр:"
                                                        type="text"
                                                        errorMessage="Регистрын дугаараа оруулна уу!!"
                                                        validate={{
                                                          required: {
                                                            value: true,
                                                          },
                                                          pattern: {
                                                            value:
                                                              "^[А-Яа-я]{2}[0-9]{8}$",
                                                            errorMessage:
                                                              "Регистрын формат буруу байна. Жишээ нь : АА12345678",
                                                          },
                                                        }}
                                                        value={
                                                          this.state.members
                                                            ? this.state.members
                                                                .register
                                                            : ""
                                                        }
                                                      />
                                                    </div>
                                                  </Col>
                                                  <Col md="6">
                                                    <div className="mb-3">
                                                      <AvField
                                                        name="phone"
                                                        label="Утасны дугаар:"
                                                        type="number"
                                                        rows="3"
                                                        errorMessage="Утасны дугаараа оруулна уу!!"
                                                        validate={{
                                                          required: {
                                                            value: true,
                                                          },
                                                          minLength: {
                                                            value: 8,
                                                            errorMessage:
                                                              "Утасны дугаар буруу байна!!",
                                                          },

                                                          maxLength: {
                                                            value: 8,
                                                            errorMessage:
                                                              "Утасны дугаар буруу байна!!",
                                                          },
                                                        }}
                                                        value={
                                                          this.state.members
                                                            ? this.state.members
                                                                .phone
                                                            : ""
                                                        }
                                                      />
                                                    </div>
                                                  </Col>
                                                </Row>
                                              </>
                                            )}

                                            <Row>
                                              <Col md="6">
                                                <div className="mb-3">
                                                  <AvField
                                                    name="email"
                                                    label="И-Мэйл"
                                                    type="email"
                                                    errorMessage="Талбар хоосон байна!!"
                                                    validate={{
                                                      required: { value: true },
                                                      email: {
                                                        value: true,
                                                        errorMessage:
                                                          "Формат буруу байна!!",
                                                      },
                                                    }}
                                                    value={
                                                      this.state.members
                                                        ? this.state.members
                                                            .email
                                                        : ""
                                                    }
                                                  />
                                                </div>
                                              </Col>
                                              <Col md="6">
                                                <div className="mb-3">
                                                  <AvField
                                                    name="registrationDate"
                                                    htmlFor="registrationDate"
                                                    label="Элссэн огноо:"
                                                    type="date"
                                                    errorMessage="Талбар хоосон байна!!"
                                                    validate={{
                                                      required: { value: true },
                                                      max: {
                                                        value: now,
                                                        errorMessage:
                                                          "Огноо буруу байна!!",
                                                      },
                                                    }}
                                                    value={
                                                      this.state.members
                                                        ? this.state.members
                                                            .registrationDate
                                                        : ""
                                                    }
                                                  />
                                                </div>
                                              </Col>
                                            </Row>

                                            {!this.state.isEdit ? (
                                              <Row className="mb-3">
                                                <Col md="6">
                                                  <div className="mb-3">
                                                    <AvField
                                                      name="password"
                                                      label="Нууц үг:"
                                                      type="password"
                                                      errorMessage="Нууц үг оруулна уу!!"
                                                      validate={{
                                                        required: {
                                                          value: true,
                                                        },
                                                        pattern: {
                                                          value:
                                                            "^(?=.*[A-Za-z])(?=.*[0-9])(?=.*[@$!%*#?&])[A-Za-z0-9@$!%*#?&]{8,20}$",
                                                          errorMessage:
                                                            "Нууц үг нь 8-20 урттай багадаа нэг тоо, нэг үсэг, нэг тэмдэгт (@$!%*#?&) агуулсан байна!!",
                                                        },
                                                      }}
                                                      value={
                                                        this.state.members
                                                          ? this.state.members
                                                              .password
                                                          : ""
                                                      }
                                                    />
                                                  </div>
                                                </Col>
                                                <Col md="6">
                                                  <div className="mb-3">
                                                    <AvField
                                                      name="passwordConfirm"
                                                      label="Нууц үг давтах"
                                                      type="password"
                                                      errorMessage="Нууц үг давтан оруулна уу!!"
                                                      validate={{
                                                        required: {
                                                          value: true,
                                                        },
                                                        match: {
                                                          value: "password",
                                                          errorMessage:
                                                            "Нууц үг таарахгүй байна!!",
                                                        },
                                                        pattern: {
                                                          value:
                                                            "^(?=.*[A-Za-z])(?=.*[0-9])(?=.*[@$!%*#?&])[A-Za-z0-9@$!%*#?&]{8,20}$",
                                                          errorMessage:
                                                            "Нууц үг нь 8-20 урттай багадаа нэг тоо, нэг үсэг, нэг тэмдэгт (@$!%*#?&) агуулсан байна!!",
                                                        },
                                                      }}
                                                    />
                                                  </div>
                                                </Col>
                                              </Row>
                                            ) : null}
                                            <Row>
                                              <Col md="6">
                                                <div className="mb-3">
                                                  <AvField
                                                    id="isAccredit"
                                                    name="isAccredit"
                                                    label="Итгэмжлэлтэй эсэх:"
                                                    type="select"
                                                    errorMessage="Талбар хоосон байна!!"
                                                    className="form-select"
                                                    validate={{
                                                      required: { value: true },
                                                    }}
                                                    value={
                                                      this.state.members
                                                        ? this.state.members
                                                            .isAccredit
                                                        : ""
                                                    }
                                                    onChange={
                                                      this.handleAccreditChange
                                                    }
                                                  >
                                                    <option value="">
                                                      -Сонгох-
                                                    </option>
                                                    <option value="true">
                                                      Тийм
                                                    </option>
                                                    <option value="false">
                                                      Үгүй
                                                    </option>
                                                  </AvField>
                                                </div>
                                              </Col>
                                            </Row>

                                            {!this.state.isAccredit ? (
                                              ""
                                            ) : (
                                              <Row>
                                                <Col className="col-lg-12">
                                                  <div className="inner-repeater mb-10">
                                                    <Row>
                                                      <Col>
                                                        <h4>
                                                          Итгэмжлэгчийн мэдээлэл
                                                        </h4>
                                                      </Col>
                                                    </Row>
                                                    <br></br>

                                                    <div>
                                                      <Row>
                                                        <Col md="6">
                                                          <div className="mb-3">
                                                            <AvField
                                                              name="accLastname"
                                                              label="Овог:"
                                                              type="text"
                                                              errorMessage="Талбар хоосон байна!!"
                                                              value={
                                                                this.state
                                                                  .members
                                                                  .accreditInfo
                                                                  ? this.state
                                                                      .members
                                                                      .accreditInfo
                                                                      .accLastname
                                                                  : ""
                                                              }
                                                              validate={{
                                                                required: {
                                                                  value: true,
                                                                },
                                                              }}
                                                            />
                                                          </div>
                                                        </Col>
                                                        <Col md="6">
                                                          <div className="mb-3">
                                                            <AvField
                                                              name="accFirstname"
                                                              label="Нэр:"
                                                              type="text"
                                                              errorMessage="Талбар хоосон байна!!"
                                                              value={
                                                                this.state
                                                                  .members
                                                                  .accreditInfo
                                                                  ? this.state
                                                                      .members
                                                                      .accreditInfo
                                                                      .accFirstname
                                                                  : ""
                                                              }
                                                              validate={{
                                                                required: {
                                                                  value: true,
                                                                },
                                                              }}
                                                            />
                                                          </div>
                                                        </Col>
                                                      </Row>
                                                      <Row>
                                                        <Col lg="6">
                                                          <AvField
                                                            label="Регистр:"
                                                            name="accRegister"
                                                            type="text"
                                                            errorMessage="Регистрын дугаараа оруулна уу!!"
                                                            value={
                                                              this.state.members
                                                                .accreditInfo
                                                                ? this.state
                                                                    .members
                                                                    .accreditInfo
                                                                    .accRegister
                                                                : ""
                                                            }
                                                            validate={{
                                                              required: {
                                                                value: true,
                                                              },
                                                              pattern: {
                                                                value:
                                                                  "^[А-Яа-я]{2}[0-9]{8}$",
                                                                errorMessage:
                                                                  "Регистрын формат буруу байна. Жишээ нь: АА12345678",
                                                              },
                                                            }}
                                                          />
                                                        </Col>
                                                        <Col lg="6">
                                                          <AvField
                                                            label="Утасны дугаар:"
                                                            name="accPhone"
                                                            type="number"
                                                            errorMessage="Утасны дугаар оруулна уу!!"
                                                            value={
                                                              this.state.members
                                                                .accreditInfo
                                                                ? this.state
                                                                    .members
                                                                    .accreditInfo
                                                                    .accPhone
                                                                : ""
                                                            }
                                                            validate={{
                                                              required: {
                                                                value: true,
                                                              },
                                                              minLength: {
                                                                value: 8,
                                                                errorMessage:
                                                                  "Утасны дугаар буруу байна!!",
                                                              },

                                                              maxLength: {
                                                                value: 8,
                                                                errorMessage:
                                                                  "Утасны дугаар буруу байна!!",
                                                              },
                                                            }}
                                                          />
                                                        </Col>
                                                      </Row>
                                                      <Row>
                                                        <Col lg="6">
                                                          <AvField
                                                            label="Итгэмжлэл эхлэх огноо:"
                                                            name="accStartDate"
                                                            type="date"
                                                            errorMessage="Талбар хоосон байна!!"
                                                            value={
                                                              this.state.members
                                                                .accreditInfo
                                                                ? this.state
                                                                    .members
                                                                    .accreditInfo
                                                                    .accStartDate
                                                                : ""
                                                            }
                                                            validate={{
                                                              required: {
                                                                value: true,
                                                              },
                                                            }}
                                                          />
                                                        </Col>
                                                        <Col lg="6">
                                                          <AvField
                                                            label=" Итгэмжлэл дуусах огноо:"
                                                            name="accExpiryDate"
                                                            type="date"
                                                            errorMessage="Талбар хоосон байна!!"
                                                            value={
                                                              this.state.members
                                                                .accreditInfo
                                                                ? this.state
                                                                    .members
                                                                    .accreditInfo
                                                                    .accExpiryDate
                                                                : ""
                                                            }
                                                            validate={{
                                                              required: {
                                                                value: true,
                                                              },
                                                            }}
                                                          />
                                                        </Col>
                                                      </Row>
                                                    </div>
                                                  </div>
                                                </Col>
                                              </Row>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            <Row>
                                              <span>
                                                Нийт гишүүн:{" "}
                                                {this.state.datas
                                                  ? this.state.datas.length
                                                  : 0}{" "}
                                              </span>
                                              <Col>
                                                <AvField
                                                  name="file"
                                                  label="Файл:"
                                                  type="file"
                                                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                  onChange={e =>
                                                    this.importExcel(e)
                                                  }
                                                  errorMessage="Талбар хоосон байна!!"
                                                  className="form-control"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                />
                                              </Col>
                                            </Row>
                                          </>
                                        )}
                                        <Row>
                                          <Col>
                                            <div className="text-end">
                                              <button
                                                type="submit"
                                                className="btn btn-success save-customer mt-2"
                                              >
                                                {!!isExcel
                                                  ? "Илгээх"
                                                  : !!isEdit
                                                  ? "Засах"
                                                  : "Нэмэх"}
                                              </button>
                                            </div>
                                          </Col>
                                        </Row>
                                      </AvForm>
                                    </ModalBody>
                                  </Modal>
                                </div>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

CompanyCustomers.propTypes = {
  members: PropTypes.array,
  stokeType: PropTypes.array,
  accreditInfo: PropTypes.object,
  start: PropTypes.number,
  totalPage: PropTypes.number,
  onGetMembers: PropTypes.func,
  onAddNewMember: PropTypes.func,
  onDeleteMember: PropTypes.func,
  onUpdateMember: PropTypes.func,
  onGetStokeType: PropTypes.func,
  history: PropTypes.object,
}

// const mapStateToProps = ({ecommerce}) => ({
//   companys: ecommerce.companys,
// })
const mapStateToProps = state => {
  return {
    members: state.CompanyMemberRed.members,
    start: state.CompanyMemberRed.start,
    totalPage: state.CompanyMemberRed.totalPage,
    message: state.CompanyMemberRed.message,
    stokeType: state.CompanyMemberRed.stokeType,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetMembers: (token, start, totalPage, history) =>
    dispatch(getCompanyMembers(token, start, totalPage, history)),
  onAddNewMember: customer => dispatch(addNewMember(customer)),
  onUpdateMember: customer => dispatch(updateMember(customer)),
  onDeleteMember: customer => dispatch(deleteMember(customer)),
  onGetStokeType: token => dispatch(getStokeType(token)),
  onExcelFile: (datas, token) => dispatch(getExcels(datas, token)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CompanyCustomers)
