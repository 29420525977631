import React, { Component } from "react"
import { Link } from "react-router-dom"
import * as moment from "moment"
import {
  Row,
  Col,
  Badge,
  Button,
  Table,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap"
import { map } from "lodash"
import PropTypes from "prop-types"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { startVote, saveVote } from "store/actions"

class AuditMeetingAgenda extends Component {
  constructor(props) {
    super(props)
    this.state = {
      token: this.props.token,
      agendas: [],
      choiseList: [],
      checkedCount: 0,
      votes: [],
      vote: {},
    }
  }
  handleVoteValidDate = (isAdvanceVote, startDateTime, endDateTime) => {
    if (isAdvanceVote) {
      const today = new Date()
      const startDate = new Date(startDateTime)
      const endDate = new Date(endDateTime)
      if (
        startDate.getTime() < today.getTime() &&
        endDate.getTime() > today.getTime()
      ) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  handleVoteClick = agenda => {
    this.setState({
      agendas: {
        content: agenda.content,
        agendaType: agenda.agendaType,
        agendaTypeId: agenda.agendaTypeId,
        isAdvanceVote: agenda.isAdvanceVote,
        voteStartTime: agenda.voteStartTime,
        voteFinishTime: agenda.voteFinishTime,
        id: agenda.id,
        choiseCount: agenda.choiseCount,
      },
    })
    this.props.onStartVote(this.props.token, agenda.id)
    this.setState({ votes: this.props.votes })
    this.setState({ choiseList: [], checkedCount: 0 })
    this.setState({ votes: this.props.votes })
    this.toggle()
  }

  toggle = () => {
    if (this.state.votes) {
      this.setState(prevState => ({
        modal: !prevState.modal,
      }))
    }
  }

  onChoiceCheck = (choiseId, quantity) => evt => {
    if (evt.target.checked && evt.target.value === "false") {
      if (this.state.agendas.choiseCount <= this.state.checkedCount) {
        evt.preventDefault()
        this.showToast(
          "error",
          this.state.agendas.choiseCount + " сонголт хийх боломжтой байна.",
          "Боломжит санал"
        )
      } else {
        const choise = {
          choiseId: choiseId,
          choiseQuantity: quantity,
        }

        this.setState({
          choiseList: [...this.state.choiseList, choise],
        })
        this.setState({ checkedCount: this.state.checkedCount + 1 })
      }
    } else {
      this.setState({
        choiseList: this.state.choiseList.filter(
          (s, sidx) => choiseId !== s.choiseId
        ),
      })
      this.setState({ checkedCount: this.state.checkedCount - 1 })
    }
  }

  showToast(type, message, title) {
    toastr.options = {
      timeOut: 5000,
      closeButton: true,
      progressBar: true,
      newestOnTop: true,
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
      showDuration: 300,
      hideDuration: 1000,
    }

    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (type === "info") toastr.info(message, title)
    else if (type === "warning") toastr.warning(message, title)
    else if (type === "error") toastr.error(message, title)
    else toastr.success(message, title)
  }

  handleValidVoteSubmit = (e, values) => {
    const vote = {
      token: this.props.token,
      agendaId: values.agendaId,
      vote: values.vote,
      loginIp: 1,
      isAdvanceVote: values.isAdvanceVote,
      quantity: values.quantity,
      choiseList: this.state.choiseList,
      description: values.description,
    }

    // update Company
    const saveVote = this.props.onSaveVote(vote)
    console.log(saveVote)
    this.showToast(
      "success",
      "Таны ирүүлсэн санал амжилттай илгээгдлээ.",
      "Санал илгээгдлээ"
    )
    this.toggle()
    this.forceUpdate()
  }

  render() {
    const { agendas, token } = this.props
    return (
      <React.Fragment>
        <div className="mt-4">
          <div className="d-flex flex-wrap">
            <h5 className="font-size-16 me-3">Хэлэлцэх асуудал</h5>
          </div>
          <hr className="mt-2" />

          <div className="table-responsive">
            <Table className="project-list-table align-middle table-borderless">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Хэлэлцэх асуудал</th>
                  <th scope="col">Төрөл</th>
                  <th scope="col">Санал авах арга</th>
                  <th scope="col">Хугацаа</th>
                  <th scope="col">Төлөв</th>
                  <th scope="col">Урьдчилсан</th>
                  <th scope="col">Эчнээ</th>
                  <th scope="col">Хурлын</th>
                  <th scope="col">Үйлдэл</th>
                </tr>
              </thead>
              <tbody>
                {map(agendas, (agenda, index) => (
                  <tr key={agenda.id}>
                    <td>{index + 1}</td>
                    <td style={{ width: "400px" }}>
                      {agenda.agendaType}
                      <div className="p text-muted mb-0">{agenda.content}</div>
                    </td>
                    <td>{agenda.voteType}</td>
                    <td>{agenda.voteMethod}</td>
                    <td>{agenda.baseVoteTime} минут</td>
                    <td>
                      <Badge
                        color={agenda.isStart ? "success" : "danger"}
                        className={
                          "bg-" + (agenda.isStart ? "success" : "danger")
                        }
                      >
                        {agenda.isVoted
                          ? "Санал өгсөн"
                          : this.handleVoteValidDate(
                              agenda.isAdvanceVote,
                              agenda.voteStartTime,
                              agenda.voteFinishTime
                            )
                          ? "Урьдчилсан санал эхэлсэн"
                          : agenda.isStart && !agenda.isFinish
                          ? "Санал хураалт эхэлсэн"
                          : agenda.isStart && agenda.isFinish
                          ? "Санал хураалт дууссан"
                          : ""}
                      </Badge>
                    </td>
                    <td>
                      <div className="form-check font-size-16">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={agenda.displayIndex}
                          checked={agenda.isAdvanceVote}
                          readOnly="readonly"
                          value={agenda.isAdvanceVote}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={agenda.displayIndex}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="form-check font-size-16">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={agenda.displayIndex}
                          checked={agenda.isExternalVote}
                          readOnly="readonly"
                          value={agenda.isExternalVote}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={agenda.displayIndex}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="form-check font-size-16">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={agenda.displayIndex}
                          checked={agenda.isMeetingVote}
                          readOnly="readonly"
                          value={agenda.isMeetingVote}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={agenda.displayIndex}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="d-flex gap-3">
                        {(!agenda.isVoted &&
                          agenda.isVote &&
                          agenda.isStart &&
                          !agenda.isFinish) ||
                          (!agenda.isVoted &&
                            this.handleVoteValidDate(
                              agenda.isAdvanceVote,
                              agenda.voteStartTime,
                              agenda.voteFinishTime
                            ) && (
                              <Button
                                type="button"
                                color="primary"
                                className="mt-3 mt-lg-0"
                                onClick={() => this.handleVoteClick(agenda)}
                              >
                                <i
                                  className="mdi mdi-check font-size-18"
                                  id="edittooltip"
                                />{" "}
                                Санал өгөх
                              </Button>
                            ))}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Modal
              size="lg"
              backdrop={"static"}
              isOpen={this.state.modal}
              className={this.props.className}
            >
              <ModalHeader toggle={this.toggle} tag="h4">
                {"Санал өгөх"}
              </ModalHeader>
              <ModalBody>
                <AvForm onValidSubmit={this.handleValidVoteSubmit}>
                  <Row>
                    <Col lg="12">
                      <p>
                        {this.state.agendas.agendaType}
                        <div className="p text-muted mb-0">
                          {this.state.agendas.content}
                        </div>
                        <AvField
                          id="agendaId"
                          name="agendaId"
                          value={
                            this.state.agendas ? this.state.agendas.id : ""
                          }
                          type="hidden"
                        />
                        <AvField
                          id="quantity"
                          name="quantity"
                          value={
                            this.props.votes ? this.props.votes.quantity : ""
                          }
                          type="hidden"
                        />
                        <AvField
                          id="isAdvanceVote"
                          name="isAdvanceVote"
                          value={this.handleVoteValidDate(
                            this.state.agendas.isAdvanceVote,
                            this.state.agendas.voteStartTime,
                            this.state.agendas.voteFinishTime
                          )}
                          type="hidden"
                        />
                      </p>
                      <Row>
                        <Col lg="6">
                          <div className="form-check form-radio-primary mb-3">
                            <div className="text-danger mb-3">
                              Сонголтын тоо: {this.state.agendas.choiseCount}
                            </div>
                            {this.state.agendas.agendaTypeId == 1 &&
                            this.state.agendas.choiseCount == 1 ? (
                              <>
                                <AvField
                                  name="vote"
                                  id="vote1"
                                  label="Зөвшөөрсөн"
                                  type="radio"
                                  className="form-check-input"
                                  errorMessage="Санал аа сонгоно уу"
                                  value="1"
                                  validate={{
                                    required: { value: true },
                                  }}
                                />
                                <AvField
                                  name="vote"
                                  id="vote2"
                                  label="Түтгэлзсэн"
                                  type="radio"
                                  className="form-check-input"
                                  errorMessage="Саналаа сонгоно уу"
                                  value="2"
                                  validate={{
                                    required: { value: true },
                                  }}
                                />
                                <AvField
                                  name="vote"
                                  id="vote3"
                                  label="Татагалзсан"
                                  type="radio"
                                  className="form-check-input"
                                  errorMessage="Саналаа сонгоно уу"
                                  value="3"
                                  validate={{
                                    required: { value: true },
                                  }}
                                />
                              </>
                            ) : (
                              map(this.props.votes.choiseList, (row, idx) => (
                                <AvField
                                  key={idx}
                                  name={"choiseId" + row.choiseId}
                                  id={"choiseId" + row.choiseId}
                                  label={row.choise}
                                  type="checkbox"
                                  className="form-check-input"
                                  errorMessage="Саналаа сонгоно уу"
                                  onChange={this.onChoiceCheck(
                                    row.choiseId,
                                    this.props.votes.quantity /
                                      this.state.agendas.choiseCount
                                  )}
                                  value={row.choiseId}
                                  validate={{
                                    required: { value: true },
                                  }}
                                />
                              ))
                            )}
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="mb-3">
                            <AvField
                              name="description"
                              label="Тайлбар"
                              type="textarea"
                              rows="3"
                              value={
                                this.state.companys
                                  ? this.state.companys.address
                                  : ""
                              }
                            />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <button
                          type="submit"
                          className="btn btn-success save-company"
                        >
                          Хадгалах
                        </button>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </ModalBody>
            </Modal>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

AuditMeetingAgenda.propTypes = {
  votes: PropTypes.object,
  token: PropTypes.string,
  onDeleteCompany: PropTypes.func,
  onStartVote: PropTypes.func,
  onSaveVote: PropTypes.func,
}

// const mapStateToProps = ({ ecommerce }) => ({
//   companys: ecommerce.companys,
// })
const mapStateToProps = state => {
  return {
    votes: state.AuditMeeting.votes,
  }
}

const mapDispatchToProps = dispatch => ({
  onStartVote: (token, agendaId) => dispatch(startVote(token, agendaId)),
  onSaveVote: vote => dispatch(saveVote(vote)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AuditMeetingAgenda)
