import axios from "axios"
import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"

// const API_URL = "http://192.168.0.108:8080/project.meeting.backend/rest"
const API_URL = "http://103.48.116.95:8085/project.meeting.backend/rest"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("authUser")
  if (user) return JSON.parse(user)
  return null
}
const getExpireTime = () => {
  const nowTime = new Date().getTime()
  const expire = new Date(localStorage.getItem("expireDate")).getTime()
  if (nowTime <= expire) return true
  return false
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null && getExpireTime()
}

// Register Method
const postFakeRegister = data => {
  return axios
    .post(url.POST_FAKE_REGISTER, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      let message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// Login Method
const postFakeLogin = data => post(url.POST_FAKE_LOGIN, data)

// postForgetPwd
const postFakeForgetPwd = data => post(url.POST_FAKE_PASSWORD_FORGET, data)

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data)

const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data)

// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      var message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// Login Method
const postJwtLogin = data => post(url.POST_FAKE_JWT_LOGIN, data)

// postForgetPwd
const postJwtForgetPwd = data => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data)

// postSocialLogin
export const postSocialLogin = data => post(url.SOCIAL_LOGIN, data)

// get Products
export const getProducts = () => get(url.GET_PRODUCTS)

// get Product detail
export const getProductDetail = id =>
  get(`${url.GET_PRODUCTS_DETAIL}/${id}`, { params: { id } })

// get dashboard charts data
export const getWeeklyData = () => get(url.GET_WEEKLY_DATA)
export const getYearlyData = () => get(url.GET_YEARLY_DATA)
export const getMonthlyData = () => get(url.GET_MONTHLY_DATA)

//Login java
// Login Method
const postJavaLogin = data => {
  var formBody = []
  for (var property in data) {
    var encodedKey = encodeURIComponent(property)
    var encodedValue = encodeURIComponent(data[property])
    formBody.push(encodedKey + "=" + encodedValue)
  }
  formBody = formBody.join("&")
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json",
      Authorization: "Basic c3lzX2N1c3RvbWVyOnN5czEyMw==",
    },
    body: formBody,
  }
  return fetch(API_URL + "/Admin/UserWebService/login", requestOptions)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) {
        return response.json()
      }
      throw response
    })
    .catch(err => {
      let message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Хуудас олдсонгүй"
            break
          case 500:
            message = "Системийн дотоод алдаа гарсан байна."
            break
          case 401:
            message = "Хүчингүй хандалт"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// get customer meeting
// export const getCustomerMeeting = () => get(url.GET_CUSTOMER_MEETING)

export const postJson = (data, method) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Accept: "application/json",
      Authorization: "Basic c3lzX2N1c3RvbWVyOnN5czEyMw==",
    },
    body: JSON.stringify(data),
  }
  return fetch(API_URL + method, requestOptions)
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.json()
      throw response
    })
    .catch(err => {
      let message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

export const postUrlEncode = (data, method) => {
  var formBody = []
  for (var property in data) {
    var encodedKey = encodeURIComponent(property)
    var encodedValue = encodeURIComponent(data[property])
    formBody.push(encodedKey + "=" + encodedValue)
  }
  formBody = formBody.join("&")

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      Accept: "application/json",
      Authorization: "Basic c3lzX2N1c3RvbWVyOnN5czEyMw==",
    },
    body: formBody,
  }
  return fetch(API_URL + method, requestOptions)
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.json()
      throw response.data
    })
    .catch(err => {
      console.log(err.response)
      let message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

export const topSellingData = month =>
  get(`${url.TOP_SELLING_DATA}/${month}`, { params: { month } })

export const getEarningChartsData = month =>
  get(`${url.GET_EARNING_DATA}/${month}`, { params: { month } })

export const postUrlEncodeDan = url => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      Accept: "application/json",
      // Authorization: "Basic c3lzX2N1c3RvbWVyOnN5czEyMw==",
    },
  }
  console.log(url)
  return fetch(url, requestOptions)
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.json()
      throw response.data
    })
    .catch(err => {
      console.log(err.response)
      let message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

export {
  getLoggedInUser,
  isUserAuthenticated,
  postFakeRegister,
  postFakeLogin,
  postFakeProfile,
  postFakeForgetPwd,
  postJwtRegister,
  postJwtLogin,
  postJwtForgetPwd,
  postJwtProfile,
  postJavaLogin,
  // postUrlEncodeDan
}
