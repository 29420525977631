import React from "react"
import PropTypes from "prop-types"
import { map, get } from "lodash"
import { Card, CardBody, Col, Media, Row } from "reactstrap"
// import img1 from "../../../assets/images/companies/img-1.png"

const GeneralSharesDetail = ({ sharesdtl }) => {
  return (
    <Card>
      <CardBody>
        <div className="Generel-Dtl">
          {/* <img src={img1} alt="" className="avatar-sm me-4" /> */}
          <Row>
            <Col xs="4" md="4">
              <Row>
                {" "}
                <p>Овог: {sharesdtl.lastname}</p>
              </Row>
              <Row>
                {" "}
                <p>Нэр: {sharesdtl.firstname}</p>
              </Row>
              <Row>
                {" "}
                <p>Регистр: {sharesdtl.register}</p>{" "}
              </Row>
            </Col>

            <Col xs="4" md="4">
              <Row>
                {" "}
                <p>Утас: {sharesdtl.phone}</p>{" "}
              </Row>
              <Row>
                {" "}
                <p>Компаны нэр: {sharesdtl.companyName}</p>{" "}
              </Row>
              <Row>
                {" "}
                <p>Төлөв: {sharesdtl.customerStatus}</p>{" "}
              </Row>
            </Col>

            <Col xs="4" md="4">
              <Row>
                {" "}
                <p>И-Мэйл: {sharesdtl.email}</p>{" "}
              </Row>
              <Row>
                {" "}
                <p>Бүртгүүлсэн огноо: {sharesdtl.registrationDate}</p>{" "}
              </Row>
              <Row>
                {" "}
                <p>Хувьцааны тоо: {sharesdtl.quantity} </p>
              </Row>
            </Col>
          </Row>

          <Row>
            <h4> Итгэмжлэгчийн мэдээлэл</h4>
            {/* {sharesdtl.accreditInfo.map(item => ( */}
            {map(sharesdtl.accreditInfo, (item, index) => (
              <>
                <Col xs="4" md="4">
                  <Row>
                    {" "}
                    <p>Овог: {item.accLastname}</p>{" "}
                  </Row>
                  <Row>
                    {" "}
                    <p>Нэр: {item.accFirstname}</p>{" "}
                  </Row>
                  <Row>
                    {" "}
                    <p>Регистр: {item.accRegister}</p>{" "}
                  </Row>
                </Col>
                <Col xs="4" md="4">
                  <Row>
                    {" "}
                    <p>Утас: {item.accPhone}</p>{" "}
                  </Row>
                  <Row>
                    {" "}
                    <p>Эхлэх огноо: {item.accStartDate}</p>{" "}
                  </Row>
                  <Row>
                    {" "}
                    <p>Дуусах огноо: {item.accExpiryDate}</p>{" "}
                  </Row>
                </Col>
              </>
            ))}
          </Row>
        </div>

        {/* <h5 className="font-size-15 mt-4">Project Details :</h5>

        <p className="text-muted">
          {get(commember, "projectDetails.description")}
        </p> */}

        {/* <div className="text-muted mt-4">
          {project.projectDetails &&
            map(project.projectDetails.points, (point, index) => (
              <p key={index}>
                <i className="mdi mdi-chevron-right text-primary me-1" />
                {point}
              </p>
            ))}
        </div> */}

        {/* <Row className="task-dates">
          <Col sm="4" xs="6">
            <div className="mt-4">
              <h5 className="font-size-14">
                <i className="bx bx-calendar me-1 text-primary" /> Start Date
              </h5>
              <p className="text-muted mb-0">{commember.startDate}</p>
            </div>
          </Col>

          <Col sm="4" xs="6">
            <div className="mt-4">
              <h5 className="font-size-14">
                <i className="bx bx-calendar-check me-1 text-primary" /> Due
                Date
              </h5>
              <p className="text-muted mb-0">{commember.dueDate}</p>
            </div>
          </Col>
        </Row> */}
      </CardBody>
    </Card>
  )
}

GeneralSharesDetail.propTypes = {
  sharesdtl: PropTypes.sharesdtl,
}

export default GeneralSharesDetail
