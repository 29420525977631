import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux"
import { isEmpty, size } from "lodash"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator"
import * as moment from 'moment';

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import {
  getAudits,
  addNewCustomer,
  updateCustomer,
  deleteCustomer
} from "store/actions"

class AdminAudit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      audits: [],
      token: "",
      CompanyColumns: [
        {
          text: "id",
          dataField: "id",
          sort: true,
          hidden: true,
          formatter: (cellContent, user) => <>{row.id}</>,
        },
        {
          dataField: "logo",
          text: "Лого",
          formatter: (cellContent, row) => (
            <>
              {!row.logo ? (
                <div className="avatar-xs">
                  <span className="avatar-title rounded-circle">
                    {row.name.charAt(0)}
                  </span>
                </div>
              ) : (
                <div>
                  <img
                    className="rounded-circle avatar-xs"
                    src={row.logo}
                    alt=""
                  />
                </div>
              )}
            </>
          ),
        },
        {
          text: "Овог",
          dataField: "lastname",
          sort: true,
        },
        {
          text: "Нэр",
          dataField: "firstname",
          sort: true,
        },
        {
          dataField: "register",
          text: "Регистр",
          sort: true,
        },
        {
          dataField: "username",
          text: "Нэвтрэх эрх",
          sort: true,
        },
        {
          dataField: "registrationDate",
          text: "Бүртгүүлсэн огноо",
          sort: true,
        },
      ],
    }
  }

  componentDidMount() {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      const { audits, onGetAudits } = this.props
      const token = obj.token
      if (audits && !audits.length) {
        onGetAudits(obj.token, this.props.page, this.props.totalPage, this.props.history)
      }
      this.setState({ audits })
      this.setState({ token })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { audits } = this.props
    if (!isEmpty(audits) && size(prevProps.audits) !== size(audits)) {
      this.setState({ audits: {}, isEdit: false })
    }
  }
  /* Insert,Update Delete data */

  render() {
    const { audits } = this.props

    const { isEdit, isUser } = this.state

    //pagination customization
    const pageOptions = {
      sizePerPage: 10,
      totalSize: this.props.totalPage, // replace later with size(companys),
      custom: true,
    }

    const defaultSorted = [
      {
        dataField: "id",
        order: "desc",
      },
    ]

    const { SearchBar } = Search

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Хянагчийн жагсаалт | Цахим хурал</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Дашбоард" breadcrumbItem="Хянагчийн жагсаалт" />
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={this.state.CompanyColumns}
                      data={audits}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={this.state.CompanyColumns}
                          data={audits}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row>
                                <Col sm="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        placeholder="Хайлт"
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="8">
                                  <div className="text-sm-end">
                                    {/* <Button
                                      type="button"
                                      color="success"
                                      className="btn-rounded mb-2 me-2"
                                      onClick={this.handleCompanyClicks}
                                    >
                                      <i className="mdi mdi-plus me-1" />{" "}
                                      Компани бүртгэх
                                    </Button> */}
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

AdminAudit.propTypes = {
  audits: PropTypes.array,
  page: PropTypes.number,
  totalPage: PropTypes.number,
  error: PropTypes.any,
  history: PropTypes.object,
  onGetAudits: PropTypes.func,
}

const mapStateToProps = state => {
  return {
    audits: state.AdminAudits.audits,
    page: state.AdminAudits.page,
    totalPage: state.AdminAudits.totalPage,
    error: state.AdminAudits.error,
  }
}
const mapDispatchToProps = dispatch => ({
  onGetAudits: (token, page, totalPage, history) =>
    dispatch(getAudits(token, page, totalPage, history)),
  // onAddNewCustomer: customer => dispatch(addNewCustomer(customer)),
  // onUpdateCustomer: customer => dispatch(updateCustomer(customer)),
  // onDeleteCustomer: customer => dispatch(deleteCustomer(customer)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminAudit)