import React, { Component } from "react"
import { map, isEmpty, size } from "lodash"
import PropTypes from "prop-types"
import { Card, CardBody, Col, Input, Label, Row, Button } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import {
  getComAgendaType,
  getComVoteType,
  getComMethodType,
} from "store/actions"
import "../CompanyMeetingCreate/style.css"
//const now = new Date().toJSON().split("T")[0]
class MeetingAgenda extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isButton: false,
      agendas: [
        {
          content: "",
          agendaTypeId: 0,
          agendaTypeName: "",
          voteTypeId: 0,
          voteMethodId: 0,
          baseVoteTime: 0,
          voteStartTime: "",
          voteFinishTime: "",
          isAdvanceVote: false,
          isMeetingVote: false,
          isExternalVote: false,
          isVote: false,
          choiseCount: 0,
          selectedTypeId: 0,
          choiseList: [
            {
              choise: "",
            },
          ],
        },
      ],
    }
  }
  componentDidMount() {
    const { onGetAgendaType, onGetVoteType, onGetMethodType } = this.props
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      onGetAgendaType(obj.token)
      onGetVoteType(obj.token)
      onGetMethodType(obj.token)
      const agendas = this.props.agendas
      if (!isEmpty(agendas)) {
        this.setState({ agendas: agendas })
      }
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const agendas = this.props.agendas
    if (!isEmpty(agendas) && size(prevProps.agendas) !== size(agendas)) {
      this.setState({ agendas: agendas })
    }
  }
  // Хэлэлцэх асуудал нэмэх
  handleAddRow = () => {
    const item = {
      content: "",
      agendaTypeId: 0,
      voteTypeId: 0,
      voteMethodId: 0,
      baseVoteTime: 0,
      voteStartTime: "",
      voteFinishTime: "",
      selectedTypeId: 0,
      isVote: false,
      isAdvanceVote: false,
      isMeetingVote: false,
      isExternalVote: false,
      choiseCount: 0,
      choiseList: [
        {
          choise: "",
        },
      ],
    }
    this.setState({
      agendas: [...this.state.agendas, item],
    })
  }
  //Хэлэлцэх асуудал устгах
  handleRemoveRow = (e, idx) => {
    this.setState({
      agendas: this.state.agendas.filter((s, sidx) => idx !== sidx),
    })
  }

  // Хэлэлцэх асуудал
  handleContentChange = idx => evt => {
    const agendas = this.state.agendas.map((agenda, sidx) => {
      if (idx !== sidx) return agenda
      return { ...agenda, content: evt.target.value }
    })

    this.setState({ agendas: agendas })
  }
  //Хэлэлцэх асуудлын төрөл
  handleAgendaTypeChange = idx => evt => {
    const agendas = this.state.agendas.map((agenda, sidx) => {
      if (idx !== sidx) return agenda
      return {
        ...agenda,
        selectedTypeId: evt.target.value,
        agendaTypeId: evt.target.value,
        agendaTypeName: this.props.agendaType.filter(
          (c, cidx) => evt.target.value == c.id
        ),
      }
    })
    this.setState({ agendas: agendas })
  }
  //Санал авах эсэх
  handleIsVoteChange = idx => evt => {
    const agendas = this.state.agendas.map((agenda, sidx) => {
      if (idx !== sidx) return agenda
      return { ...agenda, isVote: evt.target.value == "false" }
    })
    this.setState({ agendas: agendas })
    console.log("haraarai" + evt.target.value)
  }
  //Санал авах төрөл
  handleVoteTypeChange = idx => evt => {
    // alert()
    const agendas = this.state.agendas.map((agenda, sidx) => {
      if (idx !== sidx) return agenda
      return {
        ...agenda,
        voteTypeId: evt.target.value,
        voteTypeName: this.props.voteType.filter(
          (v, cidx) => evt.target.value == v.id
        ),
      }
    })

    this.setState({ agendas: agendas })
  }
  //Санал авах хэлбэр
  handleMethodTypeChange = idx => evt => {
    const agendas = this.state.agendas.map((agenda, sidx) => {
      if (idx !== sidx) return agenda
      return {
        ...agenda,
        voteMethodId: evt.target.value,
        voteMethodName: this.props.methodType.filter(
          (v, cidx) => evt.target.value == v.id
        ),
      }
    })
    this.setState({ agendas: agendas })
  }
  // Урьдчилсан санал авах
  handleIsAdvanceVoteChange = idx => evt => {
    const agendas = this.state.agendas.map((agenda, sidx) => {
      if (idx !== sidx) return agenda
      return { ...agenda, isAdvanceVote: evt.target.value == "false" }
    })
    this.setState({ agendas: agendas })
  }
  //Хурлын санал авах
  handleIsMeetingVoteChange = idx => evt => {
    const agendas = this.state.agendas.map((agenda, sidx) => {
      if (idx !== sidx) return agenda
      return { ...agenda, isMeetingVote: evt.target.value == "false" }
    })
    this.setState({ agendas: agendas })
  }
  //Эчнээ санал авах
  handleIsExternalVoteChange = idx => evt => {
    const agendas = this.state.agendas.map((agenda, sidx) => {
      if (idx !== sidx) return agenda
      return { ...agenda, isExternalVote: evt.target.value == "false" }
    })
    this.setState({ agendas: agendas })
  }
  //Санал авах хугацаа
  handleBaseVoteTimeChange = idx => evt => {
    const agendas = this.state.agendas.map((agenda, sidx) => {
      if (idx !== sidx) return agenda
      return { ...agenda, baseVoteTime: evt.target.value }
    })
    this.setState({ agendas: agendas })
  }
  //  Санал хураалт эхлэх огноо
  handleVoteStartTimeChange = idx => evt => {
    console.log(evt.target.value)
    const agendas = this.state.agendas.map((agenda, sidx) => {
      if (idx !== sidx) return agenda
      return {
        ...agenda,
        voteStartTime: evt.target.value.replace("T", " ") + ":00",
      }
    })
    this.setState({ agendas: agendas })
  }
  // Санал хураалт дуусах огноо
  handleVoteFinishTimeChange = idx => evt => {
    console.log(evt.target.value)
    const agendas = this.state.agendas.map((agenda, sidx) => {
      if (idx !== sidx) return agenda
      return {
        ...agenda,
        voteFinishTime: evt.target.value.replace("T", " ") + ":00",
      }
    })
    this.setState({ agendas: agendas })
  }
  //Сонголтын жагсаалт нэмэх
  handleChoiseListAddRow = idx => {
    const choise = {
      choise: "",
    }
    if (idx !== "") {
      this.setState(prevState => {
        const agendas = [...prevState.agendas]
        agendas[idx] = { ...agendas[idx] }
        agendas[idx].choiseList = [...agendas[idx].choiseList, choise]
        return { agendas }
      })
    }
  }
  //Сонгох хувилбарын утга оруулах
  handleChoiseNameChange = (idx, choiseIndex) => evt => {
    const agendas = this.state.agendas.map((agenda, sidx) => {
      if (idx !== sidx) return agenda
      else {
        agenda = { ...agenda }
        agenda.choiseList = agenda.choiseList.map((choise, cidx) => {
          if (choiseIndex !== cidx) return choise
          return { ...choise, choise: evt.target.value }
        })
        return agenda
      }
    })
    this.setState({ agendas: agendas })
  }
  //Сонголтын жагсаалт устгах
  handleChoiseListRemoveRow = (idx, choiseIndex) => e => {
    const agendas = this.state.agendas.map((agenda, sidx) => {
      if (idx === sidx)
        return {
          ...agenda,
          choiseList: agenda.choiseList.filter(
            (c, cidx) => choiseIndex !== cidx
          ),
        }
      return agenda
    })
    this.setState({ agendas: agendas })
  }
  //Сонголтын тоо
  handleChoiseCountChange = idx => evt => {
    const agendas = this.state.agendas.map((agenda, sidx) => {
      if (idx !== sidx) return agenda
      return { ...agenda, choiseCount: evt.target.value }
    })

    this.setState({ agendas: agendas })
  }

  // Өмнөх таб-руу буцах
  prevPage = () => {
    this.props.toggle(1)
  }
  // Дараагийн таб-руу орох
  nextPage = () => {
    this.props.toggle(3)
  }
  // Форм шалгах
  handleValidAgendaSubmit = (e, values) => {
    this.props.saveForm(this.state.agendas)
    if (this.props.isButton) this.nextPage()
  }
  render() {
    const { agendaType, voteType, methodType, agendas } = this.props
    // console.log("hardaa" + this.state.agendas.isVote)
    return (
      <React.Fragment>
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                {!this.props.isButton ? (
                  ""
                ) : (
                  <div>
                    <Button
                      onClick={this.handleAddRow}
                      color="success"
                      className="mt-3 mt-lg-0"
                    >
                      Хэлэлцэх асуудал нэмэх{" "}
                    </Button>
                  </div>
                )}
                <AvForm
                  className="repeater mt-3"
                  encType="multipart/form-data"
                  onValidSubmit={this.handleValidAgendaSubmit}
                >
                  <table style={{ width: "100%" }}>
                    <tbody>
                      {this.state.agendas.map((row, idx) => (
                        <tr
                          id={"addr" + idx}
                          key={idx}
                          className="border-bottom border-top relativeContent"
                        >
                          <td>
                            <div data-repeater-list="group-a">
                              <Row data-repeater-item>
                                <Col lg="2" className="mb-3">
                                  <AvField
                                    id={"content" + idx}
                                    name={"[" + idx + "]content"}
                                    label={idx + 1 + ".Хэлэлцэх асуудал"}
                                    errorMessage="Талбар хоосон байна!!!"
                                    type="textarea"
                                    onChange={this.handleContentChange(idx)}
                                    value={row ? row.content : ""}
                                    validate={{
                                      required: { value: true },
                                    }}
                                  />
                                </Col>

                                <Col lg="2" className="mb-3">
                                  <AvField
                                    id={"agendaTypeId" + idx}
                                    name={"[" + idx + "] agendaTypeId"}
                                    label={" Хэлэлцэх асуудлын төрөл "}
                                    type="select"
                                    errorMessage="Төрөл сонгоно уу!!"
                                    onChange={this.handleAgendaTypeChange(idx)}
                                    validate={{
                                      required: { value: true },
                                    }}
                                    className="form-select"
                                    value={row ? row.agendaTypeId : ""}
                                  >
                                    <option key="agendaType" value="">
                                      -Сонгох-
                                    </option>
                                    {map(agendaType, (type, key) => (
                                      <option
                                        key={"agendaType" + key}
                                        value={type.id}
                                      >
                                        {type.name}
                                      </option>
                                    ))}
                                  </AvField>
                                </Col>
                                {row.agendaTypeId == 0 ||
                                row.agendaTypeId == 2 ? (
                                  <></>
                                ) : (
                                  <>
                                    <Col lg="1" className="mb-2">
                                      <Label>Санал авах эсэх</Label>
                                      <div className="checkbox">
                                        <AvField
                                          id={"isVote" + idx}
                                          name={"[" + idx + "] isVote"}
                                          type="checkbox"
                                          //errorMessage="Хоосон байна!!"
                                          className="form-check-input mycheckbox"
                                          onChange={this.handleIsVoteChange(
                                            idx
                                          )}
                                          value={row ? row.isVote : ""}
                                        />
                                      </div>
                                    </Col>
                                    {!row.isVote ? (
                                      <></>
                                    ) : (
                                      <>
                                        <Col lg="1" className="mb-2">
                                          <Row>
                                            <AvField
                                              id={"isAdvanceVote" + idx}
                                              name={
                                                "[" + idx + "] isAdvanceVote"
                                              }
                                              label="Урьдчилсан"
                                              type="checkbox"
                                              // errormessage="Талбар хоосон байна!!"
                                              className="form-check-input"
                                              value={
                                                row ? row.isAdvanceVote : ""
                                              }
                                              onChange={this.handleIsAdvanceVoteChange(
                                                idx
                                              )}
                                            />
                                          </Row>
                                          <Row>
                                            <AvField
                                              id={"isExternalVote" + idx}
                                              name={
                                                "[" + idx + "] isExternalVote"
                                              }
                                              label="Эчнээ"
                                              type="checkbox"
                                              //errormessage="Талбар хоосон байна!!"
                                              className="form-check-input"
                                              value={
                                                row ? row.isExternalVote : ""
                                              }
                                              onChange={this.handleIsExternalVoteChange(
                                                idx
                                              )}
                                            />
                                          </Row>
                                          <Row>
                                            <AvField
                                              id={"isMeetingVote" + idx}
                                              name={
                                                "[" + idx + "] isMeetingVote"
                                              }
                                              label="Хурлын"
                                              type="checkbox"
                                              //errormessage="Талбар хоосон байна!!"
                                              className="form-check-input"
                                              value={
                                                row ? row.isMeetingVote : ""
                                              }
                                              onChange={this.handleIsMeetingVoteChange(
                                                idx
                                              )}
                                            />
                                          </Row>
                                        </Col>
                                        {!row.isMeetingVote ? (
                                          <></>
                                        ) : (
                                          <>
                                            <Col lg="2" className="mb-3">
                                              <AvField
                                                id={"baseVoteTime" + idx}
                                                name={
                                                  "[" + idx + "] baseVoteTime"
                                                }
                                                htmlFor="baseVoteTime"
                                                label=" Санал хураалт явагдах минут:"
                                                type="number"
                                                errorMessage="Талбар хоосон байна!!"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={
                                                  row ? row.baseVoteTime : ""
                                                }
                                                onChange={this.handleBaseVoteTimeChange(
                                                  idx
                                                )}
                                              />
                                            </Col>
                                          </>
                                        )}
                                        {!row.isAdvanceVote &&
                                        !row.isExternalVote ? (
                                          <></>
                                        ) : (
                                          <>
                                            <Col lg="2" className="mb-3">
                                              <Label
                                                htmlFor="voteStartTime"
                                                key={idx}
                                              >
                                                Санал хураалт эхлэх огноо
                                              </Label>
                                              <Input
                                                id={"voteStartTime" + idx}
                                                name={
                                                  "[" + idx + "] voteStartTime"
                                                }
                                                errorMessage="Талбар хоосон байна!!"
                                                className="form-control"
                                                type="datetime-local"
                                                onChange={this.handleVoteStartTimeChange(
                                                  idx
                                                )}
                                                // max={
                                                //   new Date(
                                                //     this.props.infoData.meetingDate
                                                //   )
                                                // }
                                                // min={
                                                //   new Date(
                                                //     this.props.infoData.declareDate
                                                //   )
                                                // }
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={
                                                  row
                                                    ? row.voteStartTime.replace(
                                                        " ",
                                                        "T"
                                                      )
                                                    : ""
                                                }
                                              />
                                            </Col>

                                            <Col lg="2" className="mb-3">
                                              <Label
                                                htmlFor="voteFinishTime"
                                                key={idx}
                                              >
                                                Санал хураалт дуусах огноо
                                              </Label>
                                              <Input
                                                id={"voteFinishTime" + idx}
                                                name={
                                                  "[" + idx + "] voteFinishTime"
                                                }
                                                errorMessage="Талбар хоосон байна!!"
                                                className="form-control"
                                                type="datetime-local"
                                                onChange={this.handleVoteFinishTimeChange(
                                                  idx
                                                )}
                                                // max={
                                                //   new Date(
                                                //     this.props.infoData.meetingDate
                                                //   )
                                                // }
                                                // min={
                                                //   new Date(
                                                //     this.state.voteStartTime
                                                //   )
                                                // }
                                                value={
                                                  row
                                                    ? row.voteFinishTime.replace(
                                                        " ",
                                                        "T"
                                                      )
                                                    : ""
                                                }
                                              />
                                            </Col>
                                          </>
                                        )}
                                        <Col lg="2" className="mb-3">
                                          <AvField
                                            id={"voteTypeId" + idx}
                                            className="form-select"
                                            name={"[" + idx + "] voteTypeId"}
                                            label={"Санал авах төрөл"}
                                            type="select"
                                            errorMessage="Төрөл сонгоно уу"
                                            onChange={this.handleVoteTypeChange(
                                              idx
                                            )}
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={row ? row.voteTypeId : ""}
                                          >
                                            <option key="voteType" value="">
                                              -Сонгох-
                                            </option>
                                            {map(voteType, (type, key) => (
                                              <option
                                                key={"voteType" + key}
                                                value={type.id}
                                              >
                                                {type.name}
                                              </option>
                                            ))}
                                          </AvField>
                                        </Col>

                                        <Col lg="2" className="mb-3">
                                          <AvField
                                            id={"methodType" + idx}
                                            className="form-select"
                                            name={"[" + idx + "] methodType"}
                                            label={"Санал авах хэлбэр"}
                                            type="select"
                                            errorMessage="Төрөл сонгоно уу"
                                            onChange={this.handleMethodTypeChange(
                                              idx
                                            )}
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={row ? row.voteMethodId : ""}
                                          >
                                            <option key="methodType" value="">
                                              -Сонгох-
                                            </option>
                                            {map(methodType, (type, key) => (
                                              <option
                                                key={"methodType" + key}
                                                value={type.id}
                                              >
                                                {type.name}
                                              </option>
                                            ))}
                                          </AvField>
                                        </Col>
                                      </>
                                    )}
                                  </>
                                )}
                              </Row>
                              <Row className="myBackground mb-3">
                                {row.agendaTypeId == 3 ||
                                row.agendaTypeId == 4 ? (
                                  <>
                                    <Col md="12" className="col-8 pt-4">
                                      <Button
                                        onClick={() =>
                                          this.handleChoiseListAddRow(idx)
                                        }
                                        color="success"
                                      >
                                        Хувилбар нэмэх
                                      </Button>
                                      {/* <AvForm
                                        className="repeater mt-3"
                                        encType="multipart/form-data"
                                        onValidSubmit={
                                          this.handleValidAgendaSubmit
                                        }
                                      > */}
                                      <table
                                        style={{ width: "100%" }}
                                        className="mb-3 mt-2"
                                      >
                                        <tbody>
                                          {row.choiseList.map(
                                            (choise, choiseIndex) => (
                                              <tr
                                                id={"choise" + choiseIndex}
                                                key={"choise" + choiseIndex}
                                              >
                                                <td
                                                  key={
                                                    "choise-td" + choiseIndex
                                                  }
                                                >
                                                  <AvField
                                                    id={"choise" + choiseIndex}
                                                    name={
                                                      "[" +
                                                      choiseIndex +
                                                      "] choise"
                                                    }
                                                    label={
                                                      choiseIndex +
                                                      1 +
                                                      ".Сонгох хувилбарын утга"
                                                    }
                                                    type="textarea"
                                                    rows="1"
                                                    errorMessage="Талбар хоосон байна!!"
                                                    onChange={this.handleChoiseNameChange(
                                                      idx,
                                                      choiseIndex
                                                    )}
                                                    value={
                                                      row ? choise.choise : ""
                                                    }
                                                    validate={{
                                                      required: {
                                                        value: true,
                                                      },
                                                    }}
                                                  />
                                                </td>
                                                <td
                                                  style={{
                                                    width: "40px",
                                                    verticalAlign: "bottom",
                                                  }}
                                                >
                                                  <a
                                                    className="action-icon text-danger"
                                                    onClick={this.handleChoiseListRemoveRow(
                                                      idx,
                                                      choiseIndex
                                                    )}
                                                  >
                                                    {" "}
                                                    <i className="mdi mdi-trash-can font-size-18"></i>
                                                  </a>
                                                </td>
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      </table>
                                    </Col>
                                    <Col lg="2" className="mb-3">
                                      <AvField
                                        id={"choiseCount" + idx}
                                        name={"[" + idx + "] choiseCount"}
                                        htmlFor="choiseCount"
                                        label="Сонголтын тоо:"
                                        type="number"
                                        errorMessage="Талбар хоосон байна!!"
                                        onChange={this.handleChoiseCountChange(
                                          idx
                                        )}
                                        validate={{
                                          required: { value: true },
                                        }}
                                        value={row ? row.choiseCount : ""}
                                      />
                                    </Col>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </Row>
                              {this.props.isButton ? (
                                <Button
                                  onClick={e => this.handleRemoveRow(e, idx)}
                                  color="danger"
                                  className="btn btn-danger btn-rounded deleteBtn"
                                >
                                  <i className="bx bx-minus" />
                                </Button>
                              ) : (
                                ""
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <div className="customButton clearfix mt-3">
                    {!this.props.isButton ? (
                      <Button
                        color="success"
                        type="submit"
                        className="mt-3 mt-lg-0"
                      >
                        Хадгалах
                      </Button>
                    ) : (
                      <>
                        <Button
                          color="primary"
                          className="mt-3 mt-lg-0"
                          onClick={this.prevPage}
                        >
                          Өмнөх
                        </Button>
                        <Button
                          color="primary"
                          type="submit"
                          // onClick={this.nextPage}
                          className="mt-3 mt-lg-0"
                        >
                          Дараах
                        </Button>
                      </>
                    )}
                  </div>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

MeetingAgenda.propTypes = {
  agendas: PropTypes.array,
  isButton: PropTypes.bool,
  agendaType: PropTypes.array,
  voteType: PropTypes.array,
  methodType: PropTypes.array,
  onGetAgendaType: PropTypes.func,
  onGetVoteType: PropTypes.func,
  onGetMethodType: PropTypes.func,
  saveForm: PropTypes.func,
  infoData: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    agendaType: state.CompanyMeet.agendaType,
    voteType: state.CompanyMeet.voteType,
    methodType: state.CompanyMeet.methodType,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetAgendaType: token => dispatch(getComAgendaType(token)),
  onGetVoteType: token => dispatch(getComVoteType(token)),
  onGetMethodType: token => dispatch(getComMethodType(token)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MeetingAgenda))
