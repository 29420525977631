import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_AUDIT_COMPANYS } from "./actionTypes"
import { getAuditCompanysSuccess, getAuditCompanysFail } from "./actions"

//Include Both Helper File with needed methods
import { postJson, isUserAuthenticated } from "helpers/fakebackend_helper"

function* fetchAuditCompanys({ payload, history }) {
  if (isUserAuthenticated()) {
    try {
      const response = yield call(
        postJson,
        {
          token: payload.token,
          start: payload.page,
          length: 9,
        },
        "/Admin/CompanyWebService/get_meeting_list_company"
      )
      if (response.responseResultType === "SUCCESS") {
        yield put(getAuditCompanysSuccess(response))
      } else {
        if (response.failureMessages.message[0].failureCode === "ERR_009") {
          history.push("/login")
        } else {
          yield put(
            getAuditCompanysFail(
              response.failureMessages.message[0].failureMessage
            )
          )
        }
      }
    } catch (error) {
      yield put(getAuditCompanysFail(error))
    }
  } else history.push("/logout")
}

function* auditCompanySaga() {
  yield takeEvery(GET_AUDIT_COMPANYS, fetchAuditCompanys)
}

export default auditCompanySaga
