import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_AUDITS,
  ADD_NEW_COMPANY,
  DELETE_COMPANY,
  UPDATE_COMPANY,
  ADD_COMPANY_USER
} from "./actionTypes"
import {
  getAuditsSuccess,
  getAuditsFail,
  addCompanyFail,
  addCompanySuccess,
  updateCompanySuccess,
  updateCompanyFail,
  deleteCompanySuccess,
  deleteCompanyFail,
  addCompanyUserSuccess,
  addCompanyUserFail
} from "./actions"

//Include Both Helper File with needed methods
import {
  postJson, isUserAuthenticated
} from "helpers/fakebackend_helper"

function* fetchAudits({ payload: { request, history } }) {
  if (isUserAuthenticated()) {
    try {
      const response = yield call(postJson, {
        token: request.token,
        start: request.page,
        length: 10,
      }, "/Admin/CompanyWebService/get_audit_list")
      if (response.responseResultType === "SUCCESS") {
        yield put(getAuditsSuccess(response))
      } else {
        if (response.failureMessages.message[0].failureCode === "ERR_009") {
          history.push("/logout")
        } else {
          yield put(getAuditsFail(response.failureMessages.message[0].failureMessage))
        }
      }
    } catch (error) {
      yield put(getAuditsFail(error))
    }
  } else history.push("/logout")
}
// function* onAddNewCompany({ payload: company }) {
//   if (isUserAuthenticated()) {
//     try {
//       const response = yield call(postJson, company, "/Admin/CompanyWebService/register_company")
//       if (response.responseResultType === "SUCCESS") {
//         yield put(addCompanySuccess(response))
//       } else {
//         if (response.failureMessages.message[0].failureCode === "ERR_009") {
//           history.push("/logout")
//         } else {
//           yield put(addCompanyFail(response.failureMessages.message[0].failureMessage))
//         }
//       }
//     } catch (error) {

//       yield put(addCompanyFail(error))
//     }
//   } else history.push("/logout")
// }

// function* onUpdateCompany({ payload: company }) {
//   try {
//     const response = yield call(postJson, company, "/Admin/CompanyWebService/update_company")
//     yield put(updateCompanySuccess(response))
//   } catch (error) {
//     yield put(updateCompanyFail(error))
//   }
// }

// function* onDeleteCompany({ payload: company }) {
//   try {
//     const response = yield call(postJson, company, "/Admin/CompanyWebService/remove_company")
//     yield put(deleteCompanySuccess(response))
//   } catch (error) {
//     yield put(deleteCompanyFail(error))
//   }
// }

// function* onAddCompanyUser({ payload: company }) {
//   if (isUserAuthenticated()) {
//     try {
//       const response = yield call(postJson, company, "/Admin/CompanyWebService/register_company_admin")
//       if (response.responseResultType === "SUCCESS") {
//         yield put(addCompanyUserSuccess(response))
//       } else {
//         if (response.failureMessages.message[0].failureCode === "ERR_009") {
//           history.push("/logout")
//         } else {
//           // console.log(response.failureMessages.message[0].failureMessage)
//           yield put(addCompanyUserFail(response.failureMessages.message[0].failureMessage))
//         }
//       }
//     } catch (error) {

//       yield put(addCompanyUserFail(error))
//     }
//   } else history.push("/logout")
// }

function* adminAuditSaga() {
  yield takeEvery(GET_AUDITS, fetchAudits)
  // yield takeEvery(ADD_NEW_COMPANY, onAddNewCompany)
  // yield takeEvery(UPDATE_COMPANY, onUpdateCompany)
  // yield takeEvery(DELETE_COMPANY, onDeleteCompany)
  // yield takeEvery(ADD_COMPANY_USER, onAddCompanyUser)
}

export default adminAuditSaga
