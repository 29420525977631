import React from "react"
import { Redirect } from "react-router-dom"
// User profile
import UserProfile from "../pages/Authentication/UserProfile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import Authorized from "../pages/Authentication/Authorized"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import CustomerMeeting from "../pages/Customer/CustomerMeeting"
import CustomerMeetingDtl from "../pages/Customer/CustomerMeeting/meeting-dtl"
import CustomerCompany from "../pages/Customer/CustomerCompany"

// Admin
import AdminCompany from "../pages/Admin/AdminCompany"
import AdminCompanyRegister from "../pages/Admin/AdminCompany/create.js"
import AdminAudit from "../pages/Admin/AdminAudit"

// Company
import CompanyMeetingDtl from "../pages/Company/CompanyMeeting/CompanyMeetingDtl/company-meeting-dtl"
import CompanyRegister from "../pages/Company/CompanyMeeting/CompanyMeetingCreate/create-meeting"
import CompanyMeeting from "../pages/Company/CompanyMeeting"
import CompanyMember from "../pages/Company/CompanyMember"
import CompanyMemberDtl from "../pages/Company/CompanyMember/company-member-dtl"
import CompanyShareholder from "../pages/Company/CompanyShareholder"
import CompanySharesDtl from "../pages/Company/CompanyShareholder/company-shares-dtl"
import CompanyMeetingUpdate from "../pages/Company/CompanyMeeting/CompanyMeetingCreate/update-meeting"
import CompanyCustomers from "pages/Company/CompanyCustomer"

// Audit

import AuditCompany from "../pages/Audit/AuditCompanyList/auditCompany"
import AuditMeetingDtl from "../pages/Audit/AuditCompanyMeeting/audit-meeting-dtl"
// Help
import Chat from "../pages/Help/chat"
const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  //profile
  { path: "/profile", component: UserProfile },

  //Admin
  { path: "/admin/company", component: AdminCompany },
  { path: "/admin/company/register", component: AdminCompanyRegister },
  { path: "/admin/audit", component: AdminAudit },

  //Customer
  { path: "/customer/meeting", component: CustomerMeeting },
  { path: "/customer/meeting/dtl/:id", component: CustomerMeetingDtl },
  { path: "/customer/company", component: CustomerCompany },

  //Company
  { path: "/company/meeting", component: CompanyMeeting },
  { path: "/company/register", component: CompanyRegister },
  { path: "/company/member", component: CompanyMember },
  { path: "/company/member/dtl/:id", component: CompanyMemberDtl },
  { path: "/company/shareholder/dtl/:id", component: CompanySharesDtl },
  { path: "/company/shareholder", component: CompanyShareholder },
  { path: "/company/meeting/dtl/:id", component: CompanyMeetingDtl },
  { path: "/company/meeting/update/:id", component: CompanyMeetingUpdate },

  { path: "/company/customer", component: CompanyCustomers },

  // Audit

  { path: "/audit/meeting", component: AuditCompany },
  { path: "/audit/meeting/dtl/:id", component: AuditMeetingDtl },
  //Help
  { path: "/company/help", component: Chat },
  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/authorized", component: Authorized },
]

export { authProtectedRoutes, publicRoutes }
