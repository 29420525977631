import PropTypes from 'prop-types'
import React from "react"
import { Link } from "react-router-dom"
import { map } from "lodash"
import {
    Badge,
} from "reactstrap"

const ChoiseResult = ({ choiseList }) => {
    return (
        <React.Fragment>
            <td >
                <ul className="list-inline mb-0">
                    {map(choiseList, (choise, dkey) => (
                        <li className="list-item me-3" key={dkey}>
                            <i className="bx bx-check me-1" /> {choise.choise}
                        </li>
                    ))}
                </ul>
            </td>
            <td style={{ minWidth: "150px" }} className="text-center">
                <ul className="list-inline mb-0">
                    {map(choiseList, (choise, dkey) => (
                        <li className="list-item me-3" key={dkey}>
                            {choise.choosedCount}
                        </li>
                    ))}
                </ul>
            </td>
            <td></td>
        </React.Fragment>
    )
}

ChoiseResult.propTypes = {
    choiseList: PropTypes.array
}

export default ChoiseResult
