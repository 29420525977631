import {
  GET_AUDITS,
  GET_AUDITS_FAIL,
  GET_AUDITS_SUCCESS,
  // ADD_NEW_COMPANY,
  // ADD_COMPANY_SUCCESS,
  // ADD_COMPANY_FAIL,
  UPDATE_COMPANY,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAIL,
  // DELETE_COMPANY,
  // DELETE_COMPANY_SUCCESS,
  // DELETE_COMPANY_FAIL,
  // ADD_COMPANY_USER,
  // ADD_COMPANY_USER_SUCCESS,
  // ADD_COMPANY_USER_FAIL
} from "./actionTypes"

export const getAudits = (token, page, totalPage, history) => ({
  type: GET_AUDITS,
  payload: { request: { token, page, totalPage }, history },
})

export const getAuditsSuccess = response => ({
  type: GET_AUDITS_SUCCESS,
  audits: response.data,
  totalPage: response.recordsTotal,
})

export const getAuditsFail = error => ({
  type: GET_AUDITS_FAIL,
  payload: error,
})

// export const addNewCompany = company => ({
//   type: ADD_NEW_COMPANY,
//   payload: company,
// })

// export const addCompanySuccess = company => ({
//   type: ADD_COMPANY_SUCCESS,
//   payload: company,
// })

// export const addCompanyFail = error => ({
//   type: ADD_COMPANY_FAIL,
//   payload: error,
// })

export const updateCompany = company => ({
  type: UPDATE_COMPANY,
  payload: company,
})

export const updateCompanySuccess = company => ({
  type: UPDATE_COMPANY_SUCCESS,
  payload: company,
})

export const updateCompanyFail = error => ({
  type: UPDATE_COMPANY_FAIL,
  payload: error,
})

// export const deleteCompany = company => ({
//   type: DELETE_COMPANY,
//   payload: company,
// })

// export const deleteCompanySuccess = company => ({
//   type: DELETE_COMPANY_SUCCESS,
//   payload: company,
// })

// export const deleteCompanyFail = error => ({
//   type: DELETE_COMPANY_FAIL,
//   payload: error,
// })

// export const addCompanyUser = company => ({
//   type: ADD_COMPANY_USER,
//   payload: company,
// })

// export const addCompanyUserSuccess = company => ({
//   type: ADD_COMPANY_USER_SUCCESS,
//   payload: company,
// })

// export const addCompanyUserFail = error => ({
//   type: ADD_COMPANY_USER_FAIL,
//   payload: error,
// })
