import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
import { isEmpty, map, values } from "lodash"
import {
  Card,
  CardBody,
  Col,
  Container,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import classnames from "classnames"
import { Link } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import MeetingDocument from "./meeting-document"
import MeetingAgenda from "./meeting-agenda"
import MeetingInformation from "./meeting-information"
import MeetingContact from "./meeting-contact"
import MeetingCustomer from "./meeting-customer"
import MeetingUserDetail from "./meeting-user-detail"
import MeetingAudit from "./meeting-audit"

import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { addComNewMeeting } from "store/actions"
class CompanyRegister extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: 1,
      passedSteps: [1],
      isFinish: false,
      meetingForm: {
        token: "",
        meetingName: "",
        address: "",
        declareDate: "",
        endTime: "",
        meetingDate: "",
        meetingTypeId: null,
        membersCount: null,
        registrationDate: "",
        startTime: "",
        agendaDtos: [],
        contactDtos: [],
        documentDtos: [],
        customerDtos: [],
        auditDto: [],
      },
    }
    this.toggleTab.bind(this)
  }
  toggle(tab) {
    this.toggleTab.bind(this)
  }
  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 7) {
        var modifiedSteps = [...this.state.passedSteps, tab]
        this.setState({
          activeTab: tab,
          passedSteps: modifiedSteps,
        })
      }
    }
  }
  showToast(type, message, title) {
    toastr.options = {
      timeOut: 5000,
      closeButton: true,
      progressBar: true,
      newestOnTop: true,
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
      showDuration: 300,
      hideDuration: 1000,
    }

    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (type === "info") toastr.info(message, title)
    else if (type === "warning") toastr.warning(message, title)
    else if (type === "error") toastr.error(message, title)
    else toastr.success(message, title)
  }
  saveFormData = values => {
    this.setState({ meetingForm: values })
  }

  saveFormAgenda = values => {
    this.setState({
      meetingForm: {
        ...this.state.meetingForm,
        agendaDtos: values,
      },
    })
  }

  saveFormDoc = values => {
    this.setState({
      meetingForm: {
        ...this.state.meetingForm,
        documentDtos: values,
      },
    })
  }

  saveFormContact = values => {
    this.setState({
      meetingForm: {
        ...this.state.meetingForm,
        contactDtos: values,
      },
    })
  }
  saveFormCustomer = values => {
    this.setState({
      meetingForm: {
        ...this.state.meetingForm,
        customerDtos: values,
      },
    })
  }
  saveFormAudit = values => {
    this.setState({
      meetingForm: {
        ...this.state.meetingForm,
        auditDto: values,
      },
    })
  }
  saveFormMeeting = () => {
    const error = this.props.error
    this.setState({
      isFinish: true,
    })
    const { onAddNewMeeting } = this.props
    onAddNewMeeting(this.state.meetingForm)
    if (error !== "") {
      this.showToast("success", "Амжилттай хадгалагдлаа.", "Амжилттай")
      setTimeout(() => {
        this.props.history.push("/company/meeting")
      }, 1000)
    } else {
      this.showToast("error", error, "Алдаа гарлаа")
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Хурал нэмэх | Цахим хурал</title>
          </MetaTags>
          <Container fluid={true}>
            <Breadcrumbs title="Компани" breadcrumbItem="Хурал нэмэх" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">Хурлын мэдээлэл</h4>
                    <div className="wizard clearfix">
                      <div className="steps clearfix">
                        <ul>
                          <NavItem
                            className={classnames({
                              current: this.state.activeTab === 1,
                            })}
                          >
                            <NavLink
                              className={classnames({
                                active: this.state.activeTab === 1,
                              })}
                              onClick={() => {
                                this.toggleTab(1)
                              }}
                            >
                              <span className="number">1.</span> Ерөнхий
                              мэдээлэл
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({
                              current: this.state.activeTab === 2,
                            })}
                          >
                            <NavLink
                              disabled={
                                !(this.state.passedSteps || []).includes(2)
                              }
                              className={classnames({
                                active: this.state.activeTab === 2,
                              })}
                              onClick={() => {
                                this.toggleTab(2)
                              }}
                            >
                              <span className="number">2.</span>{" "}
                              <span>Хэлэлцэх асуудал</span>
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({
                              current: this.state.activeTab === 3,
                            })}
                          >
                            <NavLink
                              disabled={
                                !(this.state.passedSteps || []).includes(3)
                              }
                              className={
                                (classnames({
                                  active: this.state.activeTab === 3,
                                }),
                                "done")
                              }
                              onClick={() => {
                                this.toggleTab(3)
                              }}
                            >
                              <span className="number">3.</span> Хурлын оролцогч
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({
                              current: this.state.activeTab === 4,
                            })}
                          >
                            <NavLink
                              disabled={
                                !(this.state.passedSteps || []).includes(4)
                              }
                              className={
                                (classnames({
                                  active: this.state.activeTab === 4,
                                }),
                                "done")
                              }
                              onClick={() => {
                                this.toggleTab(4)
                              }}
                            >
                              <span className="number">4.</span> Баримт бичиг
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({
                              current: this.state.activeTab === 5,
                            })}
                          >
                            <NavLink
                              disabled={
                                !(this.state.passedSteps || []).includes(5)
                              }
                              className={
                                (classnames({
                                  active: this.state.activeTab === 5,
                                }),
                                "done")
                              }
                              onClick={() => {
                                this.toggleTab(5)
                              }}
                            >
                              <span className="number">5.</span> Хурлын хянагч
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({
                              current: this.state.activeTab === 6,
                            })}
                          >
                            <NavLink
                              disabled={
                                !(this.state.passedSteps || []).includes(6)
                              }
                              className={
                                (classnames({
                                  active: this.state.activeTab === 6,
                                }),
                                "done")
                              }
                              onClick={() => {
                                this.toggleTab(6)
                              }}
                            >
                              <span className="number">6.</span> Холбоо барих
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({
                              current: this.state.activeTab === 7,
                            })}
                          >
                            <NavLink
                              disabled={
                                !(this.state.passedSteps || []).includes(7)
                              }
                              className={
                                (classnames({
                                  active: this.state.activeTab === 7,
                                }),
                                "done")
                              }
                              onClick={() => {
                                this.toggleTab(7)
                              }}
                            >
                              <span className="number">7.</span> Хурлын мэдээлэл
                            </NavLink>
                          </NavItem>
                        </ul>
                      </div>
                      <div className="content clearfix">
                        <TabContent
                          activeTab={this.state.activeTab}
                          className="body"
                        >
                          <TabPane tabId={1}>
                            <MeetingInformation
                              saveForm={this.saveFormData}
                              toggle={this.toggleTab.bind(this)}
                            />
                          </TabPane>
                          <TabPane tabId={2}>
                            <MeetingAgenda
                              infoData={this.state.meetingForm}
                              saveForm={this.saveFormAgenda}
                              isButton={true}
                              toggle={this.toggleTab.bind(this)}
                            />
                          </TabPane>
                          <TabPane tabId={3}>
                            <MeetingCustomer
                              saveForm={this.saveFormCustomer}
                              toggle={this.toggleTab.bind(this)}
                              documents={[]}
                            />
                          </TabPane>
                          <TabPane tabId={4}>
                            <MeetingDocument
                              saveForm={this.saveFormDoc}
                              toggle={this.toggleTab.bind(this)}
                            />
                          </TabPane>
                          <TabPane tabId={5}>
                            <MeetingAudit
                              saveForm={this.saveFormAudit}
                              toggle={this.toggleTab.bind(this)}
                            />
                          </TabPane>
                          <TabPane tabId={6}>
                            <MeetingContact
                              saveForm={this.saveFormContact}
                              toggle={this.toggleTab.bind(this)}
                            />
                          </TabPane>
                          <TabPane tabId={7}>
                            <MeetingUserDetail
                              formData={this.state.meetingForm}
                              saveForm={this.saveFormMeeting}
                              toggle={this.toggleTab.bind(this)}
                            />
                          </TabPane>
                        </TabContent>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
CompanyRegister.propTypes = {
  onAddNewMeeting: PropTypes.func,
  error: PropTypes.any,
  history: PropTypes.object,
}
const mapStateToProps = state => {
  return {
    error: state.CompanyMeet.error,
  }
}

const mapDispatchToProps = dispatch => ({
  onAddNewMeeting: meeting => dispatch(addComNewMeeting(meeting)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CompanyRegister)
