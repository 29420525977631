import React, { Component } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import { withRouter } from "react-router-dom"
import { map, isEmpty } from "lodash"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

import classnames from "classnames"
import MeetingIntroduction from "./meeting-indroduction"
import MeetingContact from "./meeting-contact"
import MeetingAttendance from "./meeting-attendance"
import MeetingDocument from "./meeting-document"
import MeetingAgenda from "./meeting-agenda"
import MeetingResult from "./meeting-result"
import CompanyMeetingResult from "../../Company/CompanyMeeting/CompanyMeetingDtl/company-meeting-result-dtl"
import {
  getMeetingDtl,
  getMeetingAgenda,
  getMeetingDocument,
  getMeetingResult,
  setAttendance,
} from "store/actions"

class CustomerMeetingDtl extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: "1",
      token: "",
      meetingId: "",
      votes: [],
    }
    this.toggle = this.toggle.bind(this)
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  componentDidMount() {
    const {
      match: { params },
      onGetMeeting,
      onGetMeetingAgenda,
      onGetMeetingDocument,
      onGetMeetingResult,
    } = this.props
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      if (params && params.id) {
        this.setState({ meetingId: params.id })

        onGetMeeting(obj.token, params.id, this.props.history)
        onGetMeetingAgenda(obj.token, params.id)
        onGetMeetingDocument(obj.token, params.id)
        onGetMeetingResult(obj.token, params.id)
      } else {
        onGetMeeting(obj.token, 0, this.props.history) //remove this after full integration
        onGetMeetingAgenda(obj.token, 0)
        onGetMeetingDocument(obj.token, 0)
        onGetMeetingResult(obj.token, 0)
      }
      this.setState({ token: obj.token })
    }
  }
  handleAttendance = e => {
    const { error } = this.props

    this.props.onSetAttendance(this.state.token, this.state.meetingId)
    if (error !== "") {
      this.showToast("success", "Таны ирц бүртгэгдлээ.", "Амжилттай")
      this.props.meeting.isAttandance = true
    } else {
      this.showToast("error", error, "Алдаа гарлаа")
    }
  }

  handleAttendanceValidDate = (isAttendance, startDateTime, endDateTime) => {
    if (!isAttendance) {
      const today = new Date()
      const startDate = new Date(startDateTime)
      const endDate = new Date(endDateTime)
      // console.log(
      //   startDate.getTime() < today.getTime() &&
      //     endDate.getTime() > today.getTime()
      // )
      if (
        startDate.getTime() < today.getTime() &&
        endDate.getTime() > today.getTime()
      ) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }
  showToast(type, message, title) {
    toastr.options = {
      timeOut: 5000,
      closeButton: true,
      progressBar: true,
      newestOnTop: true,
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
      showDuration: 300,
      hideDuration: 1000,
    }

    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (type === "info") toastr.info(message, title)
    else if (type === "warning") toastr.warning(message, title)
    else if (type === "error") toastr.error(message, title)
    else toastr.success(message, title)
  }

  render() {
    const { meeting, agendas, documents, results } = this.props
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Хурлын дэлгэрэнгүй | Цахим хурал</title>
          </MetaTags>
          <Container fluid={true}>
            <Breadcrumbs title="Хурал" breadcrumbItem="Хурлын дэлгэрэнгүй" />
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "1",
                          })}
                          onClick={() => {
                            this.toggle("1")
                          }}
                        >
                          Хурлын мэдээлэл
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "2",
                          })}
                          onClick={() => {
                            this.toggle("2")
                          }}
                        >
                          Баримт бичиг
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "3",
                          })}
                          onClick={() => {
                            this.toggle("3")
                          }}
                        >
                          Хэлэлцэх асуудал
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "4",
                          })}
                          onClick={() => {
                            this.toggle("4")
                          }}
                        >
                          Саналын түүх
                        </NavLink>
                      </NavItem>
                    </Nav>

                    <TabContent
                      activeTab={this.state.activeTab}
                      className="p-3 text-muted"
                    >
                      <TabPane tabId="1">
                        <Row>
                          <Col sm="12">
                            {!isEmpty(meeting) && (
                              <>
                                <Row>
                                  <Col lg="8">
                                    <MeetingIntroduction meeting={meeting} />
                                  </Col>

                                  <Col lg="4">
                                    <MeetingContact
                                      team={meeting.contactDtos}
                                    />

                                    <MeetingAttendance
                                      clickAttendance={this.handleAttendance}
                                      participatingCustomerCount={
                                        meeting.participatingCustomerCount
                                      }
                                      membersCount={meeting.membersCount}
                                      isAudit={meeting.isAudit}
                                      isAttandance={this.handleAttendanceValidDate(
                                        meeting.isAttandance,
                                        meeting.startTime,
                                        meeting.endTime
                                      )}
                                    />
                                  </Col>
                                </Row>
                              </>
                            )}
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="2">
                        <Row>
                          <Col sm="12">
                            <MeetingDocument documents={documents} />
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="3">
                        <Row>
                          <Col sm="12">
                            <MeetingAgenda
                              agendas={agendas}
                              token={this.state.token}
                              votes={this.state.votes}
                            />
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="4">
                        <Row>
                          <Col sm="12">
                            {meeting.isAudit ? (
                              <CompanyMeetingResult
                                results={results}
                                meeting={meeting}
                              />
                            ) : (
                              <MeetingResult results={results} />
                            )}
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

CustomerMeetingDtl.propTypes = {
  meeting: PropTypes.object,
  attendance: PropTypes.object,
  documents: PropTypes.array,
  agendas: PropTypes.array,
  results: PropTypes.object,
  onGetMeeting: PropTypes.func,
  onGetMeetingAgenda: PropTypes.func,
  onGetMeetingDocument: PropTypes.func,
  onGetMeetingResult: PropTypes.func,
  history: PropTypes.object,
  error: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    meeting: state.CustomerMeetings.meeting,
    agendas: state.CustomerMeetings.agendas,
    documents: state.CustomerMeetings.documents,
    results: state.CustomerMeetings.results,
    attendance: state.CustomerMeetings.attendance,
    error: state.CustomerMeetings.error,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetMeeting: (token, meetingId, history) =>
    dispatch(getMeetingDtl(token, meetingId, history)),
  onGetMeetingAgenda: (token, meetingId) =>
    dispatch(getMeetingAgenda(token, meetingId)),
  onGetMeetingDocument: (token, meetingId) =>
    dispatch(getMeetingDocument(token, meetingId)),
  onGetMeetingResult: (token, meetingId) =>
    dispatch(getMeetingResult(token, meetingId)),
  onSetAttendance: (token, meetingId) =>
    dispatch(setAttendance(token, meetingId)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CustomerMeetingDtl))
