import { call, put, takeEvery } from "redux-saga/effects"

// Ecommerce Redux States
import {
  GET_SHARES,
  ADD_NEW_SHARES,
  DELETE_SHARES,
  UPDATE_SHARES,
  GET_SHARES_DTL,
  GET_SHARESMEET_DTL,
} from "./actionTypes"
import {
  getSharesFail,
  getSharesSuccess,
  addSharesFail,
  addSharesSuccess,
  updateSharesSuccess,
  updateSharesFail,
  deleteSharesSuccess,
  deleteSharesFail,
  getSharesDtlSuccess,
  getSharesDtlFail,
  getSharesMeetDtlSuccess,
  getSharesMeetDtlFail,
} from "./actions"

//Include Both Helper File with needed methods
import { postJson } from "helpers/fakebackend_helper"

function* fetchShares({ payload: { request, history } }) {
  try {
    const response = yield call(
      postJson,
      {
        token: request.token,
        start: request.page,
        length: 10,
      },
      "/Admin/CompanyWebService/get_customer_list"
    )
    if (response.responseResultType === "SUCCESS") {
      yield put(getSharesSuccess(response))
    } else {
      yield put(
        getSharesFail(response.failureMessages.message[0].failureMessage)
      )
    }
  } catch (error) {
    yield put(getSharesFail(error))
  }
}

function* onAddNewShares({ payload: customer }) {
  try {
    const response = yield call(
      postJson,
      customer,
      "/Admin/CompanyWebService/register_customer"
    )
    if (response.responseResultType === "SUCCESS") {
      yield put(addSharesSuccess(response))
    } else {
      if (response.failureMessages.message[0].failureCode === "ERR_009") {
        history.push("/logout")
      } else {
        yield put(addSharesFail(response.failureMessages.message[0].failureMessage))
      }
    }
  } catch (error) {
    yield put(addSharesFail(error))
  }
}

function* onUpdateShares({ payload: customer }) {
  try {
    const response = yield call(
      postJson,
      customer,
      "/Admin/CompanyWebService/update_customer"
    )
    if (response.responseResultType === "SUCCESS") {
      yield put(updateSharesSuccess(response))
    } else {
      if (response.failureMessages.message[0].failureCode === "ERR_009") {
        history.push("/logout")
      } else {
        yield put(updateSharesFail(response.failureMessages.message[0].failureMessage))
      }
    }
  } catch (error) {
    yield put(updateSharesFail(error))
  }
}

function* onDeleteShares({ payload: customer }) {
    try {
    const response = yield call(postJson, customer, "/Admin/CompanyWebService/remove_customer")
    yield put(deleteSharesSuccess(response))
  } catch (error) {
    yield put(deleteSharesFail(error))
  }
}

function* fetchSharesDetail({ payload: { request, history } }) {
  try {
    const response = yield call(
      postJson,
      {
        token: request.token,
        customerId: request.customerId,
      },
      "/Admin/CompanyWebService/get_customer_introduction"
    )
    console.log("response", response)
    if (response.responseResultType === "SUCCESS") {
      yield put(getSharesDtlSuccess(response))
    } else {
      if (response.failureMessages.message[0].failureCode === "ERR_009") {
        history.push("/logout")
      }
      yield put(
        getSharesDtlFail(response.failureMessages.message[0].failureMessage)
      )
    }
  } catch (error) {
    yield put(getSharesDtlFail(error))
  }
}

function* fetchSharesMeetStory({ payload }) {
  try {
    const response = yield call(
      postJson,
      {
        token: payload.token,
        customerId: payload.customerId,
      },
      "/Admin/CompanyWebService/get_meeting_by_customer"
    )
    if (response.responseResultType === "SUCCESS") {
      yield put(getSharesMeetDtlSuccess(response.meetings))
    } else {
      if (response.failureMessages.message[0].failureCode === "ERR_009") {
        history.push("/logout")
      }
      yield put(
        getSharesMeetDtlFail(response.failureMessages.message[0].failureMessage)
      )
    }
  } catch (error) {
    yield put(getSharesMeetDtlFail(error))
  }
}

function* sharesSaga() {
  yield takeEvery(GET_SHARES, fetchShares)
  yield takeEvery(ADD_NEW_SHARES, onAddNewShares)
  yield takeEvery(UPDATE_SHARES, onUpdateShares)
  yield takeEvery(DELETE_SHARES, onDeleteShares)
  yield takeEvery(GET_SHARES_DTL, fetchSharesDetail)
  yield takeEvery(GET_SHARESMEET_DTL, fetchSharesMeetStory)
}

export default sharesSaga
