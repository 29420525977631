import {
  GET_AUDITS_FAIL,
  GET_AUDITS_SUCCESS,
  ADD_COMPANY_SUCCESS,
  ADD_COMPANY_FAIL,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAIL,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_FAIL,
  ADD_COMPANY_USER_SUCCESS,
  ADD_COMPANY_USER_FAIL
} from "./actionTypes"

const INIT_STATE = {
  page: 0,
  length: 9,
  totalPage: 0,
  draw: 1,
  audits: [],
  error: {},
}

const AdminCompanys = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_AUDITS_SUCCESS:
      return {
        ...state,
        audits: action.audits,
        totalPage: action.totalPage
      }

    case GET_AUDITS_FAIL:
      return {
        ...state,
        error: action.error,
      }

    // case ADD_COMPANY_SUCCESS:
    //   return {
    //     ...state,
    //     companys: [...state.companys, action.payload],
    //   }

    // case ADD_COMPANY_FAIL:
    //   return {
    //     ...state,
    //     error: action.error,
    //   }

    // case UPDATE_COMPANY_SUCCESS:
    //   return {
    //     ...state,
    //     companys: state.companys.map(companys =>
    //       companys.id.toString() === action.payload.id.toString()
    //         ? { companys, ...action.payload }
    //         : companys
    //     ),
    //   }

    // case UPDATE_COMPANY_FAIL:
    //   return {
    //     ...state,
    //     error: action.error,
    //   }

    // case DELETE_COMPANY_SUCCESS:
    //   return {
    //     ...state,
    //     companys: state.companys.filter(
    //       companys => companys.id.toString() !== action.payload.id.toString()
    //     ),
    //   }

    // case DELETE_COMPANY_FAIL:
    //   return {
    //     ...state,
    //     error: action.error,
    //   }

    // case ADD_COMPANY_USER_SUCCESS:
    //   return {
    //     ...state,
    //     companys: state.companys.map(member =>
    //       member.id.toString() === action.payload.id.toString()
    //         ? { member, ...action.payload }
    //         : member
    //     ),
    //   }

    // case ADD_COMPANY_USER_FAIL:
    //   return {
    //     ...state,
    //     error: action.error,
    //   }

    default:
      return state
  }
}

export default AdminCompanys
