import React, { Component } from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import { connect } from "react-redux"
import { isEmpty, size } from "lodash"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import * as moment from "moment"
import Breadcrumbs from "components/Common/Breadcrumb"

import {
  getComMeetings,
  setComVoteStart,
  setComVoteFinish,
} from "store/actions"

class CompanyMeeting extends Component {
  constructor(props) {
    super(props)
    this.state = {
      meetings: [],
      CompanyMeetingColumns: [
        {
          text: "id",
          dataField: "id",
          sort: true,
          hidden: true,
          formatter: (cellContent, user) => <>{row.id}</>,
        },
        {
          dataField: "name",
          text: "Хурлын нэр",
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <Link to={`/company/meeting/dtl/${row.id}`} className="text-dark">
                {row.name}
              </Link>
            </>
          ),
        },
        {
          text: "Хурлын огноо",
          dataField: "meetingDate",
          sort: true,
          formatter: (cellContent, row) =>
            this.handleValidDate(row.meetingDate),
        },
        {
          dataField: "registrationDate",
          text: "Бүртгэсэн огноо",
          sort: true,
          formatter: (cellContent, row) =>
            this.handleValidDate(row.registrationDate),
        },
        {
          dataField: "startTime",
          text: "Эхлэх цаг",
          sort: true,
        },

        {
          dataField: "endTime",
          text: "Дуусах цаг",
          sort: true,
        },
        {
          dataField: "status",
          text: "Төлөв",
          sort: true,
          formatter: (cellContent, row) => (
            <Badge color={row.statusColor} className={"bg-" + row.statusColor}>
              {row.meetingStatus}
            </Badge>
          ),
        },
        {
          dataField: "menu",
          isDummyField: true,
          text: "Үйлдэл",
          formatter: (cellContent, row) => (
            <UncontrolledDropdown>
              <DropdownToggle href="#" className="card-drop" tag="a">
                <i className="mdi mdi-dots-horizontal font-size-18" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                {row.meetingStatus == "Хурал товлогдсон" ? (
                  <DropdownItem href={`/company/meeting/update/${row.id}`}>
                    <i className="bx bx-pencil font-size-16 text-success me-1" />{" "}
                    Засах
                  </DropdownItem>
                ) : (
                  ""
                )}

                <DropdownItem href={`/company/meeting/dtl/${row.id}`}>
                  <i className="bx bx-network-chart font-size-16 text-primary me-1" />{" "}
                  Дэлгэрэнгүй
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          ),
        },
      ],
    }
    this.toggle = this.toggle.bind(this)
  }

  componentDidMount() {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      const { meetings } = this.props
      const token = obj.token
      this.props.onGetMeets(
        obj.token,
        this.props.page,
        this.props.totalPage,
        this.props.history
      )
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { meetings } = this.props
    if (!isEmpty(meetings) && size(prevProps.meetings) !== size(meetings)) {
      this.setState({ meetings: {}, isEdit: false })
    }
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }

  handleValidDate = date => {
    const date1 = moment(new Date(date)).format("YYYY-MM-DD")
    return date1
  }

  render() {
    const { meetings } = this.props
    const pageOptions = {
      sizePerPage: 10,
      totalSize: this.props.totalPage, // replace later with size(customers),
      custom: true,
    }
    // Custom Pagination Toggle
    const sizePerPageList = [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "15", value: 15 },
      { text: "20", value: 20 },
      { text: "25", value: 25 },
      { text: "Бүгд", value: this.props.totalSize },
    ]

    const defaultSorted = [
      {
        dataField: "id",
        order: "desc",
      },
    ]

    const { SearchBar } = Search
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Хурлын жагсаалт | Цахим хурал</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Компани" breadcrumbItem="Хурлын жагсаалт" />
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={this.state.CompanyMeetingColumns}
                      data={meetings}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={this.state.CompanyMeetingColumns}
                          data={meetings}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row>
                                <Col sm="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        placeholder="Хайлт"
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="8">
                                  <div className="text-sm-end">
                                    <Link
                                      to="/company/register"
                                      className="btn btn-success mb-2 me-2"
                                    >
                                      <i className="mdi mdi-plus me-1" />
                                      Хурал нэмэх
                                    </Link>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

CompanyMeeting.propTypes = {
  meetings: PropTypes.array,
  page: PropTypes.number,
  totalPage: PropTypes.number,
  error: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    meetings: state.CompanyMeet.meetings,
    page: state.CompanyMeet.page,
    sizePerPage: state.CompanyMeet.length,
    totalPage: state.CompanyMeet.totalPage,
    history: PropTypes.object,
    error: state.CompanyMeet.error,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetMeets: (token, page, totalPage, history) =>
    dispatch(getComMeetings(token, page, totalPage, history)),
  onSetVoteStart: (token, agendaId) =>
    dispatch(setComVoteStart(token, agendaId)),
  onSetVoteFinish: (token, agendaId) =>
    dispatch(setComVoteFinish(token, agendaId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CompanyMeeting)
