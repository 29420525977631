import { takeEvery, fork, put, call, all } from "redux-saga/effects"

// Login Redux States
import { EDIT_PROFILE, CHANGE_PASSWORD } from "./actionTypes"
import { profileSuccess, profileError } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  postFakeProfile,
  postJwtProfile,
  postJson,
  isUserAuthenticated,
} from "../../../helpers/fakebackend_helper"

const fireBaseBackend = getFirebaseBackend()

function* editProfile({ payload: { user } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.editProfileAPI,
        user.username,
        user.idx
      )
      yield put(profileSuccess(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtProfile, "/post-jwt-profile", {
        username: user.username,
        idx: user.idx,
      })
      yield put(profileSuccess(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      const response = yield call(postFakeProfile, {
        username: user.username,
        idx: user.idx,
      })
      yield put(profileSuccess(response))
    }
  } catch (error) {
    yield put(profileError(error))
  }
}
function* changePassword({ payload: { request, history } }) {
  if (isUserAuthenticated()) {
    try {
      const response = yield call(
        postJson,
        {
          token: request.token,
          loginIp: 1,
          oldPassword: request.oldPassword,
          newPassword: request.password,
        },
        "/Customer/UserWebService/change_passsword"
      )
      if (response.responseResultType === "SUCCESS") {
        yield put(profileSuccess("Амжилттай солигдлоо"))
      } else {
        if (response.failureMessages.message[0].failureCode === "ERR_009") {
          history.push("/logout")
        }
        yield put(
          profileError(response.failureMessages.message[0].failureMessage)
        )
      }
    } catch (error) {
      yield put(profileError(error))
    }
  } else history.push("/logout")
}
export function* watchProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile)
  yield takeEvery(CHANGE_PASSWORD, changePassword)
}

function* ProfileSaga() {
  yield all([fork(watchProfile)])
}

export default ProfileSaga
