import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
import { isEmpty, map, values } from "lodash"
import {
  Card,
  CardBody,
  Col,
  Container,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

import imagesDefault from "../../../assets/images/default.jpg"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { addNewCompany, getCompanyType } from "store/actions"
class AdminCompanyRegister extends Component {
  constructor(props) {
    super(props)
    this.state = {
      companyLogo: imagesDefault,
      companyForm: {
        token: "",
        name: "",
        register: "",
        typeId: "",
        address: "",
        phone: "",
        stockQuantity: "",
        registrationDate: "",
        memberCount: "",
        bodMemberCount: "",
        active: "",
        selectedType: ""
      },
    }
    this.toggleTab.bind(this)
  }

  componentDidMount() {
    const { onGetCompanyType } = this.props

    if (localStorage.getItem("authUser")) {
      // alert(123)
      const obj = JSON.parse(localStorage.getItem("authUser"))
      onGetCompanyType(obj.token)
      this.setState({ token: obj.token })
    }
  }

  toggle(tab) {
    this.toggleTab.bind(this)
  }
  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 2) {
        var modifiedSteps = [...this.state.passedSteps, tab]
        this.setState({
          activeTab: tab,
          passedSteps: modifiedSteps,
        })
      }
    }
  }
  showToast(type, message, title) {
    toastr.options = {
      timeOut: 5000,
      closeButton: true,
      progressBar: true,
      newestOnTop: true,
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
      showDuration: 300,
      hideDuration: 1000,
    }

    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (type === "info") toastr.info(message, title)
    else if (type === "warning") toastr.warning(message, title)
    else if (type === "error") toastr.error(message, title)
    else toastr.success(message, title)
  }
  handleSelectFile = event => {
    // debugger;
    if (!event.target.files[0] || event.target.files[0].length == 0) {
      //this.msg = 'You must select an image';
      return
    }
    var mimeType = event.target.files[0].type
    if (mimeType.match(/image\/*/) == null) {
      //this.msg = "Only images are supported";
      return
    }
    var reader = new FileReader()
    reader.readAsDataURL(event.target.files[0])
    reader.onload = _event => {
      this.setState({ companyLogo: reader.result })
    }
  }

  handleTypeChange = evt => {
    this.setState({ selectedType: evt.target.value })
  }

  handleValidCompanySubmit = (e, values) => {
    const { onAddNewCompany } = this.props
    const { isEdit, isUser, companys, selectedCompany } = this.state
    // console.log(values, this.state.stockQuantity)
    const newCompany = {
      token: this.state.token,
      logo: this.state.companyLogo,
      name: values["name"],
      phone: values["phone"],
      register: values["register"],
      address: values["address"],
      memberCount: values["memberCount"],
      stockQuantity: values["stockQuantity"],
      // stockQuantity: this.state.companys.stockQuantity,
      companyTypeId: values["type"],
      bodMemberCount: values["bodMemberCount"],
    }
    // this.props.companys = {...this.props.companys, newCompany}
    // save new Company
    onAddNewCompany(newCompany)
    setTimeout(() => {
      if (isEmpty(this.props.error)) {
        this.showToast("success", "Үйлдэл амжилттай боллоо.", "Амжилттай")
        this.setState({ selectedCompany: null })
        this.props.history.push("/admin/company")
      } else {
        this.showToast("error", this.props.error, "Алдаа гарлаа")
      }
    }, 2000)
  }

  render() {
    const { companyType } = this.props
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Байгууллага нэмэх |Цахим хурал</title>
          </MetaTags>
          <Container fluid={true}>
            <Breadcrumbs title="Компани" breadcrumbItem="Байгууллага нэмэх" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <AvForm onValidSubmit={this.handleValidCompanySubmit}>
                      <Row>
                        <AvField
                          id="id"
                          name="id"
                          value={
                            this.state.companys
                              ? this.state.companys.companyId
                              : ""
                          }
                          type="hidden"
                        />
                        <Col lg="12">
                          <div className="mb-3">
                            <img
                              className="rounded-circle avatar-sm"
                              src={this.state.companyLogo}
                              alt=""
                            />
                            <button
                              type="button"
                              className="ms-2 btn btn-light w-sm"
                            >
                              <input
                                type="file"
                                accept="image/jpeg, image/png"
                                onChange={e => {
                                  this.handleSelectFile(e)
                                }}
                              />
                            </button>
                          </div>
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <AvField
                                  id="type"
                                  name="type"
                                  label="Төрөл"
                                  type="select"
                                  errorMessage="Төрөл сонгоно уу"
                                  validate={{
                                    required: { value: true },
                                  }}
                                  onChange={this.handleTypeChange}
                                  className="form-select"
                                  value={
                                    this.state.companys
                                      ? this.state.companys.typeId
                                      : ""
                                  }
                                >
                                  {/* <option value>-Сонгох-</option>
                              <option value="1">Хувьцаат компани</option>
                              <option value="2">
                                Хадгаламж зээлийн хоршоо
                              </option>
                              <option value="3">Бусад</option> */}
                                  <option value="">-Сонгох-</option>
                                  {map(companyType, (type, key) => (
                                    <option
                                      key={"option" + key}
                                      value={type.id}
                                    >
                                      {type.name}
                                    </option>
                                  ))}
                                </AvField>
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <AvField
                                  name="name"
                                  label="Байгууллагын нэр"
                                  type="text"
                                  errorMessage="Байгууллагын нэр оруулна уу"
                                  validate={{
                                    required: { value: true },
                                  }}
                                  value={
                                    this.state.companys
                                      ? this.state.companys.name
                                      : ""
                                  }
                                />
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <AvField
                                  name="register"
                                  label="Регистр"
                                  type="text"
                                  errorMessage="Регистр оруулна уу"
                                  validate={{
                                    required: { value: true },

                                    pattern: {
                                      value: "^[0-9]{7}$",
                                      errorMessage:
                                        "7 оронгийн урттай зөвхөн тоон утга авна.",
                                    },
                                  }}
                                  value={
                                    this.state.companys
                                      ? this.state.companys.register
                                      : ""
                                  }
                                />
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <AvField
                                  name="phone"
                                  label="Утас"
                                  type="text"
                                  errorMessage="Утас оруулна уу"
                                  validate={{
                                    required: { value: true },
                                    pattern: {
                                      value: "^[0-9]{6,8}$",
                                      errorMessage:
                                        "6-8 оронгийн урттай зөвхөн тоон утга авна.",
                                    },
                                  }}
                                  value={
                                    this.state.companys
                                      ? this.state.companys.phone
                                      : ""
                                  }
                                />
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <AvField
                                  name="memberCount"
                                  label={
                                    this.state.selectedType == 1
                                      ? "Хувьцаа эзэмшигчдийн тоо"
                                      : this.state.selectedType == 2
                                      ? "Гишүүдийн тоо"
                                      : "Харилцагчийн тоо"
                                  }
                                  type="text"
                                  errorMessage="Талбар хоосон байна."
                                  validate={{
                                    required: { value: true },
                                    pattern: {
                                      value: "^[0-9]*$",
                                      errorMessage: "Зөвхөн тоон утга авна.",
                                    },
                                  }}
                                  value={
                                    this.state.companys
                                      ? this.state.companys.memberCount
                                      : ""
                                  }
                                />
                              </div>
                            </Col>
                            {this.state.selectedType != "" &&
                            this.state.selectedType == 1 ? (
                              <Col lg="6">
                                <div className="mb-3">
                                  {/* <div className="form-group">
                                                    <label htmlFor="stockQuantity1">Нийт хувьцааны тоо</label>
                                                    <NumberFormat
                                                      thousandSeparator={true} className="form-control" inputMode="numeric"
                                                      name="stockQuantity1"
                                                      id="stockQuantity1"
                                                      error="Талбар хоосон байна."
                                                      errormessage="Талбар хоосон байна."
                                                      // required
                                                      // validate={{
                                                      //   required: { value: true },
                                                      // }}
                                                      isAllowed={this.valid}
                                                      value={
                                                        this.state.companys
                                                          ? this.state.companys
                                                            .stockQuantity
                                                          : ""
                                                      }
                                                    />
                                                    <div className="invalid-feedback">Талбар хоосон байна.</div>
                                                  </div> */}
                                  <AvField
                                    name="stockQuantity"
                                    label="Нийт хувьцааны тоо"
                                    type="text"
                                    errorMessage="Талбар хоосон байна."
                                    validate={{
                                      required: { value: true },
                                      pattern: {
                                        value: "^[0-9]*$",
                                        errorMessage: "Зөвхөн тоон утга авна.",
                                      },
                                    }}
                                    value={
                                      this.state.companys
                                        ? this.state.companys.stockQuantity
                                        : ""
                                    }
                                  />

                                  {/* <CurrencyInput
                                                    name="stockQuantity"
                                                    label="Нийт хувьцааны тоо"
                                                    type="text"
                                                    errormessage="Талбар хоосон байна."
                                                    className="hidden"
                                                    validate={{
                                                      required: { value: true },
                                                    }}

                                                    onChange={e =>
                                                      this.handleChangeValue(e)
                                                    }
                                                    value={this.state.companys ? this.state.companys.stockQuantity : ""}
                                                  /> */}
                                </div>
                              </Col>
                            ) : (
                              ""
                            )}
                            <Col lg="6">
                              <div className="mb-3">
                                <AvField
                                  name="bodMemberCount"
                                  label={
                                    this.state.selectedType == 1
                                      ? "Төлөөлөн удирдах зөвлөлийн гишүүдийн тоо"
                                      : "Тэргүүлэгчдийн зөвлөлийн гишүүдийн тоо"
                                  }
                                  type="text"
                                  errorMessage="Талбар хоосон байна."
                                  validate={{
                                    required: { value: true },
                                    pattern: {
                                      value: "^[0-9]*$",
                                      errorMessage: "Зөвхөн тоон утга авна.",
                                    },
                                  }}
                                  value={
                                    this.state.companys
                                      ? this.state.companys.bodMemberCount
                                      : ""
                                  }
                                />
                              </div>
                            </Col>
                          </Row>

                          <div className="mb-3">
                            <AvField
                              name="address"
                              label="Хаяг"
                              type="textarea"
                              errorMessage="Хаяг оруулна уу"
                              rows="3"
                              validate={{
                                required: { value: true },
                              }}
                              value={
                                this.state.companys
                                  ? this.state.companys.address
                                  : ""
                              }
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="text-end">
                            <button
                              type="submit"
                              className="btn btn-success save-company"
                            >
                              Хадгалах
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
AdminCompanyRegister.propTypes = {
  onAddNewCompany: PropTypes.func,
  error: PropTypes.any,
  history: PropTypes.object,
  companyType: PropTypes.array,
}
const mapStateToProps = state => {
  return {
    error: state.AdminCompanys.error,
    companyType: state.AdminCompanys.companyType,
  }
}

const mapDispatchToProps = dispatch => ({
  onAddNewCompany: company => dispatch(addNewCompany(company)),
  onGetCompanyType: token => dispatch(getCompanyType(token)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminCompanyRegister)
