import {
  GET_MEETINGS_FAIL,
  GET_MEETINGS_SUCCESS,
  GET_MEETING_DTL_FAIL,
  GET_MEETING_DTL_SUCCESS,
  GET_MEETING_AGENDA_SUCCESS,
  GET_MEETING_AGENDA_FAIL,
  GET_MEETING_DOCUMENT_SUCCESS,
  GET_MEETING_DOCUMENT_FAIL,
  GET_MEETING_RESULT_SUCCESS,
  GET_MEETING_RESULT_FAIL,
  START_VOTE_SUCCESS,
  START_VOTE_FAIL,
  SAVE_VOTE_SUCCESS,
  SAVE_VOTE_FAIL,
  SET_ATTENDANCE_SUCCESS,
  SET_ATTENDANCE_FAIL
} from "./actionTypes"

const INIT_STATE = {
  page: 0,
  length: 9,
  totalPage: 0,
  draw: 1,
  meetings: [],
  meeting: {},
  agendas: [],
  documents: [],
  results: {},
  votes: [],
  attendance: {},
  error: {},
}

const CustomerMeetings = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MEETINGS_SUCCESS:
      return {
        ...state,
        meetings: action.meetings,
        totalPage: action.totalPage,
      }

    case GET_MEETINGS_FAIL:
      return {
        ...state,
        error: action.error,
      }

    case GET_MEETING_DTL_SUCCESS:
      return {
        ...state,
        meeting: action.meeting,
      }

    case GET_MEETING_DTL_FAIL:
      return {
        ...state,
        error: action.error,
      }

    case GET_MEETING_AGENDA_SUCCESS:
      return {
        ...state,
        agendas: action.agenda,
      }

    case GET_MEETING_AGENDA_FAIL:
      return {
        ...state,
        error: action.error,
      }
    case GET_MEETING_DOCUMENT_SUCCESS:
      return {
        ...state,
        documents: action.document,
      }

    case GET_MEETING_DOCUMENT_FAIL:
      return {
        ...state,
        error: action.error,
      }
    case GET_MEETING_RESULT_SUCCESS:
      return {
        ...state,
        results: action.result,
      }

    case GET_MEETING_RESULT_FAIL:
      return {
        ...state,
        error: action.error,
      }
    case START_VOTE_SUCCESS:
      return {
        ...state,
        votes: action.votes,
      }

    case START_VOTE_FAIL:
      return {
        ...state,
        error: action.error,
      }
    case SAVE_VOTE_SUCCESS:
      return {
        ...state,
        vote: action.vote,
      }

    case SAVE_VOTE_FAIL:
      return {
        ...state,
        error: action.error,
      }
    case SET_ATTENDANCE_SUCCESS:
      return {
        ...state,
        attendance: action.attendance,
      }

    case SET_ATTENDANCE_FAIL:
      return {
        ...state,
        error: action.error,
      }

    default:
      return state
  }
}

export default CustomerMeetings
