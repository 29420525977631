import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import { connect } from "react-redux"
import { isEmpty, size } from "lodash"
import { Link } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import * as moment from "moment"
import imagesDefault from "../../../assets/images/default.jpg"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import CurrencyInput from "components/Common/CurrencyInput"
import { AvForm, AvField } from "availity-reactstrap-validation"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import NumberFormat from "react-number-format"
import {
  getCompanys,
  addNewCompany,
  updateCompany,
  deleteCompany,
  addCompanyUser,
  searchEmail,
} from "store/actions"

class AdminCompany extends Component {
  constructor(props) {
    super(props)
    this.state = {
      companys: [],
      companyLogo: imagesDefault,
      stockQuantity: 0,
      token: "",
      selectedType: "",
      error: "",
      amount: 0.0,
      CompanyColumns: [
        {
          text: "id",
          dataField: "id",
          sort: true,
          hidden: true,
          formatter: (cellContent, user) => <>{row.id}</>,
        },
        {
          dataField: "logo",
          text: "Лого",
          formatter: (cellContent, row) => (
            <>
              {!row.logo ? (
                <div className="avatar-xs">
                  <span className="avatar-title rounded-circle">
                    {row.name.charAt(0)}
                  </span>
                </div>
              ) : (
                <div>
                  <img
                    className="rounded-circle avatar-xs"
                    src={row.logo}
                    alt=""
                  />
                </div>
              )}
            </>
          ),
        },
        {
          text: "Байгууллагын нэр/ Төрөл",
          dataField: "name",
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <p className="mb-1">{row.name}</p>
              <p className="mb-0">{row.type}</p>
            </>
          ),
        },
        {
          dataField: "register",
          text: "Регистр",
          sort: true,
        },
        {
          dataField: "username",
          text: "Нэвтрэх эрх",
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <p className="mb-1">
                {row.lastname
                  ? row.lastname.charAt(0) + "." + row.firstname
                  : ""}
              </p>
              <p className="mb-0">{row.username}</p>
            </>
          ),
        },
        {
          dataField: "registrationDate",
          text: "Бүртгүүлсэн огноо",
          sort: true,
          formatter: (cellContent, row) =>
            this.handleValidDate(row.registrationDate),
        },
        {
          dataField: "memberCount",
          text: "Гишүүн / ХЭ тоо",
          sort: true,
        },
        {
          dataField: "bodMemberCount",
          text: "ТУЗ-н тоо",
          sort: true,
        },
        {
          dataField: "status",
          text: "Төлөв",
          sort: true,
          formatter: (cellContent, row) => (
            <Badge color={row.statusColor} className={"bg-" + row.statusColor}>
              {row.status}
            </Badge>
          ),
        },
        {
          dataField: "menu",
          isDummyField: true,
          text: "Үйлдэл",
          formatter: (cellContent, company) => (
            <UncontrolledDropdown>
              <DropdownToggle href="#" className="card-drop" tag="a">
                <i className="mdi mdi-dots-horizontal font-size-18" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  href="#"
                  onClick={() => this.handleCompanyClick(company)}
                >
                  <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
                  Засах
                </DropdownItem>

                {!company.lastname ? (
                  <DropdownItem
                    href="#"
                    onClick={() => this.handleCompanyUser(company)}
                  >
                    <i className="mdi mdi-account-key font-size-16 text-warning me-1" />{" "}
                    Нэвтрэх эрх үүсгэх
                  </DropdownItem>
                ) : (
                  ""
                )}
                <DropdownItem
                  href="#"
                  onClick={() => this.handleDeleteCompany(company)}
                >
                  <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />{" "}
                  Устгах
                </DropdownItem>
                <DropdownItem
                  href="#"
                  onClick={() => this.handleInvoiceCompany(company)}
                >
                  <i className="mdi mdi-text font-size-16 text-info me-1" />{" "}
                  Төлбөр нэхэмжлэх
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          ),
        },
      ],
    }
    this.handleCompanyClick = this.handleCompanyClick.bind(this)
    this.toggle = this.toggle.bind(this)
    this.handleCompanyUser = this.handleCompanyUser.bind(this)
    this.handleValidCompanySubmit = this.handleValidCompanySubmit.bind(this)
    this.handleCompanyClicks = this.handleCompanyClicks.bind(this)
  }

  componentDidMount() {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      const { companys, onGetCompanys } = this.props
      const token = obj.token
      if (companys && !companys.length) {
        onGetCompanys(
          obj.token,
          this.props.page,
          this.props.totalPage,
          this.props.history
        )
      }
      this.setState({ companys })
      this.setState({ token })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { companys } = this.props
    if (!isEmpty(companys) && size(prevProps.companys) !== size(companys)) {
      this.setState({ companys: {}, isEdit: false })
    }
    if (this.props.error && !prevProps.error) {
      this.setState({ error: this.props.error })
    }
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }

  handleCompanyClicks = arg => {
    const company = arg
    this.setState({
      selectedCompany: arg,
      companys: {
        name: "",
        register: "",
        typeId: "",
        address: "",
        phone: "",
        stockQuantity: "",
        registrationDate: "",
        memberCount: "",
        bodMemberCount: "",
        active: "",
      },
      selectedType: "",
      companyLogo: imagesDefault,
      isUser: false,
    })
    this.toggle()
  }

  /* Insert,Update Delete data */

  handleDeleteCompany = company => {
    const { error } = this.props
    const deleteCompany = {
      token: this.state.token,
      companyId: company.id,
    }
    const { onDeleteCompany } = this.props
    swal({
      title: "Устгах",
      text: "Та байгууллагын мэдээлэл устгах гэж байна!",
      icon: "warning",
      buttons: {
        cancel: {
          text: "Үгүй",
          value: null,
          visible: true,
        },
        confirm: {
          text: "Тийм",
          value: true,
          visible: true,
          className: "btn-primary",
        },
      },
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        onDeleteCompany(deleteCompany)
        if (error !== "") {
          this.props.companys.filter(item => {
            return item.id !== company.id
          })
          // this.setState({business:list})}
          this.showToast(
            "success",
            "Утгах үйлдэл амжилттай боллоо.",
            "Амжилттай"
          )
        } else {
          this.showToast("error", error, "Алдаа гарлаа")
        }
      } else {
        // swal("Your imaginary file is safe!");
      }
    })
  }
  handleInvoiceCompany = company => {
    const { error } = this.props
    const deleteCompany = {
      token: this.state.token,
      companyId: company.id,
    }
    const { onDeleteCompany } = this.props
    swal({
      title: "Төлбөрийн нэхэмжлэх",
      text: "Түр засвартай байна!",
      icon: "warning",
      buttons: false,
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
      } else {
        // swal("Your imaginary file is safe!");
      }
    })
  }

  handleCompanyClick = arg => {
    const company = arg
    console.log(company)
    this.setState({
      companys: {
        companyId: company.id,
        name: company.name,
        register: company.register,
        typeId: company.typeId,
        address: company.address,
        phone: company.phone,
        stockQuantity: company.stockQuantity,
        registrationDate: company.registrationDate,
        memberCount: company.memberCount,
        bodMemberCount: company.bodMemberCount,
        active: company.active,
      },
      selectedType: company.typeId,
      companyLogo: company.logo,
      isEdit: true,
      isUser: false,
    })

    this.toggle()
  }
  handleCompanyUser = arg => {
    console.log(arg)
    const company = arg

    this.setState({
      companys: {
        companyId: company.id,
      },
      isEdit: true,
      isUser: true,
    })
    this.toggle()
  }

  handleSelectFile = event => {
    // debugger;
    if (!event.target.files[0] || event.target.files[0].length == 0) {
      //this.msg = 'You must select an image';
      return
    }
    var mimeType = event.target.files[0].type
    if (mimeType.match(/image\/*/) == null) {
      //this.msg = "Only images are supported";
      return
    }
    var reader = new FileReader()
    reader.readAsDataURL(event.target.files[0])
    reader.onload = _event => {
      this.setState({ companyLogo: reader.result })
    }
  }

  handleTypeChange = evt => {
    this.setState({ selectedType: evt.target.value })
  }
  // handleEmailChange = evt => {
  //   // const email = evt.target.value;
  //   // const { onSearchEmail } =
  //   //   this.props
  //   // // onSearchEmail(this.state.token, email, this.props.history);

  //   // if (isEmpty(this.state.error)) {
  //   //   alert(123)
  //   //   // this.showToast("success", "Үйлдэл амжилттай боллоо.", "Амжилттай")
  //   //   // this.setState({ selectedCompany: null })
  //   //   // this.toggle()
  //   // } else {
  //   //   console.log(isEmpty(this.state.error))
  //   //   this.showToast("error", this.state.error, "Алдаа гарлаа")
  //   // }
  // }

  /**
   * Handling submit Company on Company form
   */
  handleValidCompanySubmit = (e, values) => {
    alert(123)
    const { onAddNewCompany, onUpdateCompany, onAddCompanyUser } = this.props
    const { isEdit, isUser, companys, selectedCompany } = this.state
    // console.log(values, this.state.stockQuantity)
    if (isEdit) {
      if (isUser) {
        const user = {
          token: this.state.token,
          companyId: values.id,
          firstname: values.firstname,
          lastname: values.lastname,
          register: values.register,
          phone: values.phone,
          position: values.position,
          email: values.email,
          password: values.password,
          companyTypeId: values.type,
          bodMemberCount: values.bodMemberCount,
          stockQuantity: values.bodMemberCount,
        }
        // this.props.companys.filter((item)=> {return (item.id!==company.id)})
        // update Company
        onAddCompanyUser(user)
      } else {
        const updateCompany = {
          token: this.state.token,
          companyId: values.id,
          logo: this.state.companyLogo,
          name: values.name,
          phone: values.phone,
          register: values.register,
          address: values.address,
          memberCount: values.memberCount,
          stockQuantity: values.stockQuantity,
          companyTypeId: values.type,
          bodMemberCount: values.bodMemberCount,
        }

        // update Company
        onUpdateCompany(updateCompany)
      }
    } else {
      const newCompany = {
        token: this.state.token,
        logo: this.state.companyLogo,
        name: values["name"],
        phone: values["phone"],
        register: values["register"],
        address: values["address"],
        memberCount: values["memberCount"],
        stockQuantity: values["stockQuantity"],
        // stockQuantity: this.state.companys.stockQuantity,
        companyTypeId: values["type"],
        bodMemberCount: values["bodMemberCount"],
      }
      // this.props.companys = {...this.props.companys, newCompany}
      // save new Company
      onAddNewCompany(newCompany)
    }
    setTimeout(() => {
      if (isEmpty(this.props.error)) {
        this.showToast("success", "Үйлдэл амжилттай боллоо.", "Амжилттай")
        this.setState({ selectedCompany: null })
        this.toggle()
      } else {
        this.showToast("error", this.props.error, "Алдаа гарлаа")
      }
    }, 2000)
  }

  handleValidDate = date => {
    const date1 = moment(new Date(date)).format("YYYY-MM-DD")
    return date1
  }

  showToast(type, message, title) {
    toastr.options = {
      timeOut: 5000,
      closeButton: true,
      progressBar: true,
      newestOnTop: true,
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
      showDuration: 300,
      hideDuration: 1000,
    }

    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (type === "info") toastr.info(message, title)
    else if (type === "warning") toastr.warning(message, title)
    else if (type === "error") toastr.error(message, title)
    else toastr.success(message, title)
  }

  handleChangeValue = e => {
    const val = parseInt(e.target.value, 10)
    this.setState({
      companys: { ...this.state.companys, stockQuantity: val },
      stockQuantity: val,
    })
    // return <NumberFormat value={e.target.value} displayType={'text'} thousandSeparator={true}/>
  }

  /* Insert,Update Delete data */

  render() {
    const { companys } = this.props

    const { isEdit, isUser } = this.state

    //pagination customization
    const pageOptions = {
      sizePerPage: 10,
      totalSize: this.props.totalPage, // replace later with size(companys),
      custom: true,
    }

    const defaultSorted = [
      {
        dataField: "id",
        order: "desc",
      },
    ]

    const { SearchBar } = Search

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Байгууллагын жагсаалт | Цахим хурал</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs
              title="Дашбоард"
              breadcrumbItem="Байгууллагын жагсаалт"
            />
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={this.state.CompanyColumns}
                      data={companys}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={this.state.CompanyColumns}
                          data={companys}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row>
                                <Col sm="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        placeholder="Хайлт"
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="8">
                                  <div className="text-sm-end">
                                    {/* <Button
                                      type="button"
                                      color="success"
                                      className="btn-rounded mb-2 me-2"
                                      onClick={this.handleCompanyClicks}
                                    >
                                      <i className="mdi mdi-plus me-1" />{" "}
                                      Байгууллага бүртгэх
                                    </Button> */}
                                    <Link
                                      to="/admin/company/register"
                                      className="btn btn-rounded btn-success mb-2 me-2"
                                    >
                                      <i className="mdi mdi-plus me-1" />
                                      Байгууллага бүртгэх
                                    </Link>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                  <Modal
                                    size="lg"
                                    backdrop={"static"}
                                    isOpen={this.state.modal}
                                    className={this.props.className}
                                  >
                                    <ModalHeader toggle={this.toggle} tag="h4">
                                      {!!isEdit
                                        ? isUser
                                          ? "Нэвтрэх эрх үүсгэх"
                                          : "Байгууллага засах"
                                        : "Байгууллага нэмэх"}
                                    </ModalHeader>
                                    <ModalBody>
                                      <AvForm
                                        onValidSubmit={
                                          this.handleValidCompanySubmit
                                        }
                                      >
                                        <Row>
                                          <AvField
                                            id="id"
                                            name="id"
                                            value={
                                              this.state.companys
                                                ? this.state.companys.companyId
                                                : ""
                                            }
                                            type="hidden"
                                          />
                                          {!isUser ? (
                                            <Col lg="12">
                                              <div className="mb-3">
                                                <p>Лого байршуулах </p>
                                                <img
                                                  className="rounded-circle avatar-sm"
                                                  src={this.state.companyLogo}
                                                  alt=""
                                                />
                                                <button
                                                  type="button"
                                                  className="ms-2 btn btn-light w-sm"
                                                >
                                                  <input
                                                    type="file"
                                                    accept="image/jpeg, image/png"
                                                    onChange={e => {
                                                      this.handleSelectFile(e)
                                                    }}
                                                  />
                                                </button>
                                              </div>
                                              <div className="mb-3">
                                                <AvField
                                                  id="type"
                                                  name="type"
                                                  label="Төрөл"
                                                  type="select"
                                                  errorMessage="Төрөл сонгоно уу"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  onChange={
                                                    this.handleTypeChange
                                                  }
                                                  className="form-select"
                                                  value={
                                                    this.state.companys
                                                      ? this.state.companys
                                                        .typeId
                                                      : ""
                                                  }
                                                >
                                                  <option value>
                                                    -Сонгох-
                                                  </option>
                                                  <option value="1">
                                                    Хувьцаат компани
                                                  </option>
                                                  <option value="2">
                                                    Хадгаламж зээлийн хоршоо
                                                  </option>
                                                  <option value="3">
                                                    Бусад
                                                  </option>
                                                </AvField>
                                              </div>
                                              <div className="mb-3">
                                                <AvField
                                                  name="name"
                                                  label="Байгууллагын нэр"
                                                  type="text"
                                                  errorMessage="Байгууллагын нэр оруулна уу"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  value={
                                                    this.state.companys
                                                      ? this.state.companys.name
                                                      : ""
                                                  }
                                                />
                                              </div>

                                              <div className="mb-3">
                                                <AvField
                                                  name="register"
                                                  label="Регистр"
                                                  type="text"
                                                  errorMessage="Регистр оруулна уу"
                                                  validate={{
                                                    required: { value: true },

                                                    pattern: {
                                                      value: "^[0-9]{7}$",
                                                      errorMessage:
                                                        "7 оронгийн урттай зөвхөн тоон утга авна.",
                                                    },
                                                  }}
                                                  value={
                                                    this.state.companys
                                                      ? this.state.companys
                                                        .register
                                                      : ""
                                                  }
                                                />
                                              </div>

                                              <div className="mb-3">
                                                <AvField
                                                  name="phone"
                                                  label="Утас"
                                                  type="text"
                                                  errorMessage="Утас оруулна уу"
                                                  validate={{
                                                    required: { value: true },
                                                    pattern: {
                                                      value: "^[0-9]{6,8}$",
                                                      errorMessage:
                                                        "6-8 оронгийн урттай зөвхөн тоон утга авна.",
                                                    },
                                                  }}
                                                  value={
                                                    this.state.companys
                                                      ? this.state.companys
                                                        .phone
                                                      : ""
                                                  }
                                                />
                                              </div>
                                              <div className="mb-3">
                                                <AvField
                                                  name="memberCount"
                                                  label={
                                                    this.state.selectedType == 1
                                                      ? "Хувьцаа эзэмшигчдийн тоо"
                                                      : this.state
                                                        .selectedType == 2
                                                        ? "Гишүүдийн тоо"
                                                        : "Харилцагчийн тоо"
                                                  }
                                                  type="text"
                                                  errorMessage="Талбар хоосон байна."
                                                  validate={{
                                                    required: { value: true },
                                                    pattern: {
                                                      value: "^[0-9]*$",
                                                      errorMessage:
                                                        "Зөвхөн тоон утга авна.",
                                                    },
                                                  }}
                                                  value={
                                                    this.state.companys
                                                      ? this.state.companys
                                                        .memberCount
                                                      : ""
                                                  }
                                                />
                                              </div>
                                              {this.state.selectedType != "" &&
                                                this.state.selectedType == 1 ? (
                                                <div className="mb-3">
                                                  <AvField
                                                    name="stockQuantity"
                                                    label="Нийт хувьцааны тоо"
                                                    type="text"
                                                    errorMessage="Талбар хоосон байна."
                                                    validate={{
                                                      required: { value: true },
                                                      pattern: {
                                                        value: "^[0-9]*$",
                                                        errorMessage:
                                                          "Зөвхөн тоон утга авна.",
                                                      },
                                                    }}
                                                    value={
                                                      this.state.companys
                                                        ? this.state.companys
                                                          .stockQuantity
                                                        : ""
                                                    }
                                                  />
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                              <div className="mb-3">
                                                <AvField
                                                  name="bodMemberCount"
                                                  label={
                                                    this.state.selectedType == 1
                                                      ? "Төлөөлөн удирдах зөвлөлийн гишүүдийн тоо"
                                                      : "Тэргүүлэгчдийн зөвлөлийн гишүүдийн тоо"
                                                  }
                                                  type="text"
                                                  errorMessage="Талбар хоосон байна."
                                                  validate={{
                                                    required: { value: true },
                                                    pattern: {
                                                      value: "^[0-9]*$",
                                                      errorMessage:
                                                        "Зөвхөн тоон утга авна.",
                                                    },
                                                  }}
                                                  value={
                                                    this.state.companys
                                                      ? this.state.companys
                                                        .bodMemberCount
                                                      : ""
                                                  }
                                                />
                                              </div>

                                              <div className="mb-3">
                                                <AvField
                                                  name="address"
                                                  label="Хаяг"
                                                  type="textarea"
                                                  errorMessage="Хаяг оруулна уу"
                                                  rows="3"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  value={
                                                    this.state.companys
                                                      ? this.state.companys
                                                        .address
                                                      : ""
                                                  }
                                                />
                                              </div>
                                            </Col>
                                          ) : (
                                            <>
                                              <Col lg="6">
                                                <div className="mb-3">
                                                  <AvField
                                                    name="lastname"
                                                    label="Овог"
                                                    type="text"
                                                    errorMessage="Овог оруулна уу"
                                                    validate={{
                                                      required: { value: true },
                                                    }}
                                                  />
                                                </div>
                                              </Col>
                                              <Col lg="6">
                                                <div className="mb-3">
                                                  <AvField
                                                    name="firstname"
                                                    label="Нэр"
                                                    type="text"
                                                    errorMessage="Нэр оруулна уу"
                                                    validate={{
                                                      required: { value: true },
                                                    }}
                                                  />
                                                </div>
                                              </Col>
                                              <Col lg="6">
                                                <div className="mb-3">
                                                  <AvField
                                                    name="register"
                                                    label="Регистр"
                                                    type="text"
                                                    errorMessage="Регистр оруулна уу"
                                                    validate={{
                                                      required: { value: true },
                                                      pattern: {
                                                        value:
                                                          "^[А-Яа-я]{2}[0-9]{8}$",
                                                        errorMessage:
                                                          "Регистрын формат буруу байна. Жишээ: АА12345678",
                                                      },
                                                    }}
                                                  />
                                                </div>
                                              </Col>
                                              <Col lg="6">
                                                <div className="mb-3">
                                                  <AvField
                                                    name="position"
                                                    label="Албан тушаал"
                                                    type="text"
                                                    errorMessage="Албан тушаал оруулна уу"
                                                    validate={{
                                                      required: { value: true },
                                                    }}
                                                  />
                                                </div>
                                              </Col>
                                              <Col lg="6">
                                                <div className="mb-3">
                                                  <AvField
                                                    name="phone"
                                                    label="Утас"
                                                    type="text"
                                                    errorMessage="Утас оруулна уу"
                                                    validate={{
                                                      required: { value: true },
                                                      pattern: {
                                                        value: "^[0-9]{6,8}$",
                                                        errorMessage:
                                                          "6-8 оронгийн урттай зөвхөн тоон утга авна.",
                                                      },
                                                    }}
                                                  />
                                                </div>
                                              </Col>
                                              <Col lg="6">
                                                <div className="mb-3">
                                                  <AvField
                                                    name="email"
                                                    label="Имэйл"
                                                    type="email"
                                                    errorMessage="Имэйл оруулна уу"
                                                    onChange={
                                                      this.handleEmailChange
                                                    }
                                                    autocomplete="off"
                                                    validate={{
                                                      required: { value: true },
                                                      email: { value: true },
                                                    }}
                                                  />
                                                </div>
                                              </Col>
                                              <Col lg="12">
                                                <span className="text-info">
                                                  Нууц үг нь 8-20 урттай багадаа
                                                  нэг тоо, нэг үсэг, нэг тэмдэгт
                                                  (@$!%*#?&) агуулсан байна.
                                                </span>
                                              </Col>
                                              <Col lg="6">
                                                <div className="mb-3">
                                                  <AvField
                                                    name="password"
                                                    label="Нууц үг"
                                                    type="password"
                                                    errorMessage="Нууц үг оруулна уу"
                                                    validate={{
                                                      required: { value: true },
                                                      pattern: {
                                                        value:
                                                          "^(?=.*[A-Za-z])(?=.*[0-9])(?=.*[@$!%*#?&])[A-Za-z0-9@$!%*#?&]{8,20}$",
                                                        errorMessage:
                                                          "Нууц үг нь 8-20 урттай багадаа нэг тоо, нэг үсэг, нэг тэмдэгт (@$!%*#?&) агуулсан байна.",
                                                      },
                                                    }}
                                                  />
                                                </div>
                                              </Col>
                                              <Col lg="6">
                                                <div className="mb-3">
                                                  <AvField
                                                    name="passwordConfirm"
                                                    label="Нууц үг давтах"
                                                    type="password"
                                                    errorMessage="Нууц үг давтан оруулна уу"
                                                    validate={{
                                                      required: { value: true },
                                                      match: {
                                                        value: "password",
                                                        errorMessage:
                                                          "Нууц үг таарахгүй байна.",
                                                      },
                                                      pattern: {
                                                        value:
                                                          "^(?=.*[A-Za-z])(?=.*[0-9])(?=.*[@$!%*#?&])[A-Za-z0-9@$!%*#?&]{8,20}$",
                                                        errorMessage:
                                                          "Нууц үг нь 8-20 урттай багадаа нэг тоо, нэг үсэг, нэг тэмдэгт (@$!%*#?&) агуулсан байна.",
                                                      },
                                                    }}
                                                  />
                                                </div>
                                              </Col>
                                            </>
                                          )}
                                        </Row>
                                        <Row>
                                          <Col>
                                            <div className="text-end">
                                              <button
                                                type="submit"
                                                className="btn btn-success save-company"
                                              >
                                                Хадгалах
                                              </button>
                                            </div>
                                          </Col>
                                        </Row>
                                      </AvForm>
                                    </ModalBody>
                                  </Modal>
                                </div>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

AdminCompany.propTypes = {
  companys: PropTypes.array,
  page: PropTypes.number,
  totalPage: PropTypes.number,
  error: PropTypes.any,
  history: PropTypes.object,
  onGetCompanys: PropTypes.func,
  // onAddNewCompany: PropTypes.func,
  onDeleteCompany: PropTypes.func,
  onUpdateCompany: PropTypes.func,
  onAddCompanyUser: PropTypes.func,
}

// const mapStateToProps = ({ ecommerce }) => ({
//   companys: ecommerce.companys,
// })
const mapStateToProps = state => {
  return {
    companys: state.AdminCompanys.companys,
    page: state.AdminCompanys.page,
    totalPage: state.AdminCompanys.totalPage,
    error: state.AdminCompanys.error,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetCompanys: (token, page, totalPage, history) =>
    dispatch(getCompanys(token, page, totalPage, history)),
  onAddNewCompany: company => dispatch(addNewCompany(company)),
  onUpdateCompany: company => dispatch(updateCompany(company)),
  onDeleteCompany: company => dispatch(deleteCompany(company)),
  onAddCompanyUser: user => dispatch(addCompanyUser(user)),
  onSearchEmail: (token, email, history) =>
    dispatch(searchEmail(token, email, history)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminCompany)
