import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import { connect } from "react-redux"
import { isEmpty, size } from "lodash"
import { Card, CardBody, Col, Row, Badge } from "reactstrap"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"

class CompanyMeetingCustomer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      customers: [],
      selectedCustomer: [],
      token: "",
      isAccredit: false,
      CompanyCustomerColumns: [
        {
          text: "Id",
          dataField: "id",
          sort: true,
          hidden: true,
          formatter: (cellContent, row) => <>{row.id}</>,
        },
        {
          dataField: "firstname",
          text: "Овог / Нэр",
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <p className="mb-1">{row.firstname}</p>
              <p className="mb-0">{row.lastname}</p>
            </>
          ),
        },
        {
          text: "Утас / И-Мэйл",
          dataField: "phone",
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <p className="mb-1">{row.phone}</p>
              <p className="mb-0">{row.email}</p>
            </>
          ),
        },
        {
          dataField: "register",
          text: "Регистр",
          sort: true,
        },
        {
          dataField: "quantity",
          text: "Саналын эрх",
          sort: true,
        },
        {
          dataField: "isAccredit",
          text: "Итгэмжлэлтэй эсэх",
          sort: true,
          formatter: (cellContent, row) => (
            <>
              {row.isAccredit ? (
                <Badge color="success" className="bg-success font-size-12">
                  Тийм
                </Badge>
              ) : (
                <Badge color="danger" className="bg-danger font-size-12">
                  Үгүй
                </Badge>
              )}
            </>
          ),
        },
      ],
    }
  }

  render() {
    const { customers } = this.props
    console.log(customers)
    const pageOptions = {
      sizePerPage: 10,
      totalSize: this.props.totalPage, // replace later with size(companys),
      custom: true,
    }

    const defaultSorted = [
      {
        dataField: "id",
        order: "asc",
      },
    ]
    const { SearchBar } = Search
    return (
      <React.Fragment>
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  keyField="id"
                  columns={this.state.CompanyCustomerColumns}
                  data={customers}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      columns={this.state.CompanyCustomerColumns}
                      data={customers}
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row>
                            <Col sm="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar {...toolkitProps.searchProps} />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <div className="table-responsive">
                            <BootstrapTable
                              keyField={"id"}
                              responsive
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              classes={"table align-middle table-nowrap"}
                              headerWrapperClasses={"thead-light"}
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                            />
                          </div>
                          <div className="pagination pagination-rounded justify-content-end mb-2">
                            <PaginationListStandalone {...paginationProps} />
                          </div>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}
CompanyMeetingCustomer.propTypes = {
  customers: PropTypes.array,
  meetingId: PropTypes.string,
  page: PropTypes.number,
  totalPage: PropTypes.number,
  error: PropTypes.any,
  history: PropTypes.object,
}
export default CompanyMeetingCustomer
