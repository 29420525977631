import React, { Component } from "react"
import PropTypes from "prop-types"
import { map } from "lodash"

import { Table, Card, CardBody, CardFooter, CardHeader } from "reactstrap"
import "./style.css"
import QRCode from "react-qr-code"
import AuditChoiseResult from "./audit-choise-result"
import AuditChoiseResultCustomer from "./audit-choise-result-customer"
export class ComponentToPrint extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    const { meeting, results } = this.props
    let date = new Date()
    let dateStr =
      ("00" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      ("00" + date.getDate()).slice(-2) +
      "/" +
      date.getFullYear() +
      " " +
      ("00" + date.getHours()).slice(-2) +
      ":" +
      ("00" + date.getMinutes()).slice(-2) +
      ":" +
      ("00" + date.getSeconds()).slice(-2)

    return (
      <div>
        <div className={"pdfContainer"}>
          <CardHeader>
            <div className={"header"}>
              <p>
                Хэрэглэгч: {results.username}
                <br /> Цахим хурлын дугаар:{results.meetingId}
              </p>
              <p>Үр дүнг татсан хугацаа: {dateStr} </p>
            </div>
            <h1 className={"title"}>ЦАХИМ ХУРЛЫН ҮР ДҮН</h1>
            <div className={"subtitle"}>
              <div className={"subtitle-title"}>
                <h2>
                  Цахим хурал зохион байгуулагч:
                  <span>{results.companyName}</span>
                </h2>
                <h2>
                  Цахим хурлын нэр: <span>{results.meeetingName}</span>
                </h2>
                <h2>
                  Хурал эхэлсэн хугацаа: <span>{results.meetingStartTime}</span>
                </h2>
                <h2>
                  Хурал дууссан хугацаа:{" "}
                  <span>{results.meetingFinishTime}</span>
                </h2>
                <h2>
                  Урьдчилсан санал авсан хугацаа:{" "}
                  <span>{`${results.advanceVoteStartTime}-${results.advanceVoteFinishTime}`}</span>
                </h2>
                <h2>
                  Хуралд оролцох боломжит гишүүд:{" "}
                  <span>{results.meetingAviableCustomerCount}</span>
                </h2>
                <h2>
                  Нийт оролцсон гишүүдийн тоо:{" "}
                  <span>{results.meetedCustomerCount}</span>
                </h2>
              </div>
            </div>
          </CardHeader>
        </div>
        <CardBody>
          <div className={"pdfContainer"}>
            <h1 className={"title margin"}>Хурлын ирц</h1>
            <Table>
              <thead>
                <tr>
                  <th>Д/д</th>
                  <th>Овог</th>
                  <th>Нэр</th>
                  <th>Шууд оролцсон</th>
                  <th>Урьдчилсан санал илгээсэн</th>
                </tr>
              </thead>
              <tbody>
                {results.meetedCustomers
                  ? map(results.meetedCustomers, (customer, index) => (
                      <tr key={"attendance" + index + customer.id}>
                        <td>{index + 1}</td>
                        <td>{customer.lastname}</td>
                        <td>{customer.firstname}</td>
                        <td>
                          <div className="text-center">
                            {customer.isMeeting ? "+" : ""}
                          </div>
                        </td>
                        <td>
                          {customer.isAdvance ? customer.advanceDate : ""}
                        </td>
                      </tr>
                    ))
                  : ""}
              </tbody>
            </Table>
          </div>

          <div className={"pdfContainer"}>
            <h1 className={"title margin"}>ТООЛЛОГЫН КОМИССЫН ДҮГНЭЛТ</h1>
            <Table>
              <thead>
                <tr>
                  <th>Д/д</th>
                  <th>Хэлэлцсэн асуудал</th>
                  <th scope="col">Төрөл</th>
                  <th scope="col">Саналын төрөл</th>
                  <th>Зөвшөөрсөн</th>
                  <th>Татгалзсан</th>
                  <th>Түдгэлзсэн</th>
                </tr>
              </thead>
              <tbody>
                {results.voteResultDto
                  ? map(results.voteResultDto, (agenda, index) => (
                      <tr key={"audit" + index + agenda.displayIndex}>
                        <td>{agenda.displayIndex}</td>
                        <td>
                          <div className="p text-muted ">{agenda.content}</div>
                        </td>
                        <td>{agenda.agendaType}</td>
                        <td>{agenda.voteType}</td>
                        {agenda.agendaTypeId == 1 ? (
                          <>
                            <td
                              key={
                                "agenda-totalAccept" +
                                index +
                                agenda.displayIndex
                              }
                            >
                              <div className="form-check font-size-10">
                                <label className="form-check-label">
                                  {agenda.totalAccept}
                                </label>
                              </div>
                            </td>
                            <td
                              key={
                                "agenda-totalSuspend" +
                                index +
                                agenda.displayIndex
                              }
                            >
                              <div className="form-check font-size-10">
                                <label className="form-check-label">
                                  {agenda.totalSuspend}
                                </label>
                              </div>
                            </td>
                            <td
                              key={
                                "agenda-totalReject" +
                                index +
                                agenda.displayIndex
                              }
                            >
                              <div className="form-check font-size-10">
                                <label className="form-check-label">
                                  {agenda.totalReject}
                                </label>
                              </div>
                            </td>
                          </>
                        ) : (
                          <>
                            <AuditChoiseResult
                              choiseList={agenda.choiseList}
                              key={"choiseList" + agenda.displayIndex}
                            />
                          </>
                        )}
                      </tr>
                    ))
                  : ""}
              </tbody>
            </Table>
          </div>
          <div className={"pdfContainer pdfLastTable"}>
            <div className="mt-4" key={"dtl_vote"}>
              <div className="margin">
                <h5 className="title">САНАЛ ХУРААЛТЫН ДЭЛГЭРЭНГҮЙ ҮР ДҮН</h5>
              </div>
              <hr className="mt-2" />

              <div className="">
                <Table
                  className=" project-list-table align-middle"
                  key={"dtl_vote_table"}
                >
                  <thead>
                    <tr>
                      <th scope="col" rowSpan="2">
                        #
                      </th>
                      <th scope="col" rowSpan="2">
                        Овог
                      </th>
                      <th scope="col" rowSpan="2">
                        Нэр
                      </th>
                      <th
                        scope="col"
                        className="text-center"
                        colSpan={
                          results.voteResultDto
                            ? results.voteResultDto.length
                            : 1
                        }
                      >
                        Хэлэлцэх асуудлын дугаар
                      </th>
                    </tr>
                    <tr>
                      {results.voteResultDto
                        ? map(results.voteResultDto, (agenda, index) => (
                            <th
                              scope="col"
                              key={
                                "customer-column" + index + agenda.displayIndex
                              }
                            >
                              {agenda.displayIndex}
                            </th>
                          ))
                        : ""}
                    </tr>
                  </thead>
                  <tbody>
                    {results.voteResultDto
                      ? map(
                          results.voteResultDto[0].meetedCustomerVote,
                          (customer, cI) => (
                            <tr key={"customer" + cI + customer.id}>
                              <td>{cI + 1}</td>
                              <td>{customer.lastname}</td>
                              <td>{customer.firstname}</td>
                              {map(results.voteResultDto, (agendaCl, aI) =>
                                agendaCl.agendaTypeId == 1 ? (
                                  <td key={"customer-row" + aI}>
                                    {customer.cusAccept
                                      ? "+"
                                      : customer.cusReject
                                      ? "x"
                                      : customer.cusSuspend
                                      ? "-"
                                      : ""}
                                    {agendaCl.meetedCustomerVote[cI]
                                      ? agendaCl.meetedCustomerVote[cI]
                                          .cusAccept
                                        ? "+"
                                        : agendaCl.meetedCustomerVote[cI]
                                            .cusReject
                                        ? "x"
                                        : agendaCl.meetedCustomerVote[cI]
                                            .cusSuspend
                                        ? "-"
                                        : ""
                                      : ""}
                                  </td>
                                ) : agendaCl.agendaTypeId != 2 ? (
                                  <AuditChoiseResultCustomer
                                    choiseList={
                                      agendaCl.meetedCustomerVote[cI]
                                        .CustomerChoiseList
                                        ? agendaCl.meetedCustomerVote[cI]
                                            .CustomerChoiseList
                                        : []
                                    }
                                    key={
                                      "choiseList_column" +
                                      agendaCl.displayIndex
                                    }
                                  />
                                ) : (
                                  ""
                                )
                              )}
                            </tr>
                          )
                        )
                      : ""}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </CardBody>
        <CardFooter color="success">
          <div className="pdfFooter">
            <QRCode
              value="http://ehural.mn/login"
              fgColor="#b4b4b4"
              level="Q"
              size="80"
            />
            <p>
              Дээрх үр дүн нь Ehural.mn цахим хурлын системээс татсан үр дүн
              болно. Энэхүү мэдээллийн үнэн зөв эсэхийг https://ehural.mn
              холбоосоор эрх бүхий этгээд нэвтрэн орж шалгах боломжтой. Дижитал
              Метод ХХК Холбоо барих: 7744-7575
            </p>
          </div>
        </CardFooter>
      </div>
    )
  }
}

ComponentToPrint.propTypes = {
  meeting: PropTypes.object,
  results: PropTypes.object,
}
