import React, { Component } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
  Col,
  Container,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
} from "reactstrap"
import { Link, withRouter } from "react-router-dom"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import Cards
import CardProject from "./card-project"

import { getMeetings } from "store/actions"

class CustomerMeeting extends Component {
  constructor(props) {
    super(props)
    this.state = {
      token: "",
    }
  }

  componentDidMount() {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      this.props.onGetProjects(
        obj.token,
        this.props.page,
        this.props.totalPage,
        this.props.history
      )
    }
  }
  handlePageClick = page => {
    this.props({ page })
  }

  render() {
    const { meetings, page, totalPage } = this.props
    console.log(meetings)
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Хурлын жагсаалт | Цахим хурал</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Хурал" breadcrumbItem="Хурлын жагсаалт" />

            <Row>
              {/* Import Cards */}
              <CardProject projects={meetings} key={"cardproject"} />
            </Row>

            {/* <Row>
              <Col xs="12">
                <div className="text-center my-3">
                  <Link to="#" className="text-success">
                    <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                    Дараах
                  </Link>
                </div>
              </Col>
            </Row> */}
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

CustomerMeeting.propTypes = {
  meetings: PropTypes.array,
  onGetProjects: PropTypes.func,
  page: PropTypes.number,
  totalPage: PropTypes.number,
  history: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    meetings: state.CustomerMeetings.meetings,
    page: state.CustomerMeetings.page,
    totalPage: state.CustomerMeetings.totalPage,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetProjects: (token, page, totalPage, history) =>
    dispatch(getMeetings(token, page, totalPage, history)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CustomerMeeting))
