import {
  GET_SHARES,
  GET_SHARES_FAIL,
  GET_SHARES_SUCCESS,
  ADD_NEW_SHARES,
  ADD_SHARES_SUCCESS,
  ADD_SHARES_FAIL,
  UPDATE_SHARES,
  UPDATE_SHARES_SUCCESS,
  UPDATE_SHARES_FAIL,
  DELETE_SHARES,
  DELETE_SHARES_SUCCESS,
  DELETE_SHARES_FAIL,
  GET_SHARES_DTL,
  GET_SHARES_DTL_SUCCESS,
  GET_SHARES_DTL_FAIL,
  GET_SHARESMEET_DTL,
  GET_SHARESMEET_DTL_SUCCESS,
  GET_SHARESMEET_DTL_FAIL,
} from "./actionTypes"

export const getCompanyShares = (token, page, totalPage, history) => ({
  type: GET_SHARES,
  payload: { request: { token, page, totalPage }, history },
})

export const getSharesSuccess = response => ({
  type: GET_SHARES_SUCCESS,
  shares: response.data,
  totalPage: response.recordsTotal,
})

export const getSharesFail = error => ({
  type: GET_SHARES_FAIL,
  payload: error,
})

export const addNewShares = customer => ({
  type: ADD_NEW_SHARES,
  paylaod: customer,
})

export const addSharesSuccess = customer => ({
  type: ADD_SHARES_SUCCESS,
  payload: customer,
})

export const addSharesFail = error => ({
  type: ADD_SHARES_FAIL,
  payload: error,
})

export const updateShares = customer => ({
  type: UPDATE_SHARES,
  payload: customer,
})

export const updateSharesSuccess = customer => ({
  type: UPDATE_SHARES_SUCCESS,
  payload: customer,
})

export const updateSharesFail = error => ({
  type: UPDATE_SHARES_FAIL,
  payload: error,
})

export const deleteShares = customer => ({
  type: DELETE_SHARES,
  payload: customer,
})

export const deleteSharesSuccess = customer => ({
  type: DELETE_SHARES_SUCCESS,
  payload: customer,
})

export const deleteSharesFail = error => ({
  type: DELETE_SHARES_FAIL,
  payload: error,
})

/*--------------------------------------------------*/
export const getSharesDtl = (token, customerId, history) => ({
  type: GET_SHARES_DTL,
  payload: { request: { token, customerId }, history },
})

export const getSharesDtlSuccess = response => ({
  type: GET_SHARES_DTL_SUCCESS,
  sharesdtl: response,
})

export const getSharesDtlFail = error => ({
  type: GET_SHARES_DTL_FAIL,
  payload: error,
})

/*--------------------------------------------------*/
export const getSharesMeetDtl = (token, customerId) => ({
  type: GET_SHARESMEET_DTL,
  payload: { token, customerId },
})

export const getSharesMeetDtlSuccess = response => ({
  type: GET_SHARESMEET_DTL_SUCCESS,
  sharesmeetdtl: response,
})

export const getSharesMeetDtlFail = error => ({
  type: GET_SHARESMEET_DTL_FAIL,
  payload: error,
})
