import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import {
    GET_CHARTS_DATA,
    SAVE_MARK_NTF
} from "./actionTypes";
import {
    apiSuccess, apiFail,
    saveMarkNtfSuccess, saveMarkNtfFail
} from "./actions";

//Include Both Helper File with needed methods
import {
    isUserAuthenticated,
    postJson
}
    from "../../helpers/fakebackend_helper";

function* getChartsData({ payload: { token, role, history } }) {
    if (isUserAuthenticated()) {
        try {
            var url;
            if (role == "CUSTOMER") {
                url = "/Customer/CustomerWebService/get_customer_dashboard"
            } else {
                url = "/Admin/CompanyWebService/get_dashboard"
            }

            const response = yield call(
                postJson, {
                token: token
            }, url
            )


            if (response.responseResultType === "SUCCESS") {
                yield put(apiSuccess(GET_CHARTS_DATA, response));
            } else {
                if (response.failureMessages.message[0].failureCode === "ERR_009") {
                    history.push("/logout")
                } else {
                    yield put(apiFail(response.failureMessages.message[0].failureMessage))
                }
            }
        } catch (error) {
            yield put(apiFail(GET_CHARTS_DATA, error));
        }
    }
}

function* fetchSaveMarkNtfVote({ payload: form }) {
    if (isUserAuthenticated()) {
        try {
            const response = yield call(
                postJson, form,
                "/Customer/CustomerWebService/agree_notification"
            )
            if (response.responseResultType === "SUCCESS") {
                yield put(saveMarkNtfSuccess(response))
            } else {
                if (response.failureMessages.message[0].failureCode === "ERR_009") {
                    // history.push("/logout")
                }
                yield put(
                    saveMarkNtfFail(response.failureMessages.message[0].failureMessage)
                )
            }
        } catch (error) {
            yield put(saveMarkNtfFail(error))
        }
    } else history.push("/logout")
}


export function* watchGetChartsData() {
    yield takeEvery(GET_CHARTS_DATA, getChartsData);

}

function* dashboardSaga() {
    yield all([fork(watchGetChartsData)]);
    yield takeEvery(SAVE_MARK_NTF, fetchSaveMarkNtfVote)
}

export default dashboardSaga;
