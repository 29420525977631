/* CUSTOMER MEETING */
export const GET_MEETINGS = "GET_MEETINGS"
export const GET_MEETINGS_SUCCESS = "GET_MEETINGS_SUCCESS"
export const GET_MEETINGS_FAIL = "GET_MEETINGS_FAIL"


export const GET_MEETING_DTL = "GET_MEETING_DTL"
export const GET_MEETING_DTL_SUCCESS = "GET_MEETING_DTL_SUCCESS"
export const GET_MEETING_DTL_FAIL = "GET_MEETING_DTL_FAIL"

export const GET_MEETING_AGENDA = "GET_MEETING_AGENDA"
export const GET_MEETING_AGENDA_SUCCESS = "GET_MEETING_AGENDA_SUCCESS"
export const GET_MEETING_AGENDA_FAIL = "GET_MEETING_AGENDA_FAIL"

export const GET_MEETING_DOCUMENT = "GET_MEETING_DOCUMENT"
export const GET_MEETING_DOCUMENT_SUCCESS = "GET_MEETING_DOCUMENT_SUCCESS"
export const GET_MEETING_DOCUMENT_FAIL = "GET_MEETING_DOCUMENT_FAIL"

export const GET_MEETING_RESULT = "GET_MEETING_RESULT"
export const GET_MEETING_RESULT_SUCCESS = "GET_MEETING_RESULT_SUCCESS"
export const GET_MEETING_RESULT_FAIL = "GET_MEETING_RESULT_FAIL"

export const START_VOTE = "START_VOTE"
export const START_VOTE_SUCCESS = "START_VOTE_SUCCESS"
export const START_VOTE_FAIL = "START_VOTE_FAIL"

export const SAVE_VOTE = "SAVE_VOTE"
export const SAVE_VOTE_SUCCESS = "SAVE_VOTE_SUCCESS"
export const SAVE_VOTE_FAIL = "SAVE_VOTE_FAIL"

export const SET_ATTENDANCE = "SET_ATTENDANCE"
export const SET_ATTENDANCE_SUCCESS = "SET_ATTENDANCE_SUCCESS"
export const SET_ATTENDANCE_FAIL = "SET_ATTENDANCE_FAIL"

