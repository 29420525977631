import {
  GET_MEETINGS,
  GET_MEETINGS_FAIL,
  GET_MEETINGS_SUCCESS,
  GET_MEETING_DTL,
  GET_MEETING_DTL_FAIL,
  GET_MEETING_DTL_SUCCESS,
  GET_MEETING_AGENDA,
  GET_MEETING_AGENDA_SUCCESS,
  GET_MEETING_AGENDA_FAIL,
  GET_MEETING_DOCUMENT,
  GET_MEETING_DOCUMENT_SUCCESS,
  GET_MEETING_DOCUMENT_FAIL,
  GET_MEETING_RESULT,
  GET_MEETING_RESULT_SUCCESS,
  GET_MEETING_RESULT_FAIL,
  START_VOTE,
  START_VOTE_SUCCESS,
  START_VOTE_FAIL,
  SAVE_VOTE,
  SAVE_VOTE_SUCCESS,
  SAVE_VOTE_FAIL,
  SET_ATTENDANCE,
  SET_ATTENDANCE_SUCCESS,
  SET_ATTENDANCE_FAIL,
} from "./actionTypes"

export const getMeetings = (token, page, totalPage, history) => ({
  type: GET_MEETINGS,
  payload: { request: { token, page, totalPage }, history },
})

export const getMeetingsSuccess = response => ({
  type: GET_MEETINGS_SUCCESS,
  meetings: response.data,
  totalPage: response.recordsTotal,
})

export const getMeetingsFail = error => ({
  type: GET_MEETINGS_FAIL,
  payload: error,
})
/*--------------------------------------------------*/
export const getMeetingDtl = (token, meetingId, history) => ({
  type: GET_MEETING_DTL,
  payload: { request: { token, meetingId }, history },
})

export const getMeetingDtlSuccess = response => ({
  type: GET_MEETING_DTL_SUCCESS,
  meeting: response,
})

export const getMeetingDtlFail = error => ({
  type: GET_MEETING_DTL_FAIL,
  payload: error,
})
/*--------------------------------------------------*/
export const getMeetingAgenda = (token, meetingId) => ({
  type: GET_MEETING_AGENDA,
  payload: { token, meetingId },
})

export const getMeetingAgendaSuccess = response => ({
  type: GET_MEETING_AGENDA_SUCCESS,
  agenda: response,
})

export const getMeetingAgendaFail = error => ({
  type: GET_MEETING_AGENDA_FAIL,
  payload: error,
})
/*--------------------------------------------------*/
export const getMeetingDocument = (token, meetingId) => ({
  type: GET_MEETING_DOCUMENT,
  payload: { token, meetingId },
})

export const getMeetingDocumentSuccess = response => ({
  type: GET_MEETING_DOCUMENT_SUCCESS,
  document: response,
})

export const getMeetingDocumentFail = error => ({
  type: GET_MEETING_DOCUMENT_FAIL,
  payload: error,
})

/*--------------------------------------------------*/
export const getMeetingResult = (token, meetingId) => ({
  type: GET_MEETING_RESULT,
  payload: { token, meetingId },
})

export const getMeetingResultSuccess = response => ({
  type: GET_MEETING_RESULT_SUCCESS,
  result: response,
})

export const getMeetingResultFail = error => ({
  type: GET_MEETING_RESULT_FAIL,
  payload: error,
})
/*--------------------------------------------------*/
export const startVote = (token, agendaId) => ({
  type: START_VOTE,
  payload: { token, agendaId },
})
export const startVoteSuccess = response => ({
  type: START_VOTE_SUCCESS,
  votes: response,
})

export const startVoteFail = error => ({
  type: START_VOTE_FAIL,
  payload: error,
})

/*--------------------------------------------------*/

export const saveVote = vote => ({
  type: SAVE_VOTE,
  payload: vote,
})

export const saveVoteSuccess = response => ({
  type: SAVE_VOTE_SUCCESS,
  votes: response,
})

export const saveVoteFail = error => ({
  type: SAVE_VOTE_FAIL,
  payload: error,
})

/*--------------------------------------------------*/
export const setAttendance = (token, meetingId) => ({
  type: SET_ATTENDANCE,
  payload: { token, meetingId },
})

export const setAttendanceSuccess = response => ({
  type: SET_ATTENDANCE_SUCCESS,
  votes: response,
})

export const setAttendanceFail = error => ({
  type: SET_ATTENDANCE_FAIL,
  payload: error,
})

/*--------------------------------------------------*/
