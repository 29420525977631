/*================================================MEETS====================================================*/
/* MEETS */
export const GET_COM_MEETINGS = "GET_COM_MEETINGS"
export const GET_COM_MEETINGS_SUCCESS = "GET_COM_MEETINGS_SUCCESS"
export const GET_COM_MEETINGS_FAIL = "GET_COM_MEETINGS_FAIL"

/* AddMeeting */
export const ADD_COM_NEW_MEETING = "ADD_NEW_COM_MEETING"
export const ADD_COM_MEETING_SUCCESS = "ADD_COM_MEETING_SUCCESS"
export const ADD_COM_MEETING_FAIL = "ADD_COM_MEETING_FAIL"
/* UpdateMeeting */
export const UPDATE_COM_MEETING = "UPDATE_COM_MEETING"
export const UPDATE_COM_MEETING_SUCCESS = "UPDATE_COM_MEETING_SUCCESS"
export const UPDATE_COM_MEETING_FAIL = "UPDATE_COM_MEETING_FAIL"
/* DeleteMeeting */
export const DELETE_COM_MEETING = "DELETE_COM_MEETING"
export const DELETE_COM_MEETING_SUCCESS = "DELETE_COM_MEETING_SUCCESS"
export const DELETE_COM_MEETING_FAIL = "DELETE_COM_MEETING_FAIL"
/* MeetingType */
export const GET_COM_MEETING_TYPE = "GET_COM_MEETING_TYPE"
export const GET_COM_MEETING_TYPE_SUCCESS = "GET_COM_MEETING_TYPE_SUCCESS "
export const GET_COM_MEETING_TYPE_FAIL = "GET_COM_MEETING_TYPE_FAIL"
/* Company Meeting Dtl */
export const GET_COM_MEETING_DTL = "GET_COM_MEETING_DTL"
export const GET_COM_MEETING_DTL_SUCCESS = "GET_COM_MEETING_DTL_SUCCESS"
export const GET_COM_MEETING_DTL_FAIL = "GET_COM_MEETING_DTL_FAIL"
/*===============================================AGENDA	=====================================================*/
/* AGENDA		 */
export const GET_COM_MEETING_AGENDA = "GET_COM_MEETING_AGENDA"
export const GET_COM_MEETING_AGENDA_SUCCESS = "GET_COM_MEETING_AGENDA_SUCCESS"
export const GET_COM_MEETING_AGENDA_FAIL = "GET_COM_MEETING_AGENDA_FAIL"
/* AddAgenda */
export const ADD_COM_NEW_AGENDA = "ADD_NEW_COM_AGENDA"
export const ADD_COM_AGENDA_SUCCESS = "ADD_COM_AGENDA_SUCCESS"
export const ADD_COM_AGENDA_FAIL = "ADD_COM_AGENDA_FAIL"
/* UpdateAgenda */
export const UPDATE_COM_AGENDA = "UPDATE_COM_AGENDA"
export const UPDATE_COM_AGENDA_SUCCESS = "UPDATE_COM_AGENDA_SUCCESS"
export const UPDATE_COM_AGENDA_FAIL = "UPDATE_COM_AGENDA_FAIL"
/* DeleteAgenda */
export const DELETE_COM_AGENDA = "DELETE_COM_AGENDA"
export const DELETE_COM_AGENDA_SUCCESS = "DELETE_COM_AGENDA_SUCCESS"
export const DELETE_COM_AGENDA_FAIL = "DELETE_COM_AGENDA_FAIL"
/* AgendaType	 */
export const GET_COM_AGENDA_TYPE = "GET_COM_AGENDA_TYPE"
export const GET_COM_AGENDA_TYPE_SUCCESS = "GET_COM_AGENDA_TYPE_SUCCESS"
export const GET_COM_AGENDA_TYPE_FAIL = "GET_COM_AGENDA_TYPE_FAIL"
/*============================================== DOCUMENT	======================================================*/
/* DOCUMENT		 */
export const GET_COM_MEETING_DOCUMENT = "GET_COM_MEETING_DOCUMENT"
export const GET_COM_MEETING_DOCUMENT_SUCCESS =
  "GET_COM_MEETING_DOCUMENT_SUCCESS"
export const GET_COM_MEETING_DOCUMENT_FAIL = "GET_COM_MEETING_DOCUMENT_FAIL"
/* DocumentType */
export const GET_COM_MEETING_DOCUMENT_TYPE = "GET_COM_MEETING_DOCUMENT_TYPE"
export const GET_COM_MEETING_DOCUMENT_TYPE_SUCCESS =
  "GET_COM_MEETING_DOCUMENT_TYPE_SUCCESS "
export const GET_COM_MEETING_DOCUMENT_TYPE_FAIL =
  "GET_COM_MEETING_DOCUMENT_TYPE_FAIL"
/*============================================== CUSTOMER=====================================================*/
export const GET_MEETING_CUSTOMER = "GET_MEETING_CUSTOMER"
export const GET_MEETING_CUSTOMER_SUCCESS = "GET_MEETING_CUSTOMER_SUCCESS"
export const GET_MEETING_CUSTOMER_FAIL = "GET_MEETING_CUSTOMER_FAIL"
/*====================================================================================================*/
/* VoteType		 */
export const GET_COM_VOTE_TYPE = "GET_COM_VOTE_TYPE"
export const GET_COM_VOTE_TYPE_SUCCESS = "GET_COM_VOTE_TYPE_SUCCESS"
export const GET_COM_VOTE_TYPE_FAIL = "GET_COM_VOTE_TYPE_FAIL"

/* MethodType		 */
export const GET_COM_METHOD_TYPE = "GET_COM_METHOD_TYPE"
export const GET_COM_METHOD_TYPE_SUCCESS = "GET_COM_METHOD_TYPE_SUCCESS"
export const GET_COM_METHOD_TYPE_FAIL = "GET_COM_METHOD_TYPE_FAIL"

/*Result */
export const GET_COM_MEETING_RESULT = "GET_COM_MEETING_RESULT"
export const GET_COM_MEETING_RESULT_SUCCESS = "GET_COM_MEETING_RESULT_SUCCESS"
export const GET_COM_MEETING_RESULT_FAIL = "GET_COM_MEETING_RESULT_FAIL"

/* Start */
export const SET_COM_MEETING_START = "SET_COM_MEETING_START"
export const SET_COM_MEETING_START_SUCCESS = "SET_COM_MEETING_START_SUCCESS"
export const SET_COM_MEETING_START_FAIL = "SET_COM_MEETING_START_FAIL"
/* Finish */
export const SET_COM_MEETING_FINISH = "SET_COM_MEETING_FINISH"
export const SET_COM_MEETING_FINISH_SUCCESS = "SET_COM_MEETING_FINISH_SUCCESS"
export const SET_COM_MEETING_FINISH_FAIL = "SET_COM_MEETING_FINISH_FAIL"
/* Vote Start */
export const SET_COM_VOTE_START = "SET_COM_VOTE_START"
export const SET_COM_VOTE_START_SUCCESS = "SET_COM_VOTE_START_SUCCESS"
export const SET_COM_VOTE_START_FAIL = "SET_COM_VOTE_START_FAIL"
/* Vote Finish */
export const SET_COM_VOTE_FINISH = "SET_COM_VOTE_FINISH"
export const SET_COM_VOTE_FINISH_SUCCESS = "SET_COM_VOTE_FINISH_SUCCESS"
export const SET_COM_VOTE_FINISH_FAIL = "SET_COM_VOTE_FINISH_FAIL"
