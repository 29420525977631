/* CUSTOMER MEETING */
export const GET_COMPANYS = "GET_COMPANYS"
export const GET_COMPANYS_FAIL = "GET_COMPANYS_FAIL"
export const GET_COMPANYS_SUCCESS = "GET_COMPANYS_SUCCESS"

export const ADD_NEW_COMPANY = "ADD_NEW_COMPANY"
export const ADD_COMPANY_SUCCESS = "ADD_COMPANY_SUCCESS"
export const ADD_COMPANY_FAIL = "ADD_COMPANY_FAIL"

export const UPDATE_COMPANY = "UPDATE_COMPANY"
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS"
export const UPDATE_COMPANY_FAIL = "UPDATE_COMPANY_FAIL"

export const DELETE_COMPANY = "DELETE_COMPANY"
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS"
export const DELETE_COMPANY_FAIL = "DELETE_COMPANY_FAIL"

export const ADD_COMPANY_USER = "ADD_COMPANY_USER"
export const ADD_COMPANY_USER_SUCCESS = "ADD_COMPANY_USER_SUCCESS"
export const ADD_COMPANY_USER_FAIL = "ADD_COMPANY_USER_FAIL"

export const SEARCH_EMAIL = "SEARCH_EMAIL"
export const SEARCH_EMAIL_SUCCESS = "SEARCH_EMAIL_SUCCESS"
export const SEARCH_EMAIL_FAIL = "SEARCH_EMAIL_FAIL"

export const GET_COMPANY_TYPE = "GET_COMPANY_TYPE"
export const GET_COMPANY_TYPE_SUCCESS = "GET_COMPANY_TYPE_SUCCESS"
export const GET_COMPANY_TYPE_FAIL = "GET_COMPANY_TYPE_FAIL"
