import React, { Component } from "react"
import PropTypes from "prop-types"
import { Card, CardBody, CardTitle, Table, Button } from "reactstrap"

class MeetingAttendance extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  clickAttendance = e => {
    this.props.clickAttendance()
  }
  render() {
    return (
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Хурлын ирц</CardTitle>
          <div className="table-responsive">
            <Table className="table align-middle table-nowrap">
              <tbody>
                <tr key="attendance">
                  <td style={{ width: "150px" }}>
                    {this.props.participatingCustomerCount + "/" + this.props.membersCount}
                  </td>
                  <td className="text-end">
                    {this.props.isAttandance && !this.props.isAudit ? (
                      <Button
                        type="button"
                        color="primary"
                        onClick={this.clickAttendance}
                        className="">
                        <i className="mdi mdi-check font-size-18" id="edittooltip" /> Ирц бүртгүүлэх
                      </Button>
                    ) : ""}

                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card >
    )
  }
}

MeetingAttendance.propTypes = {
  membersCount: PropTypes.any,
  participatingCustomerCount: PropTypes.any,
  isAudit: PropTypes.bool,
}

export default MeetingAttendance
