import React, { Component } from "react"
import ReactToPrint from "react-to-print"
import { Card, CardBody, CardFooter, Col, Media, Row, Button } from "reactstrap"
import { ComponentToPrint } from "./pdfData"
import PropTypes from "prop-types"

class Example extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    const { meeting, results } = this.props
    console.log("PRINT render " + meeting)
    console.log("results render " + results)
    return (
      <div>
        <ReactToPrint
          trigger={() => {
            return <Button>Хурлын үр дүн татах</Button>
          }}
          content={() => this.componentRef}
        />
        <div>
          <ComponentToPrint
            ref={el => (this.componentRef = el)}
            meeting={meeting}
            results={results}
          />
        </div>
      </div>
    )
  }
}
Example.propTypes = {
  results: PropTypes.object,
  meeting: PropTypes.object,
}

export default Example
