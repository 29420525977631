import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

//Dashboard
import Dashboard from "./dashboard/reducer"

//Dasboard saas
import CustomerMeetings from "./customer-meeting/reducer"
import CustomerCompanys from "./customer-company/reducer"
import CompanyMemberRed from "./company-member/reducer"
import AdminCompanys from "./admin-company/reducer"
import AdminAudits from "./admin-audit/reducer"
import CompanyShareRed from "./company-share/reducer"
import CompanyMeet from "./company-meeting/reducer"
import AuditCompany from "./audit-company/reducer"
import AuditMeeting from "./audit-meeting/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  Dashboard,
  CustomerMeetings,
  CustomerCompanys,
  CompanyMemberRed,
  AdminCompanys,
  AdminAudits,
  CompanyShareRed,
  CompanyMeet,
  AuditCompany,
  AuditMeeting,
})

export default rootReducer
