import React from "react"
import { Link } from "react-router-dom"
import { map, get } from "lodash"
import {
  Table,
} from "reactstrap"
import PropTypes from "prop-types"


const CompanyMeetingDocument = ({ documents }) => {
  return (
    <React.Fragment>
      <div className="mt-4">
        <div className="table-responsive">
          <Table className="table align-middle table-nowrap table-hover mb-0">
            <thead>
              <tr>
                <th scope="col">Файлын нэр</th>
                <th scope="col">Төрөл</th>
                <th scope="col">Тайлбар</th>
                <th scope="col" colSpan="2">
                  Татах
                </th>
              </tr>
            </thead>
            <tbody>
              {map(documents, (documnet, key) => (
                <tr key={key}>
                  <td>
                    <Link to="#" className="text-dark fw-medium">
                      <i className={documnet.icon}></i> {documnet.fileName}
                    </Link>
                  </td>
                  <td>{documnet.documentType}</td>
                  <td>{documnet.description}</td>
                  <td>
                    <div className="d-flex gap-3">
                      <a target="_blank" href={"http://103.48.116.95:8081/api/download/" + documnet.filePath} className="text-primary">
                        <i className="mdi mdi-download font-size-18" id="edittooltip" /> Татах
                      </a>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </React.Fragment>
  )
}
CompanyMeetingDocument.propTypes = {
  documents: PropTypes.array,
}

export default CompanyMeetingDocument