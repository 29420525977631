import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_CUSTOMER_COMPANYS,
  GET_CUSTOMER_COMPANY_DTL,
  GET_CUSTOMER_COMPANY_AGENDA,
  GET_CUSTOMER_COMPANY_DOCUMENT,
} from "./actionTypes"
import {
  getCustomerCompanysSuccess,
  getCustomerCompanysFail,
  getCustomerCompanyDtlSuccess,
  getCustomerCompanyDtlFail
} from "./actions"

//Include Both Helper File with needed methods
import { postJson } from "helpers/fakebackend_helper"

function* fetchCompanys({ payload: { request, history } }) {
  try {
    const response = yield call(
      postJson,
      {
        token: request.token,
        start: request.page,
        length: 9,
      },
      "/Customer/CustomerWebService/get_customer_company"
    )
    if (response.responseResultType === "SUCCESS") {
      yield put(getCustomerCompanysSuccess(response))
    } else {
      if (response.failureMessages.message[0].failureCode === "ERR_009") {
        history.push("/logout")
      }
      yield put(
        getCustomerCompanysFail(response.failureMessages.message[0].failureMessage)
      )
    }
  } catch (error) {
    yield put(getCustomerCompanysFail(error))
  }
}
function* fetchCompanyDetail({ payload: { request, history } }) {
  try {
    const response = yield call(
      postJson,
      {
        token: request.token,
        meetingId: request.meetingId,
      },
      "/Customer/CustomerWebService/get_meeting_introduction"
    )
    if (response.responseResultType === "SUCCESS") {
      yield put(getCustomerCompanyDtlSuccess(response))
    } else {
      if (response.failureMessages.message[0].failureCode === "ERR_009") {
        history.push("/logout")
      }
      yield put(
        getCustomerCompanysFail(response.failureMessages.message[0].failureMessage)
      )
    }
  } catch (error) {
    yield put(getCustomerCompanyDtlFail(error))
  }
}

function* fetchCompanyAgenda({ payload }) {
  try {
    const response = yield call(
      postJson,
      {
        token: payload.token,
        meetingId: payload.meetingId,
      },
      "/Customer/CustomerWebService/get_meeting_detail_agenda"
    )
    yield put(getCustomerCompanyAgendaSuccess(response.agendaDtos))
  } catch (error) {
    yield put(getCustomerCompanyAgendaFail(error))
  }
}
function* fetchCompanyDocument({ payload }) {
  try {
    const response = yield call(
      postJson,
      {
        token: payload.token,
        meetingId: payload.meetingId,
      },
      "/Customer/CustomerWebService/get_meeting_detail_documents"
    )
    yield put(getCustomerCompanyDocumentSuccess(response.documentDtos))
  } catch (error) {
    yield put(getCustomerCompanyDocumentFail(error))
  }
}

function* customerCompanySaga() {
  yield takeEvery(GET_CUSTOMER_COMPANYS, fetchCompanys)
  yield takeEvery(GET_CUSTOMER_COMPANY_DTL, fetchCompanyDetail)
  yield takeEvery(GET_CUSTOMER_COMPANY_AGENDA, fetchCompanyAgenda)
  yield takeEvery(GET_CUSTOMER_COMPANY_DOCUMENT, fetchCompanyDocument)
}

export default customerCompanySaga
