import {
  GET_COM_MEETINGS_FAIL,
  GET_COM_MEETINGS_SUCCESS,
  ADD_COM_MEETING_SUCCESS,
  ADD_COM_MEETING_FAIL,
  UPDATE_COM_MEETING_SUCCESS,
  UPDATE_COM_MEETING_FAIL,
  DELETE_COM_MEETING_SUCCESS,
  DELETE_COM_MEETING_FAIL,
  GET_COM_MEETING_AGENDA_SUCCESS,
  GET_COM_MEETING_AGENDA_FAIL,
  GET_COM_AGENDA_TYPE_SUCCESS,
  GET_COM_AGENDA_TYPE_FAIL,
  ADD_COM_AGENDA_SUCCESS,
  ADD_COM_AGENDA_FAIL,
  UPDATE_COM_AGENDA_SUCCESS,
  UPDATE_COM_AGENDA_FAIL,
  DELETE_COM_AGENDA_SUCCESS,
  DELETE_COM_AGENDA_FAIL,
  GET_COM_VOTE_TYPE_SUCCESS,
  GET_COM_VOTE_TYPE_FAIL,
  GET_COM_METHOD_TYPE_SUCCESS,
  GET_COM_METHOD_TYPE_FAIL,
  GET_COM_MEETING_TYPE_SUCCESS,
  GET_COM_MEETING_TYPE_FAIL,
  GET_COM_MEETING_DOCUMENT_TYPE_SUCCESS,
  GET_COM_MEETING_DOCUMENT_TYPE_FAIL,
  GET_COM_MEETING_DTL_FAIL,
  GET_COM_MEETING_DTL_SUCCESS,
  GET_COM_MEETING_DOCUMENT_SUCCESS,
  GET_COM_MEETING_DOCUMENT_FAIL,
  GET_COM_MEETING_RESULT_SUCCESS,
  GET_COM_MEETING_RESULT_FAIL,
  SET_COM_MEETING_START_SUCCESS,
  SET_COM_MEETING_START_FAIL,
  SET_COM_VOTE_START_SUCCESS,
  SET_COM_VOTE_START_FAIL,
  SET_COM_VOTE_FINISH_SUCCESS,
  SET_COM_VOTE_FINISH_FAIL,
  SET_COM_MEETING_FINISH_SUCCESS,
  SET_COM_MEETING_FINISH_FAIL,
  GET_MEETING_CUSTOMER_SUCCESS,
  GET_MEETING_CUSTOMER_FAIL,
} from "./actionTypes"
const INIT_STATE = {
  page: 0,
  length: 9,
  totalPage: 0,
  draw: 1,
  commeets: [],
  customers: [],
  agendas: [],
  meetings: [],
  agendaType: [],
  voteType: [],
  methodType: [],
  meetingType: [],
  documentType: [],
  results: {},
  error: {},
}

const CompanyMeet = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* MEETS */
    case GET_COM_MEETINGS_SUCCESS:
      return {
        ...state,
        meetings: action.meetings,
        totalPage: action.totalPage,
      }

    case GET_COM_MEETINGS_FAIL:
      return {
        ...state,
        error: action.error,
      }
    /* AddMeeting */
    case ADD_COM_MEETING_SUCCESS:
      return {
        ...state,
        commeets: [...state.commeets, action.payload],
      }

    case ADD_COM_MEETING_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    /* UpdateMeeting */
    case UPDATE_COM_MEETING_SUCCESS:
      return {
        ...state,
        commeets: state.commeets.map(meeting =>
          meeting.id.toString() === action.payload.id.toString()
            ? { meeting, ...action.payload }
            : meeting
        ),
      }

    case UPDATE_COM_MEETING_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    /* DeleteMeeting */
    case DELETE_COM_MEETING_SUCCESS:
      return {
        ...state,
        commeets: state.commeets.filter(
          meeting => meeting.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_COM_MEETING_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    /*==============MEETS  END====================================================*/
    /* VoteType		 */
    case GET_COM_VOTE_TYPE_SUCCESS:
      return {
        ...state,
        voteType: action.voteType,
      }

    case GET_COM_VOTE_TYPE_FAIL:
      return {
        ...state,
        error: action.error,
      }
    /* MethodType		 */
    case GET_COM_METHOD_TYPE_SUCCESS:
      return {
        ...state,
        methodType: action.methodType,
      }

    case GET_COM_METHOD_TYPE_FAIL:
      return {
        ...state,
        error: action.error,
      }
    /* MeetingType */
    case GET_COM_MEETING_TYPE_SUCCESS:
      return {
        ...state,
        meetingType: action.meetingType,
      }

    case GET_COM_MEETING_TYPE_FAIL:
      return {
        ...state,
        error: action.error,
      }
    /* DocumentType */
    case GET_COM_MEETING_DOCUMENT_TYPE_SUCCESS:
      return {
        ...state,
        documentType: action.documentType,
      }

    case GET_COM_MEETING_DOCUMENT_TYPE_FAIL:
      return {
        ...state,
        error: action.error,
      }
    /* COMPANY MEETING DTL  START*/
    case GET_COM_MEETING_DTL_SUCCESS:
      return {
        ...state,
        meeting: action.meeting,
      }

    case GET_COM_MEETING_DTL_FAIL:
      return {
        ...state,
        error: action.error,
      }
    /* Company Meeting Agenda*/
    case GET_COM_MEETING_AGENDA_SUCCESS:
      return {
        ...state,
        agendas: action.agenda,
      }

    case GET_COM_MEETING_AGENDA_FAIL:
      return {
        ...state,
        error: action.error,
      }
    case GET_COM_AGENDA_TYPE_SUCCESS:
      return {
        ...state,
        agendaType: action.agendaType,
      }

    case GET_COM_AGENDA_TYPE_FAIL:
      return {
        ...state,
        error: action.error,
      }
    case ADD_COM_AGENDA_SUCCESS:
      return {
        ...state,
        agendas: [...state.agendas, action.payload],
      }

    case ADD_COM_AGENDA_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_COM_AGENDA_SUCCESS:
      return {
        ...state,
        agendas: state.agendas.map(agenda =>
          agenda.id.toString() === action.payload.id.toString()
            ? { agenda, ...action.payload }
            : agenda
        ),
      }

    case UPDATE_COM_AGENDA_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_COM_AGENDA_SUCCESS:
      return {
        ...state,
        agendas: state.agendas.filter(
          agenda => agenda.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_COM_AGENDA_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    /*Company Meeting Document*/
    case GET_COM_MEETING_DOCUMENT_SUCCESS:
      return {
        ...state,
        documents: action.document,
      }

    case GET_COM_MEETING_DOCUMENT_FAIL:
      return {
        ...state,
        error: action.error,
      }
    /*Result */
    case GET_COM_MEETING_RESULT_SUCCESS:
      return {
        ...state,
        results: action.result,
      }

    case GET_COM_MEETING_RESULT_FAIL:
      return {
        ...state,
        error: action.error,
      }
    /* Start */
    case SET_COM_MEETING_START_SUCCESS:
      return {
        ...state,
        start: action.start,
      }

    case SET_COM_MEETING_START_FAIL:
      return {
        ...state,
        error: action.error,
      }
    /* Finish */
    case SET_COM_MEETING_FINISH_SUCCESS:
      return {
        ...state,
        start: action.start,
      }

    case SET_COM_MEETING_FINISH_FAIL:
      return {
        ...state,
        error: action.error,
      }

    case SET_COM_VOTE_START_SUCCESS:
      return {
        ...state,
        start: action.start,
      }

    case SET_COM_VOTE_START_FAIL:
      return {
        ...state,
        error: action.error,
      }
    case SET_COM_VOTE_FINISH_SUCCESS:
      return {
        ...state,
        start: action.start,
      }

    case SET_COM_VOTE_FINISH_FAIL:
      return {
        ...state,
        error: action.error,
      }
    /*******COMPANY MEETING DTL END*******/
    /*CUSTOMER*/
    case GET_MEETING_CUSTOMER_SUCCESS:
      return {
        ...state,
        customers: action.customers,
        totalPage: action.totalPage,
      }

    case GET_MEETING_CUSTOMER_FAIL:
      return {
        ...state,
        error: action.error,
      }
    default:
      return state
  }
}
export default CompanyMeet
