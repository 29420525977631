import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
import { isEmpty, map, values } from "lodash"
import {
  Card,
  CardBody,
  Col,
  Container,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import classnames from "classnames"
import { Link } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import MeetingDocument from "./meeting-document"
import MeetingAgenda from "./meeting-agenda"
import MeetingInformation from "./meeting-information"
import MeetingContact from "./meeting-contact"
import MeetingCustomer from "./meeting-customer"
import MeetingAudit from "./meeting-audit"
import MeetingUserDetail from "./meeting-user-detail"

import {
  updateComMeeting,
  getComMeetingDtl,
  getComMeetingAgenda,
  getComMeetingDocument,
} from "store/actions"
class CompanyMeetingUpdate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: 1,
      passedSteps: [1],
      isFinish: false,
      meetingId: "",
      meetingForm: {
        votes: [],
        meetingId: "",
        token: "",
        meetingName: "",
        address: "",
        declareDate: "",
        endTime: "",
        meetingDate: "",
        meetingTypeId: null,
        membersCount: null,
        registrationDate: "",
        startTime: "",
        agendaDtos: [],
        contactDtos: [],
        documentDtos: [],
        customerDtos: [],
        choiseList: [
          {
            choise: "",
          },
        ],
      },
    }
    this.toggleTab.bind(this)
  }
  toggle(tab) {
    this.toggleTab.bind(this)
  }
  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 7) {
        var modifiedSteps = [...this.state.passedSteps, tab]
        this.setState({
          activeTab: tab,
          passedSteps: modifiedSteps,
        })
      }
    }
  }
  componentDidMount() {
    const {
      match: { params },
      onGetMeeting,
      onGetMeetingAgenda,
      onGetMeetingDocument,
    } = this.props
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      if (params && params.id) {
        this.setState({ meetingId: params.id, customerId: params.id })
        onGetMeeting(obj.token, params.id, this.props.history)
        onGetMeetingAgenda(obj.token, params.id, this.props.history)
        onGetMeetingDocument(obj.token, params.id, this.props.history)
        this.setState({ meetingId: params.id })
      } else {
        onGetMeeting(obj.token, 0, this.props.history)
        onGetMeetingAgenda(obj.token, 0, this.props.history)
        onGetMeetingDocument(obj.token, 0, this.props.history)
      }
      this.setState({ token: obj.token })
    }
  }
  showToast(type, message, title) {
    toastr.options = {
      timeOut: 5000,
      closeButton: true,
      progressBar: true,
      newestOnTop: true,
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
      showDuration: 300,
      hideDuration: 1000,
    }
    if (type === "info") toastr.info(message, title)
    else if (type === "warning") toastr.warning(message, title)
    else if (type === "error") toastr.error(message, title)
    else toastr.success(message, title)
  }
  updateFormData = values => {
    this.setState({
      meetingForm: {
        ...values,
        meetingId: this.state.meetingId,
      },
    })
  }

  updateFormAgenda = values => {
    this.setState({
      meetingForm: {
        ...this.state.meetingForm,
        agendaDtos: values,
      },
    })
  }

  updateFormDoc = values => {
    this.setState({
      meetingForm: {
        ...this.state.meetingForm,
        documentDtos: values,
      },
    })
  }

  updateFormContact = values => {
    this.setState({
      meetingForm: {
        ...this.state.meetingForm,
        contactDtos: values,
      },
    })
  }
  updateFormCustomer = values => {
    this.setState({
      meetingForm: {
        ...this.state.meetingForm,
        customerDtos: values,
      },
    })
  }
  updateFormAudit = values => {
    this.setState({
      meetingForm: {
        ...this.state.meetingForm,
        auditDto: values,
      },
    })
  }
  updateFormMeeting = () => {
    const error = this.props.error
    this.setState({
      isFinish: true,
    })
    const { onUpdateMeeting } = this.props
    onUpdateMeeting(this.state.meetingForm)
    if (error !== "") {
      this.showToast("success", "Амжилттай хадгалагдлаа.", "Амжилттай")
      setTimeout(() => {
        this.props.history.push("/company/meeting")
      }, 1000)
    } else {
      this.showToast("error", error, "Алдаа гарлаа")
    }
  }
  render() {
    const { meeting, agendas, customer, documents } = this.props
    console.log(agendas)
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Хурал засах | Цахим хурал</title>
          </MetaTags>
          <Container fluid={true}>
            <Breadcrumbs title="Компани" breadcrumbItem="Хурал засах" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">Хурлын мэдээлэл</h4>
                    <div className="wizard clearfix">
                      <div className="steps clearfix">
                        <ul>
                          <NavItem
                            className={classnames({
                              current: this.state.activeTab === 1,
                            })}
                          >
                            <NavLink
                              className={classnames({
                                active: this.state.activeTab === 1,
                              })}
                              onClick={() => {
                                this.toggleTab(1)
                              }}
                            >
                              <span className="number">1.</span> Ерөнхий
                              мэдээлэл
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({
                              current: this.state.activeTab === 2,
                            })}
                          >
                            <NavLink
                              disabled={
                                !(this.state.passedSteps || []).includes(2)
                              }
                              className={classnames({
                                active: this.state.activeTab === 2,
                              })}
                              onClick={() => {
                                this.toggleTab(2)
                              }}
                            >
                              <span className="number">2.</span>{" "}
                              <span>Хэлэлцэх асуудал</span>
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({
                              current: this.state.activeTab === 3,
                            })}
                          >
                            <NavLink
                              disabled={
                                !(this.state.passedSteps || []).includes(3)
                              }
                              className={
                                (classnames({
                                  active: this.state.activeTab === 3,
                                }),
                                "done")
                              }
                              onClick={() => {
                                this.toggleTab(3)
                              }}
                            >
                              <span className="number">3.</span> Хурлын оролцогч
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({
                              current: this.state.activeTab === 4,
                            })}
                          >
                            <NavLink
                              disabled={
                                !(this.state.passedSteps || []).includes(4)
                              }
                              className={
                                (classnames({
                                  active: this.state.activeTab === 4,
                                }),
                                "done")
                              }
                              onClick={() => {
                                this.toggleTab(4)
                              }}
                            >
                              <span className="number">4.</span> Баримт бичиг
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({
                              current: this.state.activeTab === 5,
                            })}
                          >
                            <NavLink
                              disabled={
                                !(this.state.passedSteps || []).includes(5)
                              }
                              className={
                                (classnames({
                                  active: this.state.activeTab === 5,
                                }),
                                "done")
                              }
                              onClick={() => {
                                this.toggleTab(5)
                              }}
                            >
                              <span className="number">5.</span> Хурлын хянагч
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({
                              current: this.state.activeTab === 6,
                            })}
                          >
                            <NavLink
                              disabled={
                                !(this.state.passedSteps || []).includes(6)
                              }
                              className={
                                (classnames({
                                  active: this.state.activeTab === 6,
                                }),
                                "done")
                              }
                              onClick={() => {
                                this.toggleTab(6)
                              }}
                            >
                              <span className="number">6.</span> Холбоо барих
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({
                              current: this.state.activeTab === 7,
                            })}
                          >
                            <NavLink
                              disabled={
                                !(this.state.passedSteps || []).includes(7)
                              }
                              className={
                                (classnames({
                                  active: this.state.activeTab === 7,
                                }),
                                "done")
                              }
                              onClick={() => {
                                this.toggleTab(7)
                              }}
                            >
                              <span className="number">7.</span> Хурлын мэдээлэл
                            </NavLink>
                          </NavItem>
                        </ul>
                      </div>
                      <div className="content clearfix">
                        <TabContent
                          activeTab={this.state.activeTab}
                          className="body"
                        >
                          <TabPane tabId={1}>
                            <MeetingInformation
                              meeting={meeting}
                              dataFormat={meeting ? meeting.startTime : ""}
                              meetingId={this.state.meetingId}
                              saveForm={this.updateFormData}
                              toggle={this.toggleTab.bind(this)}
                            />
                          </TabPane>
                          <TabPane tabId={2}>
                            <MeetingAgenda
                              infoData={this.state.meetingForm}
                              agendas={agendas}
                              token={this.state.token}
                              votes={this.state.votes}
                              saveForm={this.updateFormAgenda}
                              isButton={true}
                              toggle={this.toggleTab.bind(this)}
                            />
                          </TabPane>
                          <TabPane tabId={3}>
                            <MeetingCustomer
                              customer={meeting ? meeting.customerDtos : []}
                              saveForm={this.updateFormCustomer}
                              toggle={this.toggleTab.bind(this)}
                              documents={[]}
                            />
                          </TabPane>
                          <TabPane tabId={4}>
                            <MeetingDocument
                              documents={documents}
                              saveForm={this.updateFormDoc}
                              toggle={this.toggleTab.bind(this)}
                            />
                          </TabPane>
                          <TabPane tabId={5}>
                            <MeetingAudit
                              audits={meeting ? meeting.auditDto : []}
                              saveForm={this.updateFormAudit}
                              toggle={this.toggleTab.bind(this)}
                            />
                          </TabPane>
                          <TabPane tabId={6}>
                            <MeetingContact
                              contacts={meeting ? meeting.contactDtos : []}
                              saveForm={this.updateFormContact}
                              toggle={this.toggleTab.bind(this)}
                            />
                          </TabPane>
                          <TabPane tabId={7}>
                            <MeetingUserDetail
                              formData={this.state.meetingForm}
                              saveForm={this.updateFormMeeting}
                              toggle={this.toggleTab.bind(this)}
                            />
                          </TabPane>
                        </TabContent>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
CompanyMeetingUpdate.propTypes = {
  onUpdateMeeting: PropTypes.func,
  meeting: PropTypes.object,
  documents: PropTypes.array,
  agendas: PropTypes.array,
  customer: PropTypes.array,
  error: PropTypes.any,
  onGetMeeting: PropTypes.func,
  onGetMeetingAgenda: PropTypes.func,
  onGetMeetingCustomer: PropTypes.func,
  onGetMeetingDocument: PropTypes.func,
  onGetMeetingAudit: PropTypes.func,
  history: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    error: state.CompanyMeet.error,
    meeting: state.CompanyMeet.meeting,
    agendas: state.CompanyMeet.agendas,
    documents: state.CompanyMeet.documents,
    customer: state.CompanyShareRed.customer,
  }
}

const mapDispatchToProps = dispatch => ({
  onUpdateMeeting: meeting => dispatch(updateComMeeting(meeting)),
  onGetMeeting: (token, page, totalPage, history) =>
    dispatch(getComMeetingDtl(token, page, totalPage, history)),
  onGetMeetingAgenda: (token, meetingId, history) =>
    dispatch(getComMeetingAgenda(token, meetingId, history)),
  onGetMeetingDocument: (token, meetingId, history) =>
    dispatch(getComMeetingDocument(token, meetingId, history)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyMeetingUpdate)
