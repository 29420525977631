import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_MEETINGS,
  GET_MEETING_DTL,
  GET_MEETING_AGENDA,
  GET_MEETING_DOCUMENT,
  GET_MEETING_RESULT,
  START_VOTE,
  SAVE_VOTE,
  SET_ATTENDANCE,
} from "./actionTypes"
import {
  getMeetingsSuccess,
  getMeetingsFail,
  getMeetingDtlSuccess,
  getMeetingDtlFail,
  getMeetingAgendaSuccess,
  getMeetingAgendaFail,
  getMeetingDocumentSuccess,
  getMeetingDocumentFail,
  getMeetingResultSuccess,
  getMeetingResultFail,
  startVoteSuccess,
  startVoteFail,
  saveVoteSuccess,
  saveVoteFail,
  setAttendanceSuccess,
  setAttendanceFail
} from "./actions"

//Include Both Helper File with needed methods
import { postJson, isUserAuthenticated } from "helpers/fakebackend_helper"

function* fetchMeetings({ payload: { request, history } }) {
  if (isUserAuthenticated()) {
    try {
      const response = yield call(
        postJson,
        {
          token: request.token,
          start: request.page,
          length: 9,
        },
        "/Customer/CustomerWebService/get_meeting_list"
      )
      if (response.responseResultType === "SUCCESS") {
        yield put(getMeetingsSuccess(response))
      } else {
        if (response.failureMessages.message[0].failureCode === "ERR_009") {
          history.push("/logout")
        }
        yield put(
          getMeetingsFail(response.failureMessages.message[0].failureMessage)
        )
      }
    } catch (error) {
      yield put(getMeetingsFail(error))
    }
  } else history.push("/logout")
}
function* fetchMeetingDetail({ payload: { request, history } }) {
  if (isUserAuthenticated()) {
    try {
      const response = yield call(
        postJson,
        {
          token: request.token,
          meetingId: request.meetingId,
        },
        "/Customer/CustomerWebService/get_meeting_introduction"
      )
      if (response.responseResultType === "SUCCESS") {
        yield put(getMeetingDtlSuccess(response))
      } else {
        if (response.failureMessages.message[0].failureCode === "ERR_009") {
          history.push("/logout")
        }
        yield put(
          getMeetingsFail(response.failureMessages.message[0].failureMessage)
        )
      }
    } catch (error) {
      yield put(getMeetingDtlFail(error))
    }
  } else history.push("/logout")
}

function* fetchMeetingAgenda({ payload }) {
  // if (isUserAuthenticated()) {
    try {
      const response = yield call(
        postJson,
        {
          token: payload.token,
          meetingId: payload.meetingId,
        },
        "/Customer/CustomerWebService/get_meeting_detail_agenda"
      )
      yield put(getMeetingAgendaSuccess(response.agendaDtos))
    } catch (error) {
      yield put(getMeetingAgendaFail(error))
    }
  // } else history.push("/logout")
}
function* fetchMeetingDocument({ payload }) {
  // if (isUserAuthenticated()) {
    try {
      const response = yield call(
        postJson,
        {
          token: payload.token,
          meetingId: payload.meetingId,
        },
        "/Customer/CustomerWebService/get_meeting_detail_documents"
      )
      yield put(getMeetingDocumentSuccess(response.documentDtos))
    } catch (error) {
      yield put(getMeetingDocumentFail(error))
    }
  // } else history.push("/logout")
}
function* fetchMeetingResult({ payload }) {
  // if (isUserAuthenticated()) {
    try {
      const response = yield call(
        postJson,
        {
          token: payload.token,
          meetingId: payload.meetingId,
        },
        "/Customer/CustomerWebService/get_meeting_detail_result"
      )
      yield put(getMeetingResultSuccess(response))
    } catch (error) {
      yield put(getMeetingResultFail(error))
    }
  // } else history.push("/logout")
}

function* fetchStartVote({ payload }) {
  // if (isUserAuthenticated()) {
    try {
      const response = yield call(
        postJson,
        {
          token: payload.token,
          agendaId: payload.agendaId,
          loginIp: 1
        },
        "/Customer/CustomerWebService/start_vote"
      )
      if (response.responseResultType === "SUCCESS") {
        yield put(startVoteSuccess(response))
      } else {
        if (response.failureMessages.message[0].failureCode === "ERR_009") {
          history.push("/logout")
        }
        yield put(
          startVoteFail(response.failureMessages.message[0].failureMessage)
        )
      }
    } catch (error) {
      yield put(startVoteFail(error))
    }
  // } else history.push("/logout")
}

function* fetchSaveVote({ payload: vote }) {
  if (isUserAuthenticated()) {
    try {
      const response = yield call(
        postJson, vote,
        "/Customer/CustomerWebService/save_customer_vote"
      )
      if (response.responseResultType === "SUCCESS") {
        yield put(saveVoteSuccess(response))
      } else {
        if (response.failureMessages.message[0].failureCode === "ERR_009") {
          // history.push("/logout")
        }
        yield put(
          saveVoteFail(response.failureMessages.message[0].failureMessage)
        )
      }
    } catch (error) {
      yield put(saveVoteFail(error))
    }
  } else history.push("/logout")
}
function* fetchSetAttendance({ payload }) {
  if (isUserAuthenticated()) {
    try {
      const response = yield call(
        postJson, {
        token: payload.token,
        meetingId: payload.meetingId,
      },
        "/Customer/CustomerWebService/get_meeted_customer_registering"
      )
      if (response.responseResultType === "SUCCESS") {
        yield put(setAttendanceSuccess(response))
      } else {
        if (response.failureMessages.message[0].failureCode === "ERR_009") {
          // history.push("/logout")
        }
        yield put(
          setAttendanceFail(response.failureMessages.message[0].failureMessage)
        )
      }
    } catch (error) {
      yield put(setAttendanceFail(error))
    }
  } else history.push("/logout")
}

function* customerMeetingSaga() {
  yield takeEvery(GET_MEETINGS, fetchMeetings)
  yield takeEvery(GET_MEETING_DTL, fetchMeetingDetail)
  yield takeEvery(GET_MEETING_AGENDA, fetchMeetingAgenda)
  yield takeEvery(GET_MEETING_DOCUMENT, fetchMeetingDocument)
  yield takeEvery(GET_MEETING_RESULT, fetchMeetingResult)
  yield takeEvery(START_VOTE, fetchStartVote)
  yield takeEvery(SAVE_VOTE, fetchSaveVote)
  yield takeEvery(SET_ATTENDANCE, fetchSetAttendance)
}

export default customerMeetingSaga
