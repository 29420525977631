import {
    API_SUCCESS,
    API_FAIL,
    GET_CHARTS_DATA,
    SAVE_MARK_NTF,
    SAVE_MARK_NTF_SUCCESS,
    SAVE_MARK_NTF_FAIL
} from "./actionTypes";

export const apiSuccess = (actionType, data) => ({
    type: API_SUCCESS,
    payload: { actionType, data },
});

export const apiFail = (actionType, error) => ({
    type: API_FAIL,
    payload: { actionType, error },
});

// charts data
export const getChartsData = (token, role, history) => ({
    type: GET_CHARTS_DATA,
    payload: { token, role, history }
});

/*--------------------------------------------------*/

export const saveMarkNtf = form => ({
    type: SAVE_MARK_NTF,
    payload: form,
})

export const saveMarkNtfSuccess = response => ({
    type: SAVE_MARK_NTF_SUCCESS,
})

export const saveMarkNtfFail = error => ({
    type: SAVE_MARK_NTF_FAIL,
    payload: error,
})

/*--------------------------------------------------*/

