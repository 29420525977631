/* CUSTOMER MEETING */
export const GET_AUDIT_MEETINGS = "GET_AUDIT_MEETINGS"
export const GET_AUDIT_MEETINGS_SUCCESS = "GET_AUDIT_MEETINGS_SUCCESS"
export const GET_AUDIT_MEETINGS_FAIL = "GET_AUDIT_MEETINGS_FAIL"

export const GET_AUDIT_MEETING_DTL = "GET_AUDIT_MEETING_DTL"
export const GET_AUDIT_MEETING_DTL_SUCCESS = "GET_AUDIT_MEETING_DTL_SUCCESS"
export const GET_AUDIT_MEETING_DTL_FAIL = "GET_AUDIT_MEETING_DTL_FAIL"

export const GET_AUDIT_MEETING_AGENDA = "GET_AUDIT_MEETING_AGENDA"
export const GET_AUDIT_MEETING_AGENDA_SUCCESS =
  "GET_AUDIT_MEETING_AGENDA_SUCCESS"
export const GET_AUDIT_MEETING_AGENDA_FAIL = "GET_AUDIT_MEETING_AGENDA_FAIL"

export const GET_AUDIT_MEETING_DOCUMENT = "GET_AUDIT_MEETING_DOCUMENT"
export const GET_AUDIT_MEETING_DOCUMENT_SUCCESS =
  "GET_AUDIT_MEETING_DOCUMENT_SUCCESS"
export const GET_AUDIT_MEETING_DOCUMENT_FAIL = "GET_AUDIT_MEETING_DOCUMENT_FAIL"

export const GET_AUDIT_MEETING_RESULT = "GET_AUDIT_MEETING_RESULT"
export const GET_AUDIT_MEETING_RESULT_SUCCESS =
  "GET_AUDIT_MEETING_RESULT_SUCCESS"
export const GET_AUDIT_MEETING_RESULT_FAIL = "GET_AUDIT_MEETING_RESULT_FAIL"

export const START_AUDIT_VOTE = "START_AUDIT_VOTE"
export const START_AUDIT_VOTE_SUCCESS = "START_AUDIT_VOTE_SUCCESS"
export const START_AUDIT_VOTE_FAIL = "START_AUDIT_VOTE_FAIL"

export const SAVE_AUDIT_VOTE = "SAVE_AUDIT_VOTE"
export const SAVE_AUDIT_VOTE_SUCCESS = "SAVE_AUDIT_VOTE_SUCCESS"
export const SAVE_AUDIT_VOTE_FAIL = "SAVE_AUDIT_VOTE_FAIL"

export const SET_AUDIT_ATTENDANCE = "SET_AUDIT_ATTENDANCE"
export const SET_AUDIT_ATTENDANCE_SUCCESS = "SET_AUDIT_ATTENDANCE_SUCCESS"
export const SET_AUDIT_ATTENDANCE_FAIL = "SET_AUDIT_ATTENDANCE_FAIL"
