import { call, put, takeEvery } from "redux-saga/effects"

/*  Meeting Redux States  */
import {
  GET_COM_MEETINGS,
  ADD_COM_NEW_MEETING,
  DELETE_COM_MEETING,
  UPDATE_COM_MEETING,
  GET_COM_MEETING_AGENDA,
  ADD_COM_NEW_AGENDA,
  UPDATE_COM_AGENDA,
  DELETE_COM_AGENDA,
  GET_COM_AGENDA_TYPE,
  GET_COM_VOTE_TYPE,
  GET_COM_METHOD_TYPE,
  GET_COM_MEETING_TYPE,
  GET_COM_MEETING_DOCUMENT_TYPE,
  GET_COM_MEETING_DTL,
  GET_COM_MEETING_DOCUMENT,
  GET_COM_MEETING_RESULT,
  SET_COM_MEETING_START,
  SET_COM_VOTE_START,
  SET_COM_VOTE_FINISH,
  SET_COM_MEETING_FINISH,
  GET_MEETING_CUSTOMER,
} from "./actionTypes"
import {
  getComMeetingsSuccess,
  getComMeetingsFail,
  addComMeetingSuccess,
  addComMeetingFail,
  updateComMeetingSuccess,
  updateComMeetingFail,
  deleteComMeetingSuccess,
  deleteComMeetingFail,
  getComAgendaTypeSuccess,
  getComAgendaTypeFail,
  getComVoteTypeSuccess,
  getComVoteTypeFail,
  getComMethodTypeSuccess,
  getComMethodTypeFail,
  getComMeetingTypeSuccess,
  getComMeetingTypeFail,
  getComMeetingDocumentTypeSuccess,
  getComMeetingDocumentTypeFail,
  getComMeetingDtlSuccess,
  getComMeetingDtlFail,
  getComMeetingAgendaSuccess,
  getComMeetingAgendaFail,
  addComAgendaSuccess,
  addComAgendaFail,
  updateComAgendaSuccess,
  updateComAgendaFail,
  deleteComAgendaSuccess,
  deleteComAgendaFail,
  getComMeetingDocumentSuccess,
  getComMeetingDocumentFail,
  getComMeetingResultSuccess,
  getComMeetingResultFail,
  setComMeetingStartSuccess,
  setComMeetingStartFail,
  setComVoteStartSuccess,
  setComVoteStartFail,
  setComVoteFinishSuccess,
  setComVoteFinishFail,
  setComMeetingFinishSuccess,
  setComMeetingFinishFail,
  getMeetingCustomerSuccess,
  getMeetingCustomerFail,
} from "./actions"

import {
  postJson,
  postUrlEncode,
  isUserAuthenticated,
} from "helpers/fakebackend_helper"
/* Meetings   */
function* fetchMeetings({ payload: { request, history } }) {
  if (isUserAuthenticated()) {
    try {
      const response = yield call(
        postJson,
        {
          token: request.token,
          start: request.page,
          length: 9,
        },
        "/Admin/CompanyWebService/get_meeting_list_company"
      )
      if (response.responseResultType === "SUCCESS") {
        yield put(getComMeetingsSuccess(response))
      } else {
        if (response.failureMessages.message[0].failureCode === "ERR_009") {
          history.push("/logout")
        }
        yield put(
          getComMeetingsFail(response.failureMessages.message[0].failureMessage)
        )
      }
    } catch (error) {
      yield put(getComMeetingsFail(error))
    }
  } else history.push("/logout")
}
/* AddNewMeeting   */
function* onAddNewMeeting({ payload: { meeting, history } }) {
  if (isUserAuthenticated()) {
    try {
      const response = yield call(
        postJson,
        meeting,
        "/Admin/CompanyWebService/create_meeting"
      )
      if (response.responseResultType === "SUCCESS") {
        yield put(addComMeetingSuccess(response))
      } else {
        if (response.failureMessages.message[0].failureCode === "ERR_009") {
          history.push("/logout")
        } else {
          yield put(
            addComMeetingFail(
              response.failureMessages.message[0].failureMessage
            )
          )
        }
      }
    } catch (error) {
      yield put(addComMeetingFail(error))
    }
  } else history.push("/logout")
}
/* UpdateMeeting   */
function* onUpdateMeeting({ payload: meeting }) {
  if (isUserAuthenticated()) {
    try {
      const response = yield call(
        postJson,
        meeting,
        "/Admin/CompanyWebService/update_meeting"
      )
      yield put(updateComMeetingSuccess(response))
    } catch (error) {
      yield put(updateComMeetingFail(error))
    }
  } else history.push("/logout")
}
/* DeleteMeeting   */
function* onDeleteMeeting({ payload: meeting }) {
  if (isUserAuthenticated()) {
    try {
      const response = yield call(
        postJson,
        meeting,
        "/Admin/CompanyWebService/remove_meeting"
      )
      yield put(deleteComMeetingSuccess(response))
    } catch (error) {
      yield put(deleteComMeetingFail(error))
    }
  } else history.push("/logout")
}

/* AgendaType   */
function* fetchAgendaType({ payload }) {
  try {
    const response = yield call(
      postUrlEncode,
      {
        token: payload.token,
        name: "RefMeetingAgendaType",
      },
      "/Admin/ReferenceWebService/get_data"
    )
    yield put(getComAgendaTypeSuccess(response.datas))
  } catch (error) {
    yield put(getComAgendaTypeFail(error))
  }
}
/*   MeetingAgenda  */
function* fetchMeetingAgenda({ payload: { request, history } }) {
  if (isUserAuthenticated()) {
    try {
      const response = yield call(
        postJson,
        {
          token: request.token,
          meetingId: request.meetingId,
        },
        "/Admin/CompanyWebService/get_meeting_detail_agenda"
      )
      if (response.responseResultType === "SUCCESS") {
        yield put(getComMeetingAgendaSuccess(response.agendaDtos))
      } else {
        if (response.failureMessages.message[0].failureCode === "ERR_009") {
          history.push("/logout")
        } else {
          yield put(
            getComMeetingAgendaFail(
              response.failureMessages.message[0]
                ? response.failureMessages.message[0].failureMessage
                : "Алдаа"
            )
          )
        }
      }
    } catch (error) {
      yield put(getComMeetingAgendaFail(error))
    }
  } else history.push("/logout")
}
/* AddNewAgenda   */
function* onAddNewAgenda({ payload: { agenda, history } }) {
  console.log(agenda)
  if (isUserAuthenticated()) {
    try {
      const response = yield call(
        postJson,
        agenda,
        "/Admin/CompanyWebService/create_agenda"
      )
      if (response.responseResultType === "SUCCESS") {
        yield put(addComAgendaSuccess(response))
      } else {
        if (response.failureMessages.message[0].failureCode === "ERR_009") {
          history.push("/logout")
        } else {
          yield put(
            addComMeetingFail(
              response.failureMessages.message[0].failureMessage
            )
          )
        }
      }
    } catch (error) {
      yield put(addComAgendaFail(error))
    }
  } else history.push("/logout")
}
/* UpdateAgenda   */
function* onUpdateAgenda({ payload: agenda }) {
  if (isUserAuthenticated()) {
    try {
      const response = yield call(
        postJson,
        agenda,
        "/Admin/CompanyWebService/update_agenda"
      )
      yield put(updateComAgendaSuccess(response))
    } catch (error) {
      yield put(updateComAgendaFail(error))
    }
  } else history.push("/logout")
}
/* DeleteAgenda   */
function* onDeleteAgenda({ payload: agenda }) {
  if (isUserAuthenticated()) {
    try {
      const response = yield call(
        postJson,
        agenda,
        "/Admin/CompanyWebService/remove_meeting"
      )
      yield put(deleteComAgendaSuccess(response))
    } catch (error) {
      yield put(deleteComAgendaFail(error))
    }
  } else history.push("/logout")
}
/*  VoteType  */
function* fetchVoteType({ payload }) {
  try {
    const response = yield call(
      postUrlEncode,
      {
        token: payload.token,
        name: "RefVoteType",
      },
      "/Admin/ReferenceWebService/get_data"
    )
    yield put(getComVoteTypeSuccess(response.datas))
  } catch (error) {
    yield put(getComVoteTypeFail(error))
  }
}
/* MethodType   */
function* fetchMethodType({ payload }) {
  try {
    const response = yield call(
      postUrlEncode,
      {
        token: payload.token,
        name: "RefVoteMethod",
      },
      "/Admin/ReferenceWebService/get_data"
    )
    yield put(getComMethodTypeSuccess(response.datas))
  } catch (error) {
    yield put(getComMethodTypeFail(error))
  }
}
/*  MeetingType  */
function* fetchMeetingType({ payload }) {
  try {
    const response = yield call(
      postUrlEncode,
      {
        token: payload.token,
        name: "RefMeetingType",
      },
      "/Admin/ReferenceWebService/get_data"
    )
    yield put(getComMeetingTypeSuccess(response.datas))
  } catch (error) {
    yield put(getComMeetingTypeFail(error))
  }
}
/* MeetingDocumentType   */
function* fetchMeetingDocumentType({ payload }) {
  try {
    const response = yield call(
      postUrlEncode,
      {
        token: payload.token,
        name: "RefDocumentType",
      },
      "/Admin/ReferenceWebService/get_data_value"
    )
    yield put(getComMeetingDocumentTypeSuccess(response.datas))
  } catch (error) {
    yield put(getComMeetingDocumentTypeFail(error))
  }
}

/*  COMPANY MEETING DTL ****/
function* fetchMeetingDetail({ payload: { request, history } }) {
  if (isUserAuthenticated()) {
    try {
      const response = yield call(
        postJson,
        {
          token: request.token,
          meetingId: request.meetingId,
        },
        "/Admin/CompanyWebService/get_meeting_introduction"
      )
      if (response.responseResultType === "SUCCESS") {
        yield put(getComMeetingDtlSuccess(response))
      } else {
        if (response.failureMessages.message[0].failureCode === "ERR_009") {
          history.push("/logout")
        }
        yield put(
          getComMeetingsFail(response.failureMessages.message[0].failureMessage)
        )
      }
    } catch (error) {
      yield put(getComMeetingDtlFail(error))
    }
  } else history.push("/logout")
}
/*  MeetingDocument */
function* fetchMeetingDocument({ payload: { request, history } }) {
  if (isUserAuthenticated()) {
    try {
      const response = yield call(
        postJson,
        {
          token: request.token,
          meetingId: request.meetingId,
        },
        "/Admin/CompanyWebService/get_meeting_detail_documents"
      )
      yield put(getComMeetingDocumentSuccess(response.documentDtos))
    } catch (error) {
      yield put(getComMeetingDocumentFail(error))
    }
  } else history.push("/logout")
}
/* MeetingResult */
function* fetchMeetingResult({ payload: { request, history } }) {
  if (isUserAuthenticated()) {
    try {
      const response = yield call(
        postJson,
        {
          token: request.token,
          meetingId: request.meetingId,
        },
        "/Admin/CompanyWebService/get_meeting_detail_result"
      )
      yield put(getComMeetingResultSuccess(response))
    } catch (error) {
      yield put(getComMeetingResultFail(error))
    }
  } else history.push("/logout")
}
/* MeetingStart */
function* fetchSetMeetingStart({ payload }) {
  if (isUserAuthenticated()) {
    try {
      const response = yield call(
        postJson,
        {
          token: payload.token,
          meetingId: payload.meetingId,
        },
        "/Admin/CompanyWebService/start_meeting"
      )
      if (response.responseResultType === "SUCCESS") {
        yield put(setComMeetingStartSuccess(response))
      } else {
        if (response.failureMessages.message[0].failureCode === "ERR_009") {
          history.push("/logout")
        }
        yield put(
          setComMeetingStartFail(
            response.failureMessages.message[0].failureMessage
          )
        )
      }
    } catch (error) {
      yield put(setComMeetingStartFail(error))
    }
  } else history.push("/logout")
}
/* MeetingFinish */
function* fetchSetMeetingFinish({ payload }) {
  if (isUserAuthenticated()) {
    try {
      const response = yield call(
        postJson,
        {
          token: payload.token,
          meetingId: payload.meetingId,
        },
        "/Admin/CompanyWebService/finish_meeting"
      )
      if (response.responseResultType === "SUCCESS") {
        yield put(setComMeetingFinishSuccess(response))
      } else {
        if (response.failureMessages.message[0].failureCode === "ERR_009") {
          history.push("/logout")
        }

        yield put(
          setComMeetingFinishFail(
            response.failureMessages.message[0].failureMessage
          )
        )
      }
    } catch (error) {
      yield put(setComMeetingFinishFail(error))
    }
  } else history.push("/logout")
}
/* VoteStart */
function* fetchSetVoteStart({ payload }) {
  if (isUserAuthenticated()) {
    try {
      const response = yield call(
        postJson,
        {
          token: payload.token,
          agendaId: payload.agendaId,
        },
        "/Admin/CompanyWebService/start_agenda"
      )
      if (response.responseResultType === "SUCCESS") {
        yield put(setComVoteStartSuccess(response))
      } else {
        if (response.failureMessages.message[0].failureCode === "ERR_009") {
          history.push("/logout")
        }
        yield put(
          setComVoteStartFail(
            response.failureMessages.message[0].failureMessage
          )
        )
      }
    } catch (error) {
      yield put(setComVoteStartFail(error))
    }
  } else history.push("/logout")
}
/* VoteFinish */
function* fetchSetVoteFinish({ payload }) {
  if (isUserAuthenticated()) {
    try {
      const response = yield call(
        postJson,
        {
          token: payload.token,
          agendaId: payload.agendaId,
        },
        "/Admin/CompanyWebService/finish_agenda"
      )
      if (response.responseResultType === "SUCCESS") {
        yield put(setComVoteFinishSuccess(response))
      } else {
        if (response.failureMessages.message[0].failureCode === "ERR_009") {
          history.push("/logout")
        }
        console.log(response.failureMessages.message[0].failureMessage)
        yield put(
          setComVoteFinishFail(
            response.failureMessages.message[0].failureMessage
          )
        )
      }
    } catch (error) {
      yield put(setComVoteFinishFail(error))
    }
  } else history.push("/logout")
}
/*CUSTOMER*/
function* fetchCustomers({ payload: { request, history } }) {
  if (isUserAuthenticated()) {
    try {
      const response = yield call(
        postJson,
        {
          token: request.token,
          start: request.page,
          meetingId: request.meetingId,
          length: 9,
        },
        "/Admin/CompanyWebService/get_meeting_detail_customers"
      )
      if (response.responseResultType === "SUCCESS") {
        yield put(getMeetingCustomerSuccess(response))
      } else {
        if (response.failureMessages.message[0].failureCode === "ERR_009") {
          history.push("/logout")
        }
        yield put(
          getMeetingCustomerFail(
            response.failureMessages.message[0].failureMessage
          )
        )
      }
    } catch (error) {
      yield put(getMeetingCustomerFail(error))
    }
  } else history.push("/logout")
}
/* -----------------------------CompanyMeetingSaga-------------------------------------------*/
function* companyMeetingSaga() {
  yield takeEvery(GET_COM_MEETINGS, fetchMeetings)
  yield takeEvery(ADD_COM_NEW_MEETING, onAddNewMeeting)
  yield takeEvery(DELETE_COM_MEETING, onDeleteMeeting)
  yield takeEvery(UPDATE_COM_MEETING, onUpdateMeeting)
  yield takeEvery(GET_COM_MEETING_DTL, fetchMeetingDetail)
  yield takeEvery(GET_COM_MEETING_AGENDA, fetchMeetingAgenda)
  yield takeEvery(GET_COM_AGENDA_TYPE, fetchAgendaType)
  yield takeEvery(ADD_COM_NEW_AGENDA, onAddNewAgenda)
  yield takeEvery(UPDATE_COM_AGENDA, onUpdateAgenda)
  yield takeEvery(DELETE_COM_AGENDA, onDeleteAgenda)
  yield takeEvery(GET_COM_MEETING_DOCUMENT, fetchMeetingDocument)
  yield takeEvery(GET_COM_MEETING_RESULT, fetchMeetingResult)
  yield takeEvery(GET_COM_VOTE_TYPE, fetchVoteType)
  yield takeEvery(GET_COM_METHOD_TYPE, fetchMethodType)
  yield takeEvery(GET_COM_MEETING_TYPE, fetchMeetingType)
  yield takeEvery(GET_COM_MEETING_DOCUMENT_TYPE, fetchMeetingDocumentType)
  yield takeEvery(SET_COM_MEETING_START, fetchSetMeetingStart)
  yield takeEvery(SET_COM_MEETING_FINISH, fetchSetMeetingFinish)
  yield takeEvery(SET_COM_VOTE_START, fetchSetVoteStart)
  yield takeEvery(SET_COM_VOTE_FINISH, fetchSetVoteFinish)
  yield takeEvery(GET_MEETING_CUSTOMER, fetchCustomers)
}

export default companyMeetingSaga
