import React, { Component } from "react"
import { Link } from "react-router-dom"
import * as moment from "moment"
import {
  Row,
  Col,
  Badge,
  Button,
  Table,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap"
import { map } from "lodash"
import PropTypes from "prop-types"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { startVote, saveVote } from "store/actions"
import ChoiseResult from "./choise-result"

class MeetingResultt extends Component {
  constructor(props) {
    super(props)
    this.state = {
      token: "",
      results: [],
    }
  }

  render() {
    const { results } = this.props
    return (
      <React.Fragment>
        <div className="mt-4">
          <div className="table-responsive">
            <Table className="project-list-table align-middle table-borderless">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Хэлэлцэх асуудал</th>
                  <th scope="col">Төрөл</th>
                  <th scope="col">Санал авах арга</th>
                  <th scope="col">Огноо</th>
                  <th scope="col" colSpan="3">
                    Саналын үр дүн
                  </th>
                  <th scope="col">Тайлбар</th>
                </tr>
              </thead>
              <tbody>
                {results
                  ? map(results.voteResult, (agenda, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td style={{ width: "400px" }}>
                          {agenda.agendaType}
                          <div className="p text-muted mb-0">
                            {agenda.content}
                          </div>
                        </td>
                        <td>{agenda.voteType}</td>
                        <td>{agenda.voteMethod}</td>
                        <td>{agenda.voteDate}</td>
                        {agenda.agendaTypeId == 1 ? (
                          <>
                            <td>
                              Зөвшөөрсөн
                              <div className="form-check font-size-12">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id={agenda.displayIndex}
                                  checked={agenda.isAccept}
                                  readOnly="readonly"
                                  value={agenda.isAccept}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={agenda.displayIndex}
                                >
                                  / {agenda.acceptCount}
                                </label>
                              </div>
                            </td>
                            <td>
                              Түтгэлзсэн
                              <div className="form-check font-size-12">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id={agenda.displayIndex}
                                  checked={agenda.isSuspend}
                                  readOnly="readonly"
                                  value={agenda.isSuspend}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={agenda.displayIndex}
                                >
                                  / {agenda.suspendCount}
                                </label>
                              </div>
                            </td>
                            <td>
                              Татгалзсан
                              <div className="form-check font-size-12">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id={agenda.displayIndex}
                                  checked={agenda.isReject}
                                  readOnly="readonly"
                                  value={agenda.isReject}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={agenda.displayIndex}
                                >
                                  / {agenda.rejectCount}
                                </label>
                              </div>
                            </td>
                          </>
                        ) : (
                          <>
                            <ChoiseResult
                              choiseList={agenda.choiseList}
                              key={"choiseList" + agenda.displayIndex}
                            />
                          </>
                        )}
                        <td>{agenda.description}</td>
                      </tr>
                    ))
                  : ""}
              </tbody>
            </Table>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

MeetingResultt.propTypes = {
  results: PropTypes.any,
}

export default MeetingResultt

// const mapStateToProps = state => {
//   return {
//     votes: state.CustomerMeetings.votes,
//   }
// }

// const mapDispatchToProps = dispatch => ({
//   onStartVote: (token, agendaId) => dispatch(startVote(token, agendaId)),
//   onSaveVote: vote => dispatch(saveVote(vote)),
// })

// export default connect(mapStateToProps, mapDispatchToProps)(MeetingResultt)
