import {
  GET_AUDIT_COMPANYS_SUCCESS,
  GET_AUDIT_COMPANYS_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  page: 0,
  length: 9,
  totalPage: 0,
  draw: 1,
  companys: [],
  error: {},
}

const AuditCompany = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_AUDIT_COMPANYS_SUCCESS:
      return {
        ...state,
        companys: action.companys,
        totalPage: action.totalPage,
      }

    case GET_AUDIT_COMPANYS_FAIL:
      return {
        ...state,
        error: action.error,
      }

    default:
      return state
  }
}

export default AuditCompany
