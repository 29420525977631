import {
  GET_AUDIT_COMPANYS,
  GET_AUDIT_COMPANYS_SUCCESS,
  GET_AUDIT_COMPANYS_FAIL,
} from "./actionTypes"

export const getAuditCompanys = (token, page, totalPage) => ({
  type: GET_AUDIT_COMPANYS,
  payload: { token, page, totalPage },
})

export const getAuditCompanysSuccess = response => ({
  type: GET_AUDIT_COMPANYS_SUCCESS,
  companys: response.data,
  totalPage: response.recordsTotal,
})

export const getAuditCompanysFail = error => ({
  type: GET_AUDIT_COMPANYS_FAIL,
  payload: error,
})
