import {
  GET_CUSTOMER_COMPANYS,
  GET_CUSTOMER_COMPANYS_FAIL,
  GET_CUSTOMER_COMPANYS_SUCCESS,
  GET_CUSTOMER_COMPANY_DTL,
  GET_CUSTOMER_COMPANY_DTL_FAIL,
  GET_CUSTOMER_COMPANY_DTL_SUCCESS,
  GET_CUSTOMER_COMPANY_AGENDA,
  GET_CUSTOMER_COMPANY_AGENDA_SUCCESS,
  GET_CUSTOMER_COMPANY_AGENDA_FAIL,
  GET_CUSTOMER_COMPANY_DOCUMENT,
  GET_CUSTOMER_COMPANY_DOCUMENT_SUCCESS,
  GET_CUSTOMER_COMPANY_DOCUMENT_FAIL,

} from "./actionTypes"

export const getCustomerCompanys = (token, page, totalPage, history) => ({
  type: GET_CUSTOMER_COMPANYS,
  payload: { request: { token, page, totalPage }, history },
})

export const getCustomerCompanysSuccess = response => ({
  type: GET_CUSTOMER_COMPANYS_SUCCESS,
  companys: response.data,
  totalPage: response.recordsTotal
})

export const getCustomerCompanysFail = error => ({
  type: GET_CUSTOMER_COMPANYS_FAIL,
  payload: error,
})
/*--------------------------------------------------*/
export const getCustomerCompanyDtl = (token, meetingId, history) => ({
  type: GET_CUSTOMER_COMPANY_DTL,
  payload: { request: { token, meetingId }, history },
})

export const getCustomerCompanyDtlSuccess = response => ({
  type: GET_CUSTOMER_COMPANY_DTL_SUCCESS,
  meeting: response
})

export const getCustomerCompanyDtlFail = error => ({
  type: GET_CUSTOMER_COMPANY_DTL_FAIL,
  payload: error,
})
/*--------------------------------------------------*/
export const getCustomerCompanyAgenda = (token, meetingId) => ({
  type: GET_CUSTOMER_COMPANY_AGENDA,
  payload: { token, meetingId },
})

export const getCustomerCompanyAgendaSuccess = response => ({
  type: GET_CUSTOMER_COMPANY_AGENDA_SUCCESS,
  agenda: response
})

export const getCustomerCompanyAgendaFail = error => ({
  type: GET_CUSTOMER_COMPANY_AGENDA_FAIL,
  payload: error,
})
/*--------------------------------------------------*/
export const getCustomerCompanyDocument = (token, meetingId) => ({
  type: GET_CUSTOMER_COMPANY_DOCUMENT,
  payload: { token, meetingId },
})

export const getCustomerCompanyDocumentSuccess = response => ({
  type: GET_CUSTOMER_COMPANY_DOCUMENT_SUCCESS,
  document: response
})

export const getCustomerCompanyDocumentFail = error => ({
  type: GET_CUSTOMER_COMPANY_DOCUMENT_FAIL,
  payload: error,
})
/*--------------------------------------------------*/
