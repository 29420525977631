import React, { Component } from "react"
import PropTypes from "prop-types"
import {
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  Row,
  Button,
  FormGroup,
} from "reactstrap"

import { AvForm, AvField } from "availity-reactstrap-validation"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { map, get } from "lodash"
import { getComMeetingType } from "store/actions"
import "../CompanyMeetingCreate/style.css"
import * as moment from "moment"
//const now = new Date().toJSON().split("T")[0]

class MeetingInformation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      token: "",
      meetingTypeName: [],
      meetings: [],
      meetingId: "",
      registrationDate: "",
      declareDate: "",
      meetingDate: "",
      startTime: "",
      endTime: "",
    }
  }
  componentDidMount() {
    const { onGetMeetingType } = this.props
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      onGetMeetingType(obj.token)
      this.setState({ token: obj.token })
    }
  }

  handleMeetingTypeChange = idx => evt => {
    const meetingTypeName = this.props.meetingType.filter(
      (c, cidx) => evt.target.value == c.id
    )

    this.setState({ meetingTypeName })
  }
  handleDeclareDateChange = idx => evt => {
    if (typeof evt.target.value != "undefined") {
      if (moment(evt.target.value, "DD-MM-YYYY").isValid()) {
        const declareDate = evt.target.value
        this.setState({ declareDate: declareDate })
      }
    }
    console.log(this.state.declareDate)
  }
  handleMeetingDateChange = idx => evt => {
    if (typeof evt.target.value != "undefined") {
      if (moment(evt.target.value, "DD-MM-YYYY").isValid()) {
        const meetingDate = evt.target.value
        this.setState({ meetingDate: meetingDate })
      }
    }
    console.log(this.state.meetingDate)
  }
  handleRegistrationDateChange = idx => evt => {
    if (typeof evt.target.value != "undefined") {
      if (moment(evt.target.value, "DD-MM-YYYY").isValid()) {
        const registrationDate = evt.target.value
        this.setState({ registrationDate: registrationDate })
      }
    }
  }
  handleStartTimeChange = idx => evt => {
    const startTime = evt.target.value
    this.setState({ startTime: startTime })
  }
  handleEndTimeChange = idx => evt => {
    const endTime = evt.target.value
    this.setState({ endTime: endTime })
  }

  nextPage = () => {
    this.props.toggle(2)
  }

  handleValidInformationSubmit = (e, values) => {
    const item = {
      token: this.state.token,
      meetingId: this.state.meetingId,
      meetingTypeId: values["meetingTypeId"],
      meetingTypeName: this.state.meetingTypeName,
      meetingName: values["meetingName"],
      meetingDate: values["meetingDate"],
      declareDate: values["declareDate"],
      startTime: values["meetingDate"] + " " + values["startTime"] + ":00",
      endTime: values["meetingDate"] + " " + values["endTime"] + ":00",
      registrationDate: values["registrationDate"],
      address: values["address"],
      postAddress: "",
      membersCount: values["membersCount"],
    }
    this.setState({ meetings: values })
    this.props.saveForm(item)
    this.nextPage()
  }
  render() {
    const { meetingType, meeting } = this.props
    // console.log(this.state.declareDate)
    return (
      <React.Fragment>
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <AvForm
                  className="repeater mt-3"
                  encType="multipart/form-data"
                  onValidSubmit={this.handleValidInformationSubmit}
                >
                  <Row>
                    <Col lg="6">
                      <AvField
                        name="meetingName"
                        label="Хурлын нэр"
                        type="text"
                        errorMessage="Талбар хоосон байна!!"
                        validate={{
                          required: { value: true },
                        }}
                        value={meeting ? meeting.meetingName : ""}
                      />
                    </Col>
                    <Col lg="6">
                      <AvField
                        id="meetingTypeId"
                        name="meetingTypeId"
                        label="Хурлын төрөл"
                        type="select"
                        errorMessage="Төрөл сонгоно уу"
                        onChange={this.handleMeetingTypeChange()}
                        validate={{
                          required: { value: true },
                        }}
                        className="form-select"
                        value={meeting ? meeting.meetingTypeId : ""}
                      >
                        <option value="">-Сонгох-</option>
                        {map(meetingType, (type, key) => (
                          <option key={"option" + key} value={type.id}>
                            {type.name}
                          </option>
                        ))}
                      </AvField>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6">
                      <AvField
                        name="declareDate"
                        htmlFor="declareDate"
                        label=" Хурлын зарласан огноо:"
                        type="date"
                        errorMessage="Он сар өдрөө оруулна уу!"
                        onChange={this.handleDeclareDateChange()}
                        validate={{
                          required: { value: true },
                          max: {
                            value: new Date().toJSON().split("T")[0],
                            errorMessage: "Огноо буруу байна.",
                          },
                        }}
                        value={meeting ? meeting.declareDate : ""}
                      />
                    </Col>
                    <Col lg="6">
                      <AvField
                        name="meetingDate"
                        htmlFor="meetingDate"
                        label="Хурлын огноо:"
                        type="date"
                        errorMessage="Он сар өдрөө оруулна уу!"
                        onChange={this.handleMeetingDateChange()}
                        validate={{
                          required: { value: true },
                          min: {
                            value: meeting
                              ? this.state.declareDate != ""
                                ? this.state.declareDate
                                : meeting.declareDate
                              : new Date(
                                  this.state.declareDate != ""
                                    ? this.state.declareDate
                                    : new Date()
                                )
                                  .toJSON()
                                  .split("T")[0],
                            errorMessage:
                              "Хурлын зарласан огнооноос хойш огноо оруулна уу!",
                          },
                        }}
                        value={meeting ? meeting.meetingDate : ""}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <AvField
                        name="registrationDate"
                        htmlFor="registrationDate"
                        label="Бүртгэлийн огноо:"
                        type="date"
                        errorMessage="Он сар өдрөө оруулна уу!"
                        onChange={this.handleRegistrationDateChange()}
                        validate={{
                          required: { value: true },
                          min: {
                            value: meeting
                              ? this.state.declareDate != ""
                                ? this.state.declareDate
                                : meeting.declareDate
                              : new Date(
                                  this.state.declareDate != ""
                                    ? this.state.declareDate
                                    : new Date()
                                )
                                  .toJSON()
                                  .split("T")[0],
                            errorMessage:
                              "Хурлын зарласан огнооноос хойш огноо оруулна уу!",
                          },
                          max: {
                            value: meeting
                              ? this.state.meetingDate != ""
                                ? this.state.meetingDate
                                : meeting.meetingDate
                              : new Date(
                                  this.state.meetingDate != ""
                                    ? this.state.meetingDate
                                    : new Date()
                                )
                                  .toJSON()
                                  .split("T")[0],
                            errorMessage:
                              "Хурлын огнооноос өмнөх огноо оруулна уу! ",
                          },
                        }}
                        value={meeting ? meeting.registrationDate : ""}
                      />
                    </Col>
                    <Col lg="6">
                      <AvField
                        name="membersCount"
                        htmlFor="membersCount"
                        label="Гишүүдийн тоо"
                        type="number"
                        errorMessage="Талбар хоосон байна!!"
                        validate={{
                          required: { value: true },
                        }}
                        value={meeting ? meeting.membersCount : ""}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <AvField
                        name="startTime"
                        htmlFor="startTime"
                        label=" Хурал эхлэх цаг:"
                        type="time"
                        errorMessage="Цагаа оруулна уу!"
                        onChange={this.handleStartTimeChange()}
                        validate={{
                          required: { value: true },
                        }}
                        value={meeting ? meeting.startTime.split(" ")[1] : ""}
                      />
                    </Col>
                    <Col lg="6">
                      <AvField
                        name="endTime"
                        htmlFor="endTime"
                        label="  Хурал дуусах цаг:"
                        type="time"
                        errorMessage="Цагаа оруулна уу!"
                        onChange={this.handleEndTimeChange()}
                        validate={{
                          required: { value: true },
                          // min: {
                          //   value: new Date(this.state.startTime)
                          //     .toJSON()
                          //     .split(" ")[1],
                          //   errorMessage:
                          //     "Хурлын эхлэх цагаас хойш цаг оруулна уу!",
                          // },
                        }}
                        value={meeting ? meeting.endTime.split(" ")[1] : ""}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12">
                      <AvField
                        name="address"
                        label="Хурал зохион байгуулагдах газар:"
                        type="textarea"
                        errorMessage="Талбар хоосон байна!!"
                        validate={{
                          required: { value: true },
                        }}
                        value={meeting ? meeting.address : ""}
                      />
                    </Col>
                  </Row>
                  <div className="customButton clearfix mt-3">
                    <Button
                      color="primary"
                      type="submit"
                      // onClick={this.nextPage}
                      className="mt-3 mt-lg-0"
                    >
                      Дараах
                    </Button>
                  </div>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}
MeetingInformation.propTypes = {
  meeting: PropTypes.object,
  meetingId: PropTypes.string,
  meetingType: PropTypes.array,
  onGetMeetingType: PropTypes.func,
  saveForm: PropTypes.func,
}

const mapStateToProps = state => {
  return {
    meetingType: state.CompanyMeet.meetingType,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetMeetingType: token => dispatch(getComMeetingType(token)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MeetingInformation))
