import PropTypes from 'prop-types';
import React, { Component } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap";

// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/1.jpg";
// actions
import { changePassword, resetProfileFlag } from "../../store/actions";

class UserProfile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      role: "",
      name: "",
      idx: 1,
      firstname: "",
      lastname: "",
      company: "",
      companyType: "",
      logo: ""
    }

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this)
  }

  // handleValidSubmit

  componentDidMount() {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      this.setState({
        name: obj.firstname, email: obj.email, idx: obj.uid,
        firstname: obj.firstname,
        lastname: obj.lastname,
        company: obj.company,
        companyType: obj.companyType,
        logo: obj.logo,
        token: obj.token
      })
    }
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, ss) {
    if (this.props !== prevProps) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      this.setState({
        name: obj.firstname, email: obj.email, idx: obj.uid,
        firstname: obj.firstname,
        lastname: obj.lastname,
        company: obj.company,
        companyType: obj.companyType,
        logo: obj.logo
      })
      setTimeout(() => {
        this.props.resetProfileFlag();
      }, 3000);
    }
  }
  handleValidSubmit(event, values) {
    console.log(values)
    
    this.props.changePassword(this.state.token, values.oldPassword, values.password, this.props.history)
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumb title="Дашбоард" breadcrumbItem="Бүртгэлийн мэдээлэл" />

            <Row>
              <Col lg="8">
                <Card>
                  <CardBody>
                    <div className="d-flex">
                      <div className="me-3">
                        <img
                          src={this.state.logo ? this.state.logo : avatar}
                          alt=""
                          className="avatar-md rounded-circle img-thumbnail"
                        />
                      </div>
                      <div className="align-self-center flex-1">
                        <div className="text-muted">
                          <p className="mb-1">Овог нэр: {this.state.lastname} {this.state.firstname}</p>
                          <p className="mb-0">Байгууллагын нэр: {this.state.company} {this.state.companyType}</p>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="4">
                {this.props.error && this.props.error ? (
                  <Alert color="danger">{this.props.error}</Alert>
                ) : null}
                {this.props.success && this.props.success ? (
                  <Alert color="success">{this.props.success}</Alert>
                ) : null}
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">Нууц үг солих</h4>
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        this.handleValidSubmit(e, v)
                      }}
                    >
                      <div className="form-group">
                        <AvField
                          name="oldPassword"
                          label="Нууц үг"
                          type="password"
                          errorMessage="Хуучин нууц үг оруулна уу"
                          className="form-control"
                          validate={{
                            required: { value: true },
                          }}
                        />
                        <span className="text-info">Нууц үг нь 8-20 урттай багадаа нэг тоо, нэг үсэг, нэг тэмдэгт (@$!%*#?&) агуулсан байна.</span>
                        <AvField
                          name="password"
                          label="Шинэ нууц үг"
                          type="password"
                          errorMessage="Нууц үг оруулна уу"
                          className="form-control"
                          validate={{
                            required: { value: true },
                            pattern: {
                              value: "^(?=.*[A-Za-z])(?=.*[0-9])(?=.*[@$!%*#?&])[A-Za-z0-9@$!%*#?&]{8,20}$",
                              errorMessage: "Нууц үг нь 8-20 урттай багадаа нэг тоо, нэг үсэг, нэг тэмдэгт (@$!%*#?&) агуулсан байна.",
                            },
                          }}
                        />

                        <AvField
                          name="passwordConfirm"
                          label="Шинэ нууц үг давтах"
                          type="password"
                          className="form-control"
                          errorMessage="Нууц үг давтан оруулна уу"
                          validate={{
                            required: { value: true },
                            match: {
                              value: "password",
                              errorMessage:
                                "Нууц үг таарахгүй байна.",
                            },
                            pattern: {
                              value: "^(?=.*[A-Za-z])(?=.*[0-9])(?=.*[@$!%*#?&])[A-Za-z0-9@$!%*#?&]{8,20}$",
                              errorMessage: "Нууц үг нь 8-20 урттай багадаа нэг тоо, нэг үсэг, нэг тэмдэгт (@$!%*#?&) агуулсан байна.",
                            },
                          }}
                        />
                      </div>
                      <div className="text-center mt-4">
                        <Button type="submit" color="success">
                          Нууц үг солих
                        </Button>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>

              </Col>
            </Row>

          </Container>
        </div>
      </React.Fragment >
    )
  }
}

UserProfile.propTypes = {
  changePassword: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
  history: PropTypes.object,
}

const mapStateToProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}
const mapDispatchToProps = dispatch => ({
  resetProfileFlag: () =>
    dispatch(resetProfileFlag()),
  changePassword: (token, oldPassword, password, history) => dispatch(changePassword(token, oldPassword, password, history)),
})
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserProfile)
)
