import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import dashboardSaga from "./dashboard/saga"
import customerMeetingSaga from "./customer-meeting/saga"
import customerCompanySaga from "./customer-company/saga"
import memberSaga from "./company-member/saga"
import adminCompanySaga from "./admin-company/saga"
import adminAuditSaga from "./admin-audit/saga"
import sharesSaga from "./company-share/saga"
import companyMeetingSaga from "./company-meeting/saga"
import auditCompanySaga from "./audit-company/saga"
import auditMeetingSaga from "./audit-meeting/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(dashboardSaga),
    fork(customerMeetingSaga),
    fork(customerCompanySaga),
    fork(memberSaga),
    fork(adminCompanySaga),
    fork(adminAuditSaga),
    fork(sharesSaga),
    fork(companyMeetingSaga),
    fork(auditCompanySaga),
    fork(auditMeetingSaga),
  ])
}
